import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CountToModule } from "angular-count-to";

import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { NgApexchartsModule } from "ng-apexcharts";
import {
    NgbDropdownModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbCollapseModule,
    NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { AgmCoreModule } from "@agm/core";

// Routings
import { SharedModule } from "../shared/shared.module";
import { PagesRoutingModule } from "./pages-routing.modules";

// Modules
import { SettingsModule } from "./settings/settings.module";

import { WidgetModule } from "../shared/widget/widget.module";

// Components
import { DashboardsComponent } from "./dashboard/dashboards/dashboards.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Select2Module } from "ng-select2-component";
import { ChargerManagementModule } from "./charger-management/charger-management.module";
import { BillingManagementModule } from "./billing-management/billing-management.module";
import { NotificationManagementModule } from "./notification-management/notification-management.module";

// Pipes

@NgModule({
    declarations: [DashboardsComponent],
    imports: [
        CommonModule,
        WidgetModule,
        PagesRoutingModule,
        ScrollToModule.forRoot(),
        NgApexchartsModule,
        NgbDropdownModule,
        NgbAccordionModule,
        NgbNavModule,
        NgbProgressbarModule,
        NgbCollapseModule,
        NgbTooltipModule,
        NgbPopoverModule,
        CountToModule,
        SettingsModule,
        ChargerManagementModule,
        BillingManagementModule,
        NotificationManagementModule,
        SharedModule,
        CommonModule,
        SharedModule,
        NgxDatatableModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        Select2Module,
        AgmCoreModule.forRoot({
            apiKey: "AIzaSyDxGQind-VczlXIb7iMh0R3NcaaICSUcTE",
        }),
    ],
})
export class PagesModule {}
