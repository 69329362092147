import { Injectable } from "@angular/core";

@Injectable()
export class AppUtilities {
    // BASE_URL = "https://staging-api.tapgoev.com"; //Sandbox details
    BASE_URL = "https://dev-api.tapgoev.com";
    BACKEND_API_URL = this.BASE_URL + "/api/v1";
    FRONTEND_BASE_URL = "https://staging-app.tapgoev.com"

    constructor() {}

    public getAppDefaultCountry(): string {
        return 'Canada';
    }

    public getAppDefaultCountryCode(): string {
        return 'CA';
    }

    public getBaseUrl(): string {
        return this.BASE_URL;
    }
    
    public getBackEndApiUrl(): string {
        return this.BACKEND_API_URL;
    }

    public getUserManagementUrl(): string {
        return this.BASE_URL + "/user-management/v1";
    }

    public getBillingManagementUrl(): string {
        return this.BASE_URL + "/billing-management/v1";
    }

    public getChargingStationManagementUrl(): string {
        return this.BASE_URL + "/charging-station-management/v1";
    }

    public getCSMSApiUrl(): string {
        return 'https://staging-api.tapgoev.com/csms-standalone/api/v1';
    }

    public getLocalBMApiUrl(): string {
        return 'http://localhost:8082/api/v1';
    }

    public getFrontendBaseUrl(): string {
        return this.FRONTEND_BASE_URL;
    }

    public getCurrentUTCDateTimePlusMinutes(diff: number): string {
        const now = new Date();
        const future = new Date(now.getTime() + diff*60000);
        const year = future.getUTCFullYear();
        const month = future.getUTCMonth() + 1;
        const day = future.getUTCDate();
        const hours = future.getUTCHours();
        const minutes = future.getUTCMinutes();
        const seconds = future.getUTCSeconds();
        const milliseconds = future.getUTCMilliseconds();

        return (`${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`);
    }

    public isJSON(str: string): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    public prettyJSON(str: string): string {
        if (this.isJSON(str)) {
            let obj = JSON.parse(str);
            return JSON.stringify(obj, null, 4);
        } else {
            return str;
        }
    }

    public isEmpty(str: any) {
        return (!str || str.length === 0 );
    }

    public compare = (v1: string | number | boolean, v2: string | number | boolean) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

    /**
     * Sort the table data
     * @param Table field value
     * @param column Fetch the column
     * @param direction Sort direction Ascending or Descending
     */
    public sort(tables: any[], column: any, direction: string): any[] {
        if (direction === '' || column === '') {
            return tables;
        } else {
            return [...tables].sort((a, b) => {
                const res = this.compare(a[column], b[column]);
                return direction === 'asc' ? res : -res;
            });
        }
    }
}
