<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="chargingStationIdentifier" class="fw-semibold">Station</label>
            <input type="text" class="form-control" id="chargingStationIdentifier" name="chargingStationIdentifier" 
                required readonly [(ngModel)]="newAction.chargingStationIdentifier" #chargingStationIdentifier="ngModel">
            <span style="color:red" [hidden]="chargingStationIdentifier.valid || chargingStationIdentifier.pristine"> 
                <p>Station Identifier is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="location" class="fw-semibold">Location</label>
            <input type="text" class="form-control" id="location" name="location" 
                required [(ngModel)]="newAction.location" #location="ngModel">
            <span style="color:red" [hidden]="location.valid || location.pristine"> 
                <p>Location is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="retries" class="fw-semibold">Retries</label>
            <input type="number" class="form-control" id="retries" name="retries" 
                required [(ngModel)]="newAction.retries" #retries="ngModel" min="1">
            <span style="color:red" [hidden]="retries.valid || retries.pristine"> 
                <p>Retries is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="retryInterval" class="fw-semibold">Retry Interval</label>
            <input type="number" class="form-control" id="retryInterval" name="retryInterval" 
                required [(ngModel)]="newAction.retryInterval" #retryInterval="ngModel" min="1">
            <span style="color:red" [hidden]="retryInterval.valid || retryInterval.pristine"> 
                <p>Retry Interval is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="start" class="fw-semibold">Start</label>
            <input class="form-control flatpickr-input" type="text" mwlFlatpickr [enableTime]="true"
            dateFormat="Y-m-dTH:i" id="start" name="start" required [(ngModel)]="newAction.start" #start="ngModel">
            <span style="color:red" [hidden]="start.valid || start.pristine"> 
                <p>Start date is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="stop" class="fw-semibold">Stop</label>
            <input class="form-control flatpickr-input" type="text" mwlFlatpickr [enableTime]="true"
            dateFormat="Y-m-dTH:i" id="stop" name="stop" required [(ngModel)]="newAction.stop" #stop="ngModel">
            <span style="color:red" [hidden]="stop.valid || stop.pristine"> 
                <p>Stop date is required</p> 
            </span>
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">Submit</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>