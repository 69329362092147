import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationTemplatesComponent } from './notification-templates/notification-templates.component';
import { PushNotificationsComponent } from './push-notifications/push-notifications.component';
import { SmsTemplatesComponent } from './sms-templates/sms-templates.component';
import { SmsNotificationsComponent } from './sms-notifications/sms-notifications.component';
import { EmailTemplatesComponent } from './email-templates/email-templates.component';

const routes: Routes = [
  {
    path: 'email-templates',
    component: EmailTemplatesComponent
  },
  {
    path: 'notification-templates',
    component: NotificationTemplatesComponent
  },
  {
    path: 'push-notifications',
    component: PushNotificationsComponent
  },
  {
    path: 'sms-templates',
    component: SmsTemplatesComponent
  },
  {
    path: 'sms-notifications',
    component: SmsNotificationsComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationManagementRoutingModule { }
