import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { Organization, PagedOrganization } from '../models/organization.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';
import { GenericFilter } from '../models/generic-filter.models';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getUserManagementUrl();
  }

  /**
     * Get all organizations
     */
  getOrganizations(params: GenericFilter): Observable<PagedOrganization> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedOrganization>(`${this.backendApiUrl}/organizations?${queryString}`, this.httpOptions).pipe(
      tap((pagedOrganization: PagedOrganization) => {
        this.log(`Retrieved organizations`);
      })
    );
  }

  /**
     * Get organization
     */
  getOrganization(params: string): Observable<Organization> {
    return this.http.get<Organization>(`${this.backendApiUrl}/organizations/${params}`, this.httpOptions).pipe(
      tap((organization: Organization) => {
        this.log(`Retrieved organization`);
      })
    );
  }

  /**
     * Get all organizations
     */
  getOrganizationsByType(params: any): Observable<PagedOrganization> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedOrganization>(`${this.backendApiUrl}/organizations/organization-type?${queryString}`, this.httpOptions).pipe(
      tap((pagedOrganization: PagedOrganization) => {
        this.log(`Retrieved organizations`);
      })
    );
  }

  /**
     * Add organization
     */
  addOrganization(params: any): Observable<GenericResponse> {
    console.log('request : ', params);

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/organizations`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added organization`);
      })
    );
  }

  /**
     * Update organization
     */
  updateOrganization(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/organizations/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated organization`);
      })
    );
  }

  /** Log a OrganizationService message */
  private log(message: string) {
    console.log(`OrganizationService: ${message}`);
  }
}
