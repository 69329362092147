<div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">{{ 'PAGES.DASHBOARD.LIST.NETWORKMAP' | translate }}</h4>
      <div *ngIf="apiLoaded">
        <google-map width="100%" height="512px" [options]="options">
            @for (marker of markers; track marker) {
                <map-advanced-marker
                    #markerElem="mapAdvancedMarker"
                    [position]="marker.position"
                    [content]="marker.content"
                    [title]="marker.title"
                    (mapClick)="onMarkerClick(markerElem)"
                />
            }
            <map-info-window #infoWindow></map-info-window>
        </google-map>
      </div>
    </div>
  </div>