export interface SmsNotification {
  id: number;
  recipientName: string;
  notificationType: string;
  message: string;
  recipientUuid: string;
  msgId: string;
  uuid: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  deleted: boolean;
  deletedAt: string;
}

export interface PagedSmsNotification {
  content: SmsNotification[];
  first: boolean;
  last: boolean;
  empty: boolean;
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
  size: number;
  number: number;
}