import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Charger, GetDiagnostics } from 'src/app/core/models/charger.models';
import { ChargerService } from 'src/app/core/services/charger.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-get-diagnostics-modal',
  templateUrl: './get-diagnostics-modal.component.html',
  styleUrls: ['./get-diagnostics-modal.component.scss']
})
export class GetDiagnosticsModalComponent implements OnInit {

  @Input() public charger!: Charger;
  @Input() public isFromList!: boolean;
  @Input() public connectorIdentifier!: number;

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "PAGES.CHARGERS.LIST.GETDIAGNOSTICS";

  constructor(
    public modal: NgbActiveModal, 
    private chargerService: ChargerService,
    public sweetalertService: SweetalertService) { }
    public newAction!: {
      chargingStationIdentifier: string, location: string, retries: number, retryInterval: number, start: string, stop: string
    }

  ngOnInit(): void {
    this.newAction = {
      chargingStationIdentifier: this.charger.chargingStationIdentifier,
      location: '',
      retries: 0,
      retryInterval: 0,
      start: '',
      stop: ''
    }
  }

  
  // Handle Create button click
  onSubmit() {
    let getDiagnostics: GetDiagnostics = {
      chargingStationSelectList: [
        {
          chargingStationIdentifier: this.newAction.chargingStationIdentifier,
          endpointAddress: 'Get Diagnostics',
          ocppTransport: "JSON"
        }
      ],
      location: this.newAction.location,
      retries: this.newAction.retries,
      retryInterval: this.newAction.retryInterval,
      start: this.newAction.start,
      stop: this.newAction.stop
    }

    this.processing = true;
    this.chargerService.getDiagnostics(getDiagnostics).subscribe({
      next: (v) => {
        // console.log('getDiagnostics request submitted : ', v);
        this.sweetalertService.success(v.message).then(() => {
          this.modal.close(v);
        });
      },
      error: (e) => {
        // console.error('error occured : ', e);
        this.sweetalertService.failure(e).then(() => {
          this.processing = false;
        });
      },
      complete: () => {
        // console.info('getDiagnostics completed');
      }
    });
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }

}
