import { DecimalPipe } from '@angular/common';
import { Component, OnInit, PipeTransform, QueryList, ViewChildren } from '@angular/core';
import { Role } from 'src/app/core/models/role.models';
import { RoleService } from 'src/app/core/services/role.service';
import { RoleModalComponent } from './role-modal/role-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Table Data Match with Search input
 * @param  Role field value fetch
 * @param term Search the value
 */
function matches(table: Role, term: string, pipe: PipeTransform) {
  return pipe.transform(table.id).includes(term) || table.name.toLowerCase().includes(term.toLowerCase())
      || table.description.toLowerCase().includes(term)
      || table.uuid.toLowerCase().includes(term)
      || table.createdAt.toLowerCase().includes(term)
      || table.updatedAt.toLowerCase().includes(term);
}

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
  providers: [DecimalPipe]
})
export class RolesComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  searchTerm!: string;
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  role!: Role;
  roles!: Role[];
  allRoles!: Role[];

  constructor(private roleService: RoleService, private pipe: DecimalPipe, public modalService: NgbModal) { }

  ngOnInit(): void {
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Settings' },
      { label: 'Roles', active: true }
    ];

    this.fetchData();
  }

  fetchData() {
    this.roleService.getRoles().subscribe({
      next: (v) => {
        console.log('returned roles : ', v);
        this.collectionSize = v.length;
        this.roles = v;
        this.allRoles = this.roles;
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('roles retrieved');
      }
    });
  }

  search(value: string): void {
    this.roles = this.allRoles.filter(table => matches(table, value, this.pipe));

    // this.roles = this.allRoles.filter((val) => val.name.toLowerCase().includes(value));
    this.collectionSize = this.roles.length;
  }

  openModal(role: any, isAddNew: boolean) {
    const modalRef = this.modalService.open(RoleModalComponent);
    modalRef.componentInstance.role = role;
    modalRef.componentInstance.isAddNew = isAddNew;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  //refresh page
  refreshPage(): void {
    window.location.reload();
  }
}
