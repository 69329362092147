import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Address } from "./core/models/address.models";
import { Site } from "./core/models/site.models";

@Injectable()
export class AppUtilities {
    // BASE_URL = "https://staging-api.tapgoev.com"; //Sandbox details
    BASE_URL = "https://dev-api.tapgoev.com";
    BACKEND_API_URL = this.BASE_URL + "/api/v1";
    FRONTEND_BASE_URL = "https://staging-app.tapgoev.com";
    appConfig: {
        apiURL: string,
        frontendURL: string,
        adminURL: string,
        supportPhoneNumber: string,
        qrCodeAppURL: string,
        qrCodeMethodURL: string,
        qrCodeMemberURL : string,
        qrCodeTutorialURL : string,
        programName: string,
        googleMapsApiKey: string,
        googleMapId: string
    };

    constructor() {
        this.appConfig = environment.appConfig;
    }

    public getAppDefaultCountry(): string {
        return "Canada";
    }

    public getAppDefaultCountryCode(): string {
        return "CA";
    }

    public getBaseUrl(): string {
        return this.appConfig.apiURL;
    }

    public getBackEndApiUrl(): string {
        return this.BACKEND_API_URL;
    }

    public getUserManagementUrl(): string {
        return this.appConfig.apiURL + "/user-management/v1";
    }

    public getBillingManagementUrl(): string {
        return this.appConfig.apiURL + "/billing-management/v1";
    }

    public getChargingStationManagementUrl(): string {
        return this.appConfig.apiURL + "/charging-station-management/v1";
    }

    public getCSMSApiUrl(): string {
        return "https://staging-api.tapgoev.com/csms-standalone/api/v1";
    }

    public getLocalBMApiUrl(): string {
        return "http://localhost:8082/api/v1";
    }

    public getFrontendBaseUrl(): string {
        return this.appConfig.frontendURL;
    }

    public getGoogleMapsApikey(): string {
        return this.appConfig.googleMapsApiKey
    }

    public getAddressString(address: Address): string {
        const { addressLine1, addressLine2, city, region, postalCode } = address;
        return `${addressLine1}${addressLine2 ? " " + addressLine2 : ""}, ${city}, ${region} ${postalCode}`;
    }

    public getConnectorCount(site?: Site) {
        if (!site || !site.chargingStations) {
            return { totalConnectors: 0, availableConnectors: 0 };
        }
    
        return site.chargingStations.reduce((counts, charger) => {
            charger.connectors.forEach(connector => {
                counts.totalConnectors++;
                if (connector.chargePointStatus === 'Available') {
                    counts.availableConnectors++;
                }
            });
            return counts;
        }, { totalConnectors: 0, availableConnectors: 0 });
    }

    public getCurrentUTCDateTimePlusMinutes(diff: number): string {
        const now = new Date();
        const future = new Date(now.getTime() + diff * 60000);
        const year = future.getUTCFullYear();
        const month = future.getUTCMonth() + 1;
        const day = future.getUTCDate();
        const hours = future.getUTCHours();
        const minutes = future.getUTCMinutes();
        const seconds = future.getUTCSeconds();
        const milliseconds = future.getUTCMilliseconds();

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    }

    public isJSON(str: string): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    public prettyJSON(str: string): string {
        if (this.isJSON(str)) {
            let obj = JSON.parse(str);
            return JSON.stringify(obj, null, 4);
        } else {
            return str;
        }
    }

    public isEmpty(str: any) {
        return !str || str.length === 0;
    }

    public compare = (
        v1: string | number | boolean,
        v2: string | number | boolean
    ) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

    /**
     * Sort the table data
     * @param Table field value
     * @param column Fetch the column
     * @param direction Sort direction Ascending or Descending
     */
    public sort(tables: any[], column: any, direction: string): any[] {
        if (direction === "" || column === "") {
            return tables;
        } else {
            return [...tables].sort((a, b) => {
                const res = this.compare(a[column], b[column]);
                return direction === "asc" ? res : -res;
            });
        }
    }

    public arrayBufferToBase64(buffer: Uint8Array): string {
        let binary = "";
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return "data:image/png;base64," + window.btoa(binary);
    }
}
