import { Injectable } from '@angular/core';
import { AppUtilities } from 'src/app/app-utilities';

interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [
  { name: 'pdfMake', src: '//cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.59/pdfmake.min.js' },
  { name: 'vfsFonts', src: '//cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.59/vfs_fonts.js' },
  { name: 'PayWithConverge', src: '//demo.convergepay.com/hosted-payments/PayWithConverge.js'},
  { name: 'GoogleMap', src: '//maps.googleapis.com/maps/api/js?key='}
];

@Injectable({
  providedIn: 'root'
})
export class ScriptsService {

  private scripts: any = {};

  constructor(private appUtilities: AppUtilities) {

    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  isScriptLoaded(scriptName: string): boolean {
    return this.scripts[scriptName]?.loaded || false;
  }
  
  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // Check if Google Maps API script is already included
        if (name === 'GoogleMap' && document.querySelectorAll('script[src*="maps.googleapis.com"]').length > 0) {
          resolve({ script: name, loaded: true, status: 'Already Loaded' });
        } else {
          const script = document.createElement('script');
          script.type = 'text/javascript';
  
          if (name === 'GoogleMap') {
            script.src = this.scripts[name].src + this.appUtilities.getGoogleMapsApikey() + '&libraries=places,marker';
          } else {
            script.src = this.scripts[name].src;
          }
  
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
          script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
          document.getElementsByTagName('head')[0].appendChild(script);
        }
      }
    });
  }
}
