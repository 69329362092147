import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit, PipeTransform } from '@angular/core';
import { Charger, ClearChargingProfile, ConnectorProfile } from 'src/app/core/models/charger.models';
import { ChargingProfile } from 'src/app/core/models/charging-profile.models';
import { ChargerService } from 'src/app/core/services/charger.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


/**
 * Table Data Match with Search input
 * @param  ChargingProfile field value fetch
 * @param term Search the value
 */
function matches(table: ChargingProfile, term: string, pipe: PipeTransform) {
  return pipe.transform(table.id).includes(term) || table.description.toLowerCase().includes(term.toLowerCase())
      || table.chargingProfilePurpose.toLowerCase().includes(term)
      || table.chargingProfileKind.toLowerCase().includes(term)
      || table.recurrencyKind.toLowerCase().includes(term)
      || table.uuid.toLowerCase().includes(term)
      || table.createdAt.toLowerCase().includes(term)
      || table.updatedAt.toLowerCase().includes(term);
}

@Component({
  selector: 'app-charger-profiles',
  templateUrl: './charger-profiles.component.html',
  styleUrls: ['./charger-profiles.component.scss'],
  providers: [DecimalPipe]
})
export class ChargerProfilesComponent implements OnInit {

  @Input() charger!: Charger;

  searchTerm: string = "";
  connectorProfiles: ConnectorProfile[] = [];
  allConnectorProfiles: ConnectorProfile[] = [];
  
  constructor(
    private pipe: DecimalPipe,
    private chargerService: ChargerService,
    public sweetalertService: SweetalertService,
    public modalService: NgbModal) { }
  
    ngOnInit(): void {
      this.fetchData();
    }

  fetchData() {
      this.charger.connectors.forEach(c => {
        this.allConnectorProfiles.push({chargingProfiles: c.chargingProfiles, connectorIdentifier: c.connectorIdentifier} as ConnectorProfile);
      })
      this.connectorProfiles = JSON.parse(JSON.stringify(this.allConnectorProfiles));
  }

  search(connectorId: number, searchTerm: string): void {
    let connector = this.connectorProfiles.find(c => c.connectorIdentifier == connectorId);
    let searchConnector = this.allConnectorProfiles.find(c => c.connectorIdentifier == connectorId);
    if (connector && searchConnector) {
      if (!searchTerm) {
        connector.chargingProfiles = searchConnector.chargingProfiles;
      }
      connector.chargingProfiles = searchConnector.chargingProfiles.filter(table => matches(table, searchTerm, this.pipe));
    }
  }
    
    clearChargingProfile(chargingProfile: ChargingProfile, connector: ConnectorProfile) {
    this.sweetalertService.confirm('Clear Charging Profile?', `Are you sure you want to clear profile "${chargingProfile.description}"?`).then((result) => {
      console.log('result: ', result);
      if (result.isConfirmed) {
        let clearProfile: ClearChargingProfile = {
          chargingProfileId: chargingProfile.id,
          chargingProfilePurpose: chargingProfile.chargingProfilePurpose,
          chargingStationSelectList: [
            {
              chargingStationIdentifier: this.charger.chargingStationIdentifier,
              endpointAddress: 'ClearChargingProfile',
              ocppTransport: "JSON"
            }
          ],
          connectorId: connector.connectorIdentifier,
          filterType: "ChargingProfileId",
          stackLevel: chargingProfile.stackLevel
        }

        this.chargerService.clearChargingProfile(clearProfile).subscribe({
          next: (v) => {
            console.log('clear charging profile request submitted : ', v);
            this.sweetalertService.success(v.message).then(() => {
              
            });
          },
          error: (e) => {
            console.error('error occured : ', e);
            this.sweetalertService.failure(e).then(() => {
            });
          },
          complete: () => {
            console.info('clear charging profile completed');
          }
        });
      }
    });
  }
}
