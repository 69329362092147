import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Site, PagedSite } from 'src/app/core/models/site.models';
import { SiteService } from 'src/app/core/services/site.service';
import { SiteModalComponent } from './site-modal/site-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { CountryService } from 'src/app/core/services/country.service';
import { Country } from 'src/app/core/models/country.models';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { Organization } from 'src/app/core/models/organization.models';
import { OrganizationService } from 'src/app/core/services/organization.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss'],
  providers: [DecimalPipe]
})
export class SitesComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  searchTerm!: string;
  searchTermChanged: Subject<string> = new Subject<string>();
  inputSub: Subscription | undefined;
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  genericFilter: GenericFilter = new GenericFilter('', 0, 10, 'id', 'desc');
  site!: Site;
  sites!: Site[];
  countries!: Country[];
  organizations!: Organization[];
  pagedSites!: PagedSite;
  empty = true;

  constructor(
    private siteService: SiteService, 
    private countryService: CountryService, 
    private organizationService: OrganizationService,
    public modalService: NgbModal) { }

  ngOnInit(): void {
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Charger Management' },
      { label: 'Sites', active: true }
    ];

    this.fetchData();
    this.fetchCountriesData();
    this.fetchOrganizationsData();

    this.inputSub = this.searchTermChanged.pipe(
      debounceTime(500),
      delay(200)
    ).subscribe(value => {
        console.log(value)
        this.genericFilter.searchParam = value;
        this.fetchData();
      });
  }

  fetchData() {
    this.genericFilter.size = this.pageSize;
    this.genericFilter.page = this.page -1;
    this.siteService.getSites(this.genericFilter).subscribe({
      next: (v) => {
        console.log('returned sites : ', v);
        this.pagedSites = v;
        this.collectionSize = this.pagedSites.totalElements;
        if (this.pagedSites.content.length > 0) {
          this.sites = this.pagedSites.content;
          this.empty = false;
        }
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('sites retrieved');
      }
    });
  }

  fetchCountriesData() {
    this.countryService.getCountries().subscribe({
      next: (v) => {
        this.countries = v;
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('countries retrieved');
      }
    });
  }

  fetchOrganizationsData() {
    const organizationType = {
      type: "Site Owner",
      size: 100
    }
    this.organizationService.getOrganizationsByType(organizationType).subscribe({
      next: (v) => {
        this.organizations = v.content;
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('organizations retrieved');
      }
    });
  }

  search(value: string): void {
    this.searchTermChanged.next(value);
  }

  onSizeChange(size: number) {
    this.genericFilter.size = size;
    this.pageSize = size;
    this.page = 1;
    this.fetchData();
  }

  pageChanged(page: any) {
    console.log('page change: ', page);
    this.genericFilter.page = page - 1;
    this.fetchData();
  }

  openModal(site: any, isAddNew: boolean) {
    const modalRef = this.modalService.open(SiteModalComponent, { size: 'xl', windowClass: 'modal-holder', centered: true });
    modalRef.componentInstance.site = site;
    modalRef.componentInstance.isAddNew = isAddNew;
    modalRef.componentInstance.countries = this.countries;
    modalRef.componentInstance.organizations = this.organizations;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.refreshPage();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  //refresh page
  refreshPage(): void {
    window.location.reload();
  }


}
