import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { Configuration, PagedConfiguration } from '../models/configuration.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';
import { GenericFilter } from '../models/generic-filter.models';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getUserManagementUrl();
  }

  /**
     * Get all configurations
     */
  getConfigurations(params: GenericFilter): Observable<PagedConfiguration> {
      let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
      return this.http.get<PagedConfiguration>(`${this.backendApiUrl}/configurations?${queryString}`, this.httpOptions).pipe(
        tap((pagedConfiguration: PagedConfiguration) => {
          this.log(`Retrieved configurations`);
        })
      );
    }

  /**
     * Add configuration
     */
  addConfiguration(params: any): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/configurations`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added configuration`);
      })
    );
  }

  /**
     * Update configuration
     */
  updateConfiguration(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/configurations/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated configuration`);
      })
    );
  }

  /** Log a ConfigurationService message */
  private log(message: string) {
    // console.log(`ConfigurationService: ${message}`);
  }
}
