<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="name" class="fw-semibold">Name</label>
                    <input type="text" class="form-control" id="name" name="name" 
                        required [(ngModel)]="addSite.name" #name="ngModel">
                    <span style="color:red" [hidden]="name.valid || name.pristine"> 
                        <p>Name is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="description" class="fw-semibold">Description</label>
                    <input type="text" class="form-control" id="description" name="description" 
                        required [(ngModel)]="addSite.description" #description="ngModel">
                    <span style="color:red" [hidden]="description.valid || description.pristine"> 
                        <p>Description is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="siteOwnerUuid" class="fw-semibold">Site Owner</label>
                    <select class="form-select" id="siteOwnerUuid" name="siteOwnerUuid" [(ngModel)]="addSite.siteOwnerUuid" #siteOwnerUuid="ngModel" required>
                        <option *ngFor="let o of organizations" [ngValue]="o.uuid">{{ o.name }}</option>
                    </select>
                    <span style="color:red" [hidden]="siteOwnerUuid.valid || siteOwnerUuid.pristine"> 
                        <p>Site Owner is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="phoneNumber" class="fw-semibold">Phone</label>
                    <input type="tel" class="form-control" id="phoneNumber" name="phoneNumber" 
                        required [(ngModel)]="addSite.phoneNumber" #phoneNumber="ngModel">
                    <span style="color:red" [hidden]="phoneNumber.valid || phoneNumber.pristine"> 
                        <p>Phone number is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3" *ngIf="isApiLoaded">
                    <label for="addressLine1" class="fw-semibold">Address Line 1</label>
                    <input type="text" class="form-control" id="addressLine1" name="addressLine1"
                        ngx-google-places-autocomplete
                        [options]="options" (onAddressChange)="handleAddressChange($event)" 
                        required [(ngModel)]="addSite.addressLine1" #addressLine1="ngModel">
                    <span style="color:red" [hidden]="name.valid || name.pristine"> 
                        <p>Address Line 1 is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="addressLine2" class="fw-semibold">Address Line 2</label>
                    <input type="text" class="form-control" id="addressLine2" name="addressLine2" 
                        [(ngModel)]="addSite.addressLine2" #addressLine2="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="city" class="fw-semibold">City</label>
                    <input type="text" class="form-control" id="city" name="city" 
                        required [(ngModel)]="addSite.city" #city="ngModel">
                    <span style="color:red" [hidden]="city.valid || city.pristine"> 
                        <p>City is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="region" class="fw-semibold">Region/State</label>
                    <input type="text" class="form-control" id="region" name="region" 
                        required [(ngModel)]="addSite.region" #region="ngModel">
                    <span style="color:red" [hidden]="region.valid || region.pristine"> 
                        <p>Region/State is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="postalCode" class="fw-semibold">Postal Code</label>
                    <input type="text" class="form-control" id="postalCode" name="postalCode" 
                        required [(ngModel)]="addSite.postalCode" #postalCode="ngModel">
                    <span style="color:red" [hidden]="postalCode.valid || postalCode.pristine"> 
                        <p>Postal Code is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="country" class="fw-semibold">Country</label>
                    <select class="form-select" id="country" name="country" [(ngModel)]="addSite.country" #country="ngModel" required>
                        <option *ngFor="let c of countries">{{c.name}}</option>
                    </select>
                    <span style="color:red" [hidden]="country.valid || country.pristine"> 
                        <p>Country is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="openTime" class="fw-semibold">Open Time</label>
                    <input class="form-control flatpickr-input" type="time" id="openTime" name="openTime" 
                        required [(ngModel)]="addSite.openTime" #openTime="ngModel">
                    <span style="color:red" [hidden]="openTime.valid || openTime.pristine"> 
                        <p>Open Time is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="closeTime" class="fw-semibold">Close Time</label>
                    <input class="form-control flatpickr-input" type="time" id="closeTime" name="closeTime" 
                        required [(ngModel)]="addSite.closeTime" #closeTime="ngModel">
                    <span style="color:red" [hidden]="closeTime.valid || closeTime.pristine"> 
                        <p>Close Time is required</p> 
                    </span>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group mb-3" [hidden]="isAddNew">
                    <label for="enabled" class="fw-semibold">Status</label>
                    <select class="form-select" id="enabled" name="enabled" [(ngModel)]="addSite.enabled" #enabled="ngModel">
                        <option value="true">Enabled</option>
                        <option value="false">Disabled</option>
                    </select>
                </div>
            </div>
        </div>
        
        <div class="row" *ngFor="let rate of addSite.rates; index as i"> 
            <hr class="mt-4 mb-4"/>

            <h5 class="font-size-14 mb-3">Rate #{{ i + 1 }}</h5>

            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">Charger Type</label>
                    <select class="form-select" name="chargerType" [(ngModel)]="rate.chargerType" #chargerType="ngModel" required>
                        <option *ngFor="let c of chargerTypes" [ngValue]="c.value">{{ c.label }}</option>
                    </select>
                    <span style="color:red" [hidden]="chargerType.valid || chargerType.pristine"> 
                        <p>Charger type is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">Currency</label>
                    <input type="text" class="form-control" name="currency" 
                        required [(ngModel)]="rate.currency" #currency="ngModel">
                    <span style="color:red" [hidden]="currency.valid || currency.pristine"> 
                        <p>Currency is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">Session Fee</label>
                    <input type="number" class="form-control" name="sessionFee" 
                        required [(ngModel)]="rate.sessionFee" #sessionFee="ngModel" min="0">
                    <span style="color:red" [hidden]="sessionFee.valid || sessionFee.pristine"> 
                        <p>Session fee is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">Energy Rate Unit</label>
                    <input type="text" class="form-control" name="energyRateUnit" 
                        required [(ngModel)]="rate.energyRateUnit" #energyRateUnit="ngModel">
                    <span style="color:red" [hidden]="energyRateUnit.valid || energyRateUnit.pristine"> 
                        <p>Energy rate Unit is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">Energy Rate</label>
                    <input type="number" class="form-control" name="energyRate" 
                        required [(ngModel)]="rate.energyRate" #energyRate="ngModel" min="0">
                    <span style="color:red" [hidden]="energyRate.valid || energyRate.pristine"> 
                        <p>Energy rate is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">Idling Fee Unit</label>
                    <input type="text" class="form-control" name="idlingFeeUnit" 
                        required [(ngModel)]="rate.idlingFeeUnit" #idlingFeeUnit="ngModel">
                    <span style="color:red" [hidden]="idlingFeeUnit.valid || idlingFeeUnit.pristine"> 
                        <p>Idling fee unit is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">Idling Fee</label>
                    <input type="number" class="form-control" name="idlingFee" 
                        required [(ngModel)]="rate.idlingFee" #idlingFee="ngModel" min="0">
                    <span style="color:red" [hidden]="idlingFee.valid || idlingFee.pristine"> 
                        <p>Idling fee is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">Parking Fee Unit</label>
                    <input type="text" class="form-control" name="parkingFeeUnit" 
                        required [(ngModel)]="rate.parkingFeeUnit" #parkingFeeUnit="ngModel">
                    <span style="color:red" [hidden]="parkingFeeUnit.valid || parkingFeeUnit.pristine"> 
                        <p>Parking fee unit is required</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">Parking Fee</label>
                    <input type="number" class="form-control" name="parkingFee" 
                        required [(ngModel)]="rate.parkingFee" #parkingFee="ngModel" min="0">
                    <span style="color:red" [hidden]="parkingFee.valid || parkingFee.pristine"> 
                        <p>Parking fee is required</p> 
                    </span>
                </div>
            </div>
        </div>

        <hr/>

        <button type="button" class="btn btn-outline-primary btn-rounded" (click)="addMore()">Add more</button>

    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">Save</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>