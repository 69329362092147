import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Charger, ClearCache, GetLocalListVersion, PagedCharger } from 'src/app/core/models/charger.models';
import { ChargerService } from 'src/app/core/services/charger.service';
import { ChargerModalComponent } from '../chargers/charger-modal/charger-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { SiteService } from 'src/app/core/services/site.service';
import { Site } from 'src/app/core/models/site.models';
import { StartTransactionModalComponent } from '../chargers/start-transaction-modal/start-transaction-modal.component';
import { TriggerMessageModalComponent } from '../chargers/trigger-message-modal/trigger-message-modal.component';
import { ResetModalComponent } from '../chargers/reset-modal/reset-modal.component';
import { ChangeAvailabilityModalComponent } from '../chargers/change-availability-modal/change-availability-modal.component';
import { CancelReservationModalComponent } from '../chargers/cancel-reservation-modal/cancel-reservation-modal.component';
import { ReserveNowModalComponent } from '../chargers/reserve-now-modal/reserve-now-modal.component';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';
import { UnlockConnectorModalComponent } from '../chargers/unlock-connector-modal/unlock-connector-modal.component';
import { ChangeConfigurationModalComponent } from '../chargers/change-configuration-modal/change-configuration-modal.component';
import { GetCompositeScheduleModalComponent } from '../chargers/get-composite-schedule-modal/get-composite-schedule-modal.component';
import { GetConfigurationModalComponent } from '../chargers/get-configuration-modal/get-configuration-modal.component';
import { GetDiagnosticsModalComponent } from '../chargers/get-diagnostics-modal/get-diagnostics-modal.component';
import { SendLocalListModalComponent } from '../chargers/send-local-list-modal/send-local-list-modal.component';
import { UpdateFirmwareModalComponent } from '../chargers/update-firmware-modal/update-firmware-modal.component';
import { SetChargingProfileModalComponent } from '../chargers/set-charging-profile-modal/set-charging-profile-modal.component';
import { ClearChargingProfileModalComponent } from '../chargers/clear-charging-profile-modal/clear-charging-profile-modal.component';
import { QrCodeModalComponent } from '../chargers/qr-code-modal/qr-code-modal.component';

@Component({
  selector: 'app-list-chargers',
  templateUrl: './list-chargers.component.html',
  styleUrls: ['./list-chargers.component.scss']
})
export class ListChargersComponent implements OnInit {

  @Input() site!: Site;

  searchTerm!: string;
  searchTermChanged: Subject<string> = new Subject<string>();
  inputSub: Subscription | undefined;
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  genericFilter: GenericFilter = new GenericFilter('', 0, 10, 'id', 'desc');
  charger!: Charger;
  chargers!: Charger[];
  sites!: Site[];
  pagedChargers!: PagedCharger;
  empty = true;

  constructor(
    private chargerService: ChargerService, 
    private siteService: SiteService, 
    public sweetalertService: SweetalertService,
    public modalService: NgbModal) { }

  ngOnInit(): void {
    this.fetchData();
    this.fetchSitesData();

    this.inputSub = this.searchTermChanged.pipe(
      debounceTime(500),
      delay(200)
    ).subscribe(value => {
        console.log(value)
        this.genericFilter.searchParam = value;
        this.fetchData();
      });
  }

  fetchData() {
    this.genericFilter.size = this.pageSize;
    this.genericFilter.page = this.page -1;
    this.chargerService.getChargers(this.genericFilter, this.site.uuid).subscribe({
      next: (v) => {
        console.log('returned chargers : ', v);
        this.pagedChargers = v;
        this.collectionSize = this.pagedChargers.totalElements;
        if (this.pagedChargers.content.length > 0) {
          this.chargers = this.pagedChargers.content;
          this.empty = false;
        }
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('chargers retrieved');
      }
    });
  }

  fetchSitesData() {
    let filter: GenericFilter = new GenericFilter('', 0, 1000, 'name', 'asc');
    this.siteService.getSites(filter).subscribe({
      next: (v) => {
        this.sites = v.content;
        console.log('returned sites : ', v);
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('sites retrieved');
      }
    });
  }

  search(value: string): void {
    this.searchTermChanged.next(value);
  }

  onSizeChange(size: number) {
    this.genericFilter.size = size;
    this.pageSize = size;
    this.page = 1;
    this.fetchData();
  }

  pageChanged(page: any) {
    console.log('page change: ', page);
    this.genericFilter.page = page - 1;
    this.fetchData();
  }

  openModal(charger: any, isAddNew: boolean) {
    const modalRef = this.modalService.open(ChargerModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.isAddNew = isAddNew;
    modalRef.componentInstance.sites = this.sites;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  cancelReservationModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(CancelReservationModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  changeAvailabilityModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ChangeAvailabilityModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  changeConfigurationModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ChangeConfigurationModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  clearCache(charger: Charger) {
    console.log('charger: ', charger);
    this.sweetalertService.confirm('Clear Cache?', `Are you sure you want to clear the cache of charger ${charger.chargingStationIdentifier}?`).then((result) => {
      console.log('result: ', result);
      if (result.isConfirmed) {
        let clearCache: ClearCache = {
          chargingStationSelectList: [
            {
              chargingStationIdentifier: charger.chargingStationIdentifier,
              endpointAddress: 'ClearCache',
              ocppTransport: "JSON"
            }
          ]
        }

        this.chargerService.clearCache(clearCache).subscribe({
          next: (v) => {
            console.log('clear cache request submitted : ', v);
            this.sweetalertService.success(v.message).then(() => {
              
            });
          },
          error: (e) => {
            console.error('error occured : ', e);
            this.sweetalertService.failure(e).then(() => {
            });
          },
          complete: () => {
            console.info('clear cache completed');
          }
        });
      }
    });
  }

  clearChargingProfileModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ClearChargingProfileModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  getCompositeScheduleModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(GetCompositeScheduleModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  getConfigurationModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(GetConfigurationModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  getDiagnosticsModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(GetDiagnosticsModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  getLocalListVersionModal(charger: Charger) {
    console.log('charger: ', charger);
    this.sweetalertService.confirm('Get Local List Version?', `Are you sure you want to get the local list version of charger ${charger.chargingStationIdentifier}?`).then((result) => {
      console.log('result: ', result);
      if (result.isConfirmed) {
        let getLocalListVersion: GetLocalListVersion = {
          chargingStationSelectList: [
            {
              chargingStationIdentifier: charger.chargingStationIdentifier,
              endpointAddress: 'ClearCache',
              ocppTransport: "JSON"
            }
          ]
        }

        this.chargerService.getLocalListVersion(getLocalListVersion).subscribe({
          next: (v) => {
            console.log('getLocalListVersion request submitted : ', v);
            this.sweetalertService.success(v.message).then(() => {
              
            });
          },
          error: (e) => {
            console.error('error occured : ', e);
            this.sweetalertService.failure(e).then(() => {
            });
          },
          complete: () => {
            console.info('getLocalListVersion completed');
          }
        });
      }
    });
  }

  sendLocalListModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(SendLocalListModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  reserveNowModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ReserveNowModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  resetModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ResetModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  setChargingProfileModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(SetChargingProfileModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  startTransactionModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(StartTransactionModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  triggerMessageModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(TriggerMessageModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  unlockConnectorModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(UnlockConnectorModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  //refresh page
  refreshPage(): void {
    window.location.reload();
  }

  updateFirmwareModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(UpdateFirmwareModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  qrCodeModal(charger: any) {
    const modalRef = this.modalService.open(QrCodeModalComponent);
    modalRef.componentInstance.charger = charger;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {

      })
      .catch(() => {
        // user click outside of the modal form
        console.log('QR Code Modal: ', 'Cancel');
      });
  }
  
}
