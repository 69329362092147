import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { EmailTemplate } from '../models/email-template.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getUserManagementUrl();
  }

  /**
     * Get all email templates
     */
  getEmailTemplates(): Observable<EmailTemplate[]> {

    return this.http.get<EmailTemplate[]>(`${this.backendApiUrl}/email-templates`, this.httpOptions).pipe(
      tap((emailTemplates: EmailTemplate[]) => {
        this.log(`Retrieved emailTemplates`);
      })
    );
  }

  /**
     * Add emailTemplate
     */
  addEmailTemplate(params: any): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/email-templates`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added emailTemplates`);
      })
    );
  }

  /**
     * Update permission
     */
  updateEmailTemplate(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/email-templates/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated emailTemplates`);
      })
    );
  }

  /** Log a EmailTemplateService message */
  private log(message: string) {
    console.log(`EmailTemplateService: ${message}`);
  }
}
