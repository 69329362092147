<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="name" class="fw-semibold">Name</label>
            <input type="text" class="form-control" id="name" name="name" 
                required [(ngModel)]="role.name" #name="ngModel">
            <span style="color:red" [hidden]="name.valid || name.pristine"> 
                <p>Name is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="description" class="fw-semibold">Description</label>
            <input type="text" class="form-control" id="description" name="description" 
                required [(ngModel)]="role.description" #description="ngModel">
            <span style="color:red" [hidden]="description.valid || description.pristine"> 
                <p>Description is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="enabled" class="fw-semibold">Role Type</label>
            <select class="form-select" id="roleType" name="roleType" [(ngModel)]="role.roleType" #roleType="ngModel" required>
                <option value="BACK_OFFICE">Back Office</option>
                <option value="CUSTOMER">Customer</option>
                <option value="SITE_OWNER">Site Owner</option>
            </select>
        </div>
        <div class="form-group mb-3" [hidden]="isAddNew">
            <label for="enabled" class="fw-semibold">Status</label>
            <select class="form-select" id="enabled" name="enabled" [(ngModel)]="role.enabled" #enabled="ngModel">
                <option value="true">Enabled</option>
                <option value="false">Disabled</option>
            </select>
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">Save</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>