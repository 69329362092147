import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppUtilities } from 'src/app/app-utilities';
import { GenericFilter } from '../models/generic-filter.models';
import { tap } from 'rxjs/operators';
import { PagedWallets, UpdateWalletStatusDto } from '../models/wallet.models';
import { GenericResponse } from '../models/generic-response.models';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getBillingManagementUrl();
  }

  getWallets(params: GenericFilter): Observable<PagedWallets> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedWallets>(`${this.backendApiUrl}/wallets?${queryString}`, this.httpOptions).pipe(
      tap((pagedOrganization: PagedWallets) => {
        this.log(`Retrieved wallets`);
      })
    );
  }

  updateStatus(body: UpdateWalletStatusDto): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(`${this.backendApiUrl}/wallets/status`, body, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated Wallet Status`);
      })
    );
  }

  private log(message: string) {
    // console.log(`WalletService: ${message}`);
  }
}
