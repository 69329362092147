import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import MetisMenu from "metismenujs";
import { Router, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { MENU } from "./menu";
import { MenuItem } from "./menu.model";

import { SIDEBAR_COLOR } from "../layouts.model";
import { AuthService } from "src/app/core/services/authentication.service";
import { User } from "src/app/core/models/user.models";
import { UserState } from "src/app/store/user/reducer";
import { Store } from "@ngrx/store";

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
})

/**
 * Sidebar Component
 */
export class SidebarComponent implements OnInit {
    @ViewChild("sideMenu") sideMenu!: ElementRef;
    menu: any;
    menuItems: MenuItem[] = [];
    userPermissions: string[] = [];
    user?: User; 

    isSidebar: any;

    constructor(
        private router: Router,
        public translate: TranslateService,
        public authService: AuthService,
        private store: Store<{ user: UserState }>
    ) {
        translate.setDefaultLang("en");
        router.events.forEach((event) => {
            if (event instanceof NavigationEnd) {
                this._activateMenuDropdown();
            }
        });
    }

    ngOnInit(): void {
        // this.menuItems = MENU;
        this.initialize();

        this.isSidebar = SIDEBAR_COLOR;
        if (this.isSidebar === "dark") {
            document.body.setAttribute("data-sidebar", "dark");
        }
    }

    /**
     * Initialize
     */
    initialize(): void {
        // Subscribe to the user state in the store to get the user data
        this.store.select("user").subscribe((userState) => {
            if (userState.user) {
                this.userPermissions = userState.user.permissionNames || []; // Fallback to empty array
                // Filter the menu based on the user's permissions
                this.menuItems = this.filterMenuByPermissions(
                    MENU,
                    this.userPermissions
                );
            }
        });

        // this.menuItems = MENU;
    }

    /***
     * Activate droup down set
     */
    ngAfterViewInit() {
        this.menu = new MetisMenu("#side-menu");
        this._activateMenuDropdown();
    }

    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item: MenuItem) {
        return item.subItems !== undefined ? item.subItems.length > 0 : false;
    }

    /**
     * remove active and mm-active class
     */
    _removeAllClass(className: any) {
        const els = document.getElementsByClassName(className);
        while (els[0]) {
            els[0].classList.remove(className);
        }
    }

    /**
     * Activate the parent dropdown
     */
    _activateMenuDropdown() {
        this._removeAllClass("mm-active");
        this._removeAllClass("mm-show");
        const links: any = document.getElementsByClassName("side-nav-link-ref");
        let menuItemEl = null;
        // tslint:disable-next-line: prefer-for-of
        const paths = [];
        for (let i = 0; i < links.length; i++) {
            paths.push(links[i]["pathname"]);
        }
        var itemIndex = paths.indexOf(window.location.pathname);
        if (itemIndex === -1) {
            const strIndex = window.location.pathname.lastIndexOf("/");
            const item = window.location.pathname
                .substr(0, strIndex)
                .toString();
            menuItemEl = links[paths.indexOf(item)];
        } else {
            menuItemEl = links[itemIndex];
        }
        if (menuItemEl) {
            menuItemEl.classList.add("active");
            const parentEl = menuItemEl.parentElement;
            if (parentEl) {
                parentEl.classList.add("mm-active");
                const parent2El = parentEl.parentElement.closest("ul");
                if (parent2El && parent2El.id !== "side-menu") {
                    parent2El.classList.add("mm-show");
                    const parent3El = parent2El.parentElement;
                    if (parent3El && parent3El.id !== "side-menu") {
                        parent3El.classList.add("mm-active");
                        const childAnchor =
                            parent3El.querySelector(".has-arrow");
                        const childDropdown =
                            parent3El.querySelector(".has-dropdown");
                        if (childAnchor) {
                            childAnchor.classList.add("mm-active");
                        }
                        if (childDropdown) {
                            childDropdown.classList.add("mm-active");
                        }
                        const parent4El = parent3El.parentElement;
                        if (parent4El && parent4El.id !== "side-menu") {
                            parent4El.classList.add("mm-show");
                            const parent5El = parent4El.parentElement;
                            if (parent5El && parent5El.id !== "side-menu") {
                                parent5El.classList.add("mm-active");
                                const childanchor =
                                    parent5El.querySelector(".is-parent");
                                if (
                                    childanchor &&
                                    parent5El.id !== "side-menu"
                                ) {
                                    childanchor.classList.add("mm-active");
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private filterMenuByPermissions(
        menu: MenuItem[],
        permissions: string[]
    ): MenuItem[] {
        return menu
            .filter((item) => {
                // Include items with no permissions or where the user has at least one matching permission
                return (
                    !item.permissions ||
                    item.permissions.length === 0 ||
                    item.permissions.some((perm) => permissions.includes(perm))
                );
            })
            .map((item) => ({
                ...item,
                // Recursively filter subItems
                subItems: item.subItems
                    ? this.filterMenuByPermissions(item.subItems, permissions)
                    : undefined,
            }));
    }
}
