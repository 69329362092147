import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppUtilities } from "src/app/app-utilities";
import { User, PagedUser } from "../models/user.models";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { GenericResponse } from "../models/generic-response.models";
import { GenericFilter } from "../models/generic-filter.models";
import { Analytics } from "../models/dashboard.models";
import { VehicleResponse } from "../models/vehicle.models";

@Injectable({ providedIn: "root" })
export class UserService {
    backendApiUrl = "";
    httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    constructor(private http: HttpClient, private appUtilities: AppUtilities) {
        this.backendApiUrl = appUtilities.getUserManagementUrl();
    }

    /**
     * Get user by ID
     */
    getUser(): Observable<User> {
        return this.http
            .get<User>(`${this.backendApiUrl}/account`, this.httpOptions)
            .pipe(
                tap((user: User) => {
                    this.log(`Retrieved user`);
                })
            );
    }

    /**
     * Get all users
     */
    getUsers(params: GenericFilter, userType: string): Observable<PagedUser> {
        let queryString = Object.keys(params)
            .map((key) => key + "=" + params[key as keyof GenericFilter])
            .join("&");
        queryString = `${queryString}&userType=${userType}`;
        return this.http
            .get<PagedUser>(
                `${this.backendApiUrl}/users?${queryString}`,
                this.httpOptions
            )
            .pipe(
                tap((pagedUser: PagedUser) => {
                    this.log(`Retrieved users`);
                })
            );
    }

    /**
     * Add user
     */
    addUser(params: any): Observable<GenericResponse> {
        // console.log("request : ", params);

        return this.http
            .post<GenericResponse>(
                `${this.backendApiUrl}/users`,
                params,
                this.httpOptions
            )
            .pipe(
                tap((res: GenericResponse) => {
                    this.log(`Added user`);
                })
            );
    }

    /**
     * Update user
     */
    updateUser(params: any, uuid: string): Observable<GenericResponse> {
        return this.http
            .put<GenericResponse>(
                `${this.backendApiUrl}/users/${uuid}`,
                params,
                this.httpOptions
            )
            .pipe(
                tap((res: GenericResponse) => {
                    this.log(`Updated user`);
                })
            );
    }

    /**
     * Update user
     */
    getUserVehicles(uuid: string): Observable<VehicleResponse[]> {
        return this.http
            .get<VehicleResponse[]>(
                `${this.backendApiUrl}/vehicles/user/${uuid}`,
                this.httpOptions
            )
            .pipe(
                tap((res: VehicleResponse[]) => {
                    this.log(`Updated user`);
                })
            );
    }

    /**
     * Get active users count
     */
    getActiveUsersCount(): Observable<Analytics> {
        return this.http
            .get<Analytics>(
                `${this.backendApiUrl}/users/active-count`,
                this.httpOptions
            )
            .pipe(
                tap((pagedUser: Analytics) => {
                    this.log(`Retrieved active users count`);
                })
            );
    }

    /** Log a UserService message */
    private log(message: string) {
        // console.log(`UserService: ${message}`);
    }
}
