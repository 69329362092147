<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title | translate}}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="description" class="fw-semibold">{{ 'PAGES.COMMON.LIST.DESCRIPTION' | translate }}</label>
                    <input type="text" class="form-control" id="description" name="description" 
                        required [(ngModel)]="addChargingProfile.description" #description="ngModel">
                    <span style="color:red" [hidden]="description.valid || description.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.DESCRIPTIONREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="chargingProfileKind" class="fw-semibold">{{ 'PAGES.CHARGINGPROFILES.LIST.KIND' | translate }}</label><br />
                    <select
                      class="form-select"
                      id="chargingProfileKind"
                      name="chargingProfileKind"
                      [(ngModel)]="addChargingProfile.chargingProfileKind"
                      #chargingProfileKind="ngModel"
                    >
                      <option value="Absolute">Absolute</option>
                      <option value="Recurring">Recurring</option>
                      <option value="Relative">Relative</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="chargingProfilePurpose" class="fw-semibold">{{ 'PAGES.CHARGINGPROFILES.LIST.PURPOSE' | translate }}</label><br />
                    <select
                      class="form-select"
                      id="chargingProfilePurpose"
                      name="chargingProfilePurpose"
                      [(ngModel)]="addChargingProfile.chargingProfilePurpose"
                      #chargingProfilePurpose="ngModel"
                    >
                      <option value="ChargePointMaxProfile">ChargePointMaxProfile</option>
                      <option value="TxDefaultProfile">TxDefaultProfile</option>
                      <option value="TxProfile">TxProfile</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="chargingRateUnit" class="fw-semibold">{{ 'PAGES.CHARGINGPROFILES.LIST.CHARGINGRATEUNIT' | translate }}</label>
                    <select
                      class="form-select"
                      id="chargingRateUnit"
                      name="chargingRateUnit"
                      [(ngModel)]="addChargingProfile.chargingRateUnit"
                      #chargingRateUnit="ngModel"
                    >
                      <option value="A">Ampere</option>
                      <option value="W">Watt</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="durationInSeconds" class="fw-semibold">{{ 'PAGES.CHARGINGPROFILES.LIST.DURATIONINSECONDS' | translate }}</label>
                    <input type="number" class="form-control" id="durationInSeconds" name="durationInSeconds" 
                        required [(ngModel)]="addChargingProfile.durationInSeconds" #durationInSeconds="ngModel" min="1">
                    <span style="color:red" [hidden]="durationInSeconds.valid || durationInSeconds.pristine"> 
                        <p>{{ 'PAGES.CHARGINGPROFILES.LIST.DURATIONINSECONDSREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="minChargingRate" class="fw-semibold">{{ 'PAGES.CHARGINGPROFILES.LIST.MINCHARGINGRATE' | translate }}</label>
                    <input type="number" class="form-control" id="minChargingRate" name="minChargingRate" 
                        required [(ngModel)]="addChargingProfile.minChargingRate" #minChargingRate="ngModel">
                    <span style="color:red" [hidden]="minChargingRate.valid || minChargingRate.pristine"> 
                        <p>{{ 'PAGES.CHARGINGPROFILES.LIST.MINCHARGINGRATEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="note" class="fw-semibold">{{ 'PAGES.CHARGINGPROFILES.LIST.NOTE' | translate }}</label>
                    <input type="text" class="form-control" id="note" name="note" 
                        required [(ngModel)]="addChargingProfile.note" #note="ngModel">
                    <span style="color:red" [hidden]="note.valid || note.pristine"> 
                        <p>{{ 'PAGES.CHARGINGPROFILES.LIST.NOTEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="recurrencyKind" class="fw-semibold">{{ 'PAGES.CHARGINGPROFILES.LIST.RECURRENCYKIND' | translate }}</label>
                    <select
                      class="form-select"
                      id="recurrencyKind"
                      name="recurrencyKind"
                      [(ngModel)]="addChargingProfile.recurrencyKind"
                      #recurrencyKind="ngModel"
                    >
                      <option value="Daily">{{ 'PAGES.COMMON.LIST.DAILY' | translate }}</option>
                      <option value="Weekly">{{ 'PAGES.COMMON.LIST.WEEKLY' | translate }}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="stackLevel" class="fw-semibold">{{ 'PAGES.CHARGINGPROFILES.LIST.STACKLEVEL' | translate }}</label>
                    <input type="number" class="form-control" id="stackLevel" name="stackLevel" 
                        required [(ngModel)]="addChargingProfile.stackLevel" #stackLevel="ngModel" min="0">
                    <span style="color:red" [hidden]="stackLevel.valid || stackLevel.pristine"> 
                        <p>{{ 'PAGES.CHARGINGPROFILES.LIST.STACKLEVELREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="startSchedule" class="fw-semibold">{{ 'PAGES.CHARGINGPROFILES.LIST.STARTSCHEDULE' | translate }}</label>
                    <input class="form-control flatpickr-input" type="text" mwlFlatpickr [enableTime]="true"
                    dateFormat="Y-m-dTH:i" id="startSchedule" name="startSchedule" required [(ngModel)]="addChargingProfile.startSchedule" #startSchedule="ngModel">
                    <span style="color:red" [hidden]="startSchedule.valid || startSchedule.pristine"> 
                        <p>{{ 'PAGES.CHARGINGPROFILES.LIST.STARTSCHEDULEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="validFrom" class="fw-semibold">{{ 'PAGES.CHARGINGPROFILES.LIST.VALIDFROM' | translate }}</label>
                    <input class="form-control flatpickr-input" type="text" mwlFlatpickr [enableTime]="true"
                    dateFormat="Y-m-dTH:i" id="validFrom" name="validFrom" [(ngModel)]="addChargingProfile.validFrom" #validFrom="ngModel">
                    <!-- <span style="color:red" [hidden]="validFrom.valid || validFrom.pristine"> 
                        <p>Valid From is required</p> 
                    </span> -->
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="validTo" class="fw-semibold">{{ 'PAGES.CHARGINGPROFILES.LIST.VALIDTO' | translate }}</label>
                    <input class="form-control flatpickr-input" type="text" mwlFlatpickr [enableTime]="true"
                    dateFormat="Y-m-dTH:i" id="validTo" name="validTo" [(ngModel)]="addChargingProfile.validTo" #validTo="ngModel">
                    <!-- <span style="color:red" [hidden]="validTo.valid || validTo.pristine"> 
                        <p>Valid To is required</p> 
                    </span> -->
                </div>
            </div>
        </div>

        <div class="row" *ngFor="let schedulePeriod of addChargingProfile.schedulePeriods; index as i"> 
            <hr class="mt-4 mb-4"/>

            <h5 class="font-size-14 mb-3">{{ 'PAGES.CHARGINGPROFILES.LIST.SCHEDULEPERIOD' | translate }} #{{ i + 1 }}</h5>

            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.CHARGINGPROFILES.LIST.STARTPERIODINSECONDS' | translate }}</label>
                    <input type="number" class="form-control" name="startPeriodInSeconds" 
                        required [(ngModel)]="schedulePeriod.startPeriodInSeconds" #startPeriodInSeconds="ngModel" min="0">
                    <span style="color:red" [hidden]="startPeriodInSeconds.valid || startPeriodInSeconds.pristine"> 
                        <p>{{ 'PAGES.CHARGINGPROFILES.LIST.STARTPERIODINSECONDSREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.CHARGINGPROFILES.LIST.POWERLIMIT' | translate }}</label>
                    <input type="number" class="form-control" name="powerLimit" 
                        required [(ngModel)]="schedulePeriod.powerLimit" #powerLimit="ngModel" min="0">
                    <span style="color:red" [hidden]="powerLimit.valid || powerLimit.pristine"> 
                        <p>{{ 'PAGES.CHARGINGPROFILES.LIST.POWERLIMITREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.CHARGINGPROFILES.LIST.NUMBEROFPHASES' | translate }}</label>
                    <input type="number" class="form-control" name="numberPhases" 
                        required [(ngModel)]="schedulePeriod.numberPhases" #numberPhases="ngModel" min="0">
                    <span style="color:red" [hidden]="numberPhases.valid || numberPhases.pristine"> 
                        <p>{{ 'PAGES.CHARGINGPROFILES.LIST.NUMBEROFPHASESREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
        </div>

        <hr/>

        <button type="button" class="btn btn-outline-primary btn-rounded" (click)="addMore()">{{ 'PAGES.COMMON.LIST.ADDMORE' | translate }}</button>

        <div class="form-group mb-3" [hidden]="isAddNew">
            <label for="enabled" class="fw-semibold">{{ 'PAGES.COMMON.LIST.STATUS' | translate }}</label>
            <select class="form-select" id="enabled" name="enabled" [(ngModel)]="addChargingProfile.enabled" #enabled="ngModel">
                <option value="true">{{ 'PAGES.COMMON.LIST.ENABLED' | translate }}</option>
                <option value="false">{{ 'PAGES.COMMON.LIST.DISABLED' | translate }}</option>
            </select>
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">{{ 'PAGES.COMMON.LIST.CANCEL' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">{{ 'PAGES.COMMON.LIST.SAVE' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>