import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Rate, PagedRate } from 'src/app/core/models/rate.models';
import { RateService } from 'src/app/core/services/rate.service';
import { RateModalComponent } from './rate-modal/rate-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss']
})
export class RatesComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  searchTerm!: string;
  searchTermChanged: Subject<string> = new Subject<string>();
  inputSub: Subscription | undefined;
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  genericFilter: GenericFilter = new GenericFilter('', 0, 10, 'id', 'desc');
  rate!: Rate;
  rates!: Rate[];
  pagedRates!: PagedRate;
  empty = true;

  constructor(
    private rateService: RateService, 
    public modalService: NgbModal) { }

  ngOnInit(): void {
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Charger Management' },
      { label: 'Rates', active: true }
    ];

    this.fetchData();

    this.inputSub = this.searchTermChanged.pipe(
      debounceTime(500),
      delay(200)
    ).subscribe(value => {
        console.log(value)
        this.genericFilter.searchParam = value;
        this.fetchData();
      });
  }

  fetchData() {
    this.genericFilter.size = this.pageSize;
    this.genericFilter.page = this.page -1;
    this.rateService.getRates(this.genericFilter).subscribe({
      next: (v) => {
        console.log('returned rates : ', v);
        this.pagedRates = v;
        this.collectionSize = this.pagedRates.totalElements;
        if (this.pagedRates.content.length > 0) {
          this.rates = this.pagedRates.content;
          this.empty = false;
        }
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('rates retrieved');
      }
    });
  }

  search(value: string): void {
    this.searchTermChanged.next(value);
  }

  onSizeChange(size: number) {
    this.genericFilter.size = size;
    this.pageSize = size;
    this.page = 1;
    this.fetchData();
  }

  pageChanged(page: any) {
    console.log('page change: ', page);
    this.genericFilter.page = page - 1;
    this.fetchData();
  }

  openModal(rate: any, isAddNew: boolean) {
    const modalRef = this.modalService.open(RateModalComponent);
    modalRef.componentInstance.rate = rate;
    modalRef.componentInstance.isAddNew = isAddNew;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.refreshPage();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  //refresh page
  refreshPage(): void {
    window.location.reload();
  }

}
