<div class="card">
    <div class="card-body">
        <div class="float-end">
            <div class="dropdown" ngbDropdown>
                <a class="dropdown-toggle text-reset" href="javascript:void(0);" ngbDropdownToggle
                    id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <span class="fw-semibold">{{ 'PAGES.DASHBOARD.LIST.SORTBY' | translate }}:</span> <span class="text-muted">{{ 'PAGES.DASHBOARD.LIST.MONTHLY' | translate }}<i
                            class="mdi mdi-chevron-down ms-1"></i></span>
                </a>
                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton2"
                    ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript:void(0);">{{ 'PAGES.DASHBOARD.LIST.YEARLY' | translate }}</a>
                    <a class="dropdown-item" href="javascript:void(0);">{{ 'PAGES.DASHBOARD.LIST.MONTHLY' | translate }}</a>
                    <a class="dropdown-item" href="javascript:void(0);">{{ 'PAGES.DASHBOARD.LIST.WEEKLY' | translate }}</a>
                    <a class="dropdown-item" href="javascript:void(0);">{{ 'PAGES.DASHBOARD.LIST.TODAY' | translate }}</a>
                </div>
            </div>
        </div>

        <h4 class="card-title mb-4">{{ 'PAGES.DASHBOARD.LIST.EARNINGREPORTS' | translate }}</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="row mb-3">
                    <div class="col-6">
                        <p class="text-muted mb-1">{{ 'PAGES.DASHBOARD.LIST.THISMONTH' | translate }}</p>
                        <h5 class="mt-0">$12,582<small class="badge badge-light-success font-13">+15%</small>
                        </h5>
                    </div>
                    <div class="col-6">
                        <p class="text-muted mb-1">{{ 'PAGES.DASHBOARD.LIST.LASTMONTH' | translate }}</p>
                        <h5 class="mt-0">$98,741 <small class="badge badge-light-danger font-13">-5%</small>
                        </h5>
                    </div>
                </div>
                <p class="text-muted"><span class="text-success me-1"> 12%<i
                            class="mdi mdi-arrow-up"></i></span>{{ 'PAGES.DASHBOARD.LIST.PREVIOUSPERIOD' | translate }}</p>

                <div class="mt-4">
                    <a href="" class="btn btn-primary waves-effect waves-light btn-sm">{{ 'PAGES.DASHBOARD.LIST.GENERATEREPORTS' | translate }} <i
                            class="mdi mdi-arrow-right ms-1"></i></a>
                </div>
            </div>
            <div class="col-sm-6">
                <apx-chart dir="ltr" class="apex-charts" [series]="monthlyEarningChart.series"
                    [chart]="monthlyEarningChart.chart" [legend]="monthlyEarningChart.legend"
                    [colors]="monthlyEarningChart.colors" [labels]="monthlyEarningChart.labels"
                    [stroke]="monthlyEarningChart.stroke" [plotOptions]="monthlyEarningChart.plotOptions">
                </apx-chart>
            </div>
        </div>
    </div>
</div> <!-- end card -->