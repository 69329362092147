import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddChargingProfile, ChargingProfile, SchedulePeriod } from 'src/app/core/models/charging-profile.models';
import { ChargingProfileService } from 'src/app/core/services/charging-profile.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-charging-profile-modal',
  templateUrl: './charging-profile-modal.component.html',
  styleUrls: ['./charging-profile-modal.component.scss']
})
export class ChargingProfileModalComponent implements OnInit {

  @Input() public chargingProfile!: ChargingProfile;
  @Input() public isAddNew!: boolean;
  addChargingProfile!: AddChargingProfile;


  entryForm!: UntypedFormGroup;
  processing = false;
  title = "Edit Charging Profile";

  constructor(
    public modal: NgbActiveModal, 
    private chargingProfileService: ChargingProfileService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    if (this.chargingProfile == undefined) {
      let schedulePeriod: SchedulePeriod = {
        startPeriodInSeconds: 0,
        powerLimit: 0,
        numberPhases: 3
      }

      this.addChargingProfile = {
        chargingProfileKind: "",
        chargingProfilePurpose: "",
        chargingRateUnit: "",
        description: "",
        durationInSeconds: 0,
        minChargingRate: 0,
        note: "",
        recurrencyKind: "",
        stackLevel: 0,
        startSchedule: "",
        validFrom: "",
        validTo: "",
        schedulePeriods: [],
        enabled: false
      };
      this.addChargingProfile.schedulePeriods.push(schedulePeriod);
      this.title = "Add Charging Profile";
    } else {
      this.addChargingProfile = {
        chargingProfileKind: this.chargingProfile.chargingProfileKind,
        chargingProfilePurpose: this.chargingProfile.chargingProfilePurpose,
        chargingRateUnit: this.chargingProfile.chargingRateUnit,
        description: this.chargingProfile.description,
        durationInSeconds: this.chargingProfile.durationInSeconds,
        minChargingRate: this.chargingProfile.minChargingRate,
        note: this.chargingProfile.note,
        recurrencyKind: this.chargingProfile.recurrencyKind,
        stackLevel: this.chargingProfile.stackLevel,
        startSchedule: this.chargingProfile.startSchedule,
        validFrom: this.chargingProfile.validFrom,
        validTo: this.chargingProfile.validTo,
        schedulePeriods: this.chargingProfile.chargingSchedulePeriods,
        enabled: this.chargingProfile.enabled
      };
      this.title = "Edit Charging Profile";
      console.log("charging profile edit: ", this.addChargingProfile);
    }
  }
  
  // Handle Create button click
  onSubmit() {
    console.log('create clicked');
    this.processing = true;
    if (this.isAddNew) {
      // Add a new chargingProfile
      this.chargingProfileService.addChargingProfile(this.addChargingProfile).subscribe({
        next: (v) => {
          console.log('added chargingProfile : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          console.info('chargingProfiles completed');
        }
      });
    } else {
      // Update an existing chargingProfile
      this.chargingProfileService.updateChargingProfile(this.addChargingProfile, this.chargingProfile.uuid).subscribe({
        next: (v) => {
          console.log('updated chargingProfile : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });  
        },
        complete: () => {
          console.info('chargingProfiles completed');
        }
      });
    }
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }

  addMore() {
    let schedulePeriod: SchedulePeriod = {
      startPeriodInSeconds: 0,
      powerLimit: 0,
      numberPhases: 3
    }

    this.addChargingProfile.schedulePeriods.push(schedulePeriod);
  }
}
