import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppUtilities } from "src/app/app-utilities";
import {
    CancelReservation,
    ChangeAvailability,
    ChangeConfiguration,
    Charger,
    ClearCache,
    ClearChargingProfile,
    GetCompositeSchedule,
    GetConfiguration,
    GetDiagnostics,
    GetLocalListVersion,
    PagedCharger,
    ReserveNow,
    Reset,
    SendLocalList,
    SetChargingProfile,
    StartTransaction,
    StartTransactionAnonymous,
    StopTransaction,
    TriggerMessage,
    UnlockConnector,
    UpdateFirmware,
} from "../models/charger.models";
import { forkJoin, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { GenericResponse } from "../models/generic-response.models";
import { GenericFilter } from "../models/generic-filter.models";
import { Rate } from "../models/rate.models";

@Injectable({
    providedIn: "root",
})
export class ChargerService {
    backendApiUrl = "";
    httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    constructor(private http: HttpClient, private appUtilities: AppUtilities) {
        this.backendApiUrl = appUtilities.getChargingStationManagementUrl();
    }

    /**
     * Get all chargers
     */
    getChargers(
        params: GenericFilter,
        siteUuid?: string
    ): Observable<PagedCharger> {
        let queryString = Object.keys(params)
            .map((key) => key + "=" + params[key as keyof GenericFilter])
            .join("&");
        if (siteUuid !== undefined) {
            queryString += `&siteUuid=${siteUuid}`;
        }
        // console.log("query : " + queryString, "site uuid : " + siteUuid);
        return this.http
            .get<PagedCharger>(
                `${this.backendApiUrl}/charging-stations?${queryString}`,
                this.httpOptions
            )
            .pipe(
                tap((pagedCharger: PagedCharger) => {
                    this.log(`Retrieved chargers`);
                })
            );
    }

    /**
     * Get charger
     */
    getCharger(params: string): Observable<Charger> {
        return this.http
            .get<Charger>(
                `${this.backendApiUrl}/charging-stations/identifier/${params}`,
                this.httpOptions
            )
            .pipe(
                tap((charger: Charger) => {
                    this.log(`Retrieved charger`);
                })
            );
    }

    /**
     * Add charger
     */
    addCharger(params: any): Observable<GenericResponse> {
        // console.log("request : ", params);

        return this.http
            .post<GenericResponse>(
                `${this.backendApiUrl}/charging-stations/add`,
                params,
                this.httpOptions
            )
            .pipe(
                tap((res: GenericResponse) => {
                    this.log(`Added charger`);
                })
            );
    }

    /**
     * Update charger
     */
    updateCharger(params: any, uuid: string): Observable<GenericResponse> {
        return this.http
            .put<GenericResponse>(
                `${this.backendApiUrl}/charging-stations/${uuid}`,
                params,
                this.httpOptions
            )
            .pipe(
                tap((res: GenericResponse) => {
                    this.log(`Updated charger`);
                })
            );
    }

    /**
     * Cancel reservation
     */
  cancelReservation(params: CancelReservation): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/cancel-reservation`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Cancel reservation`);
      })
    );
  }

    /**
     * Change availability
     */
  changeAvailability(params: ChangeAvailability): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/change-availability`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Change availability`);
      })
    );
  }

    /**
     * Change configuration
     */
  changeConfiguration(params: ChangeConfiguration): Observable<GenericResponse> {
    
    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/change-configuration`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Change configuration`);
      })
    );
  }

    /**
     * Clear cache
     */
  clearCache(params: ClearCache): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/clear-cache`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Clear cache`);
      })
    );
  }

    /**
     * Clear charging profile
     */
  clearChargingProfile(params: ClearChargingProfile): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/clear-charging-profile`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Clear charging profile`);
      })
    );
  }

    /**
     * Get composite schedule
     */

  getCompositeSchedule(params: GetCompositeSchedule): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/get-composite-schedule`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Get composite schedule`);
      })
    );
  }

    /**
     * Get configuration
     */
  getConfiguration(params: GetConfiguration): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/get-configuration`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Get configuration`);
      })
    );
  }

    /**
     * Get diagnostics
     */
  getDiagnostics(params: GetDiagnostics): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/get-diagnostics`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Get diagnostics`);
      })
    );
  }

    /**
     * Get local list version
     */
  getLocalListVersion(params: GetLocalListVersion): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/get-local-list-version`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Get local list version`);
      })
    );
  }

    /**
     * Remote start transaction
     */
  remoteStartTransaction(params: StartTransaction): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/remote-start-transaction`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Remote start transaction`);
      })
    );
  }

    /**
     * Remote start transaction anonymous
     */
  remoteStartTransactionAnonymous(params: StartTransactionAnonymous): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/remote-start-transaction-anonymous`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Remote start transaction anonymous`);
      })
    );
  }

    /**
     * Remote stop transaction
     */
  remoteStopTransaction(params: any): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/remote-stop-transaction`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Remote stop transaction`);
      })
    );
  }

    /**
     * Reserve Now
     */
  reserveNow(params: ReserveNow): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/reserve-now`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`ReserveNow`);
      })
    );
  }

    /**
     * Reset
     */
  reset(params: Reset): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/reset`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Reset`);
      })
    );
  }

    /**
     * Send local list
     */
  sendLocalList(params: SendLocalList): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/send-local-list`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Send local list`);
      })
    );
  }

    /**
     * Set charging profile
     */
  setChargingProfile(params: SetChargingProfile): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/set-charging-profile`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Set charging profile`);
      })
    );
  }

    /**
     * Trigger message
     */
  triggerMessage(params: TriggerMessage): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/trigger-message`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Trigger message`);
      })
    );
  }

    /**
     * Unlock connector
     */
  unlockConnector(params: UnlockConnector): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/unlock-connector`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Unlock connector`);
      })
    );
  }

    /**
     * Update firmware
     */
  updateFirmware(params: UpdateFirmware): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/update-firmware`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Update firmware`);
      })
    );
  }

  /**
   * Commission Charger
   */
  commissionCharger(params: ChangeConfiguration[]) {
    let configRequests = [];
    for (let i = 0; i < params.length; i++) {
      configRequests.push(this.http.post<GenericResponse>(`${this.backendApiUrl}/charger-operations/change-configuration`, params[i], this.httpOptions));
    }

    return forkJoin(configRequests)
      .pipe(
        tap((res: GenericResponse[]) => {
          this.log(`Commission charger responses received`);
        })
      );
  }

    /** Log a ChargerService message */
    private log(message: string) {
        // console.log(`ChargerService: ${message}`);
    }
}
