<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="chargingStationIdentifier" class="fw-semibold">Station</label>
            <input type="text" class="form-control" id="chargingStationIdentifier" name="chargingStationIdentifier" 
                required readonly [(ngModel)]="newAction.chargingStationIdentifier" #chargingStationIdentifier="ngModel">
            <span style="color:red" [hidden]="chargingStationIdentifier.valid || chargingStationIdentifier.pristine"> 
                <p>Station Identifier is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="commaSeparatedAddUpdateList" class="fw-semibold">Add/Update List of TagIDs (Comma separated)</label>
            <input type="text" class="form-control" id="commaSeparatedAddUpdateList" name="commaSeparatedAddUpdateList" 
                [(ngModel)]="newAction.commaSeparatedAddUpdateList" #commaSeparatedAddUpdateList="ngModel">
        </div>
        <div class="form-group mb-3">
            <label for="commaSeparatedDeleteList" class="fw-semibold">List of TagIDs to Delete (Comma separated)</label>
            <input type="text" class="form-control" id="commaSeparatedDeleteList" name="commaSeparatedDeleteList" 
                [(ngModel)]="newAction.commaSeparatedDeleteList" #commaSeparatedDeleteList="ngModel">
        </div>
        <div class="form-group mb-3">
            <label for="listVersion" class="fw-semibold">List Version</label>
            <input type="number" class="form-control" id="listVersion" name="listVersion" 
                required [(ngModel)]="newAction.listVersion" #listVersion="ngModel" min="1">
            <span style="color:red" [hidden]="listVersion.valid || listVersion.pristine"> 
                <p>List Version is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="sendEmptyListWhenFull" class="fw-semibold">Send Empty List When Full</label>
            <select class="form-select" id="sendEmptyListWhenFull" name="sendEmptyListWhenFull" [(ngModel)]="newAction.sendEmptyListWhenFull" #sendEmptyListWhenFull="ngModel">
                <option value="true">True</option>
                <option value="false">False</option>
            </select>
        </div>
        <div class="form-group mb-3">
            <label for="updateType" class="fw-semibold">Update Type</label>
            <select class="form-select" id="updateType" name="updateType" [(ngModel)]="newAction.updateType" #updateType="ngModel">
                <option value="Differential">Differential</option>
                <option value="Full">Full</option>
            </select>
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">Submit</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>