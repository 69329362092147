import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Charger, ChangeConfiguration } from 'src/app/core/models/charger.models';
import { ChargerService } from 'src/app/core/services/charger.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-change-configuration-modal',
  templateUrl: './change-configuration-modal.component.html',
  styleUrls: ['./change-configuration-modal.component.scss']
})
export class ChangeConfigurationModalComponent implements OnInit {

  @Input() public charger!: Charger;
  @Input() public isFromList!: boolean;
  @Input() public connectorIdentifier!: number;

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "PAGES.CHARGERS.LIST.CHANGECONFIGURATION";

  constructor(
    public modal: NgbActiveModal, 
    private chargerService: ChargerService,
    public sweetalertService: SweetalertService) { }
    public newAction!: {
      chargingStationIdentifier: string, confKey: string, customConfKey: string, keyType: string, value: string
    }

    ngOnInit(): void {
      let connectorId = 0;
      if (!this.isFromList) {
        connectorId = this.connectorIdentifier;
      }
      this.newAction = {
        chargingStationIdentifier: this.charger.chargingStationIdentifier,
        confKey: '',
        customConfKey: '',
        keyType: '',
        value: ''
      }
    }

    // Handle Create button click
  onSubmit() {
    let changeConfiguration: ChangeConfiguration = {
      chargingStationSelectList: [
        {
          chargingStationIdentifier: this.newAction.chargingStationIdentifier,
          endpointAddress: 'ChangeConfiguration',
          ocppTransport: "JSON"
        }
      ],
      confKey: this.newAction.confKey,
      customConfKey: this.newAction.customConfKey,
      keyType: this.newAction.keyType,
      value: this.newAction.value
    }

    this.processing = true;
    this.chargerService.changeConfiguration(changeConfiguration).subscribe({
      next: (v) => {
        // console.log('changeConfiguration request submitted : ', v);
        this.sweetalertService.success(v.message).then(() => {
          this.modal.close(v);
        });
      },
      error: (e) => {
        // console.error('error occured : ', e);
        this.sweetalertService.failure(e).then(() => {
          this.processing = false;
        });
      },
      complete: () => {
        // console.info('changeConfiguration completed');
      }
    });
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }

}
