import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetModule } from './widget/widget.module';
import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { ListFilter3Pipe, ListFilter4Pipe, ListFilter5Pipe, ListFilterPipe } from '../list-filter.pipe';
import { ListFilter2Pipe } from '../list-filter.pipe';
import { PhonePipe } from '../phone.pipe';
import { DatatableComponent } from './datatable/datatable.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatatableModalComponent } from './datatable/datatable-modal/datatable-modal.component';
import { NumberAlphabetPipe } from '../number-alphabet.pipe';
import { TruncatePipe } from '../truncate.pipe';

@NgModule({
  declarations: [
    ListFilterPipe,
    ListFilter2Pipe,
    ListFilter3Pipe,
    ListFilter4Pipe,
    ListFilter5Pipe,
    TruncatePipe,
    PhonePipe,
    NumberAlphabetPipe,
    PagetitleComponent,
    DatatableComponent,
    DatatableModalComponent
  ],
  imports: [
    CommonModule,
    WidgetModule,
    FormsModule,
    NgbModule
  ],
  exports: [PagetitleComponent,DatatableComponent,WidgetModule,ListFilterPipe,ListFilter2Pipe,ListFilter3Pipe,ListFilter4Pipe,ListFilter5Pipe,PhonePipe,NumberAlphabetPipe,TruncatePipe]
})
export class SharedModule { }
