<!-- start page title -->
<app-pagetitle title="Wallets" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<app-datatable
    title="Orders"
    [rows]="orders"
    [columns]="columns"
    [collectionSize]="collectionSize"
    [paged]="true"
    [searchable]="true"
    [hasAction]="true"
    (onFetchData)="fetchData($event)">
    <ng-template let-row #actions>
        <a routerLink="/billing-management/orders/{{row.reference}}" [state]="{order: row}" class="btn btn-outline-info" title="View">
            <i class="fas fa-search"></i>
        </a>
    </ng-template>
</app-datatable>