import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddRate, Rate } from 'src/app/core/models/rate.models';
import { RateService } from 'src/app/core/services/rate.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-rate-modal',
  templateUrl: './rate-modal.component.html',
  styleUrls: ['./rate-modal.component.scss']
})
export class RateModalComponent implements OnInit {

  @Input() public rate!: Rate;
  @Input() public isAddNew!: boolean;
  addRate!: AddRate;
  isApiLoaded = false;
  options: any = {
    componentRestrictions: { country: ['CA', 'US'] }
  }

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "Edit Rate";

  constructor(
    public modal: NgbActiveModal, 
    private rateService: RateService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    if (this.rate == undefined) {
      this.addRate = {
        chargerType: "",
        currency: "",
        sessionFee: 0,
        energyRateUnit: "",
        energyRate: 0,
        idlingFeeUnit: "",
        idlingFee: 0,
        parkingFeeUnit: "",
        parkingFee: 0,
        enabled: false
      };
      this.title = "Add Rate";
    } else {
      this.addRate = {
        chargerType: "this.rate.name",
        currency: this.rate.currency,
        sessionFee: this.rate.sessionFee,
        energyRateUnit: this.rate.energyRateUnit,
        energyRate: this.rate.energyRate,
        idlingFeeUnit: this.rate.idlingFeeUnit,
        idlingFee: this.rate.idlingFee,
        parkingFeeUnit: this.rate.parkingFeeUnit,
        parkingFee: this.rate.parkingFee,
        enabled: this.rate.enabled
      };
    }
  }
  
  // Handle Create button click
  onSubmit() {
    // console.log('create clicked');
    this.processing = true;
    if (this.isAddNew) {
      // Add a new rate
      this.rateService.addRate(this.addRate).subscribe({
        next: (v) => {
          // console.log('added rate : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          // console.info('rates saved');
        }
      });
    } else {
      // Update an existing rate
      this.rateService.updateRate(this.addRate, this.rate.uuid).subscribe({
        next: (v) => {
          // console.log('updated rate : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });  
        },
        complete: () => {
          // console.info('rates saved');
        }
      });
    }
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }

}
