import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { InitiateRefund, Refund, PagedRefund } from '../models/refund.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';
import { GenericFilter } from '../models/generic-filter.models';
import { Analytics } from '../models/dashboard.models';

@Injectable({
  providedIn: 'root'
})
export class RefundService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getBillingManagementUrl();
  }

  /**
     * Get all refunds
     */
  getRefunds(params: GenericFilter): Observable<PagedRefund> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedRefund>(`${this.backendApiUrl}/refunds?${queryString}`, this.httpOptions).pipe(
      tap((pagedRefund: PagedRefund) => {
        this.log(`Retrieved refunds`);
      })
    );
  }

  /**
     * Initiate refund
     */
  initiateRefund(params: any): Observable<GenericResponse> {
    // console.log('request : ', params);

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/refunds`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Initiated refund`);
      })
    );
  }

  /**
     * Get total refunds
     */
  getTotalRefunds(): Observable<Analytics> {
    return this.http.get<Analytics>(`${this.backendApiUrl}/refunds/total-refunds`, this.httpOptions).pipe(
      tap((pagedUser: Analytics) => {
        this.log(`Retrieved total refunds`);
      })
    );
  }

  /** Log a RefundService message */
  private log(message: string) {
    // console.log(`RefundService: ${message}`);
  }
}
