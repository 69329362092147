import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { PayOrderItem, ValidateOrder } from 'src/app/core/models/order.models';
import { OrderService } from 'src/app/core/services/order.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-test-make-payment',
  templateUrl: './test-make-payment.component.html',
  styleUrls: ['./test-make-payment.component.scss']
})
export class TestMakePaymentComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  validateOrder!: ValidateOrder;
  processing: boolean = false;
  entryForm!: UntypedFormGroup;

  constructor( 
    private router: Router,
    private orderService: OrderService, 
    public sweetalertService: SweetalertService) {}

  ngOnInit(): void {
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: "Billing Management" },
      { label: "Orders" },
      { label: "Test Make Payment", active: true },
    ];

    let orderItem: PayOrderItem = {
      amount: 0,
      description: '',
      feeItem: '',
      unitCost: 0,
      unitsAcquired: 0
    }

    this.validateOrder = {
      customerName: '',
      customerUuid: '',
      orderItems: []
    }
    this.validateOrder.orderItems.push(orderItem);
  }

  addMore() {
    let orderItem: PayOrderItem = {
      amount: 0,
      description: '',
      feeItem: '',
      unitCost: 0,
      unitsAcquired: 0
    }

    this.validateOrder.orderItems.push(orderItem);
  }

  onSubmit() {
    // console.log('create clicked');
    this.processing = true;

    this.validateOrder.orderItems.forEach(function(orderItem) {
      orderItem.amount = orderItem.unitCost * orderItem.unitsAcquired
    });

    // console.log('validateOrder : ', JSON.stringify(this.validateOrder));

    this.orderService.validateOrder(this.validateOrder).subscribe({
      next: (v) => {
        // console.log('validated order : ', v);
        const navigationExtras: NavigationExtras = {state: v};
        this.router.navigate(['billing-management/orders/test-review-payment'], navigationExtras);
      },
      error: (e) => {
        // console.error('error occured : ', e);
        this.sweetalertService.failure(e).then(() => {
          this.processing = false;
        });
      },
      complete: () => {
        // console.info('validated order completed');
      }
    });
  }

}
