import { Component, OnInit, Input } from '@angular/core';
import { Transaction } from 'src/app/core/models/transaction.models';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})

/**
 * Transaction Component
 */
export class TransactionComponent implements OnInit {

  @Input() transactions!: Transaction[];
  empty: boolean = true;

  constructor() { }

  ngOnInit(): void {
    if(this.transactions.length > 0) {
      this.empty = false;
    }
  }

}
