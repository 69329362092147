import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { PushNotification, PagedPushNotification } from '../models/push-notification.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';
import { GenericFilter } from '../models/generic-filter.models';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getUserManagementUrl();
  }

  /**
     * Get all push notifications
     */
  getPushNotifications(params: GenericFilter): Observable<PagedPushNotification> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    queryString = `${queryString}`;
    return this.http.get<PagedPushNotification>(`${this.backendApiUrl}/push-notifications?${queryString}`, this.httpOptions).pipe(
      tap((pagedPushNotification: PagedPushNotification) => {
        this.log(`Retrieved push notifications`);
      })
    );
  }

  /** Log a PushNotificationService message */
  private log(message: string) {
    console.log(`PushNotificationService: ${message}`);
  }
}
