<div class="row mt-5" *ngFor="let connector of connectorProfiles">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex mb-4">
                    
                </div>
                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6">
                        <h4 class="card-title flex-grow-1">{{ charger.chargingStationIdentifier }} Connector {{
                            connector.connectorIdentifier}}</h4>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <input type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table" [(ngModel)]="connector.searchTerm"
                                    (input)="search(connector.connectorIdentifier, connector.searchTerm)">
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>

                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable"
                        class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Purpose</th>
                                <th>Kind</th>
                                <th>Recurrency</th>
                                <th>Valid From</th>
                                <th>Valid To</th>
                                <th>Start Schedule</th>
                                <th>Duration In Seconds</th>
                                <th>Charging Rate Unit</th>
                                <th>Min Charging Rate</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [hidden]="connector.chargingProfiles">
                                <td colspan="11">No data available in table</td>
                            </tr>
                            <tr *ngFor="let chargingProfile of $any(connector.chargingProfiles)"
                                [hidden]="!connector.chargingProfiles">
                                <td>
                                    <ngb-highlight [result]="chargingProfile.description" [term]="connector.searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="chargingProfile.chargingProfilePurpose"
                                        [term]="connector.searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="chargingProfile.chargingProfileKind"
                                        [term]="connector.searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="chargingProfile.recurrencyKind"
                                        [term]="connector.searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    {{ +chargingProfile.validFrom | date:'yyyy-MM-dd, h:mm a' }}
                                </td>
                                <td>
                                    {{ +chargingProfile.validTo | date:'yyyy-MM-dd, h:mm a' }}
                                </td>
                                <td>
                                    {{ +chargingProfile.startSchedule | date:'yyyy-MM-dd, h:mm a' }}
                                </td>
                                <td>
                                    {{ chargingProfile.durationInSeconds }}
                                </td>
                                <td>
                                    {{ chargingProfile.chargingRateUnit }}
                                </td>
                                <td>
                                    {{ chargingProfile.minChargingRate }}
                                </td>
                                <td>
                                    <div class="d-flex flex-wrap gap-2">
                                        <button type="button" class="btn btn-light" title="Clear charging profile"
                                            (click)="clearChargingProfile(chargingProfile, connector)">
                                            <i class=" bx bx-minus-circle"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- End table -->
            </div>
        </div>
    </div>
</div>