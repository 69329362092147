<div class="modal-header">
    <h5 class="modal-title mt-0">Edit {{ title }}</h5>
    <button
        type="button"
        class="btn-close"
        aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div
            *ngFor="let field of fields; let i = index"
            class="form-group mb-3">
            <label for="{{ field.key }}" class="fw-semibold">{{
                field.name
            }}</label>
            <div *ngIf="field.editType === 'text' || field.editType === 'number'">
                <input
                    type="{{ field.editType }}"
                    class="form-control"
                    id="{{ field.key }}"
                    name="{{ field.key }}"
                    [required]="field.required === true"
                    [(ngModel)]="updatedData[field.key]"
                    #name="ngModel" />
                <span style="color: red" [hidden]="name.valid || name.pristine">
                    <p>{{ field.name }} is required</p>
                </span>
            </div>
            <select
                *ngIf="field.editType === 'boolean'"
                class="form-select"
                id="{{ field.key }}"
                name="{{ field.key }}"
                [(ngModel)]="updatedData[field.key]"
                [required]="field.required === true"
                #toggle="ngModel">
                <option [ngValue]="true">{{field.booleanDisplay ? field.booleanDisplay[0] : "Yes"}}</option>
                <option [ngValue]="false">{{field.booleanDisplay ? field.booleanDisplay[1] : "No"}}</option>
            </select>
            <select
                *ngIf="field.editType === 'select'"
                class="form-select"
                id="{{ field.key }}"
                name="{{ field.key }}"
                [(ngModel)]="updatedData[field.key]"
                [required]="field.required === true"
                #select="ngModel">
                <option
                    *ngFor="let option of field.optionList"
                    [ngValue]="option">
                    {{ option }}
                </option>
            </select>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">
        Cancel
    </button>
    <button
        type="button"
        class="btn btn-primary w-md"
        [hidden]="processing"
        (click)="onSubmit()"
        [disabled]="!entryForm.form.valid">
        Save
    </button>
    <button
        type="button"
        class="btn btn-primary w-md"
        [hidden]="!processing"
        disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </button>
</div>
