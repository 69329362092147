<!-- start page title -->
<app-pagetitle title="Test Review Payment" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Testing Review Payment</h4>
                <p class="card-title-desc">This page merely test the Credit card payment integration and
                    should not be deployed to the LIVE server!</p>

                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Description</th>
                                <th>Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let orderItem of validateOrderResponse.orderItems">
                                <td>{{ orderItem.feeItem }}</td>
                                <td>{{ orderItem.description }}</td>
                                <td>{{ orderItem.amount }}</td>
                            </tr>
                            <tr>
                                <td colspan="2">Subtotal</td>
                                <td>{{ validateOrderResponse.subTotal }}</td>
                            </tr>
                            <tr>
                                <td colspan="2">PST</td>
                                <td>{{ validateOrderResponse.pst }}</td>
                            </tr>
                            <tr>
                                <td colspan="2">GST</td>
                                <td>{{ validateOrderResponse.gst }}</td>
                            </tr>
                            <tr>
                                <td colspan="2"><strong>Total</strong></td>
                                <td><strong>{{ validateOrderResponse.total }}</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="mt-4">
                    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
                        (click)="onSubmit()">Review</button>
                    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>