import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { SharedModule } from "src/app/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDatepickerModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FlatpickrModule } from "angularx-flatpickr";
import { Select2Module } from "ng-select2-component";

import { ChargerManagementRoutingModule } from "./charger-management-routing.module";
import { ChargersComponent } from "./chargers/chargers.component";
import { SitesComponent } from "./sites/sites.component";
import { MembershipCardsStickersComponent } from "./membership-cards-stickers/membership-cards-stickers.component";
import { SiteModalComponent } from "./sites/site-modal/site-modal.component";
import { ChargerModalComponent } from "./chargers/charger-modal/charger-modal.component";
import { ChargerViewComponent } from "./chargers/charger-view/charger-view.component";
import { StartTransactionModalComponent } from "./chargers/start-transaction-modal/start-transaction-modal.component";
import { TriggerMessageModalComponent } from "./chargers/trigger-message-modal/trigger-message-modal.component";
import { ResetModalComponent } from "./chargers/reset-modal/reset-modal.component";
import { ChangeAvailabilityModalComponent } from "./chargers/change-availability-modal/change-availability-modal.component";
import { ClearCacheModalComponent } from "./chargers/clear-cache-modal/clear-cache-modal.component";
import { UnlockConnectorModalComponent } from "./chargers/unlock-connector-modal/unlock-connector-modal.component";
import { MembershipModalComponent } from "./membership-cards-stickers/membership-modal/membership-modal.component";
import { ChargerTransactionsComponent } from "./chargers/charger-view/charger-transactions/charger-transactions.component";
import { ChargerLogsComponent } from "./chargers/charger-view/charger-logs/charger-logs.component";
import { PayloadModalComponent } from "./chargers/charger-view/payload-modal/payload-modal.component";
import { ReserveNowModalComponent } from "./chargers/reserve-now-modal/reserve-now-modal.component";
import { CancelReservationModalComponent } from "./chargers/cancel-reservation-modal/cancel-reservation-modal.component";
import { ChargingProfilesComponent } from "./charging-profiles/charging-profiles.component";
import { ChargingProfileModalComponent } from "./charging-profiles/charging-profile-modal/charging-profile-modal.component";
import { ChangeConfigurationModalComponent } from "./chargers/change-configuration-modal/change-configuration-modal.component";
import { GetCompositeScheduleModalComponent } from "./chargers/get-composite-schedule-modal/get-composite-schedule-modal.component";
import { GetConfigurationModalComponent } from "./chargers/get-configuration-modal/get-configuration-modal.component";
import { GetDiagnosticsModalComponent } from "./chargers/get-diagnostics-modal/get-diagnostics-modal.component";
import { UpdateFirmwareModalComponent } from "./chargers/update-firmware-modal/update-firmware-modal.component";
import { CanelReservationModalComponent } from "./chargers/canel-reservation-modal/canel-reservation-modal.component";
import { SendLocalListModalComponent } from "./chargers/send-local-list-modal/send-local-list-modal.component";
import { SetChargingProfileModalComponent } from "./chargers/set-charging-profile-modal/set-charging-profile-modal.component";
import { ClearChargingProfileModalComponent } from "./chargers/clear-charging-profile-modal/clear-charging-profile-modal.component";
import { ChargerProfilesComponent } from "./chargers/charger-view/charger-profiles/charger-profiles.component";
import { MeterValuesComponent } from "./chargers/charger-view/meter-values/meter-values.component";
import { ChargerReservationsComponent } from "./chargers/charger-view/charger-reservations/charger-reservations.component";
import { RatesComponent } from "./rates/rates.component";
import { RateModalComponent } from "./rates/rate-modal/rate-modal.component";
import { QrCodeModalComponent } from "./chargers/qr-code-modal/qr-code-modal.component";
import { SiteViewComponent } from "./sites/site-view/site-view.component";
import { ListChargersComponent } from "./list-chargers/list-chargers.component";
import { SiteRateComponent } from "./sites/site-view/site-rate/site-rate.component";
import { SiteImagesComponent } from "./sites/site-view/site-images/site-images.component";
import { TranslateModule } from "@ngx-translate/core";
import { GoogleMapsModule } from "@angular/google-maps";
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";

@NgModule({
    declarations: [
        ChargersComponent,
        SitesComponent,
        MembershipCardsStickersComponent,
        SiteModalComponent,
        ChargerModalComponent,
        ChargerViewComponent,
        StartTransactionModalComponent,
        TriggerMessageModalComponent,
        ResetModalComponent,
        ChangeAvailabilityModalComponent,
        ClearCacheModalComponent,
        UnlockConnectorModalComponent,
        MembershipModalComponent,
        ChargingProfilesComponent,
        ChargerTransactionsComponent,
        ChargerLogsComponent,
        PayloadModalComponent,
        ReserveNowModalComponent,
        CancelReservationModalComponent,
        ChargingProfilesComponent,
        ChargingProfileModalComponent,
        ChangeConfigurationModalComponent,
        GetCompositeScheduleModalComponent,
        GetConfigurationModalComponent,
        GetDiagnosticsModalComponent,
        UpdateFirmwareModalComponent,
        CanelReservationModalComponent,
        SendLocalListModalComponent,
        SetChargingProfileModalComponent,
        ClearChargingProfileModalComponent,
        ChargerProfilesComponent,
        MeterValuesComponent,
        ChargerReservationsComponent,
        RatesComponent,
        RateModalComponent,
        QrCodeModalComponent,
        SiteViewComponent,
        ListChargersComponent,
        SiteRateComponent,
        SiteImagesComponent,
    ],
    imports: [
        TranslateModule,
        CommonModule,
        SharedModule,
        NgxDatatableModule,
        FormsModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        NgbModule,
        Select2Module,
        FlatpickrModule.forRoot(),
        ChargerManagementRoutingModule,
        NgxGpAutocompleteModule,
        GoogleMapsModule,
    ],
})
export class ChargerManagementModule {}
