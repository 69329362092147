import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardsComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  title!: string;
  @ViewChild('content') content: any;

  constructor() { }

  ngOnInit(): void {

    this.title = 'PAGES.DASHBOARD.TEXT';

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'PAGES.DASHBOARD.TEXT' },
      { label: 'PAGES.DASHBOARD.TEXT', active: true }
    ];
  }

}
