<div class="card card-height-100">
    <div class="card-body">
        <div class="float-end">
            <div class="dropdown" ngbDropdown>
                
            </div>
        </div>
        <h4 class="card-title mb-4">{{ 'PAGES.DASHBOARD.LIST.LATESTTRANSACTIONS' | translate }}</h4>
        <div class="table-responsive">
            <app-transaction [transactions]="transactions"></app-transaction>
        </div>
    </div>
</div>