import { Component } from '@angular/core';

@Component({
  selector: 'app-firmwares',
  templateUrl: './firmwares.component.html',
  styleUrl: './firmwares.component.scss'
})
export class FirmwaresComponent {

}
