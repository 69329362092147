import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';
import { GenericFilter } from '../models/generic-filter.models';
import { PagedChargingProfile } from '../models/charging-profile.models';

@Injectable({
  providedIn: 'root'
})
export class ChargingProfileService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getChargingStationManagementUrl();
  }

  /**
     * Get all chargingProfiles
     */
  getChargingProfiles(params: GenericFilter): Observable<PagedChargingProfile> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedChargingProfile>(`${this.backendApiUrl}/charging-profiles?${queryString}`, this.httpOptions).pipe(
      tap((pagedOrganization: PagedChargingProfile) => {
        this.log(`Retrieved chargingProfiles`);
      })
    );
  }

  /**
     * Add chargingProfile
     */
  addChargingProfile(params: any): Observable<GenericResponse> {
    this.log(`Add chargingProfile request ${JSON.stringify(params)}`);

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/charging-profiles/add`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added chargingProfile`);
      })
    );
  }

  /**
     * Update chargingProfile
     */
  updateChargingProfile(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/charging-profiles/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated chargingProfile`);
      })
    );
  }

  /** Log a ChargingProfileService message */
  private log(message: string) {
    console.log(`ChargingProfileService: ${message}`);
  }
}
