<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="notificationType" class="fw-semibold">Notification Type</label>
            <select class="form-select" id="notificationType" name="notificationType" [(ngModel)]="emailTemplate.notificationType" #notificationType="ngModel" required>
                <!-- <option value="CHARGING_SESSION_ENDED">Charging Session Ended</option>
                <option value="CHARGING_SESSION_INTERRUPTED">Charging Session Interrupted</option>
                <option value="CHARGING_SESSION_STARTED">Charging Session Started</option>
                <option value="CHARGING_SESSION_STARTED">Charging Session Started</option>
                <option value="FAVOURITE_STATION_OUT_OF_SERVICE">Favourite Station Out of Service</option>
                <option value="IDLING_FEES_BEGIN_IN_5_MINUTES">Idling Fees Begin In 5 Minutes</option>
                <option value="ORDER_RECEIVED">Order Received</option>
                <option value="RESET_TOKEN">Password Reset Token</option>
                <option value="PARKING_EXPIRES_IN_10_MINUTES">Parking Expires in 10 Minutes</option>
                <option value="RECEIVE_PROMOTIONAL_OFFERS">Receive Promotional Offers</option>
                <option value="CONFIRMATION_TOKEN">Registration Confirmation Token</option>
                <option value="STORED_VALUE_LESS_THAN_10">Stored Value Less Than 10</option>
                <option value="VEHICLE_BATTERY_CHARGED_TO_80">Vehicle Battery Charged to 80</option>
                <option value="WILL_YOU_LIKE_TO_CHARGE">Will You Like to Charge</option> -->
                <option *ngFor="let n of notificationTypes" [value]="n.key">{{n.value}}</option>
            </select>
        </div>

        <div class="form-group mb-3">
            <label for="subject" class="fw-semibold">Subject</label>
            <input type="text" class="form-control" id="subject" name="subject" 
                required [(ngModel)]="emailTemplate.subject" #subject="ngModel">
            <span style="color:red" [hidden]="subject.valid || subject.pristine"> 
                <p>Subject is required</p> 
            </span>
        </div>

        <div class="form-group mb-3">
            <label for="message" class="fw-semibold">Message</label>
            <editor id="message" name="message" [init]="editorConfig" [(ngModel)]="emailTemplate.message" #message="ngModel"></editor>
        </div>

    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">Save</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>