import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CountUpModule } from 'ngx-countup';
import { NgApexchartsModule } from "ng-apexcharts";
import {
    NgbDropdownModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbCollapseModule,
    NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { GoogleMapsModule } from '@angular/google-maps';

// Routings
import { SharedModule } from "../shared/shared.module";
import { PagesRoutingModule } from "./pages-routing.modules";

// Modules
import { SettingsModule } from "./settings/settings.module";

// Components
// import { DashboardsComponent } from "./dashboard/dashboards/dashboards.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ChargerManagementModule } from "./charger-management/charger-management.module";
import { BillingManagementModule } from "./billing-management/billing-management.module";
import { NotificationManagementModule } from "./notification-management/notification-management.module";

// Pipes

@NgModule({
    // declarations: [DashboardsComponent],
    imports: [
        CommonModule,
        PagesRoutingModule,
        NgApexchartsModule,
        NgbDropdownModule,
        NgbAccordionModule,
        NgbNavModule,
        NgbProgressbarModule,
        NgbCollapseModule,
        NgbTooltipModule,
        NgbPopoverModule,
        CountUpModule,
        SettingsModule,
        ChargerManagementModule,
        BillingManagementModule,
        NotificationManagementModule,
        SharedModule,
        CommonModule,
        NgxDatatableModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        GoogleMapsModule,
        NgxGpAutocompleteModule,
    ],
})
export class PagesModule {}
