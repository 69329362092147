import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChargersComponent } from './chargers/chargers.component';
import { SitesComponent } from './sites/sites.component';
import { MembershipCardsStickersComponent } from './membership-cards-stickers/membership-cards-stickers.component';
import { ChargerViewComponent } from './chargers/charger-view/charger-view.component';
import { ChargingProfilesComponent } from './charging-profiles/charging-profiles.component';
import { MeterValuesComponent } from './chargers/charger-view/meter-values/meter-values.component';
import { RatesComponent } from './rates/rates.component';
import { SiteViewComponent } from './sites/site-view/site-view.component';

const routes: Routes = [
  {
    path: 'chargers',
    component: ChargersComponent,
  },
  {
    path: 'chargers/:chargerId',
    component: ChargerViewComponent
  },
  {
    path: 'chargers/:chargerId/meter-values/:transactionUuid',
    component: MeterValuesComponent
  },
  {
    path: 'charging-profiles',
    component: ChargingProfilesComponent
  },
  {
    path: 'sites',
    component: SitesComponent
  },
  {
    path: 'sites/:siteId',
    component: SiteViewComponent
  },
  {
    path: 'membership-cards-stickers',
    component: MembershipCardsStickersComponent
  },
  {
    path: 'rates',
    component: RatesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChargerManagementRoutingModule { }
