import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { VehicleModel } from '../models/vehicle-model.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';

@Injectable({
  providedIn: 'root'
})
export class VehicleModelService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getBackEndApiUrl();
  }

  /**
     * Get all vehicle-models
     */
  getVehicleModels(vehicleMake: string): Observable<VehicleModel[]> {
    let queryString = 'vehicleMake=' + vehicleMake;
    return this.http.get<VehicleModel[]>(`${this.backendApiUrl}/vehicle-models?${queryString}`, this.httpOptions).pipe(
      tap((vehicleModels: VehicleModel[]) => {
        this.log(`Retrieved vehicleModels`);
      })
    );
  }

  /**
     * Add vehicle-model
     */
  addVehicleModel(params: any): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/vehicle-models`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added vehicle-model`);
      })
    );
  }

  /**
     * Update vehicle-model
     */
  updateVehicleModel(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/vehicle-models/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated vehicle-model`);
      })
    );
  }

  /** Log a VehicleModelService message */
  private log(message: string) {
    console.log(`VehicleModelService: ${message}`);
  }
}
