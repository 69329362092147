import { DecimalPipe } from '@angular/common';
import { Component, OnInit, PipeTransform, QueryList, ViewChildren } from '@angular/core';
import { OrganizationType } from 'src/app/core/models/organization-type.models';
import { OrganizationTypeService } from 'src/app/core/services/organization-type.service';
import { OrganizationTypeModalComponent } from './organization-type-modal/organization-type-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Table Data Match with Search input
 * @param  OrganizationType field value fetch
 * @param term Search the value
 */
function matches(table: OrganizationType, term: string, pipe: PipeTransform) {
  return pipe.transform(table.id).includes(term) || table.name.toLowerCase().includes(term.toLowerCase())
      || table.description.toLowerCase().includes(term)
      || table.uuid.toLowerCase().includes(term)
      || table.createdAt.toLowerCase().includes(term)
      || table.updatedAt.toLowerCase().includes(term);
}

@Component({
  selector: 'app-organization-types',
  templateUrl: './organization-types.component.html',
  styleUrls: ['./organization-types.component.scss'],
  providers: [DecimalPipe]
})
export class OrganizationTypesComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  searchTerm!: string;
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  organizationType!: OrganizationType;
  organizationTypes!: OrganizationType[];
  allOrganizationTypes!: OrganizationType[];

  constructor(private organizationTypeService: OrganizationTypeService, private pipe: DecimalPipe, public modalService: NgbModal) { }

  ngOnInit(): void {
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Settings' },
      { label: 'Organization Types', active: true }
    ];
    
    this.fetchData();
  }

  fetchData() {
    this.organizationTypeService.getOrganizationTypes().subscribe({
      next: (v) => {
        console.log('returned organizationTypes : ', v);
        this.collectionSize = v.length;
        this.organizationTypes = v;
        this.allOrganizationTypes = this.organizationTypes;
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('organizationTypes retrieved');
      }
    });
  }

  search(value: string): void {
    this.organizationTypes = this.allOrganizationTypes.filter(table => matches(table, value, this.pipe));

    // this.organizationTypes = this.allOrganizationTypes.filter((val) => val.name.toLowerCase().includes(value));
    this.collectionSize = this.organizationTypes.length;
  }

  openModal(organizationType: any, isAddNew: boolean) {
    const modalRef = this.modalService.open(OrganizationTypeModalComponent);
    modalRef.componentInstance.organizationType = organizationType;
    modalRef.componentInstance.isAddNew = isAddNew;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  //refresh page
  refreshPage(): void {
    window.location.reload();
  }
}
