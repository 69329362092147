import { Component, OnInit } from '@angular/core';
import { Country } from 'src/app/core/models/country.models';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { Organization } from 'src/app/core/models/organization.models';
import { Role } from 'src/app/core/models/role.models';
import { User } from 'src/app/core/models/user.models';
import { CountryService } from 'src/app/core/services/country.service';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { RoleService } from 'src/app/core/services/role.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-recent-users-card',
  templateUrl: './recent-users-card.component.html',
  styleUrls: ['./recent-users-card.component.scss']
})
export class RecentUsersCardComponent implements OnInit {

  users!: User[];
  roles!: Role[];
  countries!: Country[];
  organizations!: Organization[];
  
  constructor(
    private userService: UserService,
    private roleService: RoleService,
    private countryService: CountryService, 
    private organizationService: OrganizationService
  ) { }

  ngOnInit(): void {
    this.fetchUserData();
  }

  private fetchUserData() {
    const genericFilter: GenericFilter = new GenericFilter('', 0, 5, 'id', 'desc');
    this.userService.getUsers(genericFilter, '').subscribe({
      next: (pagedUsers) => {
        // console.log('returned users : ', pagedUsers);
        if (pagedUsers.content.length > 0) {
          this.users = pagedUsers.content;
        }
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('users retrieved');
      }
    });
  }

  fetchRolesData() {
    this.roleService.getRoles().subscribe({
      next: (v) => {
        this.roles = v;
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('roles retrieved');
      }
    });
  }

  fetchCountriesData() {
    this.countryService.getCountries().subscribe({
      next: (v) => {
        this.countries = v;
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('countries retrieved');
      }
    });
  }

  fetchOrganizationsData() {
    const genericFilter: GenericFilter = {
      size: 100
    }
    this.organizationService.getOrganizations(genericFilter).subscribe({
      next: (v) => {
        this.organizations = v.content;
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('organizations retrieved');
      }
    });
  }
}
