import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardsComponent } from "./dashboard/dashboards/dashboards.component";

const routes: Routes = [
    {
        path: "",
        component: DashboardsComponent,
    },
    {
        path: "",
        loadChildren: () =>
            import("./dashboard/dashboard.module").then(
                (m) => m.DashboardModule
            ),
    },
    {
        path: "settings",
        loadChildren: () =>
            import("./settings/settings.module").then((m) => m.SettingsModule),
    },
    {
        path: "account-management",
        loadChildren: () =>
            import("./account-management/account-management.module").then(
                (m) => m.AccountManagementModule
            ),
    },
    {
        path: "billing-management",
        loadChildren: () =>
            import("./billing-management/billing-management.module").then(
                (m) => m.BillingManagementModule
            ),
    },
    {
        path: "charger-management",
        loadChildren: () =>
            import("./charger-management/charger-management.module").then(
                (m) => m.ChargerManagementModule
            ),
    },
    {
        path: "notification-management",
        loadChildren: () =>
            import(
                "./notification-management/notification-management.module"
            ).then((m) => m.NotificationManagementModule),
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PagesRoutingModule {}
