<div>
    <div class="d-flex mb-4">
        <h4 class="card-title flex-grow-1">List of Chargers</h4>
        <div class="flex-shrink-0">
            <button type="button" class="btn btn-primary w-md" (click)="openModal(charger, true)">Add New</button>
        </div>
    </div>

    <div class="row mb-md-2">
        <div class="col-sm-12 col-md-6">
            <div class="dataTables_length" id="tickets-table_length"><label
                    class="d-inline-flex align-items-center">Show
                    <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                        [(ngModel)]="pageSize" class="form-control form-control-sm mx-2"
                        (change)="onSizeChange(pageSize)">
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="25">25</option>
                        <option [ngValue]="50">50</option>
                        <option [ngValue]="100">100</option>
                    </select> entries</label></div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
            <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                <label class="d-inline-flex align-items-center">
                    Search:
                    <input type="text" name="searchTerm" id="searchTerm"
                        class="form-control form-control-sm ms-2"
                        aria-controls="tickets-table" [(ngModel)]="searchTerm" 
                        (input)="search(searchTerm)"
                        >
                </label>
            </div>
        </div>
        <!-- End search -->
    </div>

    <!-- Table -->
    <div class="table-responsive">
        <table id="basic-datatable"
            class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
            <thead>
                <tr>
                    <th>Identifier</th>
                    <th>Name</th>
                    <th>Serial Number</th>
                    <th>Model</th>
                    <th>Vendor</th>
                    <th >Last Updated</th>
                    <th>Status</th>
                    <th >Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr [hidden]="!empty">
                    <td>No data available in table</td>
                </tr>
                <tr *ngFor="let charger of chargers" [hidden]="pagedChargers.empty">
                    <td>
                        <ngb-highlight [result]="charger.chargingStationIdentifier" [term]="searchTerm">
                        </ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [result]="charger.name" [term]="searchTerm">
                        </ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [result]="charger.serialNumber" [term]="searchTerm">
                        </ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [result]="charger.model" [term]="searchTerm">
                        </ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [result]="charger.vendor" [term]="searchTerm">
                        </ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [result]="charger.updatedAt" [term]="searchTerm">
                        </ngb-highlight>
                    </td>
                    <td>
                        <span *ngIf="charger.enabled" class="badge rounded-pill bg-success ms-1">
                            Enabled
                        </span>
                        <span *ngIf="!charger.enabled" class="badge rounded-pill bg-warning ms-1">
                            Disabled
                        </span>
                    </td>
                    <td>
                        <div class="d-flex flex-wrap gap-2">
                            <a routerLink="/charger-management/chargers/{{charger.chargingStationIdentifier}}" class="btn btn-info" title="View">
                                <i class="fas fa-search"></i>
                            </a>
                            <button type="button" class="btn btn-primary" title="Edit" (click)="openModal(charger, false)">
                                <i class="fas fa-edit"></i>
                            </button>
                            <button type="button" class="btn btn-success" title="QR Code" (click)="qrCodeModal(charger)">
                                <i class="fas fa-qrcode"></i>
                            </button>
                            <div class="dropdown" ngbDropdown>
                                <button type="button" class="btn btn-light" title="Operations" 
                                    ngbDropdownToggle id="dropdownMenuButton" data-bs-toggle="dropdown">
                                    <i class="fas fa-cogs"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-end"
                                    aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="changeAvailabilityModal(charger, true, undefined)">Change Availability</a>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="changeConfigurationModal(charger, true, undefined)">Change Configuration</a>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="clearCache(charger)">Clear Cache</a>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="getCompositeScheduleModal(charger, true, undefined)">Get Composite Schedule</a>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="getConfigurationModal(charger, true, undefined)">Get Configuration</a>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="getDiagnosticsModal(charger, true, undefined)">Get Diagnostics</a>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="getLocalListVersionModal(charger)">Get Local List Version</a>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="reserveNowModal(charger, true, undefined)">Reserve Now</a>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="resetModal(charger, true, undefined)">Reset</a>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="sendLocalListModal(charger, true, undefined)">Send Local List</a>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="setChargingProfileModal(charger, true, undefined)">Set Charging Profile</a>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="startTransactionModal(charger, true, undefined)">Start Transaction</a>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="triggerMessageModal(charger, true, undefined)">Trigger Message</a>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="unlockConnectorModal(charger, true, undefined)">Unlock Connector</a>
                                    <a class="dropdown-item"
                                        href="javascript:void(0)" (click)="updateFirmwareModal(charger, true, undefined)">Update Firmware</a>
                                </div>
                            </div>
                        </div>
                        
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- End table -->
    <div class="row justify-content-md-between align-items-md-center mt-2">
        <div class="col-sm-12 col-md-5">
            <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                Showing
                <!-- {{permissionsDatatableService.startIndex}} to
                {{permissionsDatatableService.endIndex}} of {{permissionsDatatableService.totalRecords}} -->
                entries
            </div> 
        </div>
        <!-- Pagination -->
        <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-end pagination-rounded">
                <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" 
                    [maxSize]="5" [boundaryLinks]="true" [pageSize]="pageSize" 
                    (pageChange)="pageChanged($event)" 
                    class="d-flex justify-content-center">
                </ngb-pagination>
            </div>
        </div>
        <!-- End Pagination -->
    </div>
</div>