<div class="card card-height-100">
    <div class="card-body pb-0">
        <div class="float-end">
            <div class="dropdown" ngbDropdown>
                <a class="dropdown-toggle text-reset" href="javascript:void(0);" ngbDropdownToggle
                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <span class="fw-semibold">{{ 'PAGES.DASHBOARD.LIST.SORTBY' | translate }}:</span> <span class="text-muted">{{ 'PAGES.DASHBOARD.LIST.YEARLY' | translate }}<i
                            class="mdi mdi-chevron-down ms-1"></i></span>
                </a>
                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1"
                    ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript:void(0);">{{ 'PAGES.DASHBOARD.LIST.YEARLY' | translate }}</a>
                    <a class="dropdown-item" href="javascript:void(0);">{{ 'PAGES.DASHBOARD.LIST.MONTHLY' | translate }}</a>
                    <a class="dropdown-item" href="javascript:void(0);">{{ 'PAGES.DASHBOARD.LIST.WEEKLY' | translate }}</a>
                    <a class="dropdown-item" href="javascript:void(0);">{{ 'PAGES.DASHBOARD.LIST.TODAY' | translate }}</a>
                </div>
            </div>
        </div>
        <h4 class="card-title mb-4">{{ 'PAGES.DASHBOARD.LIST.SALESANALYTICS' | translate }}</h4>

        <div class="mt-1">
            <ul class="list-inline main-chart mb-0">
                <li class="list-inline-item chart-border-left me-0 border-0">
                    <h3 class="text-primary">$<span data-plugin="counterup">2,371</span><span
                            class="text-muted d-inline-block fw-normal font-size-15 ms-3">{{ 'PAGES.DASHBOARD.LIST.INCOME' | translate }}</span></h3>
                </li>
                <li class="list-inline-item chart-border-left me-0">
                    <h3><span data-plugin="counterup">258</span><span
                            class="text-muted d-inline-block fw-normal font-size-15 ms-3">{{ 'PAGES.DASHBOARD.LIST.SALES' | translate }}</span>
                    </h3>
                </li>
                <li class="list-inline-item chart-border-left me-0">
                    <h3><span data-plugin="counterup">3.6</span>%<span
                            class="text-muted d-inline-block fw-normal font-size-15 ms-3">{{ 'PAGES.DASHBOARD.LIST.CONVERSIONRATIO' | translate }}</span></h3>
                </li>
                <li class="list-inline-item chart-border-left me-0">
                    <h3><span data-plugin="counterup">52</span>k<span
                            class="text-muted d-inline-block fw-normal font-size-15 ms-3">{{ 'PAGES.DASHBOARD.LIST.USERS' | translate }}</span></h3>
                </li>
            </ul>
        </div>

        <div class="mt-3">
            <apx-chart dir="ltr" class="apex-charts" [chart]="emailSentBarChart.chart"
                [series]="emailSentBarChart.series" [legend]="emailSentBarChart.legend"
                [stroke]="emailSentBarChart.stroke" [colors]="emailSentBarChart.colors"
                [fill]="emailSentBarChart.fill" [dataLabels]="emailSentBarChart.dataLabels"
                [xaxis]="emailSentBarChart.xaxis" [markers]="emailSentBarChart.markers"
                [plotOptions]="emailSentBarChart.plotOptions" [yaxis]="emailSentBarChart.yaxis"
                [tooltip]="emailSentBarChart.tooltip" [grid]="emailSentBarChart.grid"
                [labels]="emailSentBarChart.labels">
            </apx-chart>
        </div>
    </div> <!-- end card-body-->
</div> <!-- end card-->