<!-- start page title -->
<app-pagetitle title="Countries" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex mb-4">
                    <h4 class="card-title flex-grow-1">List of Countries</h4>
                    <div class="flex-shrink-0">
                        <button type="button" class="btn btn-primary w-md" >Add New</button>
                    </div>
                </div>

                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6">
                        <div class="dataTables_length" id="tickets-table_length"><label
                                class="d-inline-flex align-items-center">Show
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="pageSize" class="form-control form-control-sm mx-2">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select> entries</label></div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <input type="text" name="searchTerm" 
                                    class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table" [(ngModel)]="searchTerm" 
                                    (input)="search(searchTerm)"
                                    >
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>

                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable"
                        class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th >Spanish Name</th>
                                <th >French Name</th>
                                <th >Country Code</th>
                                <th >Currency Code</th>
                                <th >Currency Name</th>
                                <th >Currency Numeric Code</th>
                                <th >Capital</th>
                                <th >Calling Code</th>
                                <th >Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let country of $any(countries | listFilter: searchTerm | slice:(page-1) * pageSize:page * pageSize)">
                                <td>
                                    <ngb-highlight [result]="country.name" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="country.official_name_es" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="country.official_name_fr" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="country.country_code" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="country.currency_code" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="country.currency_name" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="country.currency_numeric_code" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="country.capital" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="country.calling_code" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <span *ngIf="country.enabled" class="badge rounded-pill bg-success ms-1">
                                        Enabled
                                    </span>
                                    <span *ngIf="!country.enabled" class="badge rounded-pill bg-warning ms-1">
                                        Disabled
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- End table -->
                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" country="status" aria-live="polite">
                            Showing
                            <!-- {{countriesDatatableService.startIndex}} to
                            {{countriesDatatableService.endIndex}} of {{countriesDatatableService.totalRecords}} -->
                            entries
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-right float-md-end pagination-rounded">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" 
                                [maxSize]="5" [boundaryLinks]="true" [pageSize]="pageSize" 
                                class="d-flex justify-content-center">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- End Pagination -->
                </div>
            </div>
        </div>
    </div>
</div>