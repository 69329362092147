export interface EvCard {
  id: number;
  name: string;
  tagId: string;
  cardNumber: string;
  currency: string;
  balance: number;
  evCardType: string;
  userUuid: string;
  vehicleUuid: string;
  parentTagId: string;
  maxActiveTransactionCount: number;
  activeTransactionCount: string;
  uuid: string;
  enabled: boolean;
  expired: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  deleted: boolean;
  deletedAt: string;
}

export interface AddEvCard {
  enabled: boolean;
  expired: boolean;
  evCardType: string;
  maxActiveTransactionCount: number;
  parentTagId: string;
  userUuid: string;
  vehicleUuid: string;
}

export interface PagedEvCard {
  content: EvCard[];
  first: boolean;
  last: boolean;
  empty: boolean;
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
  size: number;
  number: number;
}