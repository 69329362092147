<div class="modal-header">
    <h5 class="modal-title">{{ title | translate }}</h5>
    <button type="button" class="btn-close" (click)="onCancel()"></button>
</div>

<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
        <div class="mb-3">
            <label for="userUuid" class="form-label">{{ 'PAGES.COMMON.LIST.NAME' | translate }}</label>
            <span class="text-danger">*</span>
            <div class="mb-3">
                <select2 id="userUuid" formControlName="userUuid" [data]="options" [multiple]="false"
                    [placeholder]="'PAGES.CHARGENOW.SEARCHUSERPLACEHOLDER' | translate" [customSearchEnabled]="true"
                    (update)="onSelectionChange($event)" (search)="onSearch($event)">
                </select2>
            </div>
        </div>

        <div class="mb-3">
            <label for="vehicleUuid" class="form-label">{{ 'PAGES.CHARGENOW.SELECTVEHICLE' | translate}}</label>
            <select class="form-select" id="vehicleUuid" formControlName="vehicleUuid">
                <option *ngFor="let vehicle of vehicles" [value]="vehicle.value">{{vehicle.label}}</option>
            </select>
        </div>

        <div class="mb-3">
            <label for="evCardType" class="form-label">{{ 'PAGES.CHARGENOW.CARDTYPE' | translate}}</label>
            <span class="text-danger">*</span>
            <select class="form-select" id="evCardType" formControlName="evCardType">
                <option value="CARD">{{ 'PAGES.CHARGENOW.CARD' | translate}}</option>
                <option value="STICKER">{{ 'PAGES.CHARGENOW.STICKER' | translate}}</option>
                <option value="PHONE">{{ 'PAGES.CHARGENOW.PHONE' | translate}}</option>
            </select>
        </div>

        <div class="mb-3">
            <label for="maxActiveTransactionCount" class="form-label">{{ 'PAGES.CHARGENOW.MAXACTIVETRANSACTIONS' |
                translate}}</label>
            <span class="text-danger">*</span>
            <input type="number" class="form-control" id="maxActiveTransactionCount"
                formControlName="maxActiveTransactionCount" min="1">
        </div>

        <div class="mb-3">
            <label for="tagId" class="form-label">{{ 'PAGES.CHARGENOW.TAGID' | translate}}</label>
            <span class="text-danger">*</span>

            <input type="text" class="form-control" id="tagId" formControlName="tagId">
        </div>

        <div class="mb-3">
            <label for="parentTagId" class="form-label">{{ 'PAGES.CHARGENOW.PARENTTAGID' | translate}}</label>
            <input type="text" class="form-control" id="parentTagId" formControlName="parentTagId">
        </div>

        <div class="form-check mb-3">
            <input type="checkbox" class="form-check-input" id="enabled" formControlName="enabled">
            <label class="form-check-label" for="enabled">{{ 'PAGES.CHARGENOW.ENABLED' | translate}}</label>
        </div>

        <div class="form-check mb-3">
            <input type="checkbox" class="form-check-input" id="expired" formControlName="expired">
            <label class="form-check-label" for="expired">{{ 'PAGES.CHARGENOW.EXPIRED' | translate}}</label>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCancel()">{{ 'PAGES.COMMON.LIST.CANCEL' | translate
            }}</button>
        <button type="submit" class="btn btn-primary" [disabled]="!entryForm.valid || processing">
            {{ 'PAGES.COMMON.LIST.SAVE' | translate }}
        </button>
    </div>
</form>