import { DecimalPipe } from '@angular/common';
import { Component, OnInit, PipeTransform, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { AddInternalAccount, InternalAccount, PagedInternalAccount } from 'src/app/core/models/internal-account.models';
import { InternalAccountService } from 'src/app/core/services/internal-account.service';
import { InternalAccountModalComponent } from './internal-account-modal/internal-account-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';

/**
 * Table Data Match with Search input
 * @param  InternalAccount field value fetch
 * @param term Search the value
 */
function matches(table: InternalAccount, term: string, pipe: PipeTransform) {
  return pipe.transform(table.id).includes(term) || table.name.toLowerCase().includes(term.toLowerCase())
      || table.description.toLowerCase().includes(term)
      || table.uuid.toLowerCase().includes(term)
      || table.createdAt.toLowerCase().includes(term)
      || table.updatedAt.toLowerCase().includes(term);
}

@Component({
  selector: 'app-internal-accounts',
  templateUrl: './internal-accounts.component.html',
  styleUrls: ['./internal-accounts.component.scss'],
  providers: [DecimalPipe]
})
export class InternalAccountsComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  title!: string;
  searchTerm!: string;
  searchTermChanged: Subject<string> = new Subject<string>();
  inputSub: Subscription | undefined;
  page = 1;
  pageSize = 10;
  genericFilter: GenericFilter = new GenericFilter('', 0, 10, 'id', 'desc');
  collectionSize!: number;
  currentRate = 8;
  internalAccount!: InternalAccount;
  account!: AddInternalAccount;
  internalAccounts!: InternalAccount[];
  pagedInternalAccounts!: PagedInternalAccount;
  empty = true;

  constructor(private internalAccountService: InternalAccountService, private pipe: DecimalPipe, public modalService: NgbModal) { }

  ngOnInit(): void {
    this.title = 'PAGES.INTERNALACCOUNTS.TEXT';

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'MENUITEMS.SETTINGS.TEXT' },
      { label: 'PAGES.INTERNALACCOUNTS.TEXT', active: true }
    ];

    this.fetchData();
  }

  fetchData() {
    this.genericFilter.size = this.pageSize;
    this.genericFilter.page = this.page -1;
    this.internalAccountService.getInternalAccounts(this.genericFilter).subscribe({
      next: (v) => {
        // console.log('returned internal accounts : ', v);
        this.pagedInternalAccounts = v;
        this.collectionSize = this.pagedInternalAccounts.totalElements;
        if (this.pagedInternalAccounts.content.length > 0) {
          this.internalAccounts = this.pagedInternalAccounts.content;
          this.empty = false;
        }
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('internalAccounts retrieved');
      }
    });
  }

  search(value: string): void {
    this.searchTermChanged.next(value);
  }

  onSizeChange(size: number) {
    this.genericFilter.size = size;
    this.pageSize = size;
    this.page = 1;
    this.fetchData();
  }

  pageChanged(page: any) {
    // console.log('page change: ', page);
    this.genericFilter.page = page - 1;
    this.fetchData();
  }

  openModal(internalAccount: any, isAddNew: boolean) {
    const modalRef = this.modalService.open(InternalAccountModalComponent);

    if (isAddNew) {
      modalRef.componentInstance.internalAccount = this.account;
    } else {
      let acc: AddInternalAccount = {
        name: internalAccount.name,
        description: internalAccount.description,
        currency: internalAccount.wallet.currency,
        uuid: internalAccount.uuid,
        enabled: internalAccount.enabled
      }
      modalRef.componentInstance.internalAccount = acc;
    }
    
    modalRef.componentInstance.isAddNew = isAddNew;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.refreshPage();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  //refresh page
  refreshPage(): void {
    window.location.reload();
  }
}
