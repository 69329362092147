<!-- start page title -->
<app-pagetitle title="Wallets" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<app-datatable
    title="Wallets"
    [rows]="wallets"
    [columns]="columns"
    [collectionSize]="collectionSize"
    [paged]="true"
    [searchable]="true"
    [edittable]="true"
    [addAllowed]="false"
    (onFetchData)="fetchData($event)"
    [submitHandler]="submitHandler">
</app-datatable>