<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title | translate}}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="chargingStationIdentifier" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.STATION' | translate }}</label>
            <input type="text" class="form-control" id="chargingStationIdentifier" name="chargingStationIdentifier" 
                required readonly [(ngModel)]="newAction.chargingStationIdentifier" #chargingStationIdentifier="ngModel">
            <span style="color:red" [hidden]="chargingStationIdentifier.valid || chargingStationIdentifier.pristine"> 
                <p>{{ 'PAGES.CHARGERS.LIST.STATIONREQUIRED' | translate }}</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="confKey" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.CONFIGURATIONKEY' | translate }}</label>
            <input type="text" class="form-control" id="confKey" name="confKey" 
                [(ngModel)]="newAction.confKey" #confKey="ngModel">
        </div>
        <div class="form-group mb-3">
            <label for="customConfKey" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.CUSTOMCONFIGURATIONKEY' | translate }}</label>
            <input type="text" class="form-control" id="customConfKey" name="customConfKey" 
                [(ngModel)]="newAction.customConfKey" #customConfKey="ngModel">
        </div>
        <div class="form-group mb-3">
            <label for="keyType" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.KEYTYPE' | translate }}</label>
            <select class="form-select" id="keyType" name="keyType" [(ngModel)]="newAction.keyType" #keyType="ngModel">
                <option value="CUSTOM">{{ 'PAGES.CHARGERS.LIST.CUSTOM' | translate }}</option>
                <option value="PREDEFINED">{{ 'PAGES.CHARGERS.LIST.PREDEFINED' | translate }}</option>
            </select>
        </div>
        <div class="form-group mb-3">
            <label for="value" class="fw-semibold">{{ 'PAGES.COMMON.LIST.VALUE' | translate }}</label>
            <input type="text" class="form-control" id="value" name="value" 
                required [(ngModel)]="newAction.value" #value="ngModel">
            <span style="color:red" [hidden]="value.valid || value.pristine"> 
                <p>{{ 'PAGES.COMMON.LIST.VALUEREQUIRED' | translate }}</p> 
            </span>
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">{{ 'PAGES.COMMON.LIST.CANCEL' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">{{ 'PAGES.COMMON.LIST.SUBMIT' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>