import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PushNotification } from 'src/app/core/models/push-notification.models';


@Component({
  selector: 'app-push-notification-modal',
  templateUrl: './push-notification-modal.component.html',
  styleUrls: ['./push-notification-modal.component.scss']
})
export class PushNotificationModalComponent implements OnInit {

  @Input() public pushNotification!: PushNotification;
  @Input() public isAddNew!: boolean;

  entryForm!: UntypedFormGroup;
  processing = false;
  modalTitle = "View Push Notification";

  constructor(
    public modal: NgbActiveModal) { }

  ngOnInit(): void { 
    this.modalTitle = "View Push Notification";
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }

}
