<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="chargingStationIdentifier" class="fw-semibold">Station</label>
            <input type="text" class="form-control" id="chargingStationIdentifier" name="chargingStationIdentifier" 
                required readonly [(ngModel)]="newAction.chargingStationIdentifier" #chargingStationIdentifier="ngModel">
            <span style="color:red" [hidden]="chargingStationIdentifier.valid || chargingStationIdentifier.pristine"> 
                <p>Station Identifier is required</p> 
            </span>
        </div>
        <div class="form-group mb-3" *ngIf="isFromList">
            <label for="connectorId" class="fw-semibold">Connector</label>
            <input type="number" class="form-control" id="connectorId" name="connectorId" 
                required [(ngModel)]="newAction.connectorId" #connectorId="ngModel" min="1">
            <span style="color:red" [hidden]="connectorId.valid || connectorId.pristine"> 
                <p>Connector number is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="enabled" class="fw-semibold">Message Type</label>
            <select class="form-select" id="triggerMessage" name="triggerMessage" [(ngModel)]="newAction.triggerMessage" #triggerMessage="ngModel">
                <option value="BootNotification">Boot Notification</option>
                <option value="DiagnosticsStatusNotification">Diagnostics Status Notification</option>
                <option value="FirmwareStatusNotification">Firmware Status Notification</option>
                <option value="Heartbeat">Heartbeat</option>
                <option value="MeterValues">Meter Values</option>
                <option value="StatusNotification">Status Notification</option>
            </select>
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">Submit</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>