import { Component, OnInit } from '@angular/core';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { Order } from 'src/app/core/models/order.models';
import { OrderService } from 'src/app/core/services/order.service';

@Component({
  selector: 'app-latest-orders-card',
  templateUrl: './latest-orders-card.component.html',
  styleUrls: ['./latest-orders-card.component.scss']
})
export class LatestOrdersCardComponent implements OnInit {

  orders!: Order[];
  
  constructor(private orderService: OrderService) { }

  ngOnInit(): void {
    this.fetchOrderData();
  }

  private fetchOrderData() {
    const genericFilter: GenericFilter = new GenericFilter('', 0, 5, 'id', 'desc');
    this.orderService.getOrders(genericFilter).subscribe({
      next: (pagedOrders) => {
        // console.log("returned orders : ", pagedOrders);
        this.orders = pagedOrders.content;
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info("orders retrieved");
      },
    });
  }
}
