import { MapsAPILoader } from '@agm/core';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Country } from 'src/app/core/models/country.models';
import { Organization } from 'src/app/core/models/organization.models';
import { AddRate } from 'src/app/core/models/rate.models';
import { AddSite, Site } from 'src/app/core/models/site.models';
import { SiteService } from 'src/app/core/services/site.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-site-modal',
  templateUrl: './site-modal.component.html',
  styleUrls: ['./site-modal.component.scss']
})
export class SiteModalComponent implements OnInit {

  @Input() public site!: Site;
  @Input() public isAddNew!: boolean;
  @Input() public countries!: Country[];
  @Input() public organizations!: Organization[];
  addSite!: AddSite;
  isApiLoaded = false;
  options: any = {
    componentRestrictions: { country: ['CA', 'US'] }
  }

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "Edit Site";
  chargerTypes = [
    { value: "LEVEL_1", label: "L1"},
    { value: "LEVEL_2", label: "L2"},
    { value: "LEVEL_3", label: "L3 - DC Fast"}
  ]

  rate: AddRate = {
    chargerType: 'L1',
    currency: 'CAD',
    sessionFee: 0,
    energyRateUnit: 'kWh',
    energyRate: 0,
    idlingFeeUnit: 'min',
    idlingFee: 0,
    parkingFeeUnit: 'hour',
    parkingFee: 0,
    enabled: false
  };

  constructor(
    public mapsAPILoader: MapsAPILoader,
    public modal: NgbActiveModal, 
    private siteService: SiteService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() =>{
      this.isApiLoaded = true
    });

    if (this.site == undefined) {
      this.addSite = {
        name: "",
        description: "",
        siteOwnerUuid: "",
        phoneNumber: "",
        email: "",
        openTime: "00:00",
        closeTime: "23:59",
        addressLine1: "",
        addressLine2: "",
        city: "",
        region: "",
        postalCode: "",
        country: "",
        locationLatitude: "",
        locationLongitude: "",
        rates: [],
        enabled: false
      };
      this.addSite.rates.push(this.rate);
      this.title = "Add Site";
    } else {
      let rates: AddRate[] = [];
      this.site.rates.forEach((rate) => {
        const addRate: AddRate = {
          chargerType: rate.rateId.chargerType,
          currency: rate.currency,
          sessionFee: rate.sessionFee,
          energyRateUnit: rate.energyRateUnit,
          energyRate: rate.energyRate,
          idlingFeeUnit: rate.idlingFeeUnit,
          idlingFee: rate.idlingFee,
          parkingFeeUnit: rate.parkingFeeUnit,
          parkingFee: rate.parkingFee,
          enabled: rate.enabled
        };
        rates.push(addRate);
      })
      this.addSite = {
        name: this.site.name,
        description: this.site.description,
        siteOwnerUuid: this.site.siteOwnerUuid,
        phoneNumber: this.site.phoneNumber,
        email: this.site.email,
        openTime: this.site.openTime,
        closeTime: this.site.closeTime,
        addressLine1: this.site.address.addressLine1,
        addressLine2: this.site.address.addressLine2,
        city: this.site.address.city,
        region: this.site.address.region,
        postalCode: this.site.address.postalCode,
        country: this.site.address.country,
        locationLatitude: this.site.address.latitude,
        locationLongitude: this.site.address.longitude,
        rates: rates,
        enabled: this.site.enabled
      };

      if (this.addSite.rates.length == 0) {
        this.addSite.rates.push(this.rate);
      }

      console.log("Add Site: ", this.addSite)
    }
  }
  
  // Handle Create button click
  onSubmit() {
    console.log('create clicked');
    this.processing = true;
    if (this.isAddNew) {
      // Add a new site
      this.siteService.addSite(this.addSite).subscribe({
        next: (v) => {
          console.log('added site : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          console.info('sites saved');
        }
      });
    } else {
      // Update an existing site
      console.log("Updated site: ", this.addSite)
      this.siteService.updateSite(this.addSite, this.site.uuid).subscribe({
        next: (v) => {
          console.log('updated site : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });  
        },
        complete: () => {
          console.info('sites saved');
        }
      });
    }
  }

  addMore() {
    this.addSite.rates.push(this.rate);
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }

  handleAddressChange(address: Address) {
    console.log(address)
    console.log(address.formatted_address)
    console.log(address.geometry.location.lat())
    console.log(address.geometry.location.lng())

    this.addSite.addressLine1 = address.name;
    this.addSite.city = address.address_components.filter(f => JSON.stringify(f.types) === JSON.stringify(['locality', 'political']))[0].long_name;
    this.addSite.region = address.address_components.filter(f => JSON.stringify(f.types) === JSON.stringify(['administrative_area_level_1', 'political']))[0].long_name;
    this.addSite.postalCode = address.address_components.filter(f => JSON.stringify(f.types) === JSON.stringify(['postal_code']))[0].long_name;
    this.addSite.country = address.address_components.filter(f => JSON.stringify(f.types) === JSON.stringify(['country', 'political']))[0].long_name;
    this.addSite.locationLatitude = address.geometry.location.lat().toString();
    this.addSite.locationLongitude = address.geometry.location.lng().toString();
  }
}
