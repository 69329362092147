import { DecimalPipe, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PipeTransform, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; 
import { VehicleMake } from 'src/app/core/models/vehicle-make.models';
import { VehicleMakeService } from 'src/app/core/services/vehicle-make.service';
import { VehicleModelService } from 'src/app/core/services/vehicle-model.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { PagedWalletHistories, WalletHistory } from 'src/app/core/models/wallet-history.models';
import { VehicleModel } from 'src/app/core/models/vehicle-model.models';

/**
 * Table Data Match with Search input
 * @param  VehicleModel field value fetch
 * @param term Search the value
 */
function matches(table: VehicleModel, term: string, pipe: PipeTransform) {
  return pipe.transform(table.id).includes(term) || table.name.toLowerCase().includes(term.toLowerCase())
      || table.description.toLowerCase().includes(term)
      || table.uuid.toLowerCase().includes(term)
      || table.createdAt.toLowerCase().includes(term)
      || table.updatedAt.toLowerCase().includes(term);
}

@Component({
  selector: 'app-vehicle-make-view',
  templateUrl: './vehicle-make-view.component.html',
  styleUrls: ['./vehicle-make-view.component.scss'],
  providers: [DecimalPipe]
})
export class VehicleMakeViewComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  uuid!: string;

  @Input() vehicleMake!: VehicleMake;

  searchTerm!: string;
  searchTermChanged: Subject<string> = new Subject<string>();
  inputSub: Subscription | undefined;
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  genericFilter: GenericFilter = new GenericFilter('', 0, 10, 'id', 'desc');
  vehicleModel!: VehicleModel;
  vehicleModels!: VehicleModel[];
  allVehicleModels!: VehicleModel[];
  empty = true;

  constructor(
    private route: ActivatedRoute, private vehicleMakeService: VehicleMakeService,
    private vehicleModelService: VehicleModelService, private pipe: DecimalPipe,
    public modalService: NgbModal, public sweetalertService: SweetalertService
  ) { }

  ngOnInit(): void {
    this.uuid = String(this.route.snapshot.paramMap.get('uuid'));

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Settings' },
      { label: 'Vehicle Makes' },
      { label: 'Vehicle Make Details', active: true }
    ];

    if (this.vehicleMake == undefined) { 
      this.fetchData();
    } else {
      this.fetchVehcileModelData();
    }

  }

  fetchData(): void {
    this.vehicleMakeService.getVehicleMake(this.uuid).subscribe({
      next: (v) => {
        console.log('returned vehicle make : ', v);
        this.vehicleMake = v;
        this.fetchVehcileModelData();
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('vehicle make retrieved');
      }
    })
  }

  fetchVehcileModelData() {
    this.vehicleModelService.getVehicleModels(this.vehicleMake.name).subscribe({
      next: (v) => {
        console.log('returned vehicle models : ', v);
        this.collectionSize = v.length;
        this.vehicleModels = v;
        this.allVehicleModels = this.vehicleModels;
        if (this.collectionSize > 0) {
          this.empty = false;
        }
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('vehicle models retrieved');
      }
    });
  }

  search(value: string): void {
    this.vehicleModels = this.allVehicleModels.filter(table => matches(table, value, this.pipe));

    // this.permissions = this.allPermissions.filter((val) => val.name.toLowerCase().includes(value));
    this.collectionSize = this.vehicleModels.length;
  }

}
