import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { VehicleMake } from '../models/vehicle-make.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';

@Injectable({
  providedIn: 'root'
})
export class VehicleMakeService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getUserManagementUrl();
  }

  /**
     * Get all vehicle-makes
     */
  getVehicleMakes(): Observable<VehicleMake[]> {

    return this.http.get<VehicleMake[]>(`${this.backendApiUrl}/vehicle-makes`, this.httpOptions).pipe(
      tap((vehicleMakes: VehicleMake[]) => {
        this.log(`Retrieved vehicleMakes`);
      })
    );
  }

  /**
     * Get vehicle-make
     */
  getVehicleMake(uuid: string): Observable<VehicleMake> {

    return this.http.get<VehicleMake>(`${this.backendApiUrl}/vehicle-makes/${uuid}`, this.httpOptions).pipe(
      tap((vehicleMake: VehicleMake) => {
        this.log(`Retrieved vehicleMake`);
      })
    );
  }

  /**
     * Add vehicle-make
     */
  addVehicleMake(params: any): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/vehicle-makes`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added vehicle-make`);
      })
    );
  }

  /**
     * Update vehicle-make
     */
  updateVehicleMake(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/vehicle-makes/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated vehicle-make`);
      })
    );
  }

  /** Log a VehicleMakeService message */
  private log(message: string) {
    // console.log(`VehicleMakeService: ${message}`);
  }
}
