// import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Country } from 'src/app/core/models/country.models';
import { Organization } from 'src/app/core/models/organization.models';
import { AddRate } from 'src/app/core/models/rate.models';
import { AddSite, Site } from 'src/app/core/models/site.models';
import { ScriptsService } from 'src/app/core/services/scripts.service';
import { SiteService } from 'src/app/core/services/site.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-site-modal',
  templateUrl: './site-modal.component.html',
  styleUrls: ['./site-modal.component.scss']
})
export class SiteModalComponent implements OnInit {

  @ViewChild('addressInput', { static: false }) addressInput!: ElementRef;
  autocomplete!: google.maps.places.Autocomplete;
  @Input() public site!: Site;
  @Input() public isAddNew!: boolean;
  @Input() public countries!: Country[];
  @Input() public organizations!: Organization[];
  addSite!: AddSite;
  isApiLoaded = false;
  options: any = {
    componentRestrictions: { country: ['CA', 'US'] }
  }

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "PAGES.SITES.LIST.EDIT";
  chargerTypes = [
    { value: "LEVEL_1", label: "L1"},
    { value: "LEVEL_2", label: "L2"},
    { value: "LEVEL_3", label: "L3 - DC Fast"}
  ]

  rate: AddRate = {
    chargerType: 'L1',
    currency: 'CAD',
    sessionFee: 0,
    energyRateUnit: 'kWh',
    energyRate: 0,
    idlingFeeUnit: 'min',
    idlingFee: 0,
    parkingFeeUnit: 'hour',
    parkingFee: 0,
    enabled: false
  };

  constructor(
    private ngZone: NgZone,
    public modal: NgbActiveModal, 
    private siteService: SiteService,
    private scriptsService: ScriptsService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    if (!this.scriptsService.isScriptLoaded('GoogleMap')) {
      this.scriptsService.load('GoogleMap').then(() => {
        // console.log('Google Maps script loaded.');
        this.isApiLoaded = true;
        this.loadGooglePlacesAutocomplete(); // Ensure it loads after script
      }).catch(error => {
        // console.error('Error loading Google Maps script:', error);
      });
    } else {
      this.isApiLoaded = true;
    }

    if (this.site == undefined) {
      this.addSite = {
        name: "",
        description: "",
        siteOwner: "",
        siteOwnerUuid: "",
        chargingNetwork: "TAPNGO",
        phoneNumber: "",
        email: "",
        openTime: "00:00",
        closeTime: "23:59",
        addressLine1: "",
        addressLine2: "",
        city: "",
        region: "",
        postalCode: "",
        country: "",
        locationLatitude: "",
        locationLongitude: "",
        rates: [],
        enabled: false
      };
      this.addSite.rates.push(this.rate);
      this.title = "PAGES.SITES.LIST.ADD";
    } else {
      let rates: AddRate[] = [];
      this.site.rates.forEach((rate) => {
        const addRate: AddRate = {
          chargerType: rate.rateId.chargerType,
          currency: rate.currency,
          sessionFee: rate.sessionFee,
          energyRateUnit: rate.energyRateUnit,
          energyRate: rate.energyRate,
          idlingFeeUnit: rate.idlingFeeUnit,
          idlingFee: rate.idlingFee,
          parkingFeeUnit: rate.parkingFeeUnit,
          parkingFee: rate.parkingFee,
          enabled: rate.enabled
        };
        rates.push(addRate);
      })
      this.addSite = {
        name: this.site.name,
        description: this.site.description,
        siteOwner: this.site.siteOwner,
        siteOwnerUuid: this.site.siteOwnerUuid,
        chargingNetwork: "TAPNGO",
        phoneNumber: this.site.phoneNumber,
        email: this.site.email,
        openTime: this.site.openTime,
        closeTime: this.site.closeTime,
        addressLine1: this.site.address.addressLine1,
        addressLine2: this.site.address.addressLine2,
        city: this.site.address.city,
        region: this.site.address.region,
        postalCode: this.site.address.postalCode,
        country: this.site.address.country,
        locationLatitude: this.site.address.latitude,
        locationLongitude: this.site.address.longitude,
        rates: rates,
        enabled: this.site.enabled
      };

      if (this.addSite.rates.length == 0) {
        this.addSite.rates.push(this.rate);
      }

      // console.log("Add Site: ", this.addSite)
    }
  }
  
  loadGooglePlacesAutocomplete() {
    if (!this.addressInput) {
      // console.error("Address input field is still not available.");
      return;
    } else {
      // console.log('Address input field available');
    }

    this.autocomplete = new google.maps.places.Autocomplete(this.addressInput.nativeElement, {
      types: ['geocode'],
      componentRestrictions: { country: 'CA' },
    });

    this.autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = this.autocomplete.getPlace();
        if (!place.geometry || !place.address_components || !place.geometry.location) {
          // console.warn("Place details are incomplete or unavailable.");
          return;
        }

        // Extract latitude and longitude
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        // console.log("Latitude:", latitude, "Longitude:", longitude);

        // Assign lat/lng to your model
        this.addSite.locationLatitude = latitude.toString();
        this.addSite.locationLongitude = longitude.toString();

        place.address_components.forEach(component => {
          const types = component.types;
          if (types.includes('street_number')) {
            this.addSite.addressLine1 = component.long_name;
          }
          if (types.includes('route')) {
            this.addSite.addressLine1 += ' ' + component.long_name;
          }
          if (types.includes('locality')) {
            this.addSite.city = component.long_name;
          }
          if (types.includes('administrative_area_level_1')) {
            this.addSite.region = component.short_name;
          }
          if (types.includes('country')) {
            this.addSite.country = component.long_name;
          }
          if (types.includes('postal_code')) {
            this.addSite.postalCode = component.long_name;
          }
        });

        this.addSite.addressLine1 = this.addSite.addressLine1.trim();
      });
    });
  }

  // Handle Create button click
  onSubmit() {
    // console.log('create clicked');
    this.processing = true;

    const siteOwnerObj = this.organizations.find(organization => organization.uuid === this.addSite.siteOwnerUuid);
    this.addSite.siteOwner = siteOwnerObj?.name || '';

    if (this.isAddNew) {
      // Add a new site
      this.siteService.addSite(this.addSite).subscribe({
        next: (v) => {
          // console.log('added site : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          // console.info('sites saved');
        }
      });
    } else {
      // Update an existing site
      // console.log("Updated site: ", this.addSite)
      this.siteService.updateSite(this.addSite, this.site.uuid).subscribe({
        next: (v) => {
          // console.log('updated site : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });  
        },
        complete: () => {
          // console.info('sites saved');
        }
      });
    }
  }

  addMore() {
    this.addSite.rates.push(this.rate);
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
