import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { Charger } from 'src/app/core/models/charger.models';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { MeterValue, PagedMeterValue } from 'src/app/core/models/meter-value.models';
import { Transaction } from 'src/app/core/models/transaction.models';
import { ChargerService } from 'src/app/core/services/charger.service';
import { TransactionService } from 'src/app/core/services/transaction.service';

@Component({
  selector: 'app-meter-values',
  templateUrl: './meter-values.component.html',
  styleUrls: ['./meter-values.component.scss']
})
export class MeterValuesComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  chargerId!: string;
  transactionUuid!: string;
  searchTerm!: string;
  searchTermChanged: Subject<string> = new Subject<string>();
  inputSub: Subscription | undefined;
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  genericFilter: GenericFilter = new GenericFilter('', 0, 10, 'id', 'desc');
  meterValues!: MeterValue[];
  pagedMeterValues!: PagedMeterValue;
  empty = true;
  connectorIdentifier!: number;
  transaction!: Transaction;

  @Input() charger!: Charger;

  constructor(
    private route: ActivatedRoute, private chargerService: ChargerService, private transactionService: TransactionService
  ) { }

  ngOnInit(): void {
    this.chargerId = String(this.route.snapshot.paramMap.get('chargerId'));
    this.transactionUuid = String(this.route.snapshot.paramMap.get('transactionUuid'));

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Charger Management' },
      { label: 'Chargers' },
      { label: this.chargerId},
      { label: 'Meter Values', active: true }
    ];

    if (this.charger == undefined) { 
      this.fetchCharger();
    } 

    this.fetchData();

    this.inputSub = this.searchTermChanged.pipe(
      debounceTime(500),
      delay(200)
    ).subscribe(value => {
        // console.log(value)
        this.genericFilter.searchParam = value;
        this.fetchData();
      });
  }

  fetchData() {
    this.genericFilter.size = this.pageSize;
    this.genericFilter.page = this.page -1;
    this.transactionService.getMeterValues(this.chargerId, this.transactionUuid, this.genericFilter).subscribe({
      next: (v) => {
        // console.log('returned metervalues : ', v);
        this.pagedMeterValues = v;
        this.collectionSize = this.pagedMeterValues.totalElements;
        if (this.pagedMeterValues.content.length > 0) {
          this.meterValues = this.pagedMeterValues.content;
          this.empty = false;
          this.connectorIdentifier = this.meterValues[0].connector.connectorIdentifier;
          this.transaction = this.meterValues[0].transaction;
        }
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('metervalues retrieved');
      }
    });
  }

  fetchCharger(): void {
    this.chargerService.getCharger(this.chargerId).subscribe({
      next: (v) => {
        // console.log('returned charger : ', v);
        this.charger = v;
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('charger retrieved');
      }
    })
  }

  search(value: string): void {
    this.searchTermChanged.next(value);
  }

  onSizeChange(size: number) {
    this.genericFilter.size = size;
    this.pageSize = size;
    this.page = 1;
    this.fetchData();
  }

  pageChanged(page: any) {
    // console.log('page change: ', page);
    this.genericFilter.page = page - 1;
    this.fetchData();
  }
}
