<!-- start page title -->
<app-pagetitle [title]="title | translate" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-6">
        <div class="row">
            <div class="col-md-6 col-xl-6">
                <div class="card card-height-100">
                    <div class="card-body">
                        <div class="d-flex flex-wrap align-items-center">
                            <div class="avatar-sm">
                                <span class="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                                    <i class="bx bxs-bolt-circle text-primary"></i>
                                </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h5 class="font-size-15 mb-0 text-truncate">{{ charger?.chargingStationIdentifier}}</h5>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-2 pt-1">
                            <div *ngIf="charger?.enabled">
                                <div class="mt-1 text-center">
                                    <div>
                                        <div class="d-flex flex-row justify-content-between align-items-center ">
                                            <div class="d-flex p-2">
                                                <i class="mdi mdi-circle font-size-10 mt-1 text-success"></i>
                                                <div class="flex-grow-1 ms-2">
                                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.STATUS' | translate }} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="mb-0 text-success fw-semibold font-size-15"><i
                                        class="fas fa-check-circle align-middle font-size-18"></i> {{ 'PAGES.COMMON.LIST.ENABLED' | translate }}</p>
                            </div>
                            <div *ngIf="!charger?.enabled">
                                <div class="mt-1 text-center">
                                    <div>
                                        <div class="d-flex flex-row justify-content-between align-items-center ">
                                            <div class="d-flex p-2">
                                                <i class="mdi mdi-circle font-size-10 mt-1 text-warning"></i>
                                                <div class="flex-grow-1 ms-2">
                                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.STATUS' | translate }} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="mb-0 text-warning fw-semibold font-size-15"><i
                                        class="fas fa-times align-middle font-size-18"></i> {{ 'PAGES.COMMON.LIST.DISABLED' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-6">
                <div class="card card-height-100">
                    <div class="card-body">
                        <div class="d-flex flex-wrap align-items-center">
                            <div class="avatar-sm">
                                <span class="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                                    <i class="bx bxs-plug text-primary"></i>
                                </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h5 class="font-size-15 mb-0 text-truncate">{{ 'PAGES.COMMON.LIST.CONNECTORCOUNT' | translate }}</h5>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-3 pt-1">
                            <div>
                                <h4 class="font-size-20">{{ charger?.connectors?.length }}</h4>
                                <p class="mb-0 text-success fw-semibold font-size-15">AC <i
                                        class="bx bx-trending-up align-middle font-size-18"></i></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-6">
                <div class="card card-height-100">
                    <div class="card-body">
                        <div class="d-flex flex-wrap align-items-center">
                            <div class="avatar-sm">
                                <span class="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                                    <i class="fas fa-battery-three-quarters text-primary"></i>
                                </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h5 class="font-size-15 mb-0 text-truncate">{{ 'PAGES.COMMON.LIST.TRANSACTIONS' | translate }} <span class="text-muted">/
                                        <small>{{ 'PAGES.COMMON.LIST.MONTHTODATE' | translate }}</small></span></h5>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-3 pt-1">
                            <div>
                                <h4 class="font-size-20">50</h4>
                                <p class="mb-0 text-success fw-semibold font-size-15">0.0000 {{ 'PAGES.COMMON.LIST.KWHCONSUMED' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-6">
                <div class="card card-height-100">
                    <div class="card-body">
                        <div class="d-flex flex-wrap align-items-center">
                            <div class="avatar-sm">
                                <span class="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                                    <i class="fas fa-plus text-primary"></i>
                                </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h5 class="font-size-15 mb-0 text-truncate">{{ 'PAGES.COMMON.LIST.NEW' | translate }} <span class="text-muted">/ <small>{{ 'PAGES.COMMON.LIST.MONTHTODATE' | translate }}</small></span></h5>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-3 pt-1">
                            <div>
                                <h4 class="font-size-20">3</h4>
                                <p class="mb-0 text-success fw-semibold font-size-15">+1.5% <i
                                        class="bx bx-trending-up align-middle font-size-18"></i></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">{{ charger?.site?.name }}</h4>
                <p class="card-title-desc">{{ address }}</p>
                <div *ngIf="apiLoaded">
                    <google-map width="100%" height="237px" [center]="center" [zoom]="zoom">
                        <map-marker [position]="markerPosition" [options]="markerOptions"></map-marker>
                    </google-map>
                </div>
            </div>
        </div>
    </div> <!-- end col -->
</div> <!-- end row -->

<!-- Start Tabs & Accordions -->
<div class="row">
    <div class="col-lg-12">
        <div class="card" id="tabs-accordions">
            <div class="card-body">
                <div class="d-flex flex-wrap align-items-center">
                    <div class="ms-auto">
                        <div class="dropdown" ngbDropdown>
                            <button type="button" class="btn btn-success" [title]="'PAGES.COMMON.LIST.QRCODE' | translate"
                                (click)="qrCodeModal(charger)"><i class="fas fa-qrcode"></i>
                            </button>
                            <button type="button" class="btn btn-light" title="Operations" ngbDropdownToggle
                                id="dropdownMenuButton" data-bs-toggle="dropdown">
                                <i class="fas fa-cogs"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton"
                                ngbDropdownMenu>
                                <!-- <a *ngFor="let item of dropdownItems" class="dropdown-item" href="#" (click)="item.action(charger!, true, undefined)">
                                    {{ item.label | translate }}
                                </a> -->
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="changeAvailabilityModal(charger, true, undefined)">{{ 'PAGES.CHARGERS.LIST.CHANGEAVAILABILITY' | translate }}</a>
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="changeConfigurationModal(charger, true, undefined)">{{ 'PAGES.CHARGERS.LIST.CHANGECONFIGURATION' | translate }}</a>
                                <a class="dropdown-item" href="javascript:void(0)" (click)="clearCache(charger)">{{ 'PAGES.CHARGERS.LIST.CLEARCACHE' | translate }}</a>
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="getCompositeScheduleModal(charger, true, undefined)">{{ 'PAGES.CHARGERS.LIST.GETCOMPOSITESCHEDULE' | translate }}</a>
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="getConfigurationModal(charger, true, undefined)">{{ 'PAGES.CHARGERS.LIST.GETCONFIGURATION' | translate }}</a>
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="getDiagnosticsModal(charger, true, undefined)">{{ 'PAGES.CHARGERS.LIST.GETDIAGNOSTICS' | translate }}</a>
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="getLocalListVersionModal(charger!)">{{ 'PAGES.CHARGERS.LIST.GETLOCALLISTVERSION' | translate }}</a>
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="reserveNowModal(charger, true, undefined)">{{ 'PAGES.CHARGERS.LIST.RESERVENOW' | translate }}</a>
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="resetModal(charger, true, undefined)">{{ 'PAGES.CHARGERS.LIST.RESET' | translate }}</a>
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="sendLocalListModal(charger, true, undefined)">{{ 'PAGES.CHARGERS.LIST.SENDLOCALLIST' | translate }}</a>
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="setChargingProfileModal(charger, true, undefined)">{{ 'PAGES.CHARGERS.LIST.SETCHARGINGPROFILE' | translate }}</a>
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="startTransactionModal(charger, true, undefined)">{{ 'PAGES.CHARGERS.LIST.STARTTRANSACTION' | translate }}</a>
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="triggerMessageModal(charger, true, undefined)">{{ 'PAGES.CHARGERS.LIST.TRIGGERMESSAGE' | translate }}</a>
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="unlockConnectorModal(charger, true, undefined)">{{ 'PAGES.CHARGERS.LIST.UNLOCKCONNECTOR' | translate }}</a>
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="updateFirmwareModal(charger, true, undefined)">{{ 'PAGES.CHARGERS.LIST.UPDATEFIRMWARE' | translate }}</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="javascript:void(0)"
                                    (click)="commissionCharger(charger)">{{ 'PAGES.CHARGERS.LIST.COMMISSION' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                        <div class="mt-4">
                            <ul ngbNav #nav3="ngbNav" [activeId]="1" class="nav-tabs nav-tabs-custom nav-justified">
                                <li [ngbNavItem]="1">
                                    <a ngbNavLink>
                                        <span class="d-block d-sm-none"><i class="bx bx-detail"></i></span>
                                        <span class="d-none d-sm-block">{{ 'PAGES.CHARGERS.LIST.DETAILS' | translate }}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <ng-template [ngTemplateOutlet]="DetailsTabContent"></ng-template>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="2">
                                    <a ngbNavLink>
                                        <span class="d-block d-sm-none"><i
                                                class="fas fa-battery-three-quarters"></i></span>
                                        <span class="d-none d-sm-block">{{ 'MENUITEMS.CHARGINGPROFILE.TEXT' | translate }}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <ng-template [ngTemplateOutlet]="ChargingProfilesTabContent"></ng-template>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="3">
                                    <a ngbNavLink>
                                        <span class="d-block d-sm-none"><i
                                                class="fas fa-battery-three-quarters"></i></span>
                                        <span class="d-none d-sm-block">{{ 'PAGES.CHARGERS.LIST.RESERVATIONS' | translate }}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <ng-template [ngTemplateOutlet]="ReservationsTabContent"></ng-template>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="4">
                                    <a ngbNavLink>
                                        <span class="d-block d-sm-none"><i
                                                class="fas fa-battery-three-quarters"></i></span>
                                        <span class="d-none d-sm-block">{{ 'PAGES.COMMON.LIST.TRANSACTIONS' | translate }}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <ng-template [ngTemplateOutlet]="TransactionsTabContent"></ng-template>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="5">
                                    <a ngbNavLink>
                                        <span class="d-block d-sm-none"><i class="bx bxl-blogger"></i></span>
                                        <span class="d-none d-sm-block">{{ 'PAGES.CHARGERS.LIST.STATIONLOGS' | translate }}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <ng-template [ngTemplateOutlet]="StationLogsTabContent"></ng-template>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="nav3" class="text-muted"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end Tabs -->

<ng-template #DetailsTabContent>
    <div class="row mt-5">
        <div class="col-md-12 col-xl-4">
            <div class="card card-height-100">
                <div class="card-body">
                    <h4 class="card-title mb-4">{{ 'PAGES.COMMON.LIST.GENERAL' | translate }}</h4>

                    <div>
                        <div class="d-flex justify-content-between align-items-center border-bottom pb-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.NAME' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ charger?.name }}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.CHARGERS.LIST.CHARGERID' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ charger?.chargingStationIdentifier}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.CHARGERS.LIST.REGISTRATIONSTATUS' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ charger?.registrationStatus}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.CHARGERS.LIST.IMSI' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ charger?.imsi}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center pt-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.CHARGERS.LIST.ICCID' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ charger?.iccid }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-xl-4">
            <div class="card card-height-100">
                <div class="card-body">
                    <h4 class="card-title mb-4">{{ 'PAGES.COMMON.LIST.HARDWARE' | translate }}</h4>

                    <div>
                        <div class="d-flex justify-content-between align-items-center border-bottom pb-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.VENDOR' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ charger?.vendor }}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom pb-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.MODEL' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ charger?.model }}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.SERIALNUMBER' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ charger?.serialNumber}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom pb-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.OCPPPROTOCOL' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ charger?.ocppProtocol }}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom pb-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.CHARGERS.LIST.LASTHEARTBEAT' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ charger?.lastHearbeatTimestamp }}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom pb-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.FIRMWAREVERSION' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ charger?.firmwareVersion }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-xl-4">
            <div class="card card-height-100">
                <div class="card-body">
                    <h4 class="card-title mb-4">{{ 'PAGES.COMMON.LIST.CONNECTORS' | translate }}</h4>

                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{ 'PAGES.COMMON.LIST.TYPE' | translate }}</th>
                                    <th>{{ 'PAGES.COMMON.LIST.STATUS' | translate }}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let connector of charger?.connectors">
                                <tr *ngIf="connector.connectorIdentifier > 0">
                                    <th scope="row">{{ connector.connectorIdentifier}}</th>
                                    <td>{{ connector.chargerPlugType }}</td>
                                    <td>
                                        <span *ngIf="connector.chargePointStatus == 'Available'; else notAvailable"
                                            class="badge rounded-pill bg-success ms-1">
                                            {{ connector.chargePointStatus}}
                                        </span>
                                        <ng-template #notAvailable>
                                            <span class="badge rounded-pill bg-warning ms-1">
                                                {{ connector.chargePointStatus}}
                                            </span>
                                        </ng-template>
                                    </td>
                                    <td>
                                        <div class="dropdown" ngbDropdown>
                                            <button type="button" class="btn btn-light" title="Operations"
                                                ngbDropdownToggle id="dropdownMenuButton" data-bs-toggle="dropdown">
                                                <i class="fas fa-cogs"></i>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-end"
                                                aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
                                                <a class="dropdown-item" href="javascript:void(0)"
                                                    (click)="changeAvailabilityModal(charger, false, undefined)">{{ 'PAGES.CHARGERS.LIST.CHANGEAVAILABILITY' | translate }}</a>
                                                <a class="dropdown-item" href="javascript:void(0)"
                                                    (click)="reserveNowModal(charger, false, undefined)">{{ 'PAGES.CHARGERS.LIST.RESERVENOW' | translate }}</a>
                                                <a class="dropdown-item" href="javascript:void(0)"
                                                    (click)="startTransactionModal(charger, false, connector.connectorIdentifier)">{{ 'PAGES.CHARGERS.LIST.STARTTRANSACTION' | translate }}</a>
                                                <a class="dropdown-item" href="javascript:void(0)"
                                                    (click)="unlockConnectorModal(charger, false, undefined)">{{ 'PAGES.CHARGERS.LIST.UNLOCKCONNECTOR' | translate }}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #ChargingProfilesTabContent>
    <app-charger-profiles [charger]="charger!"></app-charger-profiles>
</ng-template>

<ng-template #ReservationsTabContent>
    <app-charger-reservations [charger]="charger!"></app-charger-reservations>
</ng-template>

<ng-template #TransactionsTabContent>
    <app-charger-transactions [charger]="charger!"></app-charger-transactions>
</ng-template>

<ng-template #StationLogsTabContent>
    <app-charger-logs [charger]="charger!"></app-charger-logs>
</ng-template>