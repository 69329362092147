import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppUtilities } from 'src/app/app-utilities';
import { MessageLog } from 'src/app/core/models/message-log.models';

@Component({
  selector: 'app-payload-modal',
  templateUrl: './payload-modal.component.html',
  styleUrls: ['./payload-modal.component.scss']
})
export class PayloadModalComponent implements OnInit {

  @Input() public messageLog!: MessageLog;
  payloadForm!: UntypedFormGroup;
  title = "OCPP Payload";

  constructor(public modal: NgbActiveModal, private appUtilities: AppUtilities) { }

  ngOnInit(): void {
    this.messageLog.payload = this.appUtilities.prettyJSON(this.messageLog.payload);
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
