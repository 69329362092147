import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'; // <-- Ensure this is imported

import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { ListFilter3Pipe, ListFilter4Pipe, ListFilter5Pipe, ListFilterPipe } from '../list-filter.pipe';
import { ListFilter2Pipe } from '../list-filter.pipe';
import { PhonePipe } from '../phone.pipe';
import { DatatableComponent } from './datatable/datatable.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatatableModalComponent } from './datatable/datatable-modal/datatable-modal.component';
import { NumberAlphabetPipe } from '../number-alphabet.pipe';
import { TruncatePipe } from '../truncate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { OrderComponent } from './order/order.component';
import { TransactionComponent } from './transaction/transaction.component';
import { UserComponent } from './user/user.component';
import { AddressInputComponent } from './address-input/address-input.component';

@NgModule({
  declarations: [
    ListFilterPipe,
    ListFilter2Pipe,
    ListFilter3Pipe,
    ListFilter4Pipe,
    ListFilter5Pipe,
    TruncatePipe,
    PhonePipe,
    NumberAlphabetPipe,
    PagetitleComponent,
    DatatableComponent,
    DatatableModalComponent,
    OrderComponent,
    UserComponent,
    TransactionComponent,
    AddressInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    NgbModule
  ],
  exports: [
    PagetitleComponent,
    DatatableComponent,
    CommonModule,
    RouterModule,
    FormsModule,
    ListFilterPipe,
    ListFilter2Pipe,
    ListFilter3Pipe,
    ListFilter4Pipe,
    ListFilter5Pipe,
    PhonePipe,
    NumberAlphabetPipe,
    TruncatePipe,
    TranslateModule,
    UserComponent,
    TransactionComponent,
    OrderComponent,
    AddressInputComponent
  ]
})
export class SharedModule { }