import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Configuration, PagedConfiguration } from 'src/app/core/models/configuration.models';
import { ConfigurationModalComponent } from './configuration-modal/configuration-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrl: './configurations.component.scss',
  providers: [DecimalPipe]
})
export class ConfigurationsComponent implements OnInit {
  // bread crumb items
  breadCrumbItems!: Array<{}>;
  title!: string;
  searchTerm!: string;
  searchTermChanged: Subject<string> = new Subject<string>();
  inputSub: Subscription | undefined;
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  genericFilter: GenericFilter = new GenericFilter('', 0, 10, 'id', 'desc');
  configuration!: Configuration;
  configurations!: Configuration[];
  pagedConfigurations!: PagedConfiguration;
  empty = true;
  
  constructor(private configurationService: ConfigurationService, private pipe: DecimalPipe, public modalService: NgbModal) { }
  
  ngOnInit(): void {
    this.title = 'PAGES.CONFIGURATIONS.TEXT';
  
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'MENUITEMS.SETTINGS.TEXT' },
      { label: 'PAGES.CONFIGURATIONS.TEXT', active: true }
    ];
  
    this.fetchData();

    this.inputSub = this.searchTermChanged.pipe(
      debounceTime(500),
      delay(200)
    ).subscribe(value => {
        // console.log(value)
        this.genericFilter.searchParam = value;
        this.fetchData();
      });
  }
  
  fetchData() {
    this.genericFilter.size = this.pageSize;
    this.genericFilter.page = this.page -1;
    this.configurationService.getConfigurations(this.genericFilter).subscribe({
      next: (v) => {
        // console.log('returned configurations : ', v);
        this.pagedConfigurations = v;
        this.collectionSize = this.pagedConfigurations.totalElements;
        if (this.pagedConfigurations.content.length > 0) {
          this.configurations = this.pagedConfigurations.content;
          this.empty = false;
        }
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('configurations retrieved');
      }
    });
  }
  
  search(value: string): void {
    this.searchTermChanged.next(value);
  }

  onSizeChange(size: number) {
    this.genericFilter.size = size;
    this.pageSize = size;
    this.page = 1;
    this.fetchData();
  }

  pageChanged(page: any) {
    // console.log('page change: ', page);
    this.genericFilter.page = page - 1;
    this.fetchData();
  }
  
  openModal(configuration: any, isAddNew: boolean) {
    const modalRef = this.modalService.open(ConfigurationModalComponent);
    modalRef.componentInstance.configuration = configuration;
    modalRef.componentInstance.isAddNew = isAddNew;
  
    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }
  
  //refresh page
  refreshPage(): void {
    window.location.reload();
  }
}
