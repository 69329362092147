import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject, Subscription } from "rxjs";
import { GenericFilter } from "src/app/core/models/generic-filter.models";
import { Wallet, PagedWallets } from "src/app/core/models/wallet.models";
import { WalletService } from "src/app/core/services/wallet.service";
import { Column, SubmitData } from "src/app/core/models/datatable.models";

@Component({
    selector: "app-wallets",
    templateUrl: "./wallets.component.html",
    styleUrls: ["./wallets.component.scss"],
})
export class WalletsComponent implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;
    title!: string;
    tableTitle!: string;
    collectionSize!: number;
    wallets!: Wallet[];
    pagedWallets!: PagedWallets;

    columns: Column[] = [
        { key: "walletNumber", name: "PAGES.COMMON.LIST.WALLETNUMBER" },
        { key: "accountEmail", name: "PAGES.COMMON.LIST.EMAIL", isSearchTerm: true },
        { key: "walletType", name: "PAGES.COMMON.LIST.WALLETTYPE" },
        { key: "currency", name: "PAGES.COMMON.LIST.CURRENCY" },
        { key: "availableBalance", name: "PAGES.COMMON.LIST.AVAILABLEBALANCE", prefix: "$" },
        { key: "currentBalance", name: "PAGES.COMMON.LIST.CURRENTBALANCE", prefix: "$" },
        { key: "isAutoTopUpEnabled", name: "PAGES.WALLETS.LIST.AUTOTOPUPENABLED" },
        { key: "autoTopUpAmount", name: "PAGES.WALLETS.LIST.AUTOTOPUPAMOUNT", prefix: "$" },
        { key: "updatedAt", name: "PAGES.COMMON.LIST.LASTUPDATED" },
        {
            key: "enabled",
            name: "PAGES.COMMON.LIST.STATUS",
            editType: "boolean",
            required: true,
            booleanDisplay: ["Enabled", "Disabled"],
        },
    ];

    constructor(
        private walletService: WalletService,
        public modalService: NgbModal
    ) {}

    ngOnInit(): void {
        this.title = 'PAGES.WALLETS.TEXT';
        this.tableTitle = 'PAGES.WALLETS.LIST.LISTTITLE';

        //BreadCrumb
        this.breadCrumbItems = [
            { label: 'MENUITEMS.BILLINGMANAGEMENT.TEXT' },
            { label: "PAGES.WALLETS.TEXT", active: true },
        ];
    }

    submitHandler = (v: SubmitData): Observable<any> => {
        return this.walletService.updateStatus(v.data);
    };

    fetchData(filter: GenericFilter) {
        this.walletService.getWallets(filter).subscribe({
            next: (v) => {
                // console.log("returned organizations : ", v);
                this.pagedWallets = v;
                this.collectionSize = this.pagedWallets.totalElements;
                this.wallets = this.pagedWallets.content;
            },
            error: (e) => {
                // console.error(e);
            },
            complete: () => {
                // console.info("organizations retrieved");
            },
        });
    }
}
