import { Component, OnInit } from '@angular/core';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { Transaction } from 'src/app/core/models/transaction.models';
import { TransactionService } from 'src/app/core/services/transaction.service';

@Component({
  selector: 'app-latest-transactions-card',
  templateUrl: './latest-transactions-card.component.html',
  styleUrls: ['./latest-transactions-card.component.scss']
})
export class LatestTransactionsCardComponent implements OnInit {

  transactions!: Transaction[];
  
  constructor(private transactionService: TransactionService) { }

  ngOnInit(): void {
    this.fetchTransactionData();
  }

  private fetchTransactionData() {
      const genericFilter: GenericFilter = new GenericFilter('', 0, 5, 'id', 'desc');
      this.transactionService.getTransactions(genericFilter).subscribe({
          next: (pagedTransactions) => {
              // console.log("returned transactions : ", pagedTransactions);
              this.transactions = pagedTransactions.content;
          },
          error: (e) => {
            // console.error(e);
          },
          complete: () => {
              // console.info("transactions retrieved");
          },
      });
    }
}
