<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title | translate}}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="chargingStationIdentifier" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.STATION' | translate }}</label>
            <input type="text" class="form-control" id="chargingStationIdentifier" name="chargingStationIdentifier" 
                required readonly [(ngModel)]="newAction.chargingStationIdentifier" #chargingStationIdentifier="ngModel">
            <span style="color:red" [hidden]="chargingStationIdentifier.valid || chargingStationIdentifier.pristine"> 
                <p>{{ 'PAGES.CHARGERS.LIST.STATIONREQUIRED' | translate }}</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="commaSeparatedAddUpdateList" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.ADDUPDATELOCALLIST' | translate }}</label>
            <input type="text" class="form-control" id="commaSeparatedAddUpdateList" name="commaSeparatedAddUpdateList" 
                [(ngModel)]="newAction.commaSeparatedAddUpdateList" #commaSeparatedAddUpdateList="ngModel">
        </div>
        <div class="form-group mb-3">
            <label for="commaSeparatedDeleteList" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.LISTOFTAGIDS' | translate }}</label>
            <input type="text" class="form-control" id="commaSeparatedDeleteList" name="commaSeparatedDeleteList" 
                [(ngModel)]="newAction.commaSeparatedDeleteList" #commaSeparatedDeleteList="ngModel">
        </div>
        <div class="form-group mb-3">
            <label for="listVersion" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.LISTVERSION' | translate }}</label>
            <input type="number" class="form-control" id="listVersion" name="listVersion" 
                required [(ngModel)]="newAction.listVersion" #listVersion="ngModel" min="1">
            <span style="color:red" [hidden]="listVersion.valid || listVersion.pristine"> 
                <p>List Version is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="sendEmptyListWhenFull" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.SENDEMPTYLIST' | translate }}</label>
            <select class="form-select" id="sendEmptyListWhenFull" name="sendEmptyListWhenFull" [(ngModel)]="newAction.sendEmptyListWhenFull" #sendEmptyListWhenFull="ngModel">
                <option value="true">{{ 'PAGES.COMMON.LIST.TRUE' | translate }}</option>
                <option value="false">{{ 'PAGES.COMMON.LIST.FALSE' | translate }}</option>
            </select>
        </div>
        <div class="form-group mb-3">
            <label for="updateType" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.UPDATETYPE' | translate }}</label>
            <select class="form-select" id="updateType" name="updateType" [(ngModel)]="newAction.updateType" #updateType="ngModel">
                <option value="Differential">{{ 'PAGES.CHARGERS.LIST.DIFFERENTIAL' | translate }}</option>
                <option value="Full">{{ 'PAGES.CHARGERS.LIST.FULL' | translate }}</option>
            </select>
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">{{ 'PAGES.COMMON.LIST.CANCEL' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">{{ 'PAGES.COMMON.LIST.SUBMIT' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>