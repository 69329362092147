import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { Role } from '../models/role.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getUserManagementUrl();
  }

  /**
     * Get all roles
     */
  getRoles(): Observable<Role[]> {

    return this.http.get<Role[]>(`${this.backendApiUrl}/roles`, this.httpOptions).pipe(
      tap((roles: Role[]) => {
        this.log(`Retrieved roles`);
      })
    );
  }

  /**
     * Get role
     */
  getRole(uuid: string): Observable<Role> {

    return this.http.get<Role>(`${this.backendApiUrl}/roles/${uuid}`, this.httpOptions).pipe(
      tap((role: Role) => {
        this.log(`Retrieved role`);
      })
    );
  }

  /**
     * Add role
     */
  addRole(params: any): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/roles`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added role`);
      })
    );
  }

  /**
     * Update role
     */
  updateRole(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/roles/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated role`);
      })
    );
  }

  /**
     * Update role permissions
     */
  updateRolePermissions(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/roles/${uuid}/permissions`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated role permissions`);
      })
    );
  }

  /** Log a RoleService message */
  private log(message: string) {
    // console.log(`RoleService: ${message}`);
  }
}
