import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { SmsNotification, PagedSmsNotification } from '../models/sms-notification.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';
import { GenericFilter } from '../models/generic-filter.models';

@Injectable({
  providedIn: 'root'
})
export class SmsNotificationService {
  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getUserManagementUrl();
  }

  /**
     * Get all sms notifications
     */
  getSmsNotifications(params: GenericFilter): Observable<PagedSmsNotification> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    queryString = `${queryString}`;
    return this.http.get<PagedSmsNotification>(`${this.backendApiUrl}/sms-notifications?${queryString}`, this.httpOptions).pipe(
      tap((pagedSmsNotification: PagedSmsNotification) => {
        this.log(`Retrieved sms notifications`);
      })
    );
  }

  /** Log a SmsNotificationService message */
  private log(message: string) {
    // console.log(`SmsNotificationService: ${message}`);
  }
}
