import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberAlphabet'
})
export class NumberAlphabetPipe implements PipeTransform {

  transform(num: number): any {
    try {
      const alphabet = String.fromCharCode(num + 64);
      return alphabet;
    } catch (error) {
      return num;
    }
  }

}
