import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppUtilities } from "src/app/app-utilities";
import { EvCard, PagedEvCard } from "../models/ev-card.models";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { GenericResponse } from "../models/generic-response.models";
import { GenericFilter } from "../models/generic-filter.models";

@Injectable({
    providedIn: "root",
})
export class EvCardService {
    backendApiUrl = "";
    httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    constructor(private http: HttpClient, private appUtilities: AppUtilities) {
        this.backendApiUrl = appUtilities.getChargingStationManagementUrl();
    }

    /**
     * Get all evCards
     */
    getEvCards(params: GenericFilter): Observable<PagedEvCard> {
        let queryString = Object.keys(params)
            .map((key) => key + "=" + params[key as keyof GenericFilter])
            .join("&");
        return this.http
            .get<PagedEvCard>(
                `${this.backendApiUrl}/ev-cards?${queryString}`,
                this.httpOptions
            )
            .pipe(
                tap((pagedEvCard: PagedEvCard) => {
                    this.log(`Retrieved evCards`);
                })
            );
    }

    /**
     * Add evCard
     */
    addEvCard(params: any): Observable<GenericResponse> {
        // console.log('request : ', params);

        return this.http
            .post<GenericResponse>(
                `${this.backendApiUrl}/ev-cards/add`,
                params,
                this.httpOptions
            )
            .pipe(
                tap((res: GenericResponse) => {
                    this.log(`Added evCard`);
                })
            );
    }

    /**
     * Update evCard
     */
    updateEvCard(uuid: string, params: any): Observable<GenericResponse> {
        return this.http
            .put<GenericResponse>(
                `${this.backendApiUrl}/ev-cards/${uuid}`,
                params,
                this.httpOptions
            )
            .pipe(
                tap((res: GenericResponse) => {
                    this.log(`Updated evCard`);
                })
            );
    }

    /** Log a EvCardService message */
    private log(message: string) {
        // console.log(`EvCardService: ${message}`);
    }
}
