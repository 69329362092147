<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                © {{year}} <a href="https://tapgoev.com" target="_blank"><b>Tap&Go EV</b></a>. All rights reserved.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Licensed to Vancouver Airport Authority (YVR).
                </div>
            </div>
        </div>
    </div>
</footer>