import { Component, OnInit } from '@angular/core';
import { Analytics } from 'src/app/core/models/dashboard.models';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-active-users-card',
  templateUrl: './active-users-card.component.html',
  styleUrls: ['./active-users-card.component.scss']
})
export class ActiveUsersCardComponent implements OnInit {

  activeUsersAnalytics!: Analytics;
  
  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.fetchActiveUsersAnalytics();
  }

  private fetchActiveUsersAnalytics() {
    this.userService.getActiveUsersCount().subscribe({
      next: (v) => {
        // console.log('returned active users count : ', v);
        this.activeUsersAnalytics = v;
        if (v.differential >= 1) {
          this.activeUsersAnalytics.classCss = 'badge badge-soft-success me-1';
          this.activeUsersAnalytics.classArrowCss = 'mdi mdi-arrow-up-bold me-1';
        } else if (v.differential < 0) {
          this.activeUsersAnalytics.classCss = 'badge badge-soft-danger me-1';
          this.activeUsersAnalytics.classArrowCss = 'mdi mdi-arrow-down-bold me-1';
        } else {
          this.activeUsersAnalytics.classCss = 'badge badge-soft-warning me-1';
          this.activeUsersAnalytics.classArrowCss = 'mdi mdi-arrow-left-right-bold me-1';
        }
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('active users count retrieved');
      }
    });
  }

}
