import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { Country } from '../models/country.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getUserManagementUrl();
  }

  /**
     * Get all countries
     */
  getCountries(): Observable<Country[]> {

    return this.http.get<Country[]>(`${this.backendApiUrl}/countries`, this.httpOptions).pipe(
      tap((countries: Country[]) => {
        this.log(`Retrieved countries`);
      })
    );
  }

  /** Log a CountryService message */
  private log(message: string) {
    console.log(`CountryService: ${message}`);
  }
}
