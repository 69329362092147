import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddVehicleMake } from 'src/app/core/models/vehicle-make.models';
import { VehicleMakeService } from 'src/app/core/services/vehicle-make.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-vehicle-make-modal',
  templateUrl: './vehicle-make-modal.component.html',
  styleUrls: ['./vehicle-make-modal.component.scss']
})
export class VehicleMakeModalComponent implements OnInit {

  @Input() public vehicleMake!: AddVehicleMake;
  @Input() public isAddNew!: boolean;

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "Edit Vehicle Make";

  constructor(
    public modal: NgbActiveModal, 
    private vehicleMakeService: VehicleMakeService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    if (this.vehicleMake == undefined) {
      this.vehicleMake = {
        name: "",
        description: "",
        uuid: "",
        enabled: false
      };

      this.title = "Add Vehicle Make";
    }
  }

  
  // Handle Create button click
  onSubmit() {
    console.log('create clicked');
    this.processing = true;
    if (this.isAddNew) {
      // Add a new vehicleMake
      this.vehicleMakeService.addVehicleMake(this.vehicleMake).subscribe({
        next: (v) => {
          console.log('added vehicle-make : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          console.info('vehicle-make saved');
        }
      });
    } else {
      // Update an existing vehicleMake
      this.vehicleMakeService.updateVehicleMake(this.vehicleMake, this.vehicleMake.uuid).subscribe({
        next: (v) => {
          console.log('updated vehicle-make : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });  
        },
        complete: () => {
          console.info('vehicle-make saved');
        }
      });
    }
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
