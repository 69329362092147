import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; 
import { Organization } from 'src/app/core/models/organization.models';
import { Site } from 'src/app/core/models/site.models';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { ScriptsService } from 'src/app/core/services/scripts.service';
import { SiteService } from 'src/app/core/services/site.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-site-view',
  templateUrl: './site-view.component.html',
  styleUrls: ['./site-view.component.scss']
})
export class SiteViewComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  title!: string;
  siteId!: string;

  longitude = 0;
  latitude = 0;
  markers: any;
  zoom: number = 13;
  address = '';
  iconUrl = '';
  totalConnectorCount = 0;
  availableConnectorCount = 0;
  chargerCount = 0;
  connectorCount = 0;

  @ViewChild('streetviewMap', { static: true }) streetviewMap: any;
  @ViewChild('streetviewPano', { static: true }) streetviewPano: any;

  @Input() heading: number = 34;
  @Input() pitch: number = 10;
  @Input() scrollwheel: boolean = false;
  @Input() site!: Site;
  organization?: Organization;

  apiLoaded = false;
  center!: google.maps.LatLngLiteral;
  markerOptions!: google.maps.MarkerOptions;
  markerPosition!: google.maps.LatLngLiteral;

  constructor(
    private route: ActivatedRoute, private siteService: SiteService, private organizationService: OrganizationService,
    public modalService: NgbModal, public sweetalertService: SweetalertService, private scriptsService: ScriptsService
  ) { }

  ngOnInit(): void {
    this.title = 'PAGES.SITES.LIST.DETAILS';
    this.siteId = String(this.route.snapshot.paramMap.get('siteId'));

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'MENUITEMS.SETTINGS.TEXT' },
      { label: 'PAGES.INTERNALACCOUNTS.TEXT' },
      { label: 'PAGES.INTERNALACCOUNTS.LIST.DETAILS', active: true }
    ];
    this.breadCrumbItems = [
      { label: 'MENUITEMS.CHARGERMANAGEMENT.TEXT' },
      { label: 'PAGES.SITES.TEXT' },
      { label: 'PAGES.SITES.LIST.DETAILS', active: true }
    ];

    if (this.site == undefined) { 
      this.fetchData();
    } else {
      this.getAddress();
    }
  }

  fetchData(): void {
    this.siteService.getSite(this.siteId).subscribe({
      next: (v) => {
        // console.log('returned site : ', v);
        this.site = v;
        this.getAddress();
        this.fetchOrganization(this.site.siteOwnerUuid);
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('site retrieved');
      }
    })
  }

  getAddress() {

    if (this.site.address.addressLine2 == undefined || this.site.address.addressLine2 == '') {
      this.address = `${this.site.address.addressLine1}, ${this.site.address.city}, ${this.site.address.region}, ${this.site.address.postalCode}, ${this.site.address.country}`;
    } else {
      this.address = `${this.site.address.addressLine1}, ${this.site.address.addressLine2}, ${this.site.address.city}, ${this.site.address.region}, ${this.site.address.postalCode}, ${this.site.address.country}`;
    }
    
    this.chargerCount = this.site.chargingStations.length;
    this.site.chargingStations.forEach((chargingStation) => {
      this.connectorCount += chargingStation.connectors.length;
    });

    this.scriptsService.load('GoogleMap').then(() => {
      this.latitude = Number(this.site.address.latitude);
      this.longitude = Number(this.site.address.longitude);

      this.center = { lat: this.latitude, lng: this.longitude };

      this.markerOptions = {
        icon: {
          url: '../../../../../assets/images/pin_green.png',
          scaledSize: new google.maps.Size(36, 48) // Resize marker
        }
      };

      this.markerPosition = this.center;
      this.apiLoaded = true;
    })
  }

  fetchOrganization(siteOwnerUuid: string): void {
    this.organizationService.getOrganization(siteOwnerUuid).subscribe({
      next: (v) => {
        // console.log('returned organization : ', v);
        this.organization = v;
      },
      error: (e) => console.error(e),
      complete: () => {
        // console.info('organization retrieved');
      }
    })
  }

}
