import { Component, OnInit, Inject, EventEmitter, Output } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

import { LanguageService } from "../../core/services/language.service";
import { AuthService } from "src/app/core/services/authentication.service";
import { User } from "../../core/models/user.models";
import { Observable } from "rxjs";
import { UserState } from "src/app/store/user/reducer";
import { Store, select } from "@ngrx/store";

@Component({
    selector: "app-topbar",
    templateUrl: "./topbar.component.html",
    styleUrls: ["./topbar.component.scss"],
})
/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
    element: any;
    flagvalue: any;
    cookieValue: any;
    countryName: any;
    valueset: any;
    user!: User;
    user$: Observable<User | null>;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private router: Router,
        public languageService: LanguageService,
        public _cookiesService: CookieService,
        private authService: AuthService,
        private store: Store<{ user: UserState }>
    ) {
        store.subscribe((state) => console.log("Current State:", state));
        this.user$ = store.select((state) => state.user.user);
        this.user$.subscribe((user) => {
            this.user = user ? user : ({} as User);
        });
    }

    /***
     * Language Listing
     */
    listLang = [
        { text: "English", flag: "assets/images/flags/canada.jpg", lang: "en" },
        { text: "French", flag: "assets/images/flags/canada.jpg", lang: "fr" },
    ];

    @Output() settingsButtonClicked = new EventEmitter();
    @Output() mobileMenuButtonClicked = new EventEmitter();

    ngOnInit(): void {
        this.element = document.documentElement;
        // Cookies wise Language set
        this.cookieValue = this._cookiesService.get("lang");
        const val = this.listLang.filter((x) => x.lang === this.cookieValue);
        this.countryName = val.map((element) => element.text);
        if (val.length === 0) {
            if (this.flagvalue === undefined) {
                this.valueset = "assets/images/flags/canada.jpg";
            }
        } else {
            this.flagvalue = val.map((element) => element.flag);
        }
    }

    /***
     * Language Value Set
     */
    setLanguage(text: string, lang: string, flag: string) {
        this.countryName = text;
        this.flagvalue = flag;
        this.cookieValue = lang;
        this.languageService.setLanguage(lang);
    }

    /**
     * Fullscreen method
     */
    fullscreen() {
        document.body.classList.toggle("fullscreen-enable");
        if (
            !document.fullscreenElement &&
            !this.element.mozFullScreenElement &&
            !this.element.webkitFullscreenElement
        ) {
            if (this.element.requestFullscreen) {
                this.element.requestFullscreen();
            } else if (this.element.mozRequestFullScreen) {
                /* Firefox */
                this.element.mozRequestFullScreen();
            } else if (this.element.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                this.element.webkitRequestFullscreen();
            } else if (this.element.msRequestFullscreen) {
                /* IE/Edge */
                this.element.msRequestFullscreen();
            }
        } else {
            if (this.document.exitFullscreen) {
                this.document.exitFullscreen();
            } else if (this.document.mozCancelFullScreen) {
                /* Firefox */
                this.document.mozCancelFullScreen();
            } else if (this.document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this.document.webkitExitFullscreen();
            } else if (this.document.msExitFullscreen) {
                /* IE/Edge */
                this.document.msExitFullscreen();
            }
        }
    }

    /**
     * Toggle the menu bar when having mobile screen
     */
    toggleMobileMenu(event: any) {
        event.preventDefault();
        this.mobileMenuButtonClicked.emit();
    }

    /**
     * Toggles the right sidebar
     */
    toggleRightSidebar() {
        this.settingsButtonClicked.emit();
    }

    /**
     * Logout the user
     */
    logout() {
        this.authService.logout();
        this.router.navigate(["/account/login"]);
    }
}
