import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { NotificationTemplate } from '../models/notification-template.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';

@Injectable({
  providedIn: 'root'
})
export class NotificationTemplateService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getUserManagementUrl();
  }

  /**
     * Get all notification templates
     */
  getNotificationTemplates(): Observable<NotificationTemplate[]> {

    return this.http.get<NotificationTemplate[]>(`${this.backendApiUrl}/notification-templates`, this.httpOptions).pipe(
      tap((notificationTemplates: NotificationTemplate[]) => {
        this.log(`Retrieved notificationTemplates`);
      })
    );
  }

  /**
     * Add notificationTemplate
     */
  addNotificationTemplate(params: any): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/notification-templates`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added notificationTemplates`);
      })
    );
  }

  /**
     * Update permission
     */
  updateNotificationTemplate(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/notification-templates/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated notificationTemplates`);
      })
    );
  }

  /** Log a NotificationTemplateService message */
  private log(message: string) {
    // console.log(`NotificationTemplateService: ${message}`);
  }
}
