<!-- start page title -->
<app-pagetitle [title]="title | translate" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex mb-4">
                    <h4 class="card-title flex-grow-1">{{ 'PAGES.SITES.LIST.LISTTITLE' | translate }}</h4>
                    <div class="flex-shrink-0">
                        <button type="button" class="btn btn-primary w-md" (click)="openModal(chargingProfile, true)">{{ 'PAGES.COMMON.LIST.ADDNEW' | translate }}</button>
                    </div>
                </div>

                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6">
                        <div class="dataTables_length" id="tickets-table_length"><label
                                class="d-inline-flex align-items-center">{{ 'PAGES.COMMON.LIST.SHOW' | translate }}
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="pageSize" class="form-control form-control-sm mx-2"
                                    (change)="onSizeChange(pageSize)">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select> {{ 'PAGES.COMMON.LIST.ENTRIES' | translate }}</label></div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">
                                {{ 'PAGES.COMMON.LIST.SEARCH' | translate }}:
                                <input type="text" name="searchTerm" id="searchTerm"
                                    class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table" [(ngModel)]="searchTerm" 
                                    (input)="search(searchTerm)"
                                    >
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>

                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable"
                        class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th>{{ 'PAGES.COMMON.LIST.DESCRIPTION' | translate }}</th>
                                <th>{{ 'PAGES.CHARGINGPROFILES.LIST.PURPOSE' | translate }}</th>
                                <th>{{ 'PAGES.CHARGINGPROFILES.LIST.KIND' | translate }}</th>
                                <th>{{ 'PAGES.CHARGINGPROFILES.LIST.RECURRENCYKIND' | translate }}</th>
                                <th>{{ 'PAGES.CHARGINGPROFILES.LIST.VALIDFROM' | translate }}</th>
                                <th>{{ 'PAGES.CHARGINGPROFILES.LIST.VALIDTO' | translate }}</th>
                                <th>{{ 'PAGES.CHARGINGPROFILES.LIST.STARTSCHEDULE' | translate }}</th>
                                <th>{{ 'PAGES.CHARGINGPROFILES.LIST.DURATIONINSECONDS' | translate }}</th>
                                <th>{{ 'PAGES.CHARGINGPROFILES.LIST.CHARGINGRATEUNIT' | translate }}</th>
                                <th>{{ 'PAGES.CHARGINGPROFILES.LIST.MINCHARGINGRATE' | translate }}</th>
                                <th>{{ 'PAGES.COMMON.LIST.LASTUPDATED' | translate }}</th>
                                <th>{{ 'PAGES.COMMON.LIST.STATUS' | translate }}</th>
                                <th >{{ 'PAGES.COMMON.LIST.ACTIONS' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [hidden]="!empty">
                                <td colspan="13">{{ 'PAGES.COMMON.LIST.NODATAAVAILABLE' | translate }}</td>
                            </tr>
                            <tr *ngFor="let chargingProfile of chargingProfiles" [hidden]="pagedChargingProfiles.empty">
                                <td>
                                    <ngb-highlight [result]="chargingProfile.description" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    {{ chargingProfile.chargingProfilePurpose }}
                                </td>
                                <td>
                                    {{ chargingProfile.chargingProfileKind }}
                                </td>
                                <td>
                                    {{ chargingProfile.recurrencyKind }}
                                </td>
                                <td>
                                    {{ +chargingProfile.validFrom | date:'yyyy-MM-dd, h:mm a' }}
                                </td>
                                <td>
                                    {{ chargingProfile.validTo | date:'yyyy-MM-dd, h:mm a' }}
                                </td>
                                <td>
                                    {{ chargingProfile.startSchedule | date:'yyyy-MM-dd, h:mm a' }}
                                </td>
                                <td>
                                    {{ chargingProfile.durationInSeconds }}
                                </td>
                                <td>
                                    {{ chargingProfile.chargingRateUnit }}
                                </td>
                                <td>
                                    {{ chargingProfile.minChargingRate }}
                                </td>
                                <td>
                                    <ngb-highlight [result]="chargingProfile.updatedAt" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <span *ngIf="chargingProfile.enabled" class="badge rounded-pill bg-success ms-1">
                                        {{ 'PAGES.COMMON.LIST.ENABLED' | translate }}
                                    </span>
                                    <span *ngIf="!chargingProfile.enabled" class="badge rounded-pill bg-warning ms-1">
                                        {{ 'PAGES.COMMON.LIST.DISABLED' | translate }}
                                    </span>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-outline-primary" [title]="'PAGES.COMMON.LIST.EDIT' | translate" (click)="openModal(chargingProfile, false)">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- End table -->
                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            {{ 'PAGES.COMMON.LIST.SHOWINGENTRIES' | translate }}
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-right float-md-end pagination-rounded">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" 
                                [maxSize]="5" [boundaryLinks]="true" [pageSize]="pageSize" 
                                (pageChange)="pageChanged($event)" 
                                class="d-flex justify-content-center">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- End Pagination -->
                </div>
            </div>
        </div>
    </div>
</div>