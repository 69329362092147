import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 60,
        label: 'MENUITEMS.GENERAL.TEXT',
        isTitle: true,
        permissions: []
    },
    {
        id: 61,
        label: 'MENUITEMS.DASHBOARD.TEXT',
        icon: 'bx bxs-dashboard',
        link: '/',
        permissions: ['ROLE_BACKOFFICE']
    },
    {
        id: 62,
        label: 'MENUITEMS.SETTINGS.TEXT',
        icon: 'bx bxs-cog',
        permissions: ['ROLE_BACKOFFICE'],
        subItems: [
            {
                id: 63,
                label: 'MENUITEMS.SETTINGS.LIST.PERMISSIONS',
                link: '/settings/permissions',
                permissions: [],
                parentId: 62
            },
            {
                id: 64,
                label: 'MENUITEMS.SETTINGS.LIST.ROLES',
                link: '/settings/roles',
                permissions: [],
                parentId: 62
            },
            {
                id: 65,
                label: 'MENUITEMS.SETTINGS.LIST.VEHICLEMAKES',
                link: '/settings/vehicle-makes',
                permissions: [],
                parentId: 62
            },
            {
                id: 66,
                label: 'MENUITEMS.SETTINGS.LIST.ORGANIZATIONTYPES',
                link: '/settings/organization-types',
                permissions: [],
                parentId: 62
            },
            {
                id: 67,
                label: 'MENUITEMS.SETTINGS.LIST.COUNTRIES',
                link: '/settings/countries',
                permissions: [],
                parentId: 62
            },
            {
                id: 68,
                label: 'MENUITEMS.SETTINGS.LIST.INTERNALACCOUNTS',
                link: '/settings/internal-accounts',
                permissions: [],
                parentId: 62
            },
        ]
    },
    {
        id: 69,
        label: 'MENUITEMS.ACCOUNTMANAGEMENT.TEXT',
        isTitle: true,
        permissions: []
    },
    {
        id: 70,
        label: 'MENUITEMS.ORGANIZATIONS.TEXT',
        icon: 'bx bxs-briefcase',
        link: '/account-management/organizations',
        permissions: ['ROLE_CAN_VIEW_ORGANIZATION']
    },
    {
        id: 71,
        label: 'MENUITEMS.USERS.TEXT',
        icon: 'mdi mdi-account-group',
        link: '/account-management/users',
        permissions: []
    },
    {
        id: 72,
        label: 'MENUITEMS.BILLINGMANAGEMENT.TEXT',
        isTitle: true
    },
    {
        id: 73,
        label: 'MENUITEMS.ORDERS.TEXT',
        icon: 'bx bxs-dollar-circle',
        link: '/billing-management/orders'
    },
    {
        id: 74,
        label: 'MENUITEMS.REFUNDS.TEXT',
        icon: 'bx bx-dollar-circle',
        link: '/billing-management/refunds'
    },
    {
        id: 75,
        label: 'MENUITEMS.PRODUCTS.TEXT',
        icon: 'bx bxs-purchase-tag',
        link: '/billing-management/products'
    },
    {
        id: 76,
        label: 'MENUITEMS.WALLETS.TEXT',
        icon: 'bx bxs-wallet',
        link: '/billing-management/wallets'
    },
    {
        id: 77,
        label: 'MENUITEMS.CHARGERMANAGEMENT.TEXT',
        isTitle: true
    },
    {
        id: 78,
        label: 'MENUITEMS.SITES.TEXT',
        icon: 'bx bx-sitemap',
        link: '/charger-management/sites'
    },
    {
        id: 79,
        label: 'MENUITEMS.CHARGERS.TEXT',
        icon: 'bx bxs-plug',
        link: '/charger-management/chargers'
    },
    {
        id: 80,
        label: 'MENUITEMS.CHARGINGPROFILE.TEXT',
        icon: 'bx bx-brightness',
        link: '/charger-management/charging-profiles'
    },
    {
        id: 81,
        label: 'MENUITEMS.MEMBERSHIPCARDS.TEXT',
        icon: 'bx bxs-id-card',
        link: '/charger-management/membership-cards-stickers'
    },
    {
        id: 82,
        label: 'MENUITEMS.NOTIFICATIONMANAGEMENT.TEXT',
        isTitle: true,
        permissions: ['ROLE_CAN_VIEW_TEMPLATE']
    },
    {
        id: 83,
        label: 'MENUITEMS.EMAILTEMPLATES.TEXT', 
        icon: 'bx bxs-envelope',
        link: '/notification-management/email-templates',
        permissions: ['ROLE_CAN_VIEW_TEMPLATE']
    },
    {
        id: 83,
        label: 'MENUITEMS.TEMPLATES.TEXT', 
        icon: 'bx bxs-bell-plus',
        link: '/notification-management/notification-templates',
        permissions: ['ROLE_CAN_VIEW_TEMPLATE']
    },
    {
        id: 84,
        label: 'MENUITEMS.PUSHNOTIFICATIONS.TEXT', 
        icon: 'bx bxs-bell-ring',
        link: '/notification-management/push-notifications'
    },
    {
        id: 85,
        label: 'MENUITEMS.SMSTEMPLATES.TEXT', 
        icon: 'bx bxs-comment-add',
        link: '/notification-management/sms-templates'
    },
    {
        id: 86,
        label: 'MENUITEMS.SMSNOTIFICATIONS.TEXT', 
        icon: 'bx bxs-chat',
        link: '/notification-management/sms-notifications'
    },
    {
        id: 1000,
        label: 'MENUITEMS.SPACER.TEXT',
        isTitle: true
    },
    // {
    //     id: 1,
    //     label: 'MENUITEMS.MENU.TEXT',
    //     isTitle: true
    // },
    // {
    //     id: 2,
    //     label: 'MENUITEMS.DASHBOARDS.TEXT',
    //     icon: 'bx bxs-dashboard',
    //     subItems: [
    //         {
    //             id: 3,
    //             label: 'MENUITEMS.DASHBOARDS.LIST.ECOMMERCE',
    //             link: '/',
    //             parentId: 2
    //         },
    //         {
    //             id: 4,
    //             label: 'MENUITEMS.DASHBOARDS.LIST.CRYPTO',
    //             link: '/crypto',
    //             parentId: 2
    //         },
    //     ]
    // },
    // {
    //     id: 7,
    //     isLayout: true
    // },
    // {
    //     id: 8,
    //     label: 'MORE',
    //     isTitle: true
    // },
    // {
    //     id: 9,
    //     label: 'MENUITEMS.APPS.TEXT',
    //     icon: 'bx bxs-grid',
    //     subItems: [
    //         {
    //             id: 10,
    //             label: 'MENUITEMS.APPS.LIST.CALENDAR',
    //             link: '/apps/calender',
    //             parentId: 9
    //         },
    //         {
    //             id: 11,
    //             label: 'MENUITEMS.APPS.LIST.CHAT',
    //             link: '/apps/chat',
    //             parentId: 9
    //         },
    //         {
    //             id: 12,
    //             label: 'MENUITEMS.APPS.LIST.EMAIL',
    //             badge: {
    //                 variant: 'success',
    //                 text: 'Em',
    //             },
    //             parentId: 9,
    //             subItems: [
    //                 {
    //                     id: 13,
    //                     label: 'MENUITEMS.APPS.LIST.SUB.INBOX',
    //                     link: '/apps/inbox',
    //                     parentId: 12
    //                 },
    //                 {
    //                     id: 14,
    //                     label: 'MENUITEMS.APPS.LIST.SUB.READEMAIL',
    //                     link: '/apps/read/1',
    //                     parentId: 12
    //                 }
    //             ]
    //         }
    //     ]
    // },
    // {
    //     id: 15,
    //     label: 'MENUITEMS.AUTHENTICATION.TEXT',
    //     icon: 'bx bx-user-circle',
    //     badge: {
    //         variant: 'success',
    //         text: '9',
    //     },
    //     subItems: [
    //         {
    //             id: 16,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN',
    //             link: '/account/login1',
    //             parentId: 15
    //         },
    //         {
    //             id: 17,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER',
    //             link: '/account/register1',
    //             parentId: 15
    //         },
    //         {
    //             id: 18,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD',
    //             link: '/account/recoverpwd1',
    //             parentId: 15
    //         },
    //         {
    //             id: 19,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN',
    //             link: '/account/lockscreen',
    //             parentId: 15
    //         },
    //         {
    //             id: 20,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL',
    //             link: '/account/confirm-mail',
    //             parentId: 15
    //         },
    //         {
    //             id: 21,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION',
    //             link: '/account/email-verification',
    //             parentId: 15
    //         },
    //         {
    //             id: 22,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION',
    //             link: '/account/two-step-verification',
    //             parentId: 15
    //         }
    //     ]
    // },
    // {
    //     id: 23,
    //     label: 'MENUITEMS.PAGES.TEXT',
    //     icon: 'bx bx-file',
    //     subItems: [
    //         {
    //             id: 24,
    //             label: 'MENUITEMS.PAGES.LIST.STARTER',
    //             link: '/pages/starter',
    //             parentId: 23
    //         },
    //         {
    //             id: 24,
    //             label: 'MENUITEMS.PAGES.LIST.PRELOADER',
    //             link: '/pages/preloader',
    //             parentId: 23
    //         },
    //         {
    //             id: 26,
    //             label: 'MENUITEMS.PAGES.LIST.PROFILE',
    //             link: '/pages/profile',
    //             parentId: 23
    //         },
    //         {
    //             id: 26,
    //             label: 'MENUITEMS.PAGES.LIST.TEAM',
    //             link: '/pages/team',
    //             parentId: 23
    //         },
    //         {
    //             id: 26,
    //             label: 'MENUITEMS.PAGES.LIST.GALLERY',
    //             link: '/pages/gallery',
    //             parentId: 23
    //         },
    //         {
    //             id: 27,
    //             label: 'MENUITEMS.PAGES.LIST.INVOICE',
    //             link: '/pages/invoice',
    //             parentId: 23
    //         },
    //         {
    //             id: 28,
    //             label: 'MENUITEMS.PAGES.LIST.MAINTENANCE',
    //             link: '/pages/maintenance',
    //             parentId: 23
    //         },
    //         {
    //             id: 29,
    //             label: 'MENUITEMS.PAGES.LIST.COMINGSOON',
    //             link: '/pages/comingsoon',
    //             parentId: 23
    //         },
    //         {
    //             id: 30,
    //             label: 'MENUITEMS.PAGES.LIST.TIMELINE',
    //             link: '/pages/timeline',
    //             parentId: 23
    //         },
    //         {
    //             id: 31,
    //             label: 'MENUITEMS.PAGES.LIST.PRICING',
    //             link: '/pages/pricing',
    //             parentId: 23
    //         },
    //         {
    //             id: 32,
    //             label: 'MENUITEMS.PAGES.LIST.ERROR404',
    //             link: '/pages/404',
    //             parentId: 23
    //         },
    //         {
    //             id: 33,
    //             label: 'MENUITEMS.PAGES.LIST.ERROR500',
    //             link: '/pages/500',
    //             parentId: 23
    //         }
    //     ]
    // },
    // {
    //     id: 34,
    //     label: 'MENUITEMS.ADMINKIT.TEXT',
    //     isTitle: true
    // },
    // {
    //     id: 35,
    //     label: 'MENUITEMS.UIELEMENTS.TEXT',
    //     icon: 'bx bx-briefcase',
    //     link: '/ui',

    // },
    // {
    //     id: 36,
    //     label: 'MENUITEMS.ADVANCEDKIT.TEXT',
    //     icon: 'bx bxs-cube-alt',
    //     subItems: [
    //         {
    //             id: 37,
    //             label: 'MENUITEMS.ADVANCEDKIT.LIST.SWEETALERT',
    //             link: '/advanced/sweet-alert',
    //             parentId: 36
    //         },
    //         {
    //             id: 38,
    //             label: 'MENUITEMS.ADVANCEDKIT.LIST.RANGESLIDER',
    //             link: '/advanced/rangeslider',
    //             parentId: 36
    //         },
    //         {
    //             id: 39,
    //             label: 'MENUITEMS.ADVANCEDKIT.LIST.NOTIFICATIONS',
    //             link: '/advanced/notifications',
    //             parentId: 36
    //         },
    //         {
    //             id: 40,
    //             label: 'MENUITEMS.ADVANCEDKIT.LIST.CAROUSEL',
    //             link: '/advanced/carousel',
    //             parentId: 36
    //         }
    //     ]
    // },
    // {
    //     id: 41,
    //     label: 'MENUITEMS.TYPOGRAPHY.TEXT',
    //     icon: 'bx bx-text',
    //     link: '/typography',

    // },
    // {
    //     id: 42,
    //     label: 'MENUITEMS.FORMS.TEXT',
    //     icon: 'bx bxs-magic-wand',
    //     badge: {
    //         variant: 'danger',
    //         text: '2',
    //     },
    //     subItems: [
    //         {
    //             id: 43,
    //             label: 'MENUITEMS.FORMS.LIST.ELEMENTS',
    //             link: '/form/elements',
    //             parentId: 42
    //         },
    //         {
    //             id: 44,
    //             label: 'MENUITEMS.FORMS.LIST.ADVANCED',
    //             link: '/form/advanced',
    //             parentId: 42
    //         }
    //     ]
    // },
    // {
    //     id: 45,
    //     label: 'MENUITEMS.TABLES.TEXT',
    //     icon: 'bx bx-table',
    //     subItems: [
    //         {
    //             id: 46,
    //             label: 'MENUITEMS.TABLES.LIST.BASIC',
    //             link: '/tables/basic',
    //             parentId: 45
    //         },
    //         {
    //             id: 47,
    //             label: 'MENUITEMS.TABLES.LIST.ADVANCED',
    //             link: '/tables/datatable',
    //             parentId: 45
    //         }
    //     ]
    // },
    // {
    //     id: 48,
    //     label: 'MENUITEMS.CHARTS.TEXT',
    //     icon: 'bx bx-doughnut-chart',
    //     link: '/charts',

    // },
    // {
    //     id: 49,
    //     label: 'MENUITEMS.ICONS.TEXT',
    //     icon: 'bx bx-layer',
    //     subItems: [
    //         {
    //             id: 50,
    //             label: 'MENUITEMS.ICONS.LIST.BOXICONS',
    //             link: '/icons/boxicons',
    //             parentId: 49
    //         },
    //         {
    //             id: 51,
    //             label: 'MENUITEMS.ICONS.LIST.MATERIALDESIGN',
    //             link: '/icons/materialdesign',
    //             parentId: 49
    //         },
    //         {
    //             id: 52,
    //             label: 'MENUITEMS.ICONS.LIST.DRIPICONS',
    //             link: '/icons/dripicons',
    //             parentId: 49
    //         },
    //         {
    //             id: 53,
    //             label: 'MENUITEMS.ICONS.LIST.FONTAWESOME',
    //             link: '/icons/fontawesome',
    //             parentId: 49
    //         }
    //     ]
    // },
    // {
    //     id: 54,
    //     label: 'MENUITEMS.MAPS.TEXT',
    //     icon: 'bx bx-map',
    //     link: '/maps',

    // },
    // {
    //     id: 55,
    //     label: 'MENUITEMS.MULTILEVEL.TEXT',
    //     icon: 'bx-share-alt',
    //     subItems: [
    //         {
    //             id: 56,
    //             label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.1',
    //             parentId: 55
    //         },
    //         {
    //             id: 57,
    //             label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.2',
    //             parentId: 55,
    //             subItems: [
    //                 {
    //                     id: 58,
    //                     label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.1',
    //                     parentId: 57,
    //                 },
    //                 {
    //                     id: 59,
    //                     label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.2',
    //                     parentId: 57,
    //                 }
    //             ]
    //         },
    //     ]
    // }
];

