import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Refund, PagedRefund } from 'src/app/core/models/refund.models';
import { RefundService } from 'src/app/core/services/refund.service';
import { ModalMessage } from 'src/app/core/models/modal-message.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';

@Component({
  selector: 'app-refunds',
  templateUrl: './refunds.component.html',
  styleUrls: ['./refunds.component.scss'],
  providers: [DecimalPipe]
})
export class RefundsComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  title!: string;
  searchTerm!: string;
  searchTermChanged: Subject<string> = new Subject<string>();
  inputSub: Subscription | undefined;
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  genericFilter: GenericFilter = new GenericFilter('', 0, 10, 'id', 'desc');
  refund!: Refund;
  refunds!: Refund[];
  pagedRefunds!: PagedRefund;
  empty = true;

  constructor(
    private refundService: RefundService, 
    public modalService: NgbModal) { }

  ngOnInit(): void {
    this.title = 'PAGES.REFUNDS.TEXT';

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'MENUITEMS.BILLINGMANAGEMENT.TEXT' },
      { label: 'PAGES.REFUNDS.TEXT', active: true }
    ];

    this.fetchData();

    this.inputSub = this.searchTermChanged.pipe(
      debounceTime(500),
      delay(200)
    ).subscribe(value => {
        // console.log(value)
        this.genericFilter.searchParam = value;
        this.fetchData();
      });
  }

  fetchData() {
    this.genericFilter.size = this.pageSize;
    this.genericFilter.page = this.page -1;
    this.refundService.getRefunds(this.genericFilter).subscribe({
      next: (v) => {
        // console.log('returned refunds : ', v);
        this.pagedRefunds = v;
        this.collectionSize = this.pagedRefunds.totalElements;
        if (this.pagedRefunds.content.length > 0) {
          this.refunds = this.pagedRefunds.content;
          this.empty = false;
        }
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('refunds retrieved');
      }
    });
  }

  search(value: string): void {
    this.searchTermChanged.next(value);
  }

  onSizeChange(size: number) {
    this.genericFilter.size = size;
    this.pageSize = size;
    this.page = 1;
    this.fetchData();
  }

  pageChanged(page: any) {
    // console.log('page change: ', page);
    this.genericFilter.page = page - 1;
    this.fetchData();
  }

  //refresh page
  refreshPage(): void {
    window.location.reload();
  }

}
