<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #payloadForm="ngForm">
        <div class="form-group mb-3">
            <label for="payloadId" class="fw-semibold">Payload</label>
            <textarea type="text" class="form-control" id="payloadId" name="payloadName" rows="20"
                readonly [(ngModel)]="messageLog.payload" #payload="ngModel"></textarea>
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">Close</button>
</div>