import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild, AfterViewInit, AfterViewChecked, forwardRef, ViewChildren } from '@angular/core';
import { ScriptsService } from 'src/app/core/services/scripts.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true
    }
  ]
})
export class AddressInputComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @ViewChild('autocomplete', { static: false }) addressInput!: ElementRef;
  @Output() addressSelected = new EventEmitter<any>();

  addressLine1: string = '';
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private ngZone: NgZone, private scriptsService: ScriptsService) {}

  ngOnInit(): void {
    // Load the Google Maps script only if it's not already loaded
    // if (!this.scriptsService.isScriptLoaded('GoogleMap')) {
    //   this.scriptsService.load('GoogleMap').then(() => {
    //     console.log('Google Maps script loaded.');
    //   }).catch(error => console.error('Error loading Google Maps script:', error));
    // } else {
    //   console.log('Google Maps script already loaded.');
    // }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.addressInput) {
        this.loadGooglePlacesAutocomplete();
      } else {
        console.error('Address input field is still not available.');
      }
    }, 500);  // Adjust timeout to ensure modal content is rendered
  }

  loadGooglePlacesAutocomplete() {
    if (!this.addressInput) {
      console.error("ViewChild addressInput is not initialized.");
      return;
    }

    const autocomplete = new google.maps.places.Autocomplete(this.addressInput.nativeElement, {
      types: ['geocode'],
      componentRestrictions: { country: 'CA' },
    });

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.address_components) {
          console.warn("Place details are incomplete or unavailable.");
          return;
        }

        let addressData = {
          addressLine1: '',
          city: '',
          region: '',
          country: '',
          postalCode: ''
        };

        place.address_components.forEach(component => {
          const types = component.types;
          if (types.includes('street_number') || types.includes('route')) {
            addressData.addressLine1 += component.long_name + ' ';
          }
          if (types.includes('locality')) {
            addressData.city = component.long_name;
          }
          if (types.includes('administrative_area_level_1')) {
            addressData.region = component.short_name;
          }
          if (types.includes('country')) {
            addressData.country = component.long_name;
          }
          if (types.includes('postal_code')) {
            addressData.postalCode = component.long_name;
          }
        });

        addressData.addressLine1 = addressData.addressLine1.trim();

        this.addressLine1 = addressData.addressLine1;
        this.onChange(this.addressLine1);
        this.onTouched();
        this.addressSelected.emit(addressData);
      });
    });
  }

  // ControlValueAccessor Methods (For Form Integration)
  writeValue(value: string): void {
    this.addressLine1 = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}