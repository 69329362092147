import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateWalletStatusDto, Wallet } from 'src/app/core/models/wallet.models';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';
import { WalletService } from 'src/app/core/services/wallet.service';

@Component({
  selector: 'app-wallet-modal',
  templateUrl: './wallet-modal.component.html',
  styleUrls: ['./wallet-modal.component.scss']
})
export class WalletModalComponent implements OnInit {

  @Input() public wallet!: Wallet;
  updateWalletStatusDto!: UpdateWalletStatusDto;

  processing = false;
  title = "Edit Wallet";

  constructor(
    public modal: NgbActiveModal, 
    private walletService: WalletService,
    public sweetalertService: SweetalertService
  ) { }

  ngOnInit(): void {
    this.updateWalletStatusDto = {
      walletUuid: this.wallet.uuid,
      enabled: this.wallet.enabled
    }
  }

  onSubmit() {
    this.walletService.updateStatus(this.updateWalletStatusDto).subscribe({
      next: (v) => {
        // console.log('updated wallet : ', v);
        this.sweetalertService.success(v.message).then(() => {
          this.modal.close(v);
        });
      },
      error: (e) => {
        // console.error('error occured : ', e);
        this.sweetalertService.failure(e).then(() => {
          this.processing = false;
        });  
      },
      complete: () => {
        // console.info('wallet updated');
      }
    });
  }

  onCancel() {
    this.modal.close();
  }

}
