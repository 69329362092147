import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject, Subscription } from "rxjs";
import { GenericFilter } from "src/app/core/models/generic-filter.models";
import { Wallet, PagedWallets } from "src/app/core/models/wallet.models";
import { WalletService } from "src/app/core/services/wallet.service";
import { Column, SubmitData } from "src/app/core/models/datatable.models";

@Component({
    selector: "app-wallets",
    templateUrl: "./wallets.component.html",
    styleUrls: ["./wallets.component.scss"],
})
export class WalletsComponent implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;
    collectionSize!: number;
    wallets!: Wallet[];
    pagedWallets!: PagedWallets;

    columns: Column[] = [
        { key: "walletNumber", name: "Wallet Number" },
        { key: "accountEmail", name: "Account Email", isSearchTerm: true },
        { key: "walletType", name: "Wallet Type" },
        { key: "currency", name: "Currency" },
        { key: "availableBalance", name: "Available Balance", prefix: "$" },
        { key: "currentBalance", name: "Current Balance", prefix: "$" },
        { key: "isAutoTopUpEnabled", name: "Auto Top Up Enabled" },
        { key: "autoTopUpAmount", name: "Auto Top Up Amount", prefix: "$" },
        { key: "updatedAt", name: "Last Updated" },
        {
            key: "enabled",
            name: "Status",
            editType: "boolean",
            required: true,
            booleanDisplay: ["Enabled", "Disabled"],
        },
    ];

    constructor(
        private walletService: WalletService,
        public modalService: NgbModal
    ) {}

    ngOnInit(): void {
        //BreadCrumb
        this.breadCrumbItems = [
            { label: "Billing Management" },
            { label: "Wallets", active: true },
        ];
    }

    submitHandler = (v: SubmitData): Observable<any> => {
        return this.walletService.updateStatus(v.data);
    };

    fetchData(filter: GenericFilter) {
        this.walletService.getWallets(filter).subscribe({
            next: (v) => {
                console.log("returned organizations : ", v);
                this.pagedWallets = v;
                this.collectionSize = this.pagedWallets.totalElements;
                this.wallets = this.pagedWallets.content;
            },
            error: (e) => console.error(e),
            complete: () => {
                console.info("organizations retrieved");
            },
        });
    }
}
