import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Column } from "src/app/core/models/datatable.models";
import { GenericFilter } from "src/app/core/models/generic-filter.models";
import { Order, OrderDetail } from "src/app/core/models/order.models";
import { OrderService } from "src/app/core/services/order.service";

@Component({
    selector: "app-order-details",
    templateUrl: "./order-details.component.html",
    styleUrls: ["./order-details.component.scss"],
})
export class OrderDetailsComponent implements OnInit {
    title: string = "Orders Details";
    reference!: string;
    breadCrumbItems!: Array<{}>;
    collectionSize!: number;
    order!: Order;
    energyOrderDetail!: OrderDetail;
    nonEnergyOrderDetail!: OrderDetail;

    columns: Column[] = [
        { key: "orderDetailType", name: "Type" },
        { key: "updatedAt", name: "Last Updated" },
    ];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private orderService: OrderService
    ) {}

    ngOnInit(): void {
        const state = this.router.getCurrentNavigation()?.extras.state;
        this.reference = String(this.route.snapshot.paramMap.get("reference"));
        this.title = `Orders Details (${this.reference})`;
        //BreadCrumb
        this.breadCrumbItems = [
            { label: "Billing Management" },
            { label: "Orders" },
            { label: this.reference, active: true },
        ];

        if (state) {
            this.order = state["order"];
            this.energyOrderDetail = this.order.orderDetails.find(
                (v) => v.orderDetailType === "ENERGY_FEES"
            )!;
            this.nonEnergyOrderDetail = this.order.orderDetails.find(
                (v) => v.orderDetailType === "NON_ENERGY_FEES"
            )!;
        } else {
            this.fetchData();
        }
    }

    fetchData() {
        const filter = new GenericFilter(this.reference, 0, 1, "id", "desc");
        this.orderService.getOrders(filter).subscribe({
            next: (v) => {
                this.order = v.content[0];
                this.energyOrderDetail = this.order.orderDetails.find(
                    (v) => v.orderDetailType === "ENERGY_FEES"
                )!;
                this.nonEnergyOrderDetail = this.order.orderDetails.find(
                    (v) => v.orderDetailType === "NON_ENERGY_FEES"
                )!;
            },
            error: (e) => console.error(e),
            complete: () => {
                console.info("orders retrieved");
            },
        });
    }
}
