import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { GenericResponse } from 'src/app/core/models/generic-response.models';
import { FinalizeOrder, Order, ValidateOrderResponse } from 'src/app/core/models/order.models';
import { OrderService } from 'src/app/core/services/order.service';
import { ScriptsService } from 'src/app/core/services/scripts.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';
declare let PayWithConverge: any;

@Component({
  selector: 'app-test-review-payment',
  templateUrl: './test-review-payment.component.html',
  styleUrls: ['./test-review-payment.component.scss']
})
export class TestReviewPaymentComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  validateOrderResponse!: ValidateOrderResponse;
  finalizeOrder!: FinalizeOrder;
  finalizeOrderResponse!: GenericResponse;
  processing: boolean = false;

  constructor(private router: Router, 
    private orderService: OrderService, 
    private scriptsService: ScriptsService,
    public sweetalertService: SweetalertService) {
      this.scriptsService.load('PayWithConverge');

      const navigation = this.router.getCurrentNavigation();
      // console.log('navigation : ', navigation);

      this.validateOrderResponse = navigation?.extras.state as ValidateOrderResponse;
      // console.log('validateOrderResponse : ', JSON.stringify(this.validateOrderResponse));
  }

  ngOnInit(): void {
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: "Billing Management" },
      { label: "Orders" },
      { label: "Test Review Payment", active: true },
    ];

    if (this.validateOrderResponse == null) {
      this.router.navigate(['billing-management/orders/test-make-payment']);
    }
  }

  onSubmit() {
    let os = this.orderService;
    let sa = this.sweetalertService;
    let r = this.router;
    const navigationExtras: NavigationExtras = {state: this.validateOrderResponse};

    // console.log('create clicked');
    this.processing = true;
    this.finalizeOrder = {
      customerName: this.validateOrderResponse.customerName,
      customerUuid: this.validateOrderResponse.customerUuid,
      description: "MEMBERSHIP_MATERIALS",
      details: "Membership materials",
      notes: "",
      orderItems: this.validateOrderResponse.orderItems
    }

    this.orderService.finalizeOrder(this.finalizeOrder).subscribe({
      next: (v) => {
        // console.log('finalize order response : ', v);
        this.finalizeOrderResponse = v;
        let info = this.finalizeOrderResponse.additionalInfo.find(addInfo => addInfo.key === 'reference');

        // Send transaction to ConvergePay
        let paymentFields = {
          ssl_txn_auth_token: this.validateOrderResponse.token,
          ssl_amount: this.validateOrderResponse.total,
          ssl_merchant_txn_id: info?.value
        };
    
        let callback = {
          onError: function (error: any) {
              // console.log("error : ", error);
          },
          onCancelled: function () {
            // console.log("cancelled", "");
          },
          onDeclined: function (response: any) {
            // console.log("declined : ", JSON.stringify(response, null, '\t'));
          },
          onApproval: function (response: any) {
            // console.log("approval : ", JSON.stringify(response, null, '\t'));

            let verifyOrder = {
              approval: response,
              reference: info?.value
            } 
            os.verifyOrder(verifyOrder).subscribe({
              next: (o) => {
                const verified: Order = o;
                // console.log("verified order : ", JSON.stringify(verified, null, '\t'));

                if (verified.paymentStatus === 'SUCCESSFUL') {
                  sa.success('Your order completed successfully!').then(() => {
                    r.navigate(['billing-management/orders']);
                  });
                } else {
                  sa.failure(verified.paymentStatusDescription).then(() => {
                    r.navigate(['billing-management/orders/test-review-payment'], navigationExtras);
                  });
                }
              },
              error: (e) => {
                // console.error('error occured : ', e);
                sa.failure(e).then(() => {
                  r.navigate(['billing-management/orders/test-review-payment'], navigationExtras);
                });
              },
              complete: () => {
                // console.info('review payment completed');
              }
            });
          }
        };
    
        PayWithConverge.open(paymentFields, callback);
      },
      error: (e) => {
        // console.error('error occured : ', e);
        this.sweetalertService.failure(e).then(() => {
          this.processing = false;
        });
      },
      complete: () => {
        // console.info('validated order completed');
      }
    });
  }
}
