import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { AuthService } from "src/app/core/services/authentication.service";
import { UserService } from "src/app/core/services/user.service";
import * as UserActions from "./actions";

@Injectable()
export class UserEffects {
    loginUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.loginUser),
            mergeMap((action) =>
                this.authService.login(action.email, action.password).pipe(
                    map((auth) => UserActions.loginUserSuccess({ auth })),
                    catchError((error) =>
                        of(UserActions.loginUserFailure({ error }))
                    )
                )
            )
        )
    );

    loadUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.loadUser),
            mergeMap(() =>
                this.userService.getUser().pipe(
                    map((user) => UserActions.loadUserSuccess({ user })),
                    catchError((error) =>
                        of(UserActions.loadUserFailure({ error }))
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private userService: UserService
    ) {}
}
