import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CountriesComponent } from './countries/countries.component';
import { OrganizationTypesComponent } from './organization-types/organization-types.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { RolesComponent } from './roles/roles.component';
import { VehicleMakesComponent } from './vehicle-makes/vehicle-makes.component';
import { RolePermissionsComponent } from './roles/role-permissions/role-permissions.component';
import { InternalAccountsComponent } from './internal-accounts/internal-accounts.component';
import { AccountViewComponent } from './internal-accounts/account-view/account-view.component';
import { VehicleMakeViewComponent } from './vehicle-makes/vehicle-make-view/vehicle-make-view.component';

const routes: Routes = [
  {
    path: 'permissions',
    component: PermissionsComponent
  },
  {
    path: 'roles',
    component: RolesComponent
  },
  {
    path: 'roles/:uuid',
    component: RolePermissionsComponent
  },
  {
    path: 'vehicle-makes',
    component: VehicleMakesComponent
  },
  {
    path: 'vehicle-makes/:uuid',
    component: VehicleMakeViewComponent
  },
  {
    path: 'organization-types',
    component: OrganizationTypesComponent
  },
  {
    path: 'countries',
    component: CountriesComponent
  },
  {
    path: 'internal-accounts',
    component: InternalAccountsComponent
  },
  {
    path: 'internal-accounts/:uuid',
    component: AccountViewComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
