import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pagetitle',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})

/**
 * Page-Title Component
 */
export class PagetitleComponent implements OnInit {

  @Input()
  breadcrumbItems!: Array<{
    active?: boolean;
    label?: string;
  }>;

  items!: Array<{
    active: boolean;
    label: string;
  }>;
  item!: {active?: boolean; label?: string;}

  @Input() title: string | undefined;

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.setDefaultLang('en');
  }

}
