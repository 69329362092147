import { Component, OnInit, Input } from '@angular/core';
import { Order } from 'src/app/core/models/order.models';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})

/**
 * Order Component
 */
export class OrderComponent implements OnInit {

  @Input() orders!: Order[];

  constructor() { }

  ngOnInit(): void {
  }

}
