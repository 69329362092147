import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { GenericFilter } from "src/app/core/models/generic-filter.models";
import { PagedProduct, Product } from "src/app/core/models/product.models";
import { ProductService } from "src/app/core/services/product.service";
import { Column, SubmitData } from "src/app/core/models/datatable.models";

@Component({
    selector: "app-products",
    templateUrl: "./products.component.html",
    styleUrls: ["./products.component.scss"],
})
export class ProductsComponent implements OnInit {
    breadCrumbItems!: Array<{}>;
    collectionSize!: number;
    products!: Product[];
    pagedProduct!: PagedProduct;
    columns: Column[] = [
        { key: "name", name: "Name", isSearchTerm: true, editType: "text", required: true },
        { key: "description", name: "Description", editType: "text" },
        { key: "unitPrice", name: "Unit Price", prefix: "$", editType: "number", required: true },
        { key: "taxable", name: "Taxable", editType: "boolean", required: true, booleanDisplay: ["Yes", "No"] },
        { key: "updatedAt", name: "Last Updated" },
        { key: "enabled", name: "Status", editType: "boolean", required: true, booleanDisplay: ["Enabled", "Disabled"]},
    ]; 
    submitHandler = (v: SubmitData): Observable<any> => {
      if (v.isAddNew) {
        return this.productService.addProduct(v.data);
      }
      else {
        return this.productService.updateProduct(v.data, v.data.uuid);
      }
    };

    constructor(
        public modalService: NgbModal,
        private productService: ProductService
    ) {}

    ngOnInit(): void {
        //BreadCrumb
        this.breadCrumbItems = [
          { label: 'Billing Management' },
          { label: 'Products', active: true }
        ];
    }

    fetchData(filter: GenericFilter) {
        this.productService.getProducts(filter).subscribe({
            next: (v) => {
                console.log("returned products : ", v);
                this.pagedProduct = v;
                this.collectionSize = this.pagedProduct.totalElements;
                this.products = this.pagedProduct.content;
            },
            error: (e) => console.error(e),
            complete: () => {
                console.info("products retrieved");
            },
        });
    }
}
