import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; 
import { ChangeConfiguration, Charger, ClearCache, GetLocalListVersion } from 'src/app/core/models/charger.models';
import { ChargerService } from 'src/app/core/services/charger.service';
import { ResetModalComponent } from '../reset-modal/reset-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models';
import { StartTransactionModalComponent } from '../start-transaction-modal/start-transaction-modal.component';
import { TriggerMessageModalComponent } from '../trigger-message-modal/trigger-message-modal.component';
import { ChangeAvailabilityModalComponent } from '../change-availability-modal/change-availability-modal.component';
import { TransactionService } from 'src/app/core/services/transaction.service';
import { ReserveNowModalComponent } from '../reserve-now-modal/reserve-now-modal.component';
import { CancelReservationModalComponent } from '../cancel-reservation-modal/cancel-reservation-modal.component';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';
import { UnlockConnectorModalComponent } from '../unlock-connector-modal/unlock-connector-modal.component';
import { ChangeConfigurationModalComponent } from '../change-configuration-modal/change-configuration-modal.component';
import { GetCompositeScheduleModalComponent } from '../get-composite-schedule-modal/get-composite-schedule-modal.component';
import { GetConfigurationModalComponent } from '../get-configuration-modal/get-configuration-modal.component';
import { GetDiagnosticsModalComponent } from '../get-diagnostics-modal/get-diagnostics-modal.component';
import { SendLocalListModalComponent } from '../send-local-list-modal/send-local-list-modal.component';
import { UpdateFirmwareModalComponent } from '../update-firmware-modal/update-firmware-modal.component';
import { ClearChargingProfileModalComponent } from '../clear-charging-profile-modal/clear-charging-profile-modal.component';
import { SetChargingProfileModalComponent } from '../set-charging-profile-modal/set-charging-profile-modal.component';
import { Rate } from 'src/app/core/models/rate.models';
import { AppUtilities } from 'src/app/app-utilities';
import { TranslateService } from '@ngx-translate/core';
import { GoogleMapsService } from 'src/app/core/services/google-maps.service';
import { ScriptsService } from 'src/app/core/services/scripts.service';
import { QrCodeModalComponent } from '../qr-code-modal/qr-code-modal.component';

@Component({
  selector: 'app-charger-view',
  templateUrl: './charger-view.component.html',
  styleUrls: ['./charger-view.component.scss']
})
export class ChargerViewComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  title!: string;
  chargerId!: string;

  longitude = 0;
  latitude = 0;
  markers: any;
  zoom: number = 15;
  address = '';
  iconUrl = '';
  totalConnectorCount = 0;
  availableConnectorCount = 0;
  rates!: Rate[];
  processing = false;

  @ViewChild('streetviewMap', { static: true }) streetviewMap: any;
  @ViewChild('streetviewPano', { static: true }) streetviewPano: any;

  @Input() heading: number = 34;
  @Input() pitch: number = 10;
  @Input() scrollwheel: boolean = false;
  @Input() charger?: Charger;

  apiLoaded = false;
  center!: google.maps.LatLngLiteral;
  markerOptions!: google.maps.MarkerOptions;
  markerPosition!: google.maps.LatLngLiteral;

  constructor(
    private route: ActivatedRoute, private chargerService: ChargerService, private transactionService: TransactionService,
    private scriptsService: ScriptsService,
    public modalService: NgbModal, public sweetalertService: SweetalertService, private appUtilities: AppUtilities,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.title = 'PAGES.CHARGERS.LIST.DETAILS';
    this.chargerId = String(this.route.snapshot.paramMap.get('chargerId'));

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'MENUITEMS.CHARGERMANAGEMENT.TEXT' },
      { label: 'PAGES.CHARGERS.TEXT' },
      { label: this.chargerId, active: true }
    ];

    if (this.charger == undefined) { 
      this.fetchData();
    } else {
      this.getAddress();
    }
  }

  fetchData(): void {
    this.chargerService.getCharger(this.chargerId).subscribe({
      next: (v) => {
        // console.log('returned charger : ', v);
        this.charger = v;
        this.getAddress();
        this.rates = this.charger?.site.rates;
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('charger retrieved');
      }
    })
  }

  getAddress() {
    if (this.charger?.site.address.addressLine2 == undefined || this.charger?.site.address.addressLine2 == '') {
      this.address = `${this.charger?.site.address.addressLine1}, ${this.charger?.site.address.city}, ${this.charger?.site.address.region}, ${this.charger?.site.address.postalCode}, ${this.charger?.site.address.country}`;
    } else {
      this.address = `${this.charger?.site.address.addressLine1}, ${this.charger?.site.address.addressLine2}, ${this.charger?.site.address.city}, ${this.charger?.site.address.region}, ${this.charger?.site.address.postalCode}, ${this.charger?.site.address.country}`;
    }

    this.scriptsService.load('GoogleMap').then(() => {
      this.latitude = Number(this.charger?.site.address.latitude);
      this.longitude = Number(this.charger?.site.address.longitude);

      this.center = { lat: this.latitude, lng: this.longitude };

      this.markerOptions = {
        icon: {
          url: '../../../../../assets/images/pin_green.png',
          scaledSize: new google.maps.Size(36, 48) // Resize marker
        }
      };

      this.markerPosition = this.center;
      this.apiLoaded = true;
    })
    
    this.totalConnectorCount = this.charger?.connectors.length ?? 0;
    this.charger?.connectors.forEach((connector) => {
      if (connector.chargePointStatus === 'Available') {
        this.availableConnectorCount += 1;
      }
    });
  }

  qrCodeModal(charger: any) {
    const modalRef = this.modalService.open(QrCodeModalComponent);
    modalRef.componentInstance.charger = charger;
  
    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {})
      .catch(() => {
        // user click outside of the modal form
        // console.log("QR Code Modal: ", "Cancel");
    });
  }

  cancelReservationModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(CancelReservationModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }
  
  changeAvailabilityModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ChangeAvailabilityModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  changeConfigurationModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ChangeConfigurationModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  clearCache(charger?: Charger) {
    if (!this.charger) {
      const title = this.translate.instant('PAGES.COMMON.LIST.FAILURETITLE');
      const params = { errorMessage: "Something went wrong" };
      const message = this.translate.instant('PAGES.COMMON.LIST.FAILUREMESSAGE', params);

      this.sweetalertService.failure(message, title).then(() => {});
    }
    else {
      const title = this.translate.instant('PAGES.CHARGERS.LIST.CLEARCACHE');
      const message = this.translate.instant('PAGES.CHARGERS.LIST.CLEARCACHEMESSAGE');
      const confirmButtonText = this.translate.instant('PAGES.COMMON.LIST.YES');
      const cancelButtonText = this.translate.instant('PAGES.COMMON.LIST.CANCEL');

      this.sweetalertService.confirm(title, message, confirmButtonText, cancelButtonText).then((result) => {
        if (result.isConfirmed) {
          let clearCache: ClearCache = {
            chargingStationSelectList: [
              {
                chargingStationIdentifier: this.charger!.chargingStationIdentifier,
                endpointAddress: 'ClearCache',
                ocppTransport: "JSON"
              }
            ]
          }
  
          this.chargerService.clearCache(clearCache).subscribe({
            next: (v) => {
              // console.log('clear cache request submitted : ', v);
              const title = this.translate.instant('PAGES.COMMON.LIST.SUCCESSTITLE');
              const message = this.translate.instant('PAGES.COMMON.LIST.SUCCESSMESSAGE');

              this.sweetalertService.success(message, title).then(() => {});
            },
            error: (e) => {
              // console.error('error occurred : ', e);
              const title = this.translate.instant('PAGES.COMMON.LIST.FAILURETITLE');
              const params = { errorMessage: e };
              const message = this.translate.instant('PAGES.COMMON.LIST.FAILUREMESSAGE', params);

              this.sweetalertService.failure(message, title).then(() => {});
            },
            complete: () => {
              // console.info('clear cache completed');
            }
          });
        }
      });
    }
  }

  clearChargingProfileModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ClearChargingProfileModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  getCompositeScheduleModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(GetCompositeScheduleModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  getConfigurationModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(GetConfigurationModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  getDiagnosticsModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(GetDiagnosticsModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  getLocalListVersionModal(charger: Charger) {
    const title = this.translate.instant('PAGES.CHARGERS.LIST.GETLOCALLISTVERSION');
    const message = this.translate.instant('PAGES.CHARGERS.LIST.GETLOCALLISTVERSIONMESSAGE');
    const confirmButtonText = this.translate.instant('PAGES.COMMON.LIST.YES');
    const cancelButtonText = this.translate.instant('PAGES.COMMON.LIST.CANCEL');

    this.sweetalertService.confirm(title, message, confirmButtonText, cancelButtonText).then((result) => {
      // console.log('result: ', result);
      if (result.isConfirmed) {
        let getLocalListVersion: GetLocalListVersion = {
          chargingStationSelectList: [
            {
              chargingStationIdentifier: charger.chargingStationIdentifier,
              endpointAddress: 'ClearCache',
              ocppTransport: "JSON"
            }
          ]
        }

        this.chargerService.getLocalListVersion(getLocalListVersion).subscribe({
          next: (v) => {
            // console.log('getLocalListVersion request submitted : ', v);
            const title = this.translate.instant('PAGES.COMMON.LIST.SUCCESSTITLE');
            const message = this.translate.instant('PAGES.COMMON.LIST.SUCCESSMESSAGE');
                                
            this.sweetalertService.success(message, title).then(() => {});
          },
          error: (e) => {
            // console.error('error occurred : ', e);
            const title = this.translate.instant('PAGES.COMMON.LIST.FAILURETITLE');
            const params = { errorMessage: e };
            const message = this.translate.instant('PAGES.COMMON.LIST.FAILUREMESSAGE', params);
                                
            this.sweetalertService.failure(message, title).then(() => {});
          },
          complete: () => {
            // console.info('getLocalListVersion completed');
          }
        });
      }
    });
  }

  sendLocalListModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(SendLocalListModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }
  
  reserveNowModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ReserveNowModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }
  
  resetModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ResetModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  setChargingProfileModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(SetChargingProfileModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  startTransactionModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(StartTransactionModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  triggerMessageModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(TriggerMessageModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  unlockConnectorModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(UnlockConnectorModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  updateFirmwareModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(UpdateFirmwareModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  // todo modify the backend and create an endpoint that will receive a single post request to handle
  // the sending of different ChangeConfiguration requests to chargers for the below
  commissionCharger(charger?: Charger) {
    if (!this.charger) {
      this.sweetalertService.failure("Something went wrong");
    }
    else {
      this.sweetalertService.confirm('Commission Charger?', `Are you sure you want to commission charger ${charger!.chargingStationIdentifier}?`).then((result) => {
        if (result.isConfirmed) {
          let data: ChangeConfiguration[] = [];
    
    if (this.rates != undefined && this.rates.length > 0) {
      for (let i = 0; i < this.rates.length; i++) {
        const currencyConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "ChargerCurrency",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: this.rates[i].currency
        };
        data.push(currencyConfig);

        const sessionFeeConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "SessionFee",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: this.rates[i].sessionFee.toString()
        };
        data.push(sessionFeeConfig);

        const energyRateConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "EnergyRate",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: this.rates[i].energyRate.toString()
        };
        data.push(energyRateConfig);

        const energyRateUnitConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "EnergyRateUnit ",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: this.rates[i].energyRateUnit
        };
        data.push(energyRateUnitConfig);

        const idlingFeeConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "IdlingFee",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: this.rates[i].idlingFee.toString()
        };
        data.push(idlingFeeConfig);

        const idlingFeeUnitConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "IdlingFeeUnit  ",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: this.rates[i].energyRateUnit
        };
        data.push(idlingFeeUnitConfig);

        // todo modifiy the backend rates table to accommodate idlingFeeStart
        const idlingFeeStartConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "IdlingFeeStart",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: "0"
        };
        data.push(idlingFeeStartConfig);

        // todo modifiy the backend rates table to accommodate MaxIdlingTime
        const maxIdlingTimeConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "MaxIdlingTime",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: "0"
        };
        data.push(maxIdlingTimeConfig);

        const supportPhoneNumberConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "SupportPhoneNumber",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: this.appUtilities.appConfig.supportPhoneNumber
        };
        data.push(supportPhoneNumberConfig);

        const qrCodeURLConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "QRCodeURL",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: `${this.appUtilities.appConfig.adminURL}/account/charge-now/${charger!.chargingStationIdentifier}`
        };
        data.push(qrCodeURLConfig);

        const qrCodeAppURLConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "QRCodeAppURL",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: this.appUtilities.appConfig.qrCodeAppURL
        };
        data.push(qrCodeAppURLConfig);

        const qrCodeMethodURLConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "QRCodeMethodURL",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: this.appUtilities.appConfig.qrCodeMethodURL
        };
        data.push(qrCodeMethodURLConfig);

        const qrCodeMemberURLConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "QRCodeMemberURL",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: this.appUtilities.appConfig.qrCodeMemberURL
        };
        data.push(qrCodeMemberURLConfig);

        const qrCodeTutorialURLConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "QRCodeTutorialURL",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: this.appUtilities.appConfig.qrCodeTutorialURL
        };
        data.push(qrCodeTutorialURLConfig);

        const programNameConfig: ChangeConfiguration = {
          chargingStationSelectList: [{ chargingStationIdentifier: charger!.chargingStationIdentifier, endpointAddress: charger!.chargingStationIdentifier, ocppTransport: "JSON"}],
          confKey: "ProgramName",
          customConfKey: "",
          keyType: "PREDEFINED",
          value: this.appUtilities.appConfig.programName
        };
        data.push(programNameConfig);

      }

      this.chargerService.commissionCharger(data).subscribe({
        next: (v) => {
          // console.log('Charger commissioning responses received : ', v);
          this.sweetalertService.success('Commissioning request submitted successfully.').then(() => {
            this.processing = false;
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          // console.info('commissioning request completed');
        }
      });
      
    } else {
      this.sweetalertService.failure('Unable to commission the charger!').then(() => {
        this.processing = false;
      });
    }
        }
      });
    }
  }

}
