<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex mb-4">
                    <h4 class="card-title flex-grow-1">{{ title | translate }}</h4>
                    <div *ngIf="addAllowed" class="flex-shrink-0">
                        <button type="button" class="btn btn-primary w-md" (click)="openModal(data, true)">
                            {{ 'PAGES.COMMON.LIST.ADDNEW' | translate }}
                        </button>
                    </div>
                </div>

                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6">
                        <div class="dataTables_length" id="tickets-table_length">
                            <label class="d-inline-flex al ign-items-center">{{ 'PAGES.COMMON.LIST.SHOW' | translate }}
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="pageSize" class="form-control form-control-sm mx-2"
                                    (change)="onSizeChange(pageSize)">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select>
                                {{ 'PAGES.COMMON.LIST.ENTRIES' | translate }}</label>
                        </div>
                    </div>
                    <!-- Search -->
                    <div *ngIf="searchable" class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">
                                {{ 'PAGES.COMMON.LIST.SEARCH' | translate }}:
                                <input type="text" name="searchTerm" id="searchTerm"
                                    class="form-control form-control-sm ms-2" aria-controls="tickets-table"
                                    [(ngModel)]="searchTerm" (input)="search(searchTerm)"
                                    [placeholder]="" />
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>

                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable"
                        class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th *ngFor="let column of columns">
                                    {{ column["name"] | translate }}
                                </th>
                                <th *ngIf="hasAction || edittable">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [hidden]="!empty">
                                <td>{{ 'PAGES.COMMON.LIST.NODATAAVAILABLE' | translate }}</td>
                            </tr>
                            <tr *ngFor="let row of rows; let i = index" [hidden]="empty">
                                <td *ngFor="let column of columns">
                                    <ngb-highlight *ngIf="column['isSearchTerm']; else notHightlight"
                                        [result]="getDisplayValue(row[column['key']], column)" [term]="searchTerm">
                                    </ngb-highlight>
                                    <ng-template #notHightlight>
                                        <span *ngIf="column['key'] !== 'enabled'">
                                            {{ getDisplayValue(row[column["key"]], column) }}
                                        </span>
                                        <span *ngIf="column['key'] === 'enabled' && row.enabled"
                                            class="badge rounded-pill bg-success ms-1">
                                            {{ 'PAGES.COMMON.LIST.ENABLED' | translate }}
                                        </span>
                                        <span *ngIf="column['key'] === 'enabled' && !row.enabled"
                                            class="badge rounded-pill bg-warning ms-1">
                                            {{ 'PAGES.COMMON.LIST.DISABLED' | translate }}
                                        </span>
                                    </ng-template>
                                </td>
                                <td *ngIf="hasAction || edittable">
                                    <div id="action-{{i}}" class="d-flex flex-wrap gap-2">
                                        <ng-container [ngTemplateOutlet]="actionsTemplateRef"
                                            [ngTemplateOutletContext]="{$implicit:row}"></ng-container>
                                        <!-- <ng- select="[actions]"></ng-> -->
                                        <button *ngIf="edittable" type="button" class="btn btn-outline-primary"
                                        [title]="'PAGES.COMMON.LIST.EDIT' | translate" (click)="openModal(row, false)">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- End table -->
                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            {{ 'PAGES.COMMON.LIST.SHOWINGENTRIES' | translate }}
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-right float-md-end pagination-rounded">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [maxSize]="5"
                                [boundaryLinks]="true" [pageSize]="pageSize"
                                (pageChange)="paged ? pageChanged($event) : null" class="d-flex justify-content-center">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- End Pagination -->
                </div>
            </div>
        </div>
    </div>
</div>