import { Component, OnInit } from '@angular/core';
import { Analytics } from 'src/app/core/models/dashboard.models';
import { OrderService } from 'src/app/core/services/order.service';

@Component({
  selector: 'app-total-revenue-card',
  templateUrl: './total-revenue-card.component.html',
  styleUrls: ['./total-revenue-card.component.scss']
})
export class TotalRevenueCardComponent implements OnInit {

  totalRevenueAnalytics!: Analytics;
  
  constructor(private orderService: OrderService,) { }

  ngOnInit(): void {
    this.fetchTotalRevenueAnalytics();
  }

  private fetchTotalRevenueAnalytics() {
    this.orderService.getTotalRevenue().subscribe({
      next: (v) => {
        // console.log('returned total revenue : ', v);
        this.totalRevenueAnalytics = v;
        if (v.differential >= 1) {
          this.totalRevenueAnalytics.classCss = 'badge badge-soft-success me-1';
          this.totalRevenueAnalytics.classArrowCss = 'mdi mdi-arrow-up-bold me-1';
        } else if (v.differential < 0) {
          this.totalRevenueAnalytics.classCss = 'badge badge-soft-danger me-1';
          this.totalRevenueAnalytics.classArrowCss = 'mdi mdi-arrow-down-bold me-1';
        } else {
          this.totalRevenueAnalytics.classCss = 'badge badge-soft-warning me-1';
          this.totalRevenueAnalytics.classArrowCss = 'mdi mdi-arrow-left-right-bold me-1';
        }
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('total revenue retrieved');
      }
    });
  }
}
