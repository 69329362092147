<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <img src="{{ qrUrl }}" alt="" height="400">
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">Cancel</button>
</div>