import { Component, OnInit, ViewChild } from '@angular/core';
import { emailSentBarChart, monthlyEarningChart, transactions, orders, users } from './data';
import { ChartType } from './dashboard.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/core/services/user.service';
import { Analytics } from 'src/app/core/models/dashboard.models';
import { OrderService } from 'src/app/core/services/order.service';
import { RefundService } from 'src/app/core/services/refund.service';
import { User } from 'src/app/core/models/user.models';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { RoleService } from 'src/app/core/services/role.service';
import { Role } from 'src/app/core/models/role.models';
import { CountryService } from 'src/app/core/services/country.service';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { Country } from 'src/app/core/models/country.models';
import { Organization } from 'src/app/core/models/organization.models';
import { Order } from 'src/app/core/models/order.models';
import { Transaction } from 'src/app/core/models/transaction.models';
import { TransactionService } from 'src/app/core/services/transaction.service';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardsComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;

  emailSentBarChart!: ChartType;
  monthlyEarningChart!: ChartType;
  transactions!: Transaction[];
  orders!: Order[];
  users!: User[];
  roles!: Role[];
  countries!: Country[];
  organizations!: Organization[];
  activeUsersAnalytics!: Analytics;
  allTimeOrdersAnalytics!: Analytics;
  totalRevenueAnalytics!: Analytics;
  totalRefundsAnalytics!: Analytics;
  @ViewChild('content') content: any;

  constructor(private modalService: NgbModal, 
    private userService: UserService, 
    private orderService: OrderService, 
    private transactionService: TransactionService,
    private roleService: RoleService,
    private countryService: CountryService, 
    private organizationService: OrganizationService,
    private refundService: RefundService) { }

  ngOnInit(): void {

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Dashboard' },
      { label: 'Dashboard', active: true }
    ];

    /**
     * Fetches the data
     */
    this.fetchData();
    this.fetchActiveUsersAnalytics();
    this.fetchAllTimeOrdersAnalytics();
    this.fetchTotalRevenueAnalytics();
    this.fetchTotalRefundsAnalytics();
    this.fetchTransactionData();
    this.fetchOrderData();
    this.fetchUserData();
    this.fetchRolesData();
    this.fetchCountriesData();
    this.fetchOrganizationsData();
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.openModal();
    // }, 2000);
  }

  /**
   * Fetches the data
   */
  private fetchData() {
    this.emailSentBarChart = emailSentBarChart;
    this.monthlyEarningChart = monthlyEarningChart;
  }

  /***
   * Subscribe Model open
   */
  // openModal() {
  //   this.modalService.open(this.content, { centered: true });
  // }

  private fetchActiveUsersAnalytics() {
    this.userService.getActiveUsersCount().subscribe({
      next: (v) => {
        console.log('returned active users count : ', v);
        this.activeUsersAnalytics = v;
        if (v.differential >= 1) {
          this.activeUsersAnalytics.classCss = 'badge badge-soft-success me-1';
          this.activeUsersAnalytics.classArrowCss = 'mdi mdi-arrow-up-bold me-1';
        } else if (v.differential < 0) {
          this.activeUsersAnalytics.classCss = 'badge badge-soft-danger me-1';
          this.activeUsersAnalytics.classArrowCss = 'mdi mdi-arrow-down-bold me-1';
        } else {
          this.activeUsersAnalytics.classCss = 'badge badge-soft-warning me-1';
          this.activeUsersAnalytics.classArrowCss = 'mdi mdi-arrow-left-right-bold me-1';
        }
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('active users count retrieved');
      }
    });
  }

  private fetchAllTimeOrdersAnalytics() {
    this.orderService.getAllTimeOrdersCount().subscribe({
      next: (v) => {
        console.log('returned all time orders count : ', v);
        this.allTimeOrdersAnalytics = v;
        if (v.differential >= 1) {
          this.allTimeOrdersAnalytics.classCss = 'badge badge-soft-success me-1';
          this.allTimeOrdersAnalytics.classArrowCss = 'mdi mdi-arrow-up-bold me-1';
        } else if (v.differential < 0) {
          this.allTimeOrdersAnalytics.classCss = 'badge badge-soft-danger me-1';
          this.allTimeOrdersAnalytics.classArrowCss = 'mdi mdi-arrow-down-bold me-1';
        } else {
          this.allTimeOrdersAnalytics.classCss = 'badge badge-soft-warning me-1';
          this.allTimeOrdersAnalytics.classArrowCss = 'mdi mdi-arrow-left-right-bold me-1';
        }
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('all time orders count retrieved');
      }
    });
  }

  private fetchTotalRevenueAnalytics() {
    this.orderService.getTotalRevenue().subscribe({
      next: (v) => {
        console.log('returned total revenue : ', v);
        this.totalRevenueAnalytics = v;
        if (v.differential >= 1) {
          this.totalRevenueAnalytics.classCss = 'badge badge-soft-success me-1';
          this.totalRevenueAnalytics.classArrowCss = 'mdi mdi-arrow-up-bold me-1';
        } else if (v.differential < 0) {
          this.totalRevenueAnalytics.classCss = 'badge badge-soft-danger me-1';
          this.totalRevenueAnalytics.classArrowCss = 'mdi mdi-arrow-down-bold me-1';
        } else {
          this.totalRevenueAnalytics.classCss = 'badge badge-soft-warning me-1';
          this.totalRevenueAnalytics.classArrowCss = 'mdi mdi-arrow-left-right-bold me-1';
        }
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('total revenue retrieved');
      }
    });
  }

  private fetchTotalRefundsAnalytics() {
    this.refundService.getTotalRefunds().subscribe({
      next: (v) => {
        console.log('returned total refunds : ', v);
        this.totalRefundsAnalytics = v;
        if (v.differential >= 1) {
          this.totalRefundsAnalytics.classCss = 'badge badge-soft-success me-1';
          this.totalRefundsAnalytics.classArrowCss = 'mdi mdi-arrow-up-bold me-1';
        } else if (v.differential < 0) {
          this.totalRefundsAnalytics.classCss = 'badge badge-soft-danger me-1';
          this.totalRefundsAnalytics.classArrowCss = 'mdi mdi-arrow-down-bold me-1';
        } else {
          this.totalRefundsAnalytics.classCss = 'badge badge-soft-warning me-1';
          this.totalRefundsAnalytics.classArrowCss = 'mdi mdi-arrow-left-right-bold me-1';
        }
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('total refunds retrieved');
      }
    });
  }

  private fetchTransactionData() {
    const genericFilter: GenericFilter = new GenericFilter('', 0, 5, 'id', 'desc');
    this.transactionService.getTransactions(genericFilter).subscribe({
        next: (pagedTransactions) => {
            console.log("returned transactions : ", pagedTransactions);
            this.transactions = pagedTransactions.content;
        },
        error: (e) => console.error(e),
        complete: () => {
            console.info("transactions retrieved");
        },
    });
  }

  private fetchOrderData() {
    const genericFilter: GenericFilter = new GenericFilter('', 0, 5, 'id', 'desc');
    this.orderService.getOrders(genericFilter).subscribe({
        next: (pagedOrders) => {
            console.log("returned orders : ", pagedOrders);
            this.orders = pagedOrders.content;
        },
        error: (e) => console.error(e),
        complete: () => {
            console.info("orders retrieved");
        },
    });
  }

  private fetchUserData() {
    const genericFilter: GenericFilter = new GenericFilter('', 0, 5, 'id', 'desc');
    this.userService.getUsers(genericFilter, '').subscribe({
      next: (pagedUsers) => {
        console.log('returned users : ', pagedUsers);
        if (pagedUsers.content.length > 0) {
          this.users = pagedUsers.content;
        }
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('users retrieved');
      }
    });
  }

  fetchRolesData() {
    this.roleService.getRoles().subscribe({
      next: (v) => {
        this.roles = v;
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('roles retrieved');
      }
    });
  }

  fetchCountriesData() {
    this.countryService.getCountries().subscribe({
      next: (v) => {
        this.countries = v;
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('countries retrieved');
      }
    });
  }

  fetchOrganizationsData() {
    const genericFilter: GenericFilter = {
      size: 100
    }
    this.organizationService.getOrganizations(genericFilter).subscribe({
      next: (v) => {
        this.organizations = v.content;
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('organizations retrieved');
      }
    });
  }
}
