import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { PagedRate } from '../models/rate.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';
import { GenericFilter } from '../models/generic-filter.models';

@Injectable({
  providedIn: 'root'
})
export class RateService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getBackEndApiUrl();
  }

  /**
     * Get all rates
     */
  getRates(params: GenericFilter): Observable<PagedRate> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedRate>(`${this.backendApiUrl}/rates?${queryString}`, this.httpOptions).pipe(
      tap((pagedRate: PagedRate) => {
        this.log(`Retrieved rates`);
      })
    );
  }

  /**
     * Add rate
     */
  addRate(params: any): Observable<GenericResponse> {
    // console.log('request : ', params);

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/rates/add`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added rate`);
      })
    );
  }

  /**
     * Update rate
     */
  updateRate(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/rates/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated rate`);
      })
    );
  }

  /** Log a RateService message */
  private log(message: string) {
    // console.log(`RateService: ${message}`);
  }
}
