import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { EvCard, PagedEvCard } from 'src/app/core/models/ev-card.models';
import { EvCardService } from 'src/app/core/services/ev-card.service';
import { MembershipModalComponent } from './membership-modal/membership-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';

@Component({
  selector: 'app-membership-cards-stickers',
  templateUrl: './membership-cards-stickers.component.html',
  styleUrls: ['./membership-cards-stickers.component.scss'],
  providers: [DecimalPipe]
})
export class MembershipCardsStickersComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  title!: string;
  searchTerm!: string;
  searchTermChanged: Subject<string> = new Subject<string>();
  inputSub: Subscription | undefined;
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  genericFilter: GenericFilter = new GenericFilter('', 0, 10, 'id', 'desc');
  evCard!: EvCard;
  evCards!: EvCard[];
  pagedEvCards!: PagedEvCard;
  empty = true;

  constructor(
    private evCardService: EvCardService, 
    public modalService: NgbModal) { }

  ngOnInit(): void {
    this.title = 'PAGES.CARDSSTICKERS.TEXT';
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'MENUITEMS.CHARGERMANAGEMENT.TEXT' },
      { label: 'PAGES.CARDSSTICKERS.TEXT', active: true }
    ];

    this.fetchData();

    this.inputSub = this.searchTermChanged.pipe(
      debounceTime(500),
      delay(200)
    ).subscribe(value => {
        // console.log(value)
        this.genericFilter.searchParam = value;
        this.fetchData();
      });
  }

  fetchData() {
    this.genericFilter.size = this.pageSize;
    this.genericFilter.page = this.page -1;
    this.evCardService.getEvCards(this.genericFilter).subscribe({
      next: (v) => {
        // console.log('returned evCards : ', v);
        this.pagedEvCards = v;
        this.collectionSize = this.pagedEvCards.totalElements;
        if (this.pagedEvCards.content.length > 0) {
          this.evCards = this.pagedEvCards.content;
          this.empty = false;
        }
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('evCards retrieved');
      }
    });
  }

  search(value: string): void {
    this.searchTermChanged.next(value);
  }

  onSizeChange(size: number) {
    this.genericFilter.size = size;
    this.pageSize = size;
    this.page = 1;
    this.fetchData();
  }

  pageChanged(page: any) {
    // console.log('page change: ', page);
    this.genericFilter.page = page - 1;
    this.fetchData();
  }

  openModal(evCard: any, isAddNew: boolean) {
    const modalRef = this.modalService.open(MembershipModalComponent);
    modalRef.componentInstance.evCard = evCard;
    modalRef.componentInstance.isAddNew = isAddNew;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.refreshPage();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  //refresh page
  refreshPage(): void {
    window.location.reload();
  }

}
