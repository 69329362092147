<div class="modal-header">
  <h5 class="modal-title mt-0">{{ title | translate }}</h5>
  <button
    type="button"
    class="btn-close"
    aria-hidden="true"
    (click)="modal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <form #entryForm="ngForm">
    <div class="form-group mb-3">
      <label for="name" class="fw-semibold">{{ 'PAGES.COMMON.LIST.NAME' | translate }}</label>
      <input
        type="text"
        class="form-control"
        id="name"
        name="name"
        required
        [(ngModel)]="addProduct.name"
        #name="ngModel"
      />
      <span style="color: red" [hidden]="name.valid || name.pristine">
        <p>{{ 'PAGES.COMMON.LIST.NAMEREQUIRED' | translate }}</p>
      </span>
    </div>
    <div class="form-group mb-3">
      <label for="description" class="fw-semibold">{{ 'PAGES.COMMON.LIST.DESCRIPTION' | translate }}</label>
      <input
        type="text"
        class="form-control"
        id="description"
        name="description"
        [(ngModel)]="addProduct.description"
        #description="ngModel"
      />
    </div>
    <div class="form-group mb-3">
      <label for="unitPrice" class="fw-semibold">{{ 'PAGES.PRODUCTS.LIST.UNITPRICE' | translate }}</label>
      <input
        type="text"
        class="form-control"
        id="unitPrice"
        name="unitPrice"
        required
        [(ngModel)]="addProduct.unitPrice"
        #unitPrice="ngModel"
      />
      <span style="color: red" [hidden]="name.valid || name.pristine">
        <p>{{ 'PAGES.PRODUCTS.LIST.UNITPRICEREQUIRED' | translate }}</p>
      </span>
    </div>
    <div class="form-group mb-3">
      <label for="taxable" class="fw-semibold">{{ 'PAGES.PRODUCTS.LIST.TAXABLE' | translate }}</label><br />
      <select
        class="form-select"
        id="taxable"
        name="taxable"
        [(ngModel)]="addProduct.taxable"
        #taxable="ngModel"
      >
        <option value="true">{{ 'PAGES.COMMON.LIST.YES' | translate }}</option>
        <option value="false">{{ 'PAGES.COMMON.LIST.NO' | translate }}</option>
      </select>
    </div>
    <div class="form-group mb-3" [hidden]="isAddNew">
      <label for="enabled" class="fw-semibold">{{ 'PAGES.COMMON.LIST.STATUS' | translate }}</label>
      <select
        class="form-select"
        id="enabled"
        name="enabled"
        [(ngModel)]="addProduct.enabled"
        #enabled="ngModel"
      >
        <option value="true">{{ 'PAGES.COMMON.LIST.ENABLED' | translate }}</option>
        <option value="false">{{ 'PAGES.COMMON.LIST.DISABLED' | translate }}</option>
      </select>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light w-md" (click)="onCancel()">
    {{ 'PAGES.COMMON.LIST.CANCEL' | translate }}
  </button>
  <button
    type="button"
    class="btn btn-primary w-md"
    [hidden]="processing"
    (click)="onSubmit()"
    [disabled]="!entryForm.form.valid"
  >
  {{ 'PAGES.COMMON.LIST.SAVE' | translate }}
  </button>
  <button
    type="button"
    class="btn btn-primary w-md"
    [hidden]="!processing"
    disabled
  >
    <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </button>
</div>
