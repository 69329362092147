<div class="table-responsive mb-0">
  <table class="table align-middle table-striped table-nowrap mb-0">
    <tbody>
      <tr *ngFor="let data of orders">
        <td>{{ data.reference }}</td>
        <td>
          {{ data.customerName }}
        </td>
        <td>
          <span class="badge bg-success bg-soft text-success"
                    [ngClass]=" { 
                      'text-danger bg-danger': data.paymentStatus === 'FAILED',
                      'text-warning bg-warning': data.paymentStatus === 'PENDING' || data.paymentStatus === 'REFUNDED' || data.paymentStatus === 'PARTIALLY_REFUNDED' || data.paymentStatus === 'INITIATED' || data.paymentStatus === 'PARTIAL'
                      }">{{data.paymentStatus}}</span>
        </td>
        <td>
          {{ data.total | currency: 'CAD' }}
        </td>
        <td>
          {{ data.updatedAt }}
        </td>
        <td>
          <a [routerLink]="['/billing-management/orders', data.reference]" class="btn btn-info btn-sm waves-effect" title="View">
            <i class="mdi mdi-credit-card-search-outline me-1"> {{ 'PAGES.COMMON.LIST.VIEW' | translate }}</i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- end table -->
