import { createAction, props } from "@ngrx/store";
import { User } from "src/app/core/models/user.models";
import { UserAuth } from "src/app/core/models/authentication.models";

export const loginUser = createAction(
    "[User] Login User",
    props<{ email: string; password: string }>()
);
export const loginUserSuccess = createAction(
    "[User] Login User Success",
    props<{ auth: UserAuth }>()
);
export const loginUserFailure = createAction(
    "[User] Login User Failure",
    props<{ error: any }>()
);

export const loadUser = createAction("[User] Load User");

export const loadUserSuccess = createAction(
    "[User] Load User Success",
    props<{ user: User }>()
);
export const loadUserFailure = createAction(
    "[User] Load User Failure",
    props<{ error: any }>()
);
