import { DecimalPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
    Charger,
    ClearCache,
    GetLocalListVersion,
    PagedCharger,
} from "src/app/core/models/charger.models";
import { ChargerService } from "src/app/core/services/charger.service";
import { ChargerModalComponent } from "./charger-modal/charger-modal.component";
import { ModalMessage } from "src/app/core/models/modal-message.models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GenericFilter } from "src/app/core/models/generic-filter.models";
import { Subject, Subscription } from "rxjs";
import { debounceTime, delay } from "rxjs/operators";
import { SiteService } from "src/app/core/services/site.service";
import { Site } from "src/app/core/models/site.models";
import { StartTransactionModalComponent } from "./start-transaction-modal/start-transaction-modal.component";
import { TriggerMessageModalComponent } from "./trigger-message-modal/trigger-message-modal.component";
import { ResetModalComponent } from "./reset-modal/reset-modal.component";
import { ChangeAvailabilityModalComponent } from "./change-availability-modal/change-availability-modal.component";
import { CancelReservationModalComponent } from "./cancel-reservation-modal/cancel-reservation-modal.component";
import { ReserveNowModalComponent } from "./reserve-now-modal/reserve-now-modal.component";
import { SweetalertService } from "src/app/core/services/sweetalert.service";
import { UnlockConnectorModalComponent } from "./unlock-connector-modal/unlock-connector-modal.component";
import { ChangeConfigurationModalComponent } from "./change-configuration-modal/change-configuration-modal.component";
import { GetCompositeScheduleModalComponent } from "./get-composite-schedule-modal/get-composite-schedule-modal.component";
import { GetConfigurationModalComponent } from "./get-configuration-modal/get-configuration-modal.component";
import { GetDiagnosticsModalComponent } from "./get-diagnostics-modal/get-diagnostics-modal.component";
import { SendLocalListModalComponent } from "./send-local-list-modal/send-local-list-modal.component";
import { UpdateFirmwareModalComponent } from "./update-firmware-modal/update-firmware-modal.component";
import { SetChargingProfileModalComponent } from "./set-charging-profile-modal/set-charging-profile-modal.component";
import { ClearChargingProfileModalComponent } from "./clear-charging-profile-modal/clear-charging-profile-modal.component";
import { QrCodeModalComponent } from "./qr-code-modal/qr-code-modal.component";
import { CustomMapMarker } from "src/app/core/models/shared.models";

@Component({
    selector: "app-chargers",
    templateUrl: "./chargers.component.html",
    styleUrls: ["./chargers.component.scss"],
    providers: [DecimalPipe],
})
export class ChargersComponent implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;
    searchTerm!: string;
    searchTermChanged: Subject<string> = new Subject<string>();
    inputSub: Subscription | undefined;
    page = 1;
    pageSize = 10;
    collectionSize!: number;
    currentRate = 8;
    genericFilter: GenericFilter = new GenericFilter("", 0, 10, "id", "desc");
    charger!: Charger;
    chargers!: Charger[];
    sites!: Site[];
    pagedChargers!: PagedCharger;
    empty = true;

    icons = {
        unavailable: {
            url: "../../../../../assets/images/pin_gray.png",
            scaledSize: {
                width: 36,
                height: 48,
            },
        },
        outOfService: {
            url: "../../../../../assets/images/pin_red.png",
            scaledSize: {
                width: 36,
                height: 48,
            },
        },
        available: {
            url: "../../../../../assets/images/pin_green.png",
            scaledSize: {
                width: 36,
                height: 48,
            },
        },
    };

    longitude = -123.01;
    latitude = 49.23;
    markers: Array<CustomMapMarker> = [];
    zoom: number = 11;
    chargerURL = "/charger-management/chargers/";

    constructor(
        private chargerService: ChargerService,
        private siteService: SiteService,
        public sweetalertService: SweetalertService,
        public modalService: NgbModal
    ) {}

    ngOnInit(): void {
        //BreadCrumb
        this.breadCrumbItems = [
            { label: "Charger Management" },
            { label: "Chargers", active: true },
        ];

        this.fetchData();
        this.fetchSitesData();

        this.inputSub = this.searchTermChanged
            .pipe(debounceTime(500), delay(200))
            .subscribe((value) => {
                console.log(value);
                this.genericFilter.searchParam = value;
                this.fetchData();
            });
    }

    fetchData() {
        this.genericFilter.size = this.pageSize;
        this.genericFilter.page = this.page - 1;
        this.chargerService.getChargers(this.genericFilter).subscribe({
            next: (v) => {
                console.log("returned chargers : ", v);
                this.pagedChargers = v;
                this.markers = this.pagedChargers.content.map<CustomMapMarker>(
                    (item) => {
                        return {
                            latitude: Number(item.locationLattitude),
                            longitude: Number(item.locationLongitude),
                            iconUrl: item.enabled
                                ? this.icons.available
                                : this.icons.unavailable,
                            title: item.name,
                            link: `${this.chargerURL}${item.chargingStationIdentifier}`,
                        };
                    }
                );
                this.collectionSize = this.pagedChargers.totalElements;
                if (this.pagedChargers.content.length > 0) {
                    this.chargers = this.pagedChargers.content;
                    this.empty = false;
                }
            },
            error: (e) => console.error(e),
            complete: () => {
                console.info("chargers retrieved");
            },
        });
    }

    fetchSitesData() {
        let filter: GenericFilter = new GenericFilter(
            "",
            0,
            1000,
            "name",
            "asc"
        );
        this.siteService.getSites(filter).subscribe({
            next: (v) => {
                this.sites = v.content;
                console.log("returned sites : ", v);
            },
            error: (e) => console.error(e),
            complete: () => {
                console.info("sites retrieved");
            },
        });
    }

    search(value: string): void {
        this.searchTermChanged.next(value);
    }

    onSizeChange(size: number) {
        this.genericFilter.size = size;
        this.pageSize = size;
        this.page = 1;
        this.fetchData();
    }

    pageChanged(page: any) {
        console.log("page change: ", page);
        this.genericFilter.page = page - 1;
        this.fetchData();
    }

    openModal(charger: any, isAddNew: boolean) {
        const modalRef = this.modalService.open(ChargerModalComponent, {
            size: "xl",
            windowClass: "modal-holder",
            centered: true,
        });
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.isAddNew = isAddNew;
        modalRef.componentInstance.sites = this.sites;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    cancelReservationModal(
        charger: any,
        isFromList: boolean,
        connectorIdentifier: any
    ) {
        const modalRef = this.modalService.open(
            CancelReservationModalComponent
        );
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    changeAvailabilityModal(
        charger: any,
        isFromList: boolean,
        connectorIdentifier: any
    ) {
        const modalRef = this.modalService.open(
            ChangeAvailabilityModalComponent
        );
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    changeConfigurationModal(
        charger: any,
        isFromList: boolean,
        connectorIdentifier: any
    ) {
        const modalRef = this.modalService.open(
            ChangeConfigurationModalComponent
        );
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    clearCache(charger: Charger) {
        console.log("charger: ", charger);
        this.sweetalertService
            .confirm(
                "Clear Cache?",
                `Are you sure you want to clear the cache of charger ${charger.chargingStationIdentifier}?`
            )
            .then((result) => {
                console.log("result: ", result);
                if (result.isConfirmed) {
                    let clearCache: ClearCache = {
                        chargingStationSelectList: [
                            {
                                chargingStationIdentifier:
                                    charger.chargingStationIdentifier,
                                endpointAddress: "ClearCache",
                                ocppTransport: "JSON",
                            },
                        ],
                    };

                    this.chargerService.clearCache(clearCache).subscribe({
                        next: (v) => {
                            console.log("clear cache request submitted : ", v);
                            this.sweetalertService
                                .success(v.message)
                                .then(() => {});
                        },
                        error: (e) => {
                            console.error("error occured : ", e);
                            this.sweetalertService.failure(e).then(() => {});
                        },
                        complete: () => {
                            console.info("clear cache completed");
                        },
                    });
                }
            });
    }

    clearChargingProfileModal(
        charger: any,
        isFromList: boolean,
        connectorIdentifier: any
    ) {
        const modalRef = this.modalService.open(
            ClearChargingProfileModalComponent
        );
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    getCompositeScheduleModal(
        charger: any,
        isFromList: boolean,
        connectorIdentifier: any
    ) {
        const modalRef = this.modalService.open(
            GetCompositeScheduleModalComponent
        );
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    getConfigurationModal(
        charger: any,
        isFromList: boolean,
        connectorIdentifier: any
    ) {
        const modalRef = this.modalService.open(GetConfigurationModalComponent);
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    getDiagnosticsModal(
        charger: any,
        isFromList: boolean,
        connectorIdentifier: any
    ) {
        const modalRef = this.modalService.open(GetDiagnosticsModalComponent);
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    getLocalListVersionModal(charger: Charger) {
        console.log("charger: ", charger);
        this.sweetalertService
            .confirm(
                "Get Local List Version?",
                `Are you sure you want to get the local list version of charger ${charger.chargingStationIdentifier}?`
            )
            .then((result) => {
                console.log("result: ", result);
                if (result.isConfirmed) {
                    let getLocalListVersion: GetLocalListVersion = {
                        chargingStationSelectList: [
                            {
                                chargingStationIdentifier:
                                    charger.chargingStationIdentifier,
                                endpointAddress: "ClearCache",
                                ocppTransport: "JSON",
                            },
                        ],
                    };

                    this.chargerService
                        .getLocalListVersion(getLocalListVersion)
                        .subscribe({
                            next: (v) => {
                                console.log(
                                    "getLocalListVersion request submitted : ",
                                    v
                                );
                                this.sweetalertService
                                    .success(v.message)
                                    .then(() => {});
                            },
                            error: (e) => {
                                console.error("error occured : ", e);
                                this.sweetalertService
                                    .failure(e)
                                    .then(() => {});
                            },
                            complete: () => {
                                console.info("getLocalListVersion completed");
                            },
                        });
                }
            });
    }

    sendLocalListModal(
        charger: any,
        isFromList: boolean,
        connectorIdentifier: any
    ) {
        const modalRef = this.modalService.open(SendLocalListModalComponent);
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    reserveNowModal(
        charger: any,
        isFromList: boolean,
        connectorIdentifier: any
    ) {
        const modalRef = this.modalService.open(ReserveNowModalComponent);
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    resetModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
        const modalRef = this.modalService.open(ResetModalComponent);
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    setChargingProfileModal(
        charger: any,
        isFromList: boolean,
        connectorIdentifier: any
    ) {
        const modalRef = this.modalService.open(
            SetChargingProfileModalComponent
        );
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    startTransactionModal(
        charger: any,
        isFromList: boolean,
        connectorIdentifier: any
    ) {
        const modalRef = this.modalService.open(StartTransactionModalComponent);
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    triggerMessageModal(
        charger: any,
        isFromList: boolean,
        connectorIdentifier: any
    ) {
        const modalRef = this.modalService.open(TriggerMessageModalComponent);
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    unlockConnectorModal(
        charger: any,
        isFromList: boolean,
        connectorIdentifier: any
    ) {
        const modalRef = this.modalService.open(UnlockConnectorModalComponent);
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    //refresh page
    refreshPage(): void {
        window.location.reload();
    }

    updateFirmwareModal(
        charger: any,
        isFromList: boolean,
        connectorIdentifier: any
    ) {
        const modalRef = this.modalService.open(UpdateFirmwareModalComponent);
        modalRef.componentInstance.charger = charger;
        modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
        modalRef.componentInstance.isFromList = isFromList;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.fetchData();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    qrCodeModal(charger: any) {
        const modalRef = this.modalService.open(QrCodeModalComponent);
        modalRef.componentInstance.charger = charger;

        // handle result passing back from modal form component
        modalRef.result
            .then((result: ModalMessage) => {})
            .catch(() => {
                // user click outside of the modal form
                console.log("QR Code Modal: ", "Cancel");
            });
    }

    onMarkerClick(marker: CustomMapMarker) {
        window.location.href = marker.link;
    }
}
