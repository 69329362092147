import { Address } from "./address.models";
import { Organization } from "./organization.models";

export interface User {
  id: number;
  lastName: string;
  firstName: string;
  email: string;
  callingCode: string;
  phoneNumber: string;
  lastLoggedIn: string;
  lastPasswordResetDate: string;
  userType: string;
  organization: Organization;
  address: Address;
  verified: boolean;
  hasVehicle: boolean;
  hasCreditCard: boolean;
  userPreferences: UserPreferences;
  notificationPreferences: NotificationPreference[];
  permissionNames: string[];
  profilePicPath: string;
  names: string;
  roleNames: string[];
  uuid: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  deleted: boolean;
  deletedAt: string;
}

export interface UserPreferences {
  language: string;
  distanceUnit: string;
  startScreen: string;
  isNotificationEnabled: boolean;
  isSmsNotificationEnabled: boolean;
}

export interface NotificationPreference {
  notificationType: string;
  emailEnabled: boolean;
  smsEnabled: boolean;
}

export interface AddUser {
  callingCode: string;
  email: string;
  firstName: string;
  lastName: string;
  organizationUuid: string;
  phoneNumber: string;
  roleNames: string[];
  userType: string;
  uuid: string;
  enabled: boolean;
}

export interface PagedUser {
  content: User[];
  first: boolean;
  last: boolean;
  empty: boolean;
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
  size: number;
  number: number;
}