import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppUtilities } from 'src/app/app-utilities';
import { Charger } from 'src/app/core/models/charger.models';

@Component({
  selector: 'app-qr-code-modal',
  templateUrl: './qr-code-modal.component.html',
  styleUrls: ['./qr-code-modal.component.scss']
})
export class QrCodeModalComponent implements OnInit {

  @Input() public charger!: Charger;

  title!: string;
  qrUrl!: string;

  constructor(public modal: NgbActiveModal, private appUtilities: AppUtilities) { }

  ngOnInit(): void {
    this.title = `QR Code - ${this.charger.chargingStationIdentifier}`;
    this.qrUrl = `${this.appUtilities.getBackEndApiUrl()}/charging-stations/qrcode/${this.charger.chargingStationIdentifier}.png`;
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
