import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddOrganizationType } from 'src/app/core/models/organization-type.models';
import { OrganizationTypeService } from 'src/app/core/services/organization-type.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-organization-type-modal',
  templateUrl: './organization-type-modal.component.html',
  styleUrls: ['./organization-type-modal.component.scss']
})
export class OrganizationTypeModalComponent implements OnInit {

  @Input() public organizationType!: AddOrganizationType;
  @Input() public isAddNew!: boolean;

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "PAGES.ORGANIZATIONTYPES.LIST.EDIT";

  constructor(
    public modal: NgbActiveModal, 
    private organizationTypeService: OrganizationTypeService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    if (this.organizationType == undefined) {
      this.organizationType = {
        name: "",
        description: "",
        uuid: "",
        enabled: false
      };

      this.title = "PAGES.ORGANIZATIONTYPES.LIST.ADD";
    }
  }

  
  // Handle Create button click
  onSubmit() {
    // console.log('create clicked');
    this.processing = true;
    if (this.isAddNew) {
      // Add a new organizationType
      this.organizationTypeService.addOrganizationType(this.organizationType).subscribe({
        next: (v) => {
          // console.log('added organization-type : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          // console.info('organization-type saved');
        }
      });
    } else {
      // Update an existing organizationType
      this.organizationTypeService.updateOrganizationType(this.organizationType, this.organizationType.uuid).subscribe({
        next: (v) => {
          // console.log('updated organization-type : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });  
        },
        complete: () => {
          // console.info('organization-type saved');
        }
      });
    }
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
