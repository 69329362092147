import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { InternalAccount, PagedInternalAccount } from '../models/internal-account.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';
import { GenericFilter } from '../models/generic-filter.models';

@Injectable({
  providedIn: 'root'
})
export class InternalAccountService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getBillingManagementUrl();
  }

  /**
     * Get all internal-accounts
     */
  getInternalAccounts(params: GenericFilter): Observable<PagedInternalAccount> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedInternalAccount>(`${this.backendApiUrl}/internal-accounts?${queryString}`, this.httpOptions).pipe(
      tap((pagedInternalAccount: PagedInternalAccount) => {
        this.log(`Retrieved internal-accounts`);
      })
    );
  }

  /**
     * Get internalaccount
     */
  getInternalAccount(params: string): Observable<InternalAccount> {
    return this.http.get<InternalAccount>(`${this.backendApiUrl}/internal-accounts/${params}`, this.httpOptions).pipe(
      tap((internalaccount: InternalAccount) => {
        this.log(`Retrieved internal-account`);
      })
    );
  }

  /**
     * Get all internal-accounts
     */
  getInternalAccountsByType(params: any): Observable<PagedInternalAccount> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedInternalAccount>(`${this.backendApiUrl}/internal-accounts/internalaccount-type?${queryString}`, this.httpOptions).pipe(
      tap((pagedInternalAccount: PagedInternalAccount) => {
        this.log(`Retrieved internal-accounts`);
      })
    );
  }

  /**
     * Add internalaccount
     */
  addInternalAccount(params: any): Observable<GenericResponse> {
    console.log('request : ', params);

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/internal-accounts`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added internal-account`);
      })
    );
  }

  /**
     * Update internalaccount
     */
  updateInternalAccount(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/internal-accounts/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated internal-account`);
      })
    );
  }

  /** Log a InternalAccountService message */
  private log(message: string) {
    console.log(`InternalAccountService: ${message}`);
  }
}
