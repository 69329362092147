import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { Charger } from 'src/app/core/models/charger.models';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { PagedMessageLog, MessageLog } from 'src/app/core/models/message-log.models';
import { MessageLogService } from 'src/app/core/services/message-log.service';
import { PayloadModalComponent } from '../payload-modal/payload-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models';

@Component({
  selector: 'app-charger-logs',
  templateUrl: './charger-logs.component.html',
  styleUrls: ['./charger-logs.component.scss']
})
export class ChargerLogsComponent implements OnInit, OnDestroy {

  @Input() charger!: Charger;

  searchTerm!: string;
  searchTermChanged: Subject<string> = new Subject<string>();
  inputSub: Subscription | undefined;
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  genericFilter: GenericFilter = new GenericFilter('', 0, 10, 'id', 'desc');
  messageLog!: MessageLog;
  messageLogs!: MessageLog[];
  pagedMessageLogs!: PagedMessageLog;
  empty = true;

  fetchLogSubscription?: Subscription;
  fetchLogTimeout?: ReturnType<typeof setTimeout>;
  
  constructor(private messageLogService: MessageLogService, public modalService: NgbModal) { }

  ngOnInit(): void {
    this.fetchData();

    this.inputSub = this.searchTermChanged.pipe(
      debounceTime(500),
      delay(200)
    ).subscribe(value => {
        console.log(value)
        this.genericFilter.searchParam = value;
        this.fetchData();
      });
  }

  ngOnDestroy(): void {
    this.removeFetchDataSubscription();
  }

  removeFetchDataSubscription(): void {
    this.fetchLogSubscription?.unsubscribe();
    clearTimeout(this.fetchLogTimeout);
  }

  fetchData() {
    this.removeFetchDataSubscription();
    this.genericFilter.size = this.pageSize;
    this.genericFilter.page = this.page -1;
    this.fetchLogSubscription = this.messageLogService.getChargerMessageLogs(this.charger.chargingStationIdentifier, this.genericFilter).subscribe({
      next: (v) => {
        console.log('returned charger messageLogs : ', v);
        this.pagedMessageLogs = v;
        this.collectionSize = this.pagedMessageLogs.totalElements;
        if (this.pagedMessageLogs.content.length > 0) {
          this.messageLogs = this.pagedMessageLogs.content;
          this.empty = false;
        }
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('charger messageLogs retrieved');
        this.fetchLogTimeout = setTimeout(() => this.fetchData(), 5000);
      }
    });
  }

  search(value: string): void {
    this.searchTermChanged.next(value);
  }

  onSizeChange(size: number) {
    this.genericFilter.size = size;
    this.pageSize = size;
    this.page = 1;
    this.fetchData();
  }

  pageChanged(page: any) {
    console.log('page change: ', page);
    this.genericFilter.page = page - 1;
    this.fetchData();
  }

  payloadModal(log: MessageLog) {
    const modalRef = this.modalService.open(PayloadModalComponent);
    modalRef.componentInstance.messageLog = log;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }
  
}
