<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="lastName" class="fw-semibold">Last Name</label>
            <input type="text" class="form-control" id="lastName" name="lastName" 
                required [(ngModel)]="addUser.lastName" #lastName="ngModel">
            <span style="color:red" [hidden]="lastName.valid || lastName.pristine"> 
                <p>Last Name is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="firstName" class="fw-semibold">First Name</label>
            <input type="text" class="form-control" id="firstName" name="firstName" 
                required [(ngModel)]="addUser.firstName" #firstName="ngModel">
            <span style="color:red" [hidden]="firstName.valid || firstName.pristine"> 
                <p>First Name is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="email" class="fw-semibold">Email</label>
            <input type="email" class="form-control" id="email" name="email" 
                required [(ngModel)]="addUser.email" #email="ngModel">
            <span style="color:red" [hidden]="email.valid || email.pristine"> 
                <p>Email is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="phoneNumber" class="fw-semibold">Phone Number</label>
            <input type="tel" class="form-control" id="phoneNumber" name="phoneNumber" 
                required [(ngModel)]="addUser.phoneNumber" #phoneNumber="ngModel">
            <span style="color:red" [hidden]="phoneNumber.valid || phoneNumber.pristine"> 
                <p>Phone number is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="organizationUuid" class="fw-semibold">Organization</label>
            <select class="form-select" id="organizationUuid" name="organizationUuid" [(ngModel)]="addUser.organizationUuid" #organizationUuid="ngModel">
                <option *ngFor="let o of organizations" [ngValue]="o.uuid">{{ o.name }}</option>
            </select>
        </div>
        <div class="form-group mb-3">
            <label for="userType" class="fw-semibold">Roles</label>
            <select class="form-control" id="userType" 
                name="roleNames" [(ngModel)]="addUser.roleNames" 
                #roleNames="ngModel" required multiple
            >
                <option *ngFor="let role of roles">{{ role.name }}</option>
            </select>
            <span style="color:red" [hidden]="roleNames.valid || roleNames.pristine"> 
                <p>Roles are required</p> 
            </span>
        </div>
        <div class="form-group mb-3" [hidden]="isAddNew">
            <label for="enabled" class="fw-semibold">Status</label>
            <select class="form-select" id="enabled" name="enabled" [(ngModel)]="addUser.enabled" #enabled="ngModel">
                <option value="true">Enabled</option>
                <option value="false">Disabled</option>
            </select>
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">Save</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>