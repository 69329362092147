import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'listFilter'})
export class ListFilterPipe implements PipeTransform {

    transform(list: any[], filterText: string): any {
        return list ? list.filter(item => item.name.search(new RegExp(filterText, 'i')) > -1) : [];
    }
}

@Pipe({ name: 'listFilter2'})
export class ListFilter2Pipe implements PipeTransform {

    transform(list: any[], filterText: string): any {
        return list ? list.filter(item => item.description.search(new RegExp(filterText, 'i')) > -1) : [];
    }
}

@Pipe({ name: 'listFilter3'})
export class ListFilter3Pipe implements PipeTransform {

    transform(list: any[], filterText: string): any {
        return list ? list.filter(item => item.rateId.chargerType.search(new RegExp(filterText, 'i')) > -1) : [];
    }
}

@Pipe({ name: 'listFilter4'})
export class ListFilter4Pipe implements PipeTransform {

    transform(list: any[], filterText: string): any {
        return list ? list.filter(item => item.englishSubject.search(new RegExp(filterText, 'i')) > -1) : [];
    }
}

@Pipe({ name: 'listFilter5'})
export class ListFilter5Pipe implements PipeTransform {

    transform(list: any[], filterText: string): any {
        return list ? list.filter(item => item.notificationType.search(new RegExp(filterText, 'i')) > -1) : [];
    }
}