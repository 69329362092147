import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { OrganizationType } from '../models/organization-type.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';

@Injectable({
  providedIn: 'root'
})
export class OrganizationTypeService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getUserManagementUrl();
  }

  /**
     * Get all organizationtypes
     */
  getOrganizationTypes(): Observable<OrganizationType[]> {

    return this.http.get<OrganizationType[]>(`${this.backendApiUrl}/organization-types`, this.httpOptions).pipe(
      tap((organizationtypes: OrganizationType[]) => {
        this.log(`Retrieved organization-types`);
      })
    );
  }

  /**
     * Add organizationtype
     */
  addOrganizationType(params: any): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/organization-types`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added organization-type`);
      })
    );
  }

  /**
     * Update organizationtype
     */
  updateOrganizationType(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/organization-types/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated organization-type`);
      })
    );
  }

  /** Log a OrganizationTypeService message */
  private log(message: string) {
    console.log(`OrganizationTypeService: ${message}`);
  }
}
