<div class="card bg-pattern">
    <div class="card-body">
        <div class="row align-items-center">
            <div class="col-sm-8">
                <div class="avatar-xs mb-3">
                    <span class="avatar-title rounded-circle bg-light font-size-24">
                        <i class="mdi mdi-bullhorn-outline text-primary"></i>
                    </span>
                </div>
                <p class="font-size-18">{{ 'PAGES.DASHBOARD.LIST.ISSUES' | translate }} <i class="mdi mdi-arrow-right"></i></p>
                <div class="mt-4">
                    <a href="javascript: void(0);" class="btn btn-success waves-effect waves-light">{{ 'PAGES.DASHBOARD.LIST.CHECKISSUES' | translate }}</a>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="mt-4 mt-sm-0">
                    <img src="assets/images/widget-img.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
    </div> 
</div>