export const environment = {
    production: false,
    defaultauth: "tapgoev",
    appConfig: {
        apiURL: "https://dev-api.tapgoev.com",
        frontendURL: "https://staging-app.tapgoev.com",
        adminURL: "https://staging-admin.tapgoev.com",
        supportPhoneNumber: "1-866-338-3369",
        qrCodeAppURL: "https://staging-app.tapgoev.com",
        qrCodeMethodURL: "https://staging-app.tapgoev.com/how-to-start-charging",
        qrCodeMemberURL : "https://staging-app.tapgoev.com/become-a-member",
        qrCodeTutorialURL : "https://staging-app.tapgoev.com/video-tutorial",
        programName: "Tap&GoEV",
        googleMapsApiKey: "AIzaSyDoI1XVIq9a2GGa9Ol6veel-Oar2yK1yuw",
        googleMapId: "3268be0b1183b229"
    },
    googleMapsApiKey: "AIzaSyDoI1XVIq9a2GGa9Ol6veel-Oar2yK1yuw",
};