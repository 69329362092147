import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Charger, Reset, UnlockConnector } from 'src/app/core/models/charger.models';
import { ChargerService } from 'src/app/core/services/charger.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-charger-modal',
  templateUrl: './unlock-connector-modal.component.html',
  styleUrls: ['./unlock-connector-modal.component.scss']
})
export class UnlockConnectorModalComponent implements OnInit {

  @Input() public charger!: Charger;
  @Input() public isFromList!: boolean;

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "PAGES.CHARGERS.LIST.UNLOCKCONNECTOR";

  constructor(
    public modal: NgbActiveModal, 
    private chargerService: ChargerService,
    public sweetalertService: SweetalertService) { }
    public newAction!: {
      chargingStationIdentifier: string, connectorId: number, resetType: string
    }

  ngOnInit(): void {
    this.newAction = {
      chargingStationIdentifier: this.charger.chargingStationIdentifier,
      connectorId: 0,
      resetType: ''
    }
  }

  
  // Handle Create button click
  onSubmit() {
    let unlockConnector: UnlockConnector = {
      chargingStationSelectList: [
        {
          chargingStationIdentifier: this.newAction.chargingStationIdentifier,
          endpointAddress: 'UnlockConnector',
          ocppTransport: "JSON"
        }
      ],
      connectorId: this.newAction.connectorId
    }

    this.processing = true;
    this.chargerService.unlockConnector(unlockConnector).subscribe({
      next: (v) => {
        // console.log('unlockConnector request submitted : ', v);
        this.sweetalertService.success(v.message).then(() => {
          this.modal.close(v);
        });
      },
      error: (e) => {
        // console.error('error occured : ', e);
        this.sweetalertService.failure(e).then(() => {
          this.processing = false;
        });
      },
      complete: () => {
        // console.info('unlockConnector submitted');
      }
    });
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
