import { Component, OnInit, ViewChild } from '@angular/core';
import { monthlyEarningChart } from '../dashboards/data';
import { ChartType } from '../dashboards/dashboard.model';


@Component({
  selector: 'app-earning-reports-card',
  templateUrl: './earning-reports-card.component.html',
  styleUrls: ['./earning-reports-card.component.scss']
})
export class EarningReportsCardComponent implements OnInit {

  monthlyEarningChart!: ChartType;
  @ViewChild('content') content: any;

  constructor() { }

  ngOnInit(): void {
    this.fetchData();
  }

  private fetchData() {
    this.monthlyEarningChart = monthlyEarningChart;
  }
}
