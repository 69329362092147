import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppUtilities } from 'src/app/app-utilities';
import { GenericFilter } from '../models/generic-filter.models';
import { PagedReservation } from '../models/reservation.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getBackEndApiUrl();
  }

  /**
     * Get all active reservations
     */
  getReservations(params: GenericFilter): Observable<PagedReservation> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedReservation>(`${this.backendApiUrl}/reservations?${queryString}`, this.httpOptions).pipe(
      tap((pagedCharger: PagedReservation) => {
        this.log(`Retrieved reservations`);
      })
    );
  }

  /**
     * Get all charger active reservations
     */
  getChargerReservations(chargeBoxId: string, params: GenericFilter): Observable<PagedReservation> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedReservation>(`${this.backendApiUrl}/reservations/charging-station/${chargeBoxId}?${queryString}`, this.httpOptions).pipe(
      tap((pagedCharger: PagedReservation) => {
        this.log(`Retrieved charger ${chargeBoxId} reservations`);
      })
    );
  }

  /** Log a ReservationService message */
  private log(message: string) {
    // console.log(`ReservationService: ${message}`);
  }
}
