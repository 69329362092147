import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCharger, Charger, Connector } from 'src/app/core/models/charger.models';
import { Site } from 'src/app/core/models/site.models';
import { ChargerService } from 'src/app/core/services/charger.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-charger-modal',
  templateUrl: './charger-modal.component.html',
  styleUrls: ['./charger-modal.component.scss']
})
export class ChargerModalComponent implements OnInit {

  @Input() public charger!: Charger;
  @Input() public isAddNew!: boolean;
  @Input() public sites!: Site[];

  addCharger!: AddCharger;
  entryForm!: UntypedFormGroup;
  processing = false;
  title = "Edit Charger";
  chargerPlugTypes = [
    "CCS", "CHADEMO", "GB_T", "J1772", "MENNEKENS", "SUPERCHARGER"
  ];
  chargePointStatuses = [
    "Available", "Charging", "Faulted", "Finishing", "Preparing", "Reserved", "SuspendedEV", "SuspendedEVSE", "Unavailable"
  ];
  chargerTypes = ["LEVEL_1", "LEVEL_2", "LEVEL_3"];

  constructor(
    public modal: NgbActiveModal, 
    private chargerService: ChargerService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    console.log("sites : ", this.sites);
    
    if (this.charger == undefined) {
      let connector: Connector = {
        id: 0,
        connectorIdentifier: 0,
        chargerPlugType: "J1772",
        chargePointStatus: "Unavailable",
        chargingProfiles: [],
        enabled: false
      }

      this.addCharger = {
        name: "",
        serialNumber: "",
        model: "",
        vendor: "",
        chargerType: "",
        description: "",
        iccid: "",
        imsi: "",
        locationLattitude: "",
        locationLongitude: "",
        meterSerialNumber: "",
        connectorCount: 2,
        connectors: [],
        registrationStatus: "",
        siteUuid: "",
        enabled: false
      };

      for (let i = 1; i <= this.addCharger.connectorCount; i++) {
        console.log("i is: " + i);
        const conn : Connector = { ...connector };
        conn.connectorIdentifier = i;
        this.addCharger.connectors.push(conn);
      }
      this.title = "Add Charger";
    } else {
      this.addCharger = {
        name: this.charger.name,
        serialNumber: this.charger.serialNumber,
        model: this.charger.model,
        vendor: this.charger.vendor,
        chargerType: this.charger.chargerType,
        description: this.charger.description,
        iccid: this.charger.iccid,
        imsi: this.charger.imsi,
        locationLattitude: this.charger.locationLattitude,
        locationLongitude: this.charger.locationLongitude,
        meterSerialNumber: this.charger.meterSerialNumber,
        connectorCount: this.charger.connectors.length,
        connectors: this.charger.connectors,
        registrationStatus: this.charger.registrationStatus,
        siteUuid: this.charger.site.uuid,
        enabled: this.charger.enabled
      };
    }

    console.log("add charger: ", this.addCharger)
  }

  
  // Handle Create button click
  onSubmit() {
    console.log('create clicked');
    this.processing = true;
    if (this.isAddNew) {
      // Add a new charger
      this.chargerService.addCharger(this.addCharger).subscribe({
        next: (v) => {
          console.log('added charger : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          console.info('chargers saved');
        }
      });
    } else {
      // Update an existing charger
      this.chargerService.updateCharger(this.addCharger, this.charger.uuid).subscribe({
        next: (v) => {
          console.log('updated charger : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });  
        },
        complete: () => {
          console.info('chargers saved');
        }
      });
    }
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
