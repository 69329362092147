import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AddEvCard, EvCard } from "src/app/core/models/ev-card.models";
import { EvCardService } from "src/app/core/services/ev-card.service";
import { SweetalertService } from "src/app/core/services/sweetalert.service";
import { GenericFilter } from "src/app/core/models/generic-filter.models";
import { Observable, Subject, Subscription } from "rxjs";
import { catchError, debounceTime, switchMap, tap } from "rxjs/operators";
import { PagedUser } from "src/app/core/models/user.models";
import { UserService } from "src/app/core/services/user.service";
import { User } from "src/app/core/models/user.models";
import {
    Select2Data,
    Select2Option,
    Select2SearchEvent,
    Select2UpdateValue,
} from "ng-select2-component";
import { VehicleResponse } from "src/app/core/models/vehicle.models";

@Component({
    selector: "app-membership-modal",
    templateUrl: "./membership-modal.component.html",
    styleUrls: ["./membership-modal.component.scss"],
})
export class MembershipModalComponent implements OnInit {
    @Input() public evCard!: EvCard;
    @Input() public isAddNew!: boolean;
    addEvCard!: AddEvCard;
    searchTerm!: string;
    searchTermChanged: Subject<string> = new Subject<string>();
    inputSub: Subscription | undefined;
    page = 1;
    pageSize = 10;
    collectionSize!: number;
    currentRate = 8;
    genericFilter: GenericFilter = new GenericFilter(
        "",
        0,
        100,
        "firstName",
        "asc"
    );
    pagedUsers!: PagedUser;

    entryForm!: UntypedFormGroup;
    processing = false;
    title = "PAGES.CARDSSTICKERS.LIST.EDIT";

    options: Select2Data = []; // Full list of options from the API
    filteredOptions: User[] = []; // Filtered options for display
    selectedOption: User | null = null; // Selected option
    isLoading = false;
    empty = false;
    vehicles: { value: string; label: string }[] = [];

    constructor(
        public modal: NgbActiveModal,
        private evCardService: EvCardService,
        private userService: UserService,
        private formBuilder: FormBuilder,
        public sweetalertService: SweetalertService
    ) {}

    ngOnInit(): void {
        if (this.evCard == undefined) {
            this.addEvCard = {
                name: "",
                expired: false,
                evCardType: "",
                maxActiveTransactionCount: 1,
                parentTagId: "",
                userUuid: "",
                vehicleUuid: "",
                enabled: true,
                tagId: "",
            };
            this.title = "PAGES.CARDSSTICKERS.LIST.ADD";
        } else {
            if (this.evCard.userUuid) {
                this.loadVehicles(this.evCard.userUuid);
            }
            this.options = [
                {
                    label: this.evCard.name,
                    value: this.evCard.userUuid,
                },
            ];
            this.addEvCard = {
                name: this.evCard.name,
                expired: this.evCard.expired,
                evCardType: this.evCard.evCardType,
                maxActiveTransactionCount:
                    this.evCard.maxActiveTransactionCount,
                parentTagId: this.evCard.parentTagId,
                userUuid:
                    this.evCard.userUuid != null ? this.evCard.userUuid : "",
                vehicleUuid: this.evCard.vehicleUuid,
                enabled: this.evCard.enabled,
                tagId: this.evCard.tagId,
            };
        }

        this.searchTermChanged
            .pipe(
                debounceTime(300), // Wait for 300ms after typing stops
                switchMap((searchTerm: string) => this.searchItems(searchTerm)),
                tap(() => (this.isLoading = true)), // Show loading spinner while waiting for results
                catchError(() => {
                    this.isLoading = false;
                    return [];
                })
            )
            .subscribe((items) => {
                // this.options = items.content; // Update the items for the select dropdown
                this.isLoading = false; // Hide loading spinner
                this.options = items.content.map((user) => ({
                    label: user.names,
                    value: user.uuid,
                }));
            });

        this.initForm();
    }

    private initForm() {
        this.entryForm = this.formBuilder.group({
            name: [this.addEvCard.name, Validators.required],
            expired: [this.addEvCard.expired],
            evCardType: [this.addEvCard.evCardType, Validators.required],
            maxActiveTransactionCount: [
                this.addEvCard.maxActiveTransactionCount,
                [Validators.required, Validators.min(1)],
            ],
            parentTagId: [this.addEvCard.parentTagId],
            userUuid: [this.addEvCard.userUuid, Validators.required],
            vehicleUuid: [this.addEvCard.vehicleUuid],
            enabled: [this.addEvCard.enabled],
            tagId: [this.addEvCard.tagId, Validators.required],
        });
    }

    onSearch(event: Select2SearchEvent<Select2UpdateValue>) {
        const searchTerm = String(event.search).trim();
        if (searchTerm) {
            this.searchTermChanged.next(searchTerm);
        }
    }

    searchItems(searchTerm: string): Observable<PagedUser> {
        this.genericFilter.size = this.pageSize;
        this.genericFilter.page = this.page - 1;
        this.genericFilter.searchParam = searchTerm;

        return this.userService.getUsers(this.genericFilter, "NON_BACK_OFFICE");
    }

    findOptionByValue(value: string): Select2Option | undefined {
        for (const option of this.options) {
            if ("value" in option && option.value === value) {
                return option as Select2Option;
            }
        }
        return undefined;
    }

    onSelectionChange(e: any): void {
        if (e) {
            this.loadVehicles(e.value.toString());
            this.entryForm.patchValue({
                name: this.findOptionByValue(e.value)?.label,
            });
        }
    }

    loadVehicles(userUuid: string) {
        this.userService.getUserVehicles(userUuid).subscribe({
            next: (vehicles: VehicleResponse[]) => {
                this.vehicles = vehicles.map((vehicle) => ({
                    label: `${vehicle.vehicleMake} ${vehicle.vehicleModel} (${vehicle.registrationNumber})`,
                    value: vehicle.uuid,
                }));
            },
            error: (error: any) => {
                console.error("Error loading vehicles:", error);
                this.sweetalertService.failure("Error loading vehicles");
            },
        });
    }

    fetchUserData() {
        this.genericFilter.size = this.pageSize;
        this.genericFilter.page = this.page - 1;
        this.userService.getUsers(this.genericFilter, "").subscribe({
            next: (v) => {
                // console.log('returned users : ', v);
                this.pagedUsers = v;
                this.collectionSize = this.pagedUsers.totalElements;
                if (this.pagedUsers.content.length > 0) {
                    this.filteredOptions = this.pagedUsers.content;
                    this.empty = false;
                }
            },
            error: (e) =>
                console.error((e: any) => {
                    // console.log('An error occured while retrieving users', e);
                    this.filteredOptions = [];
                }),
            complete: () => {
                console.info("users retrieved");
            },
        });
    }

    search(value: string): void {
        // this.selectedOption = null;
        this.searchTermChanged.next(value);
    }

    selectOption(option: User) {
        this.selectedOption = option;
        this.searchTerm = `${option.firstName} ${option.lastName}`;

        // console.log("Selected option:", this.selectedOption); // Use value for further operations
        this.filteredOptions = []; // Clear options after selection
    }

    // Handle Create button click
    onSubmit() {
        if (this.entryForm.valid) {
            this.processing = true;
            const formData = this.entryForm.value;

            if (this.isAddNew) {
                this.evCardService.addEvCard(formData).subscribe({
                    next: () => {
                        this.sweetalertService.success(
                            "Card created successfully"
                        );
                        setTimeout(() => {
                            this.processing = false;
                        }, 10000);
                        this.modal.close(true);
                    },
                    error: (error) => {
                        this.processing = false;
                        this.sweetalertService.failure("Error creating card");
                    },
                });
            } else {
                this.evCardService
                    .updateEvCard(this.evCard.uuid, formData)
                    .subscribe({
                        next: () => {
                            this.sweetalertService.success(
                                "Card updated successfully"
                            );
                            this.modal.close(true);
                        },
                        error: (error) => {
                            this.processing = false;
                            this.sweetalertService.failure(
                                "Error updating card"
                            );
                        },
                    });
            }
        }
    }

    // Handle Delete button click
    onCancel() {
        this.modal.close();
    }

    ngOnDestroy() {
        // Unsubscribe when the component is destroyed
        if (this.inputSub) {
            this.inputSub.unsubscribe();
        }
    }
}
