import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-canel-reservation-modal',
  templateUrl: './canel-reservation-modal.component.html',
  styleUrls: ['./canel-reservation-modal.component.scss']
})
export class CanelReservationModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
