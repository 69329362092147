start page title
<app-pagetitle title="Vehicle Make Details" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row mb-4">
    <div class="col-xl-3">
        <div class="card h-100">
            <div class="card-body">
                
                <div class="table-responsive">
                    <h5 class="font-size-16 mb-3">Vehicle Make Details</h5>

                    <hr class="my-4">

                    <div>
                        <p class="mb-1 text-muted font-size-13">Name :</p>
                        <h5 class="font-size-14">{{ vehicleMake?.name }}</h5>
                    </div>
                    <div class="mt-3">
                        <p class="mb-1 text-muted font-size-13">Description :</p>
                        <h5 class="font-size-14">{{ vehicleMake?.description}}</h5>
                    </div>
                    <div class="mt-3">
                        <p class="mb-1 text-muted font-size-13">Last Updated :</p>
                        <h5 class="font-size-14">{{ vehicleMake?.updatedAt}}</h5>
                    </div>
                    <div class="mt-3">
                        <p class="mb-1 text-muted font-size-13">Status :</p>
                        <h5 class="font-size-14">
                            <span *ngIf="vehicleMake.enabled" class="badge badge-soft-success font-size-12">Enabled</span>
                            <span *ngIf="!vehicleMake.enabled" class="badge badge-soft-warning font-size-12">Enabled</span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-9">
        <div class="card mb-0">
            <div class="card-body">
                <div class="d-flex mb-4">
                    <h4 class="card-title flex-grow-1">{{ vehicleMake?.name}} Models</h4>
                </div>

                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6">
                        <div class="dataTables_length" id="tickets-table_length"><label
                                class="d-inline-flex align-items-center">Show
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="pageSize" class="form-control form-control-sm mx-2">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select> entries</label></div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <input type="text" name="searchTerm" 
                                    class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table" [(ngModel)]="searchTerm" 
                                    (input)="search(searchTerm)"
                                    >
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>

                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable"
                        class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th >Name</th>
                                <th >Description</th>
                                <th >Last Updated</th>
                                <th >Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [hidden]="!empty">
                                <td>No data available in table</td>
                            </tr>
                            <tr *ngFor="let vehicleModel of $any(vehicleModels | listFilter: searchTerm | slice:(page-1) * pageSize:page * pageSize)">
                                <td>
                                    <ngb-highlight [result]="vehicleModel.name" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="vehicleModel.description" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="vehicleModel.updatedAt" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <span *ngIf="vehicleModel.enabled" class="badge rounded-pill bg-success ms-1">
                                        Enabled
                                    </span>
                                    <span *ngIf="!vehicleModel.enabled" class="badge rounded-pill bg-warning ms-1">
                                        Disabled
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- End table -->
                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            Showing
                            <!-- {{permissionsDatatableService.startIndex}} to
                            {{permissionsDatatableService.endIndex}} of {{permissionsDatatableService.totalRecords}} -->
                            entries
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-right float-md-end pagination-rounded">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" 
                                [maxSize]="5" [boundaryLinks]="true" [pageSize]="pageSize" 
                                class="d-flex justify-content-center">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- End Pagination -->
                </div>

            </div>
        </div>
    </div>
</div>


