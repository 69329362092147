<table class="table table-hover table-borderless align-middle table-centered table-nowrap mb-0">
    <thead class="table-light">
        <tr>
            <!-- <th>ID</th> -->
            <th>Name</th>
            <th>Type</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Status</th>
            <th colspan="2">Roles</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of users">
            <!-- <td>
                {{data.id}}
            </td> -->
            <td>
                <img :src="{{data.profilePicPath === null || data.profilePicPath.length === 0 ? defaultAvatar : baseUrl + data.profilePicPath}}" alt="user" class="rounded-circle h-auto avatar-xs me-2">
                <span> {{data.names}}</span>
            </td>
            <td>
                {{data.userType}}
            </td>
            <td>
                {{data.email}}
            </td>
            <td>
                {{data.phoneNumber}}
            </td>
            <td>
                <span class="badge bg-success bg-soft text-success"
                    [ngClass]=" { 'text-danger bg-danger': data.enabled === false }">{{data.enabled ? 'Enabled' : 'Disabled'}}</span>
            </td>
            <td>
                {{data.roleNames}}
            </td>
            <td>
                <div class="dropdown" ngbDropdown>
                    <a class="text-muted dropdown-toggle font-size-16" role="button" href="javascript:void(0);"
                        ngbDropdownToggle data-bs-toggle="dropdown" aria-haspopup="true">
                        <i class="mdi mdi-dots-vertical"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);" (click)="openModal(data, false)">Edit</a>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</table>