<!-- start page title -->
<app-pagetitle title="SMS Notifications" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex mb-4">
                    <h4 class="card-title flex-grow-1">List of SMS Notifications</h4>
                    <!-- <div class="flex-shrink-0">
                        <button type="button" class="btn btn-primary w-md" (click)="openModal(smsNotification, true)">Add New</button>
                    </div> -->
                </div>

                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6">
                        <div class="dataTables_length" id="tickets-table_length"><label
                                class="d-inline-flex align-items-center">Show
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="pageSize" class="form-control form-control-sm mx-2"
                                    (change)="onSizeChange(pageSize)">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select> entries</label></div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <input type="text" name="searchTerm" id="searchTerm"
                                    class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table" [(ngModel)]="searchTerm" 
                                    (input)="search(searchTerm)"
                                    >
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>

                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable"
                        class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th>Recipient Name</th>
                                <th>Notification Type</th>
                                <th>Message ID</th>
                                <th>Last Updated</th>
                                <th >Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [hidden]="!empty">
                                <td>No data available in table</td>
                            </tr>
                            <tr *ngFor="let smsNotification of smsNotifications" [hidden]="pagedSmsNotifications.empty">
                                <td>
                                    <ngb-highlight [result]="smsNotification.recipientName" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    {{ smsNotification.notificationType }}
                                </td>
                                <td>
                                    {{ smsNotification.msgId }}
                                </td>
                                <td>
                                    <ngb-highlight [result]="smsNotification.updatedAt" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-outline-primary" title="View" (click)="openModal(smsNotification, false)">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- End table -->
                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            Showing
                            <!-- {{permissionsDatatableService.startIndex}} to
                            {{permissionsDatatableService.endIndex}} of {{permissionsDatatableService.totalRecords}} -->
                            entries
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-right float-md-end pagination-rounded">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" 
                                [maxSize]="5" [boundaryLinks]="true" [pageSize]="pageSize" 
                                (pageChange)="pageChanged($event)" 
                                class="d-flex justify-content-center">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- End Pagination -->
                </div>
            </div>
        </div>
    </div>
</div>