<!-- start page title -->
<app-pagetitle [title]="title | translate" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<app-datatable
    [title]="tableTitle | translate"
    [rows]="orders"
    [columns]="columns"
    [collectionSize]="collectionSize"
    [paged]="true"
    [searchable]="true"
    [hasAction]="true"
    (onFetchData)="fetchData($event)">
    <ng-template let-row #actions>
        <a routerLink="/billing-management/orders/{{row.reference}}" [state]="{order: row}" class="btn btn-outline-info" [title]="'PAGES.COMMON.LIST.VIEW' | translate">
            <i class="fas fa-search"></i>
        </a>
    </ng-template>
</app-datatable>