<!-- start page title -->
<app-pagetitle [title]="title"
    [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->


<div class="card">
    <div class="card-body">
        <div class="row row-cols-5 justify-content-around">
            <div class="col">
                <div class="row row-cols-1">
                    <div class="col">
                        <h6 class="mb-0">Reference Number</h6>
                        <p class="mb-2">{{order.reference}}</p>
                    </div>
                    <div class="col">
                        <h6 class="mb-0">Customer Name</h6>
                        <p class="mb-2">{{order.customerName}}</p>
                    </div>
                    <div class="col">
                        <h6 class="mb-0">Description</h6>
                        <p class="mb-2">{{order.description}}</p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row row-cols-1">
                    <div class="col">
                        <h6 class="mb-0">Details</h6>
                        <p class="mb-2">{{order.details}}</p>
                    </div>
                    <div class="col">
                        <h6 class="mb-0">EVSE</h6>
                        <p class="mb-2">{{order.evse}}</p>
                    </div>
                    <div class="col">
                        <h6 class="mb-0">Notes</h6>
                        <p class="mb-2">{{order.notes}}</p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row row-cols-1">
                    <div class="col">
                        <h6 class="mb-0">Site Address</h6>
                        <p class="mb-2">{{order.siteAddress}}</p>
                    </div>
                    <div class="col">
                        <h6 class="mb-0">Site Owner</h6>
                        <p class="mb-2">{{order.siteOwner}}</p>
                    </div>
                    <div>
                        <h6 class="mb-0">Payment Status</h6>
                        <p class="mb-2">{{order.paymentStatus}}</p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row row-cols-1">
                    <div class="col">
                        <h6 class="mb-0">Energy Fees</h6>
                        <p class="mb-2">${{order.energyFees}}</p>
                    </div>
                    <div class="col">
                        <h6 class="mb-0">Non-energy Fees</h6>
                        <p class="mb-2">${{order.nonEnergyFees}}</p>
                    </div>
                    <div class="col">
                        <h6 class="mb-0">Sub Total</h6>
                        <p class="mb-2">${{order.subTotal}}</p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row row-cols-1">
                    <div class="col">
                        <h6 class="mb-0">GST</h6>
                        <p class="mb-2">${{order.gst}}</p>
                    </div>
                    <div class="col">
                        <h6 class="mb-0">PST</h6>
                        <p class="mb-2">${{order.pst}}</p>
                    </div>
                    <div class="col">
                        <h6 class="mb-0">Total</h6>
                        <p class="mb-2">${{order.total}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Start Tabs & Accordions -->
<div class="row">
    <div class="col-lg-12">
        <div class="card" id="tabs-accordions">
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-12">
                        <div>
                            <ul ngbNav #nav3="ngbNav" [activeId]="1" class="nav-tabs nav-tabs-custom nav-justified">
                                <li [ngbNavItem]="1">
                                    <a ngbNavLink>
                                        <span class="d-block d-sm-none"><i class="bx bx-detail"></i></span>
                                        <span class="d-none d-sm-block">Energy Fees</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <ng-template [ngTemplateOutlet]="TabContent" [ngTemplateOutletContext]="{$implicit:energyOrderDetail}"></ng-template>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="2">
                                    <a ngbNavLink>
                                        <span class="d-block d-sm-none"><i
                                                class="fas fa-battery-three-quarters"></i></span>
                                        <span class="d-none d-sm-block">Non-energy Fees</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <ng-template [ngTemplateOutlet]="TabContent" [ngTemplateOutletContext]="{$implicit:nonEnergyOrderDetail}"></ng-template>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="nav3" class="text-muted"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end Tabs -->

<ng-template #TabContent let-orderDetail>
    <div class="card">
        <div class="card-body">
            <table class="table dt-responsive">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Description</th>
                        <th>Units</th>
                        <th>Unit Cost</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="!orderDetail || !orderDetail.orderItems; else datatable">
                        <td>No data available in table</td>
                    </tr>
                    <ng-template #datatable>
                        <tr *ngFor="let item of orderDetail.orderItems">
                            <td>{{ item.feeItem }}</td>
                            <td>{{ item.description }}</td>
                            <td>{{ item.unitsAcquired }}</td>
                            <td>${{ item.unitCost }}</td>
                            <td>${{ item.amount }}</td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                            <td colspan="2">Subtotal</td>
                            <td>${{ orderDetail.subTotal }}</td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                            <td colspan="2">GST (5%)</td>
                            <td>${{ orderDetail.gst }}</td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                            <td colspan="2">PST (7%)</td>
                            <td>${{ orderDetail.pst }}</td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                            <td colspan="2" class="fw-bold">Total</td>
                            <td class="fw-bold">${{ orderDetail.total }}</td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                            <td colspan="3" class="fw-bold">Payment Method</td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                            <td colspan="3">{{ orderDetail.paymentMethod }}</td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>