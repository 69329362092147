import { Component, OnInit } from '@angular/core';
import { Analytics } from 'src/app/core/models/dashboard.models';
import { OrderService } from 'src/app/core/services/order.service';

@Component({
  selector: 'app-all-time-orders-card',
  templateUrl: './all-time-orders-card.component.html',
  styleUrls: ['./all-time-orders-card.component.scss']
})
export class AllTimeOrdersCardComponent implements OnInit {

  allTimeOrdersAnalytics!: Analytics;
  
  constructor(private orderService: OrderService) { }

  ngOnInit(): void {
    this.fetchAllTimeOrdersAnalytics();
  }

  private fetchAllTimeOrdersAnalytics() {
    this.orderService.getAllTimeOrdersCount().subscribe({
      next: (v) => {
        // console.log('returned all time orders count : ', v);
        this.allTimeOrdersAnalytics = v;
        if (v.differential >= 1) {
          this.allTimeOrdersAnalytics.classCss = 'badge badge-soft-success me-1';
          this.allTimeOrdersAnalytics.classArrowCss = 'mdi mdi-arrow-up-bold me-1';
        } else if (v.differential < 0) {
          this.allTimeOrdersAnalytics.classCss = 'badge badge-soft-danger me-1';
          this.allTimeOrdersAnalytics.classArrowCss = 'mdi mdi-arrow-down-bold me-1';
        } else {
          this.allTimeOrdersAnalytics.classCss = 'badge badge-soft-warning me-1';
          this.allTimeOrdersAnalytics.classArrowCss = 'mdi mdi-arrow-left-right-bold me-1';
        }
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('all time orders count retrieved');
      }
    });
  }
}
