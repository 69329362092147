import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppUtilities } from 'src/app/app-utilities';
import { Charger, ReserveNow } from 'src/app/core/models/charger.models';
import { ChargerService } from 'src/app/core/services/charger.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-reserve-now-modal',
  templateUrl: './reserve-now-modal.component.html',
  styleUrls: ['./reserve-now-modal.component.scss']
})
export class ReserveNowModalComponent implements OnInit {

  @Input() public charger!: Charger;
  @Input() public isFromList!: boolean;
  @Input() public connectorIdentifier!: number;

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "PAGES.CHARGERS.LIST.RESERVENOW";

  constructor(
    public modal: NgbActiveModal, 
    private chargerService: ChargerService,
    public appUtilities: AppUtilities,
    public sweetalertService: SweetalertService) { }
    public newAction!: {
      chargingStationIdentifier: string, connectorId: number, idTag: string, expiry: string
    }

  ngOnInit(): void {
    let connectorId = 0;
    if (this.connectorIdentifier != undefined) {
      connectorId = this.connectorIdentifier;
    }

    this.newAction = {
      chargingStationIdentifier: this.charger.chargingStationIdentifier,
      connectorId: connectorId,
      idTag: '',
      expiry: this.appUtilities.getCurrentUTCDateTimePlusMinutes(30)
    }
    // console.log(this.newAction);
  }

  
  // Handle Create button click
  onSubmit() {
    let reserveNow: ReserveNow = {
      chargingStationSelectList: [
        {
          chargingStationIdentifier: this.newAction.chargingStationIdentifier,
          endpointAddress: 'ReserveNow',
          ocppTransport: "JSON"
        }
      ],
      connectorId: this.newAction.connectorId,
      idTag: this.newAction.idTag,
      expiry: this.newAction.expiry
    }

    this.processing = true;
    this.chargerService.reserveNow(reserveNow).subscribe({
      next: (v) => {
        // console.log('reserve now request submitted : ', v);
        this.sweetalertService.success(v.message).then(() => {
          this.modal.close(v);
        });
      },
      error: (e) => {
        // console.error('error occured : ', e);
        this.sweetalertService.failure(e).then(() => {
          this.processing = false;
        });
      },
      complete: () => {
        // console.info('chargers saved');
      }
    });
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
