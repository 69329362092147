import { DecimalPipe } from '@angular/common';
import { Component, OnInit, PipeTransform, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationTemplate } from 'src/app/core/models/notification-template.models';
import { NotificationTemplateService } from 'src/app/core/services/notification-template.service';
import { NotificationTemplateModalComponent } from './notification-template-modal/notification-template-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models'; 

/**
 * Table Data Match with Search input
 * @param  NotificationTemplate field value fetch
 * @param term Search the value
 */
function matches(table: NotificationTemplate, term: string, pipe: PipeTransform) {
  return pipe.transform(table.id).includes(term) || table.notificationType.toLowerCase().includes(term.toLowerCase())
      || table.englishSubject.toLowerCase().includes(term)
      || table.frenchSubject.toLowerCase().includes(term)
      || table.englishMessage.toLowerCase().includes(term)
      || table.frenchMessage.toLowerCase().includes(term)
      || table.uuid.toLowerCase().includes(term)
      || table.createdAt.toLowerCase().includes(term)
      || table.updatedAt.toLowerCase().includes(term);
}

@Component({
  selector: 'app-notification-templates',
  templateUrl: './notification-templates.component.html',
  styleUrls: ['./notification-templates.component.scss'],
  providers: [DecimalPipe]
})
export class NotificationTemplatesComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  title!: string;
  searchTerm!: string; 
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  notificationTemplate!: NotificationTemplate;
  notificationTemplates!: NotificationTemplate[];
  allNotificationTemplates!: NotificationTemplate[];

  constructor(private notificationTemplateService: NotificationTemplateService, private pipe: DecimalPipe, public modalService: NgbModal) { }

  ngOnInit(): void {
    this.title = 'PAGES.NOTIFICATIONTEMPLATES.TEXT';
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'MENUITEMS.NOTIFICATIONMANAGEMENT.TEXT' },
      { label: 'PAGES.NOTIFICATIONTEMPLATES.TEXT', active: true }
    ];

    this.fetchData();
  }

  fetchData() {
    this.notificationTemplateService.getNotificationTemplates().subscribe({
      next: (v) => {
        // console.log('returned notificationtemplates : ', v);
        this.collectionSize = v.length;
        this.notificationTemplates = v;
        this.allNotificationTemplates = this.notificationTemplates;
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('notificationtemplates retrieved');
      }
    });
  }

  search(value: string): void {
    this.notificationTemplates = this.allNotificationTemplates.filter(table => matches(table, value, this.pipe));
    this.collectionSize = this.notificationTemplates.length;
  }

  openModal(notificationTemplate: any, isAddNew: boolean) {
    const modalRef = this.modalService.open(NotificationTemplateModalComponent);
    modalRef.componentInstance.notificationTemplate = notificationTemplate;
    modalRef.componentInstance.isAddNew = isAddNew;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  //refresh page
  refreshPage(): void {
    window.location.reload();
  }
}
