import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Charger, ChangeAvailability, SendLocalList } from 'src/app/core/models/charger.models';
import { ChargerService } from 'src/app/core/services/charger.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-send-local-list-modal',
  templateUrl: './send-local-list-modal.component.html',
  styleUrls: ['./send-local-list-modal.component.scss']
})
export class SendLocalListModalComponent implements OnInit {

  @Input() public charger!: Charger;
  @Input() public isFromList!: boolean;
  @Input() public connectorIdentifier!: number;

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "Send Local List";

  constructor(
    public modal: NgbActiveModal, 
    private chargerService: ChargerService,
    public sweetalertService: SweetalertService) { }
    public newAction!: {
      chargingStationIdentifier: string, 
      commaSeparatedAddUpdateList: string, 
      commaSeparatedDeleteList: string, 
      listVersion: number,
      sendEmptyListWhenFull: boolean,
      updateType: string
    }

  ngOnInit(): void {
    let connectorId = 0;
    if (!this.isFromList) {
      connectorId = this.connectorIdentifier;
    }
    this.newAction = {
      chargingStationIdentifier: this.charger.chargingStationIdentifier,
      commaSeparatedAddUpdateList: '',
      commaSeparatedDeleteList: '',
      listVersion: 0,
      sendEmptyListWhenFull: true,
      updateType: ''
    }
  }

  
  // Handle Submit button click
  onSubmit() {
    let sendLocalList: SendLocalList = {
      chargingStationSelectList: [
        {
          chargingStationIdentifier: this.newAction.chargingStationIdentifier,
          endpointAddress: 'SendLocalList',
          ocppTransport: "JSON"
        }
      ],
      addUpdateList: this.newAction.commaSeparatedAddUpdateList.split(','),
      deleteList: this.newAction.commaSeparatedDeleteList.split(','),
      listVersion: this.newAction.listVersion,
      sendEmptyListWhenFull: this.newAction.sendEmptyListWhenFull,
      updateType: this.newAction.updateType
    }

    this.processing = true;
    this.chargerService.sendLocalList(sendLocalList).subscribe({
      next: (v) => {
        console.log('sendLocalList request submitted : ', v);
        this.sweetalertService.success(v.message).then(() => {
          this.modal.close(v);
        });
      },
      error: (e) => {
        console.error('error occured : ', e);
        this.sweetalertService.failure(e).then(() => {
          this.processing = false;
        });
      },
      complete: () => {
        console.info('sendLocalList completed');
      }
    });
  }

  // Handle Cancel button click
  onCancel() {
    this.modal.close();
  }

}
