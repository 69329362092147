import { Component, OnInit, Input } from '@angular/core';
import { AppUtilities } from 'src/app/app-utilities';
import { User } from 'src/app/core/models/user.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModalComponent } from 'src/app/pages/account-management/users/user-modal/user-modal.component';
import { Role } from 'src/app/core/models/role.models';
import { Country } from 'src/app/core/models/country.models';
import { Organization } from 'src/app/core/models/organization.models';
import { ModalMessage } from 'src/app/core/models/modal-message.models';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})

/**
 * User Component
 */
export class UserComponent implements OnInit {

  @Input() users!: User[];
  @Input() roles!: Role[];
  @Input() countries!: Country[];
  @Input() organizations!: Organization[];
  baseUrl!: string;
  defaultAvatar!: string;

  constructor(private appUtilities: AppUtilities, public modalService: NgbModal) { }

  ngOnInit(): void {
    this.baseUrl = this.appUtilities.BASE_URL;
    this.defaultAvatar = 'assets/images/users/avatar-default.jpg'
  }

  openModal(user: any, isAddNew: boolean) {
    const modalRef = this.modalService.open(UserModalComponent);
    modalRef.componentInstance.user = user;
    modalRef.componentInstance.isAddNew = isAddNew;
    modalRef.componentInstance.roles = this.roles;
    modalRef.componentInstance.countries = this.countries;
    modalRef.componentInstance.organizations = this.organizations;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.refreshPage();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  //refresh page
  refreshPage(): void {
    window.location.reload();
  }

}
