import { DecimalPipe } from '@angular/common';
import { Component, OnInit, PipeTransform, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailTemplateModalComponent } from './email-template-modal/email-template-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models';
import { EmailTemplate } from 'src/app/core/models/email-template.models';
import { EmailTemplateService } from 'src/app/core/services/email-template.service';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { KeyValue } from 'src/app/core/models/key-value.models';

/**
 * Table Data Match with Search input
 * @param  EmailTemplate field value fetch
 * @param term Search the value
 */
function matches(table: EmailTemplate, term: string, pipe: PipeTransform) {
  return pipe.transform(table.id).includes(term) || table.notificationType.toLowerCase().includes(term.toLowerCase())
      || table.subject.toLowerCase().includes(term)
      || table.message.toLowerCase().includes(term)
      || table.uuid.toLowerCase().includes(term)
      || table.createdAt.toLowerCase().includes(term)
      || table.updatedAt.toLowerCase().includes(term);
}

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss'],
  providers: [DecimalPipe]
})
export class EmailTemplatesComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  searchTerm!: string; 
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  emailTemplate!: EmailTemplate;
  emailTemplates!: EmailTemplate[];
  allEmailTemplates!: EmailTemplate[];
  notificationTypes!: KeyValue[];

  constructor(
    private emailTemplateService: EmailTemplateService, 
    private pipe: DecimalPipe, 
    public modalService: NgbModal, 
    private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Notification Management' },
      { label: 'Email Templates', active: true } 
    ];

    this.fetchData();
    this.fetchNotificationTypes();
  }

  fetchData() {
    this.emailTemplateService.getEmailTemplates().subscribe({
      next: (v) => {
        console.log('returned notificationtemplates : ', v);
        this.collectionSize = v.length;
        this.emailTemplates = v;
        this.allEmailTemplates = this.emailTemplates;
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('notificationtemplates retrieved');
      }
    });
  }

  fetchNotificationTypes() {
    this.messagingService.getNotificationTypes().subscribe({
      next: (v) => {
        console.log('returned notificationtypes : ', v);
        this.notificationTypes = v;
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('notificationtypes retrieved');
      }
    });
  }

  search(value: string): void {
    this.emailTemplates = this.allEmailTemplates.filter(table => matches(table, value, this.pipe));
    this.collectionSize = this.emailTemplates.length;
  }

  openModal(emailTemplate: any, isAddNew: boolean) {
    const modalRef = this.modalService.open(EmailTemplateModalComponent, {
      size: "xl",
      windowClass: "modal-holder",
      centered: true,
    });
    modalRef.componentInstance.emailTemplate = emailTemplate;
    modalRef.componentInstance.isAddNew = isAddNew;
    modalRef.componentInstance.notificationTypes = this.notificationTypes;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  //refresh page
  refreshPage(): void {
    window.location.reload();
  }
}
