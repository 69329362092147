<div class="modal-header">
    <h5 class="modal-title mt-0">{{ modalTitle }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="name" class="fw-semibold">Recipient Name</label>
            <input type="text" class="form-control" id="recipientName" name="recipientName" 
                readonly [(ngModel)]="smsNotification.recipientName" #recipientName="ngModel">
        </div>
        <div class="form-group mb-3">
            <label for="recipientUuid" class="fw-semibold">Recipient UUID</label>
            <input type="text" class="form-control" id="recipientUuid" name="recipientUuid" 
                readonly [(ngModel)]="smsNotification.recipientUuid" #recipientUuid="ngModel">
        </div>
        <div class="form-group mb-3">
            <label for="notificationType" class="fw-semibold">Notification Type</label>
            <input type="text" class="form-control" id="notificationType" name="notificationType" 
                readonly [(ngModel)]="smsNotification.notificationType" #notificationType="ngModel">
        </div>
        <div class="form-group mb-3">
            <label for="msgId" class="fw-semibold">Message ID</label>
            <input type="text" class="form-control" id="msgId" name="msgId" 
                readonly [(ngModel)]="smsNotification.msgId" #msgId="ngModel">
        </div>
        <div class="form-group mb-3">
            <label for="message" class="fw-semibold">Message</label>
            <textarea id="message" name="message" class="form-control" rows="10" cols="50" 
                readonly [(ngModel)]="smsNotification.message" #message="ngModel"></textarea>
        </div>   
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">Cancel</button>
</div>