<!-- start page title -->
<app-pagetitle [title]="title | translate" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-6">
        <div class="row">
            <div class="col-md-6 col-xl-6">
                <div class="card card-height-100">
                    <div class="card-body">
                        <div class="d-flex flex-wrap align-items-center">
                            <div class="avatar-sm">
                                <span class="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                                    <i class="bx bxs-bolt-circle text-primary"></i>
                                </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h5 class="font-size-15 mb-0 text-truncate">{{ 'PAGES.COMMON.LIST.CHARGERCOUNT' | translate }}</h5>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-3 pt-1">
                            <div>
                                <h4 class="font-size-20">{{ chargerCount }}</h4>
                                <p class="mb-0 text-success fw-semibold font-size-15">{{ 'PAGES.COMMON.LIST.COUNT' | translate }} <i
                                        class="bx bx-trending-up align-middle font-size-18"></i></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-6">
                <div class="card card-height-100">
                    <div class="card-body">
                        <div class="d-flex flex-wrap align-items-center">
                            <div class="avatar-sm">
                                <span class="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                                    <i class="bx bxs-plug text-primary"></i>
                                </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h5 class="font-size-15 mb-0 text-truncate">{{ 'PAGES.COMMON.LIST.CONNECTORCOUNT' | translate }}</h5>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-3 pt-1">
                            <div>
                                <h4 class="font-size-20">{{ connectorCount }}</h4>
                                <p class="mb-0 text-success fw-semibold font-size-15">{{ 'PAGES.COMMON.LIST.AC' | translate }} <i
                                        class="bx bx-trending-up align-middle font-size-18"></i></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-6">
                <div class="card card-height-100">
                    <div class="card-body">
                        <div class="d-flex flex-wrap align-items-center">
                            <div class="avatar-sm">
                                <span class="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                                    <i class="fas fa-battery-three-quarters text-primary"></i>
                                </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h5 class="font-size-15 mb-0 text-truncate">{{ 'PAGES.COMMON.LIST.TRANSACTIONS' | translate }} <span class="text-muted">/
                                        <small>{{ 'PAGES.COMMON.LIST.MONTHTODATE' | translate }}</small></span></h5>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-3 pt-1">
                            <div>
                                <h4 class="font-size-20">50</h4>
                                <p class="mb-0 text-success fw-semibold font-size-15">0.0000 {{ 'PAGES.COMMON.LIST.KWHCONSUMED' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-6">
                <div class="card card-height-100">
                    <div class="card-body">
                        <div class="d-flex flex-wrap align-items-center">
                            <div class="avatar-sm">
                                <span class="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                                    <i class="fas fa-plus text-primary"></i>
                                </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h5 class="font-size-15 mb-0 text-truncate">New <span class="text-muted">/ <small>{{ 'PAGES.COMMON.LIST.MONTHTODATE' | translate }}</small></span></h5>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-3 pt-1">
                            <div>
                                <h4 class="font-size-20">3</h4>
                                <p class="mb-0 text-success fw-semibold font-size-15">+1.5% <i
                                        class="bx bx-trending-up align-middle font-size-18"></i></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">{{ site?.name }}</h4>
                <p class="card-title-desc">{{ address }}</p>
                <div *ngIf="apiLoaded">
                    <google-map width="100%" height="237px" [center]="center" [zoom]="zoom">
                        <map-marker [position]="markerPosition" [options]="markerOptions"></map-marker>
                    </google-map>
                </div>
            </div>
        </div>
    </div> <!-- end col -->
</div> <!-- end row -->

<div class="row" *ngIf="site">
    <div class="card m-2 p-4">
        <h5>{{ 'PAGES.SITES.LIST.SITEIMAGES' | translate }}</h5>
        <site-images [siteUuid]="site.uuid" [imageUuids]="site.siteImageUuidList"
            (refetchSiteData)="fetchData()"></site-images>
    </div>
</div>

<!-- Start Tabs & Accordions -->
<div class="row">
    <div class="col-lg-12">
        <div class="card" id="tabs-accordions">
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="mt-4">
                            <ul ngbNav #nav3="ngbNav" [activeId]="1" class="nav-tabs nav-tabs-custom nav-justified">
                                <li [ngbNavItem]="1">
                                    <a ngbNavLink>
                                        <span class="d-block d-sm-none"><i class="bx bx-detail"></i></span>
                                        <span class="d-none d-sm-block">{{ 'PAGES.COMMON.LIST.DETAILS' | translate }}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <ng-template [ngTemplateOutlet]="DetailsTabContent"></ng-template>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="2">
                                    <a ngbNavLink>
                                        <span class="d-block d-sm-none"><i
                                                class="fas fa-battery-three-quarters"></i></span>
                                        <span class="d-none d-sm-block">{{ 'PAGES.COMMON.LIST.RATES' | translate }}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <ng-template [ngTemplateOutlet]="RatesTabContent"></ng-template>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="3">
                                    <a ngbNavLink>
                                        <span class="d-block d-sm-none"><i
                                                class="fas fa-battery-three-quarters"></i></span>
                                        <span class="d-none d-sm-block">{{ 'MENUITEMS.CHARGERS.TEXT' | translate }}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <ng-template [ngTemplateOutlet]="ChargersTabContent"></ng-template>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="nav3" class="text-muted"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #DetailsTabContent>
    <div class="row mt-5">
        <div class="col-md-12 col-xl-6">
            <div class="card card-height-100">
                <div class="card-body">
                    <h4 class="card-title mb-4">General</h4>

                    <div>
                        <div class="d-flex justify-content-between align-items-center border-bottom pb-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.NAME' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ site?.name }}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.DESCRIPTION' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ site?.description}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.ADDRESS' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ site?.address?.addressLine1}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.CITY' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ site?.address?.city}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.REGION' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ site?.address?.region}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.COUNTRY' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ site?.address?.country}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.EMAIL' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ site?.email}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.PHONE' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ site?.phoneNumber}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.SITES.LIST.OPENINGTIME' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ site?.openTime}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center pt-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.SITES.LIST.CLOSINGTIME' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ site?.closeTime }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-xl-6">
            <div class="card card-height-100">
                <div class="card-body">
                    <h4 class="card-title mb-4">{{ 'PAGES.COMMON.LIST.OWNEDBY' | translate }}</h4>

                    <div>
                        <div class="d-flex justify-content-between align-items-center border-bottom pb-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.NAME' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ organization?.name }}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.ADDRESS' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ organization?.address?.addressLine1}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.CITY' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ organization?.address?.city}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.REGION' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ organization?.address?.region}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.COUNTRY' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ organization?.address?.country}}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.WEBSITE' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ organization?.website }}</h5>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div class="d-flex">
                                <div class="flex-grow-1 ms-2">
                                    <p class="mb-0">{{ 'PAGES.COMMON.LIST.PHONE' | translate }}</p>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-0 font-size-14">{{ organization?.phoneNumber}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #RatesTabContent>
    <app-site-rate [rates]="site.rates"></app-site-rate>
</ng-template>

<ng-template #ChargersTabContent>
    <div class="row mt-5">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <app-list-chargers [site]="site"></app-list-chargers>
                </div>
            </div>
        </div>
    </div>
</ng-template>