import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
    HttpClientModule,
    HTTP_INTERCEPTORS,
    HttpClient,
} from "@angular/common/http";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { ErrorInterceptor } from "./core/helpers/error.interceptor";
import { JwtInterceptor } from "./core/helpers/jwt.interceptor";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
    NgbModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbNavModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { LayoutsModule } from "./layouts/layouts.module";
import { PagesModule } from "./pages/pages.module";

import { AppUtilities } from "../app/app-utilities";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";

import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { userReducer } from "./store/user/reducer";
import { UserEffects } from "./store/user/effects";
import { GoogleMapsModule } from "@angular/google-maps";
import { Loader } from "@googlemaps/js-api-loader";

export function createTranslateLoader(http: HttpClient): any {
    return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        TranslateModule.forRoot({
            defaultLanguage: "en",
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }), 
        HttpClientModule,
        BrowserModule,
        NgxDatatableModule,
        AppRoutingModule,
        PagesModule,
        NgbModule,
        NgbTooltipModule,
        NgbPopoverModule,
        NgbNavModule,
        LayoutsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        EditorModule,
        GoogleMapsModule,
        StoreModule.forRoot({ user: userReducer }),
        EffectsModule.forRoot([UserEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: true,
        }),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" },
        // {
        //     provide: Loader,
        //     useValue: new Loader({
        //       apiKey: 'AIzaSyDZ_as4Pf7I381zgGg08siES7Y5seMc3VU',
        //       libraries: ['places']
        //     })
        // },
        AppUtilities,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
