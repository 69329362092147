import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { SharedModule } from "src/app/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BillingManagementRoutingModule } from "./billing-management-routing.module";
import { WalletsComponent } from "./wallets/wallets.component";
import { WalletModalComponent } from "./wallets/wallet-modal/wallet-modal.component";
import { ProductModalComponent } from "./products/product-modal/product-modal.component";
import { ProductsComponent } from "./products/products.component";
import { OrdersComponent } from "./orders/orders.component";
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { TestMakePaymentComponent } from './orders/test-make-payment/test-make-payment.component';
import { TestReviewPaymentComponent } from './orders/test-review-payment/test-review-payment.component';
import { RefundsComponent } from './refunds/refunds.component';

@NgModule({
  declarations: [
    WalletsComponent,
    WalletModalComponent,
    ProductsComponent,
    ProductModalComponent,
    OrdersComponent,
    OrderDetailsComponent,
    TestMakePaymentComponent,
    TestReviewPaymentComponent,
    RefundsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BillingManagementRoutingModule,
  ],
})
export class BillingManagementModule {}
