import { DecimalPipe } from '@angular/common';
import { Component, OnInit, PipeTransform, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SmsTemplate } from 'src/app/core/models/sms-template.models';
import { SmsTemplateService } from 'src/app/core/services/sms-template.service';
import { SmsTemplateModalComponent } from './sms-template-modal/sms-template-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models';

/**
 * Table Data Match with Search input
 * @param  SmsTemplate field value fetch
 * @param term Search the value
 */
function matches(table: SmsTemplate, term: string, pipe: PipeTransform) {
  return pipe.transform(table.id).includes(term) || table.notificationType.toLowerCase().includes(term.toLowerCase())
      || table.englishMessage.toLowerCase().includes(term)
      || table.frenchMessage.toLowerCase().includes(term)
      || table.uuid.toLowerCase().includes(term)
      || table.createdAt.toLowerCase().includes(term)
      || table.updatedAt.toLowerCase().includes(term);
}

@Component({
  selector: 'app-sms-templates',
  templateUrl: './sms-templates.component.html',
  styleUrls: ['./sms-templates.component.scss'],
  providers: [DecimalPipe]
})
export class SmsTemplatesComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  title!: string;
  searchTerm!: string; 
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  smsTemplate!: SmsTemplate;
  smsTemplates!: SmsTemplate[];
  allSmsTemplates!: SmsTemplate[];

  constructor(private smsTemplateService: SmsTemplateService, private pipe: DecimalPipe, public modalService: NgbModal) { }

  ngOnInit(): void {
    this.title = 'PAGES.SMSTEMPLATES.TEXT';
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'MENUITEMS.NOTIFICATIONMANAGEMENT.TEXT' },
      { label: 'PAGES.SMSTEMPLATES.TEXT', active: true }
    ];


    this.fetchData();
  }

  fetchData() {
    this.smsTemplateService.getSmsTemplates().subscribe({
      next: (v) => {
        // console.log('returned smstemplates : ', v);
        this.collectionSize = v.length;
        this.smsTemplates = v;
        this.allSmsTemplates = this.smsTemplates;
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('smstemplates retrieved');
      }
    });
  }

  search(value: string): void {
    this.smsTemplates = this.allSmsTemplates.filter(table => matches(table, value, this.pipe));
    this.collectionSize = this.smsTemplates.length;
  }

  openModal(smsTemplate: any, isAddNew: boolean) {
    const modalRef = this.modalService.open(SmsTemplateModalComponent);
    modalRef.componentInstance.smsTemplate = smsTemplate;
    modalRef.componentInstance.isAddNew = isAddNew;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  //refresh page
  refreshPage(): void {
    window.location.reload();
  }
}
