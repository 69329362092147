<div class="card card-height-100">
    <div class="card-body">
        <div class="float-end">
            <div class="dropdown" ngbDropdown>
                <a class="btn btn-light dropdown-toggle text-reset" href="javascript:void(0);"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    ngbDropdownToggle>
                    <i class="mdi mdi-dots-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <!-- item-->
                    <a class="dropdown-item" routerLink="/billing-management/orders">{{ 'PAGES.COMMON.LIST.SEEALL' | translate }}</a>
                    <!-- item-->
                </div>
            </div>
        </div>
        <h4 class="card-title mb-4">{{ 'PAGES.DASHBOARD.LIST.LATESTORDERS' | translate }}</h4>
        <div class="table-responsive">
            <app-order [orders]="orders"></app-order>
        </div>
    </div>
</div>