import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { PagedSite, Site } from '../models/site.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';
import { GenericFilter } from '../models/generic-filter.models';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getChargingStationManagementUrl();
  }

  /**
     * Get all sites
     */
  getSites(params: GenericFilter): Observable<PagedSite> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedSite>(`${this.backendApiUrl}/sites?${queryString}`, this.httpOptions).pipe(
      tap((pagedSite: PagedSite) => {
        this.log(`Retrieved sites`);
      })
    );
  }

  /**
     * Get site
     */
  getSite(params: string): Observable<Site> {
    return this.http.get<Site>(`${this.backendApiUrl}/sites/view/${params}`, this.httpOptions).pipe(
      tap((site: Site) => {
        this.log(`Retrieved site`);
      })
    );
  }

  /**
     * Add site
     */
  addSite(params: any): Observable<GenericResponse> {
    console.log('request : ', params);

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/sites/add`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added site`);
      })
    );
  }

  /**
     * Update site
     */
  updateSite(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/sites/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated site`);
      })
    );
  }

  /** Log a SiteService message */
  private log(message: string) {
    console.log(`SiteService: ${message}`);
  }
}
