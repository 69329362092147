import { Component, OnInit } from "@angular/core";
import { Column } from "src/app/core/models/datatable.models";
import { GenericFilter } from "src/app/core/models/generic-filter.models";
import { Order, PagedOrders } from "src/app/core/models/order.models";
import { OrderService } from "src/app/core/services/order.service";

@Component({
    selector: "app-orders",
    templateUrl: "./orders.component.html",
    styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;
    title!: string;
    tableTitle!: string;
    collectionSize!: number;
    orders!: Order[];
    pagedOrders!: PagedOrders;

    columns: Column[] = [
        { key: "reference", name: "PAGES.COMMON.LIST.REFERENCE", isSearchTerm: true },
        { key: "customerName", name: "PAGES.COMMON.LIST.CUSTOMER", isSearchTerm: true },
        { key: "description", name: "PAGES.COMMON.LIST.DESCRIPTION" },
        { key: "details", name: "PAGES.COMMON.LIST.DETAILS" },
        { key: "notes", name: "PAGES.COMMON.LIST.NOTES" },
        { key: "evse", name: "EVSE", isSearchTerm: true },
        // { key: "siteAddress", name: "Site Address" },
        { key: "energyFees", name: "PAGES.ORDERS.LIST.ENERGYFEES", prefix: "$" },
        { key: "nonEnergyFees", name: "PAGES.ORDERS.LIST.NONENERGYFEES", prefix: "$" },
        { key: "subTotal", name: "PAGES.ORDERS.LIST.SUBTOTAL", prefix: "$" },
        { key: "pst", name: "PST (7%)", prefix: "$" },
        { key: "gst", name: "GST (5%)", prefix: "$" },
        { key: "total", name: "PAGES.ORDERS.LIST.TOTAL", prefix: "$" },
        { key: "paymentStatus", name: "PAGES.ORDERS.LIST.PAYMENTSTATUS" },
        { key: "updatedAt", name: "PAGES.COMMON.LIST.LASTUPDATED" }
    ];

    constructor(private orderService: OrderService) {}

    ngOnInit(): void {
        this.title = 'PAGES.ORDERS.TEXT';
        this.tableTitle = 'PAGES.ORDERS.LIST.LISTTITLE';

        //BreadCrumb
        this.breadCrumbItems = [
            { label: 'MENUITEMS.BILLINGMANAGEMENT.TEXT' },
            { label: "PAGES.ORDERS.TEXT", active: true },
        ];
    }

    fetchData(filter: GenericFilter) {
        this.orderService.getOrders(filter).subscribe({
            next: (v) => {
                // console.log("returned orders : ", v);
                this.pagedOrders = v;
                this.collectionSize = this.pagedOrders.totalElements;
                this.orders = this.pagedOrders.content;
            },
            error: (e) => {
                // console.error(e);
            },
            complete: () => {
                // console.info("orders retrieved");
            },
        });
    }
}
