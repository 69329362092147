import { ChargingProfile } from "./charging-profile.models";
import { Site } from "./site.models";

export interface Charger {
  id: number;
  chargingStationIdentifier: string;
  name: string;
  serialNumber: string;
  model: string;
  vendor: string;
  chargerType: string;
  description: string;
  availabilityStatus: string;
  diagnosticsStatus: string;
  diagnosticsTimestamp: string;
  firmwareVersion: string;
  firmwareLastUpdate: string;
  firmwareStatus: string;
  iccid: string;
  imsi: string;
  lastHearbeatTimestamp: string;
  locationLattitude: string;
  locationLongitude: string;
  meterSerialNumber: string;
  meterType: string;
  registrationStatus: string;
  ocppProtocol: string;
  site: Site;
  siteOwnerUuid: string;
  connectors: Connector[];
  uuid: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  deleted: boolean;
  deletedAt: string;
}


export interface Connector {
  id: number;
  connectorIdentifier: number;
  chargePointStatus: string;
  chargerPlugType: string;
  chargingProfiles: ChargingProfile[];
  enabled: boolean;
}

export interface ConnectorProfile {
  connectorIdentifier: number;
  chargingProfiles: ChargingProfile[];
  searchTerm: string;
  empty: boolean;
}

export interface AddCharger {
  name: string;
  serialNumber: string;
  model: string;
  vendor: string;
  chargerType: string;
  description: string;
  iccid: string;
  imsi: string;
  locationLattitude: string;
  locationLongitude: string;
  meterSerialNumber: string;
  connectorCount: number;
  connectors: Connector[];
  registrationStatus: string;
  siteUuid: string;
  enabled: boolean;
}

export interface ChargingStationSelect {
  chargingStationIdentifier: string;
  endpointAddress: string;
  ocppTransport: string;
}

export interface CancelReservation {
  chargingStationSelectList: ChargingStationSelect[];
  reservationId: number;
}

export interface ChangeAvailability {
  chargingStationSelectList: ChargingStationSelect[];
  connectorId: number;
  availType: string;
}

export interface ChangeConfiguration {
  chargingStationSelectList: ChargingStationSelect[];
  confKey: string;
  customConfKey: string;
  keyType: string;
  value: string;
}

export interface ClearCache {
  chargingStationSelectList: ChargingStationSelect[];
}

export interface ClearChargingProfile {
  chargingStationSelectList: ChargingStationSelect[];
  chargingProfilePurpose: string;
  chargingProfileId: number;
  connectorId: number;
  filterType: string;
  stackLevel: number;
}

export interface DataTransfer {
  chargingStationSelectList: ChargingStationSelect[];
  data: string;
  messageId: string;
  vendorId: string;
}

export interface GetCompositeSchedule {
  chargingStationSelectList: ChargingStationSelect[];
  chargingRateUnit: string;
  connectorId: number;
  durationInSeconds: number;
}

export interface GetConfiguration {
  chargingStationSelectList: ChargingStationSelect[];
  commaSeparatedCustomConfKeys: string;
  confKeyList: string[];
}

export interface GetDiagnostics {
  chargingStationSelectList: ChargingStationSelect[];
  location: string;
  retries: number;
  retryInterval: number;
  start: string;
  stop: string;
}

export interface GetLocalListVersion {
  chargingStationSelectList: ChargingStationSelect[];
}

export interface ReserveNow {
  chargingStationSelectList: ChargingStationSelect[];
  connectorId: number;
  idTag: string;
  expiry: string;
}

export interface Reset {
  chargingStationSelectList: ChargingStationSelect[];
  connectorId: number;
  resetType: string;
}

export interface SendLocalList {
  chargingStationSelectList: ChargingStationSelect[];
  addUpdateList: string[];
  deleteList: string[];
  listVersion: number;
  sendEmptyListWhenFull: boolean;
  updateType: string;
}

export interface StartTransaction {
  chargingStationSelectList: ChargingStationSelect[];
  connectorId: number;
  idTag: string;
}

export interface StartTransactionAnonymous {
  chargingStationIdentifier: string;
  connectorId: number;
  customerName: string;
  customerUuid: string|undefined;
  email: string;
  idTag: string|undefined;
}

export interface SetChargingProfile {
  chargingStationSelectList: ChargingStationSelect[];
  connectorId: number;
  chargingProfileUuid: string;
}

export interface StopTransaction {
  chargingStationSelectList: ChargingStationSelect[];
  transactionId: number;
}

export interface TriggerMessage {
  chargingStationSelectList: ChargingStationSelect[];
  connectorId: number;
  triggerMessage: string;
}

export interface UnlockConnector {
  chargingStationSelectList: ChargingStationSelect[];
  connectorId: number;
}

export interface UpdateFirmware {
  chargingStationSelectList: ChargingStationSelect[];
  location: string;
  retries: number;
  retrieve: string;
  retryInterval: number;
}

export interface PagedCharger {
  content: Charger[];
  first: boolean;
  last: boolean;
  empty: boolean;
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
  size: number;
  number: number;
}