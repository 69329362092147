<div class="card card-height-100">
    <div class="card-body">
        <div class="float-end">
            <ul class="list-inline mb-0">
                <li class="list-inline-item mr-2">
                    <div class="dropdown d-inline-block" ngbDropdown>
                        <a class="btn btn-light dropdown-toggle text-reset" href="javascript:void(0);"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            ngbDropdownToggle>
                            <i class="mdi mdi-dots-vertical"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <!-- item-->
                            <a class="dropdown-item" routerLink="/account-management/users">{{ 'PAGES.COMMON.LIST.SEEALL' | translate }}</a>
                            <!-- item-->
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <h4 class="card-title mb-4">{{ 'PAGES.DASHBOARD.LIST.RECENTUSERS' | translate }}</h4>
        <div class="table-responsive">
            <app-user [users]="users" [roles]="roles" [countries]="countries" [organizations]="organizations"></app-user>
        </div>
        <!--end table-responsive-->
    </div>
</div>