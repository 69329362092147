import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppUtilities } from "src/app/app-utilities";
import { PagedSite, Site } from "../models/site.models";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { GenericResponse } from "../models/generic-response.models";
import { GenericFilter } from "../models/generic-filter.models";

@Injectable({
    providedIn: "root",
})
export class SiteService {
    backendApiUrl = "";
    httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getChargingStationManagementUrl();
  }
  
    /**
     * Get all sites
     */
    getSites(params: GenericFilter): Observable<PagedSite> {
        let queryString = Object.keys(params)
            .map((key) => key + "=" + params[key as keyof GenericFilter])
            .join("&");
        return this.http
            .get<PagedSite>(
                `${this.backendApiUrl}/sites?${queryString}`,
                this.httpOptions
            )
            .pipe(
                tap((pagedSite: PagedSite) => {
                    this.log(`Retrieved sites`);
                })
            );
    }

    /**
     * Get site
     */
    getSite(params: string): Observable<Site> {
        return this.http
            .get<Site>(
                `${this.backendApiUrl}/sites/view/${params}`,
                this.httpOptions
            )
            .pipe(
                tap((site: Site) => {
                    this.log(`Retrieved site`);
                })
            );
    }

    /**
     * Add site
     */
    addSite(params: any): Observable<GenericResponse> {
        // console.log("request : ", params);

        return this.http
            .post<GenericResponse>(
                `${this.backendApiUrl}/sites/add`,
                params,
                this.httpOptions
            )
            .pipe(
                tap((res: GenericResponse) => {
                    this.log(`Added site`);
                })
            );
    }

    /**
     * Update site
     */
    updateSite(params: any, uuid: string): Observable<GenericResponse> {
        return this.http
            .put<GenericResponse>(
                `${this.backendApiUrl}/sites/${uuid}`,
                params,
                this.httpOptions
            )
            .pipe(
                tap((res: GenericResponse) => {
                    this.log(`Updated site`);
                })
            );
    }

    getImage(imageUuid: string): Observable<Uint8Array> {
        return this.http
            .get(`${this.backendApiUrl}/sites/images/${imageUuid}`, {
                responseType: "arraybuffer",
            })
            .pipe(
                tap(() => {
                    this.log(`Retrieved image with UUID: ${imageUuid}`);
                }),
                map((data: ArrayBuffer) => new Uint8Array(data))
            );
    }

    deleteImage(imageUuid: string): Observable<void> {
        return this.http
            .delete<void>(
                `${this.backendApiUrl}/sites/images/${imageUuid}`,
                this.httpOptions
            )
            .pipe(
                tap(() => {
                    this.log(`Deleted image with UUID: ${imageUuid}`);
                })
            );
    }

    uploadImage(
        siteUuid: string,
        image: File
    ): Observable<{ success: boolean; message: string }> {
        const formData: FormData = new FormData();
        formData.append("image", image, image.name);

        const uploadHttpOptions = {
            headers: new HttpHeaders({
                // When using FormData, Content-Type should not be specified as it is set automatically
            }),
        };

        return this.http
            .post<{ success: boolean; message: string }>(
                `${this.backendApiUrl}/sites/images/${siteUuid}`,
                formData,
                uploadHttpOptions
            )
            .pipe(
                tap((response) => {
                    this.log(
                        `Uploaded image for site UUID: ${siteUuid}, Response: ${JSON.stringify(
                            response
                        )}`
                    );
                })
            );
    }

    /** Log a SiteService message */
    private log(message: string) {
        // console.log(`SiteService: ${message}`);
    }
}
