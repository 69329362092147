export interface WalletHistory {
  id: number;
  description: string;
  amount: number;
  balance: number;
  currency: string;
  transactionType: string;
  accountUuid: string;
  reference: string;
  uuid: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  deleted: boolean;
  deletedAt: string;
}

export interface PagedWalletHistories {
  content: WalletHistory[];
  first: boolean;
  last: boolean;
  empty: boolean;
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
  size: number;
  number: number;
}