import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit, PipeTransform } from '@angular/core';
import { Rate } from 'src/app/core/models/rate.models';

/**
 * Table Data Match with Search input
 * @param  Rate field value fetch
 * @param term Search the value
 */
function matches(table: Rate, term: string, pipe: PipeTransform) {
  return pipe.transform(table.id).includes(term) || table.rateId.chargerType.toLowerCase().includes(term.toLowerCase())
      || table.uuid.toLowerCase().includes(term)
      || table.createdAt.toLowerCase().includes(term)
      || table.updatedAt.toLowerCase().includes(term);
}

@Component({
  selector: 'app-site-rate',
  templateUrl: './site-rate.component.html',
  styleUrls: ['./site-rate.component.scss'],
  providers: [DecimalPipe]
})
export class SiteRateComponent implements OnInit {

  @Input() rates!: Rate[];

  searchTerm!: string; 
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  rate!: Rate;
  allRates!: Rate[];

  constructor(private pipe: DecimalPipe) { }

  ngOnInit(): void {
  }

  search(value: string): void {
    this.rates = this.allRates.filter(table => matches(table, value, this.pipe));

    // this.permissions = this.allPermissions.filter((val) => val.name.toLowerCase().includes(value));
    this.collectionSize = this.rates.length;
  }

}
