<div class="row mt-5">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex mb-4">
                    <h4 class="card-title flex-grow-1">{{ charger.chargingStationIdentifier }} {{ 'PAGES.CHARGERS.LIST.RESERVATIONS' | translate }}</h4>
                </div>
                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6">
                        <div class="dataTables_length" id="tickets-table_length"><label
                                class="d-inline-flex align-items-center">{{ 'PAGES.COMMON.LIST.SHOW' | translate }}
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="pageSize" class="form-control form-control-sm mx-2"
                                    (change)="onSizeChange(pageSize)">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select> {{ 'PAGES.COMMON.LIST.ENTRIES' | translate }}</label></div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">
                                {{ 'PAGES.COMMON.LIST.SEARCH' | translate }}:
                                <input type="text" name="searchTerm" id="searchTerm"
                                    class="form-control form-control-sm ms-2" aria-controls="tickets-table"
                                    [(ngModel)]="searchTerm" (input)="search(searchTerm)">
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>

                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable"
                        class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th>{{ 'PAGES.COMMON.LIST.IDTAG' | translate }}</th>
                                <th>{{ 'PAGES.COMMON.LIST.CONNECTOR' | translate }}</th>
                                <th>{{ 'PAGES.COMMON.LIST.STARTDATE' | translate }}</th>
                                <th>{{ 'PAGES.COMMON.LIST.EXPIRYDATE' | translate }}</th>
                                <th>{{ 'PAGES.COMMON.LIST.STATUS' | translate }}</th>
                                <th>{{ 'PAGES.COMMON.LIST.LASTUPDATED' | translate }}</th>
                                <th>{{ 'PAGES.COMMON.LIST.ACTIONS' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [hidden]="!empty">
                                <td colspan="7">{{ 'PAGES.COMMON.LIST.NODATAAVAILABLE' | translate }}</td>
                            </tr>
                            <tr *ngFor="let reservation of reservations" [hidden]="pagedReservations.empty">
                                <td>
                                    <ngb-highlight [result]="reservation.idTag" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    {{ reservation?.connector?.connectorIdentifier }}
                                </td>
                                <td>
                                    {{ reservation?.startDateTime }}
                                </td>
                                <td>
                                    {{ reservation?.expiryDateTime }}
                                </td>
                                <td>
                                    {{ reservation?.reservationStatus }}
                                </td>
                                <td>
                                    {{ reservation?.updatedAt }}
                                </td>
                                <td>
                                    <ngb-highlight [result]="reservation.updatedAt" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <div class="d-flex flex-wrap gap-2">
                                        <button type="button" class="btn btn-light" [title]="'PAGES.CHARGERS.LIST.STARTTRANSACTION' | translate"
                                            (click)="cancelReservation(reservation)">
                                            <i class=" bx bx-stop-circle"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- End table -->
                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            {{ 'PAGES.COMMON.LIST.SHOWINGENTRIES' | translate }}
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-right float-md-end pagination-rounded">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [maxSize]="5"
                                [boundaryLinks]="true" [pageSize]="pageSize" (pageChange)="pageChanged($event)"
                                class="d-flex justify-content-center">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- End Pagination -->
                </div>
            </div>
        </div>
    </div>
</div>