import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddConfiguration } from 'src/app/core/models/configuration.models';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-configuration-modal',
  templateUrl: './configuration-modal.component.html',
  styleUrl: './configuration-modal.component.scss'
})
export class ConfigurationModalComponent implements OnInit {

  @Input() public configuration!: AddConfiguration;
  @Input() public isAddNew!: boolean;

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "PAGES.CONFIGURATIONS.LIST.EDIT";

  constructor(
    public modal: NgbActiveModal, 
    private configurationService: ConfigurationService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    if (this.configuration == undefined) {
      this.configuration = {
        name: "",
        value: "",
        encrypt: false,
        uuid: "",
        enabled: false
      };

      this.title = "PAGES.CONFIGURATIONS.LIST.ADD";
    }
  }

  
  // Handle Create button click
  onSubmit() {
    // console.log('create clicked');
    this.processing = true;
    if (this.isAddNew) {
      // Add a new configuration
      this.configurationService.addConfiguration(this.configuration).subscribe({
        next: (v) => {
          // console.log('added configuration : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          // console.info('configurations saved');
        }
      });
    } else {
      // Update an existing configuration
      this.configurationService.updateConfiguration(this.configuration, this.configuration.uuid).subscribe({
        next: (v) => {
          // console.log('updated configuration : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });  
        },
        complete: () => {
          // console.info('configurations saved');
        }
      });
    }
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
