import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { SharedModule } from "src/app/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { EditorModule } from "@tinymce/tinymce-angular";
import { NotificationManagementRoutingModule } from "./notification-management-routing.module";
import { NotificationTemplatesComponent } from "./notification-templates/notification-templates.component";
import { NotificationTemplateModalComponent } from './notification-templates/notification-template-modal/notification-template-modal.component';
import { PushNotificationsComponent } from './push-notifications/push-notifications.component';
import { PushNotificationModalComponent } from './push-notifications/push-notification-modal/push-notification-modal.component';
import { SmsTemplatesComponent } from './sms-templates/sms-templates.component';
import { SmsNotificationsComponent } from './sms-notifications/sms-notifications.component';
import { SmsTemplateModalComponent } from './sms-templates/sms-template-modal/sms-template-modal.component';
import { SmsNotificationModalComponent } from './sms-notifications/sms-notification-modal/sms-notification-modal.component';
import { EmailTemplatesComponent } from './email-templates/email-templates.component';
import { EmailTemplateModalComponent } from "./email-templates/email-template-modal/email-template-modal.component";




@NgModule({
  declarations: [
    NotificationTemplatesComponent,
    NotificationTemplateModalComponent,
    PushNotificationsComponent,
    PushNotificationModalComponent,
    SmsTemplatesComponent,
    SmsNotificationsComponent,
    SmsTemplateModalComponent,
    SmsNotificationModalComponent,
    EmailTemplatesComponent,
    EmailTemplateModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    EditorModule,
    NotificationManagementRoutingModule
  ]
})
export class NotificationManagementModule { }
