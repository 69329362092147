import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppUtilities } from "src/app/app-utilities";
import { SiteService } from "src/app/core/services/site.service";

@Component({
    selector: "site-images",
    templateUrl: "./site-images.component.html",
    styleUrls: ["./site-images.component.scss"],
})
export class SiteImagesComponent implements OnInit {
    @Input() siteUuid: string = "";
    @Input() imageUuids: string[] = [];
    @Output() refetchSiteData: EventEmitter<void> = new EventEmitter<void>();
    images: { uuid: string; data: Uint8Array; hovered: boolean }[] = [];
    maxImages = 3;
    selectedImage: { uuid: string; data: Uint8Array } | null = null;

    constructor(
        private siteService: SiteService,
        public appUtilities: AppUtilities
    ) {}

    ngOnInit(): void {}

    ngOnChanges(): void {
        this.loadImages();
    }

    selectImage(image: { uuid: string; data: Uint8Array }): void {
        this.selectedImage = image;
    }

    loadImages(): void {
        this.images = [];
        this.imageUuids.forEach((uuid) => {
            this.siteService.getImage(uuid).subscribe((data) => {
                this.images.push({ uuid, data, hovered: false });
                this.selectedImage = this.images[0];
            });
        });
    }

    onUpload(event: any): void {
        // console.log(event);
        const file = event.target.files[0];
        if (file) {
            this.siteService
                .uploadImage(this.siteUuid, file)
                .subscribe((response) => {
                    if (response) {
                        this.refetchSiteData.emit();
                    }
                });
        }
    }

    onDelete(imageUuid: string): void {
        this.siteService.deleteImage(imageUuid).subscribe(() => {
            this.images = this.images.filter((img) => img.uuid !== imageUuid);
        });
    }
}
