import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { KeyValue } from '../models/key-value.models';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getUserManagementUrl();
  }

  /**
     * Get all notification types
     */
  getNotificationTypes(): Observable<KeyValue[]> {
    return this.http.get<KeyValue[]>(`${this.backendApiUrl}/messaging/notification-types`, this.httpOptions).pipe(
      tap((notificationTypes: KeyValue[]) => {
        this.log(`Retrieved notification types`);
      })
    );
  }

  /** Log a MessagingService object */
  private log(message: string) {
    console.log(`MessagingService: ${message}`);
  }
}
