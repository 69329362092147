import { Injectable } from "@angular/core";
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { AuthService } from "src/app/core/services/authentication.service";
import { UserState } from "src/app/store/user/reducer";
import * as UserActions from "src/app/store/user/actions";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private store: Store<{ user: UserState }>
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = this.authService.getToken();
        if (token && token.length > 0) {
            this.store.dispatch(UserActions.loadUser());

            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.authService.logout();
        this.router.navigate(["/account/login"], {
            queryParams: { returnUrl: state.url },
        });
        return false;
    }
}
