import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Charger, GetCompositeSchedule } from 'src/app/core/models/charger.models';
import { ChargerService } from 'src/app/core/services/charger.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-get-composite-schedule-modal',
  templateUrl: './get-composite-schedule-modal.component.html',
  styleUrls: ['./get-composite-schedule-modal.component.scss']
})
export class GetCompositeScheduleModalComponent implements OnInit {

  @Input() public charger!: Charger;
  @Input() public isFromList!: boolean;
  @Input() public connectorIdentifier!: number;

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "Get Composite Schedule";

  constructor(
    public modal: NgbActiveModal, 
    private chargerService: ChargerService,
    public sweetalertService: SweetalertService) { }
    public newAction!: {
      chargingStationIdentifier: string, chargingRateUnit: string, connectorId: number, durationInSeconds: number
    }

    ngOnInit(): void {
      let connectorId = 0;
      if (!this.isFromList) {
        connectorId = this.connectorIdentifier;
      }
      this.newAction = {
        chargingStationIdentifier: this.charger.chargingStationIdentifier,
        chargingRateUnit: '',
        connectorId: connectorId,
        durationInSeconds: 0
      }
    }
  
    
    // Handle Create button click
    onSubmit() {
      let getCompositeSchedule: GetCompositeSchedule = {
        chargingStationSelectList: [
          {
            chargingStationIdentifier: this.newAction.chargingStationIdentifier,
            endpointAddress: 'ChangeAvailability',
            ocppTransport: "JSON"
          }
        ],
        chargingRateUnit: this.newAction.chargingRateUnit,
        connectorId: this.newAction.connectorId,
        durationInSeconds: this.newAction.durationInSeconds
      }
  
      this.processing = true;
      this.chargerService.getCompositeSchedule(getCompositeSchedule).subscribe({
        next: (v) => {
          console.log('getCompositeSchedule request submitted : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          console.info('getCompositeSchedule completed');
        }
      });
    }
  
    // Handle Delete button click
    onCancel() {
      this.modal.close();
    }

}
