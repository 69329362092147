<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title | translate}}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3"> 
                    <label for="siteUuid" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.SITE' | translate }}</label>
                    <select name="siteUuid" id="siteUuid" class="form-select"
                            [(ngModel)]="addCharger.siteUuid" #siteUuid="ngModel" >
                        <option *ngFor="let s of sites" [ngValue]="s.uuid">{{ s.name }}</option>
                    </select>
                    <span style="color:red" [hidden]="addCharger.siteUuid || siteUuid.pristine"> 
                        <p>{{ 'PAGES.CHARGERS.LIST.SITEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="chargingStationIdentifier" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.CHARGERID' | translate }}</label>
                    <input type="text" class="form-control" id="chargingStationIdentifier" name="chargingStationIdentifier" 
                        [(ngModel)]="addCharger.chargingStationIdentifier" #chargingStationIdentifier="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="name" class="fw-semibold">{{ 'PAGES.COMMON.LIST.NAME' | translate }}</label>
                    <input type="text" class="form-control" id="name" name="name" 
                        required [(ngModel)]="addCharger.name" #name="ngModel">
                    <span style="color:red" [hidden]="name.valid || name.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.NAMEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="serialNumber" class="fw-semibold">{{ 'PAGES.COMMON.LIST.SERIALNUMBER' | translate }}</label>
                    <input type="text" class="form-control" id="serialNumber" name="serialNumber" 
                        required [(ngModel)]="addCharger.serialNumber" #serialNumber="ngModel">
                    <span style="color:red" [hidden]="serialNumber.valid || serialNumber.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.SERIALNUMBERREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="vendor" class="fw-semibold">{{ 'PAGES.COMMON.LIST.VENDOR' | translate }}</label>
                    <input type="text" class="form-control" id="vendor" name="vendor" 
                        required [(ngModel)]="addCharger.vendor" #vendor="ngModel">
                    <span style="color:red" [hidden]="vendor.valid || vendor.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.VENDORREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="model" class="fw-semibold">{{ 'PAGES.COMMON.LIST.MODEL' | translate }}</label>
                    <input type="text" class="form-control" id="model" name="model" 
                        required [(ngModel)]="addCharger.model" #model="ngModel">
                    <span style="color:red" [hidden]="model.valid || model.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.MODELREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="description" class="fw-semibold">{{ 'PAGES.COMMON.LIST.DESCRIPTION' | translate }}</label>
                    <input type="text" class="form-control" id="description" name="description" 
                        [(ngModel)]="addCharger.description" #description="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3"> 
                    <label for="chargerType" class="fw-semibold">{{ 'PAGES.COMMON.LIST.CHARGERTYPE' | translate }}</label>
                    <select name="chargerType" id="chargerType" class="form-select"
                            [(ngModel)]="addCharger.chargerType" #chargerType="ngModel">
                        <option *ngFor="let type of chargerTypes" [ngValue]="">{{ type }}</option>
                    </select>
                    <span style="color:red" [hidden]="addCharger.chargerType || chargerType.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.CHARGERTYPEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="imsi" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.IMSI' | translate }}</label>
                    <input type="text" class="form-control" id="imsi" name="imsi" 
                        [(ngModel)]="addCharger.imsi" #description="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="iccid" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.ICCID' | translate }}</label>
                    <input type="text" class="form-control" id="iccid" name="iccid" 
                        [(ngModel)]="addCharger.iccid" #iccid="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="meterSerialNumber" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.METERSERIALNUMBER' | translate }}</label>
                    <input type="text" class="form-control" id="meterSerialNumber" name="meterSerialNumber" 
                        [(ngModel)]="addCharger.meterSerialNumber" #meterSerialNumber="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="locationLattitude" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.LOCATIONLATITUDE' | translate }}</label>
                    <input type="text" class="form-control" id="locationLattitude" name="locationLattitude" 
                        [(ngModel)]="addCharger.locationLattitude" #iccid="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="locationLongitude" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.LOCATIONLONGITUDE' | translate }}</label>
                    <input type="text" class="form-control" id="locationLongitude" name="locationLongitude" 
                        [(ngModel)]="addCharger.locationLongitude" #locationLongitude="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="registrationStatus" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.REGISTRATIONSTATUS' | translate }}</label>
                    <select class="form-select" id="registrationStatus" name="registrationStatus" [(ngModel)]="addCharger.registrationStatus" #registrationStatus="ngModel">
                        <option value="Accepted">{{ 'PAGES.COMMON.LIST.ACCEPTED' | translate }}</option>
                        <option value="Pending">{{ 'PAGES.COMMON.LIST.SCHEDULED' | translate }}</option>
                        <option value="Rejected">{{ 'PAGES.COMMON.LIST.REJECTED' | translate }}</option>
                    </select>
                </div>
            </div>
        </div>
        
        <!-- <div class="form-group mb-3" [hidden]="!isAddNew">
            <label for="connectorCount" class="fw-semibold">Connector Count</label>
            <input type="number" class="form-control" id="connectorCount" name="connectorCount" 
                required [(ngModel)]="addCharger.connectorCount" #connectorCount="ngModel" min="1">
            <span style="color:red" [hidden]="connectorCount.valid || connectorCount.pristine"> 
                <p>Connector Count is required</p> 
            </span>
        </div> -->

        <div class="row" *ngFor="let connector of addCharger.connectors; index as i">
            <hr class="mt-4 mb-4"/> 

            <h5 class="font-size-14 mb-3">Connector #{{ i + 1 }}</h5>

            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.IDENTIFIER' | translate }}</label>
                    <input type="number" class="form-control" name="connectorIdentifier{{ i + 1}}"
                        required [(ngModel)]="connector.connectorIdentifier" #connectorIdentifier="ngModel" readonly>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.PLUGTYPE' | translate }}</label>
                    <select class="form-select" id="chargerPlugType" name="chargerPlugType" [(ngModel)]="connector.chargerPlugType" #chargerPlugType="ngModel" required="">
                        <option *ngFor="let plugType of chargerPlugTypes" [value]="plugType">{{ plugType }}</option>
                    </select>
                    <span style="color:red" [hidden]="chargerPlugType.valid || chargerPlugType.pristine"> 
                        <p>{{ 'PAGES.CHARGERS.LIST.PLUGTYPEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.CHARGEPOINTSTATUS' | translate }}</label>
                    <select class="form-select" id="chargePointStatus" name="chargePointStatus" [(ngModel)]="connector.chargePointStatus" #chargePointStatus="ngModel" required="">
                        <option *ngFor="let status of chargePointStatuses" [value]="status">{{ status }}</option>
                    </select>
                    <span style="color:red" [hidden]="chargePointStatus.valid || chargePointStatus.pristine"> 
                        <p>{{ 'PAGES.CHARGERS.LIST.CHARGEPOINTSTATUSREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
        </div>

        <button type="button" class="btn btn-outline-primary btn-rounded" (click)="addMore()" [hidden]="!isAddNew">{{ 'PAGES.COMMON.LIST.ADDMORE' | translate }}</button>

        <hr [hidden]="isAddNew"/>
        
        <div class="form-group mb-3" [hidden]="isAddNew">
            <label for="enabled" class="fw-semibold">{{ 'PAGES.COMMON.LIST.STATUS' | translate }}</label>
            <select class="form-select" id="enabled" name="enabled" [(ngModel)]="addCharger.enabled" #enabled="ngModel">
                <option value="true">{{ 'PAGES.COMMON.LIST.ENABLED' | translate }}</option>
                <option value="false">{{ 'PAGES.COMMON.LIST.DISABLED' | translate }}</option>
            </select>
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">{{ 'PAGES.COMMON.LIST.CANCEL' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">{{ 'PAGES.COMMON.LIST.SAVE' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>