import { createReducer, on } from "@ngrx/store";
import { User } from "src/app/core/models/user.models";
import * as UserActions from "./actions";
import { UserAuth } from "src/app/core/models/authentication.models";

export interface UserState {
    user: User | null;
    auth: UserAuth | null;
    error: any;
}

export const initialState: UserState = {
    user: null,
    auth: null,
    error: null,
};

export const userReducer = createReducer(
    initialState,
    on(UserActions.loginUserSuccess, (state, { auth }) => ({
        ...state,
        auth,
    })),
    on(UserActions.loginUserFailure, (state, { error }) => ({
        ...state,
        error,
    })),
    on(UserActions.loadUserSuccess, (state, { user }) => ({
        ...state,
        user,
    })),
    on(UserActions.loadUserFailure, (state, { error }) => ({
        ...state,
        error,
    }))
);
