<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title | translate}}</h5>
    <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="name" class="fw-semibold">{{ 'PAGES.COMMON.LIST.NAME' | translate }}</label>
                    <input type="text" class="form-control" id="name" name="name" 
                        required [(ngModel)]="addSite.name" #name="ngModel">
                    <span style="color:red" [hidden]="name.valid || name.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.NAMEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="description" class="fw-semibold">{{ 'PAGES.COMMON.LIST.DESCRIPTION' | translate }}</label>
                    <input type="text" class="form-control" id="description" name="description" 
                        required [(ngModel)]="addSite.description" #description="ngModel">
                    <span style="color:red" [hidden]="description.valid || description.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.DESCRIPTIONREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="siteOwnerUuid" class="fw-semibold">{{ 'PAGES.SITES.LIST.SITEOWNER' | translate }}</label>
                    <select class="form-select" id="siteOwnerUuid" name="siteOwnerUuid" [(ngModel)]="addSite.siteOwnerUuid" #siteOwnerUuid="ngModel" required>
                        <option *ngFor="let o of organizations" [ngValue]="o.uuid">{{ o.name }}</option>
                    </select>
                    <span style="color:red" [hidden]="siteOwnerUuid.valid || siteOwnerUuid.pristine"> 
                        <p>{{ 'PAGES.SITES.LIST.SITEOWNERREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="phoneNumber" class="fw-semibold">{{ 'PAGES.COMMON.LIST.PHONE' | translate }}</label>
                    <input type="tel" class="form-control" id="phoneNumber" name="phoneNumber" 
                        required [(ngModel)]="addSite.phoneNumber" #phoneNumber="ngModel">
                    <span style="color:red" [hidden]="phoneNumber.valid || phoneNumber.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.PHONEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="addressLine1" class="fw-semibold">{{ 'PAGES.COMMON.LIST.ADDRESSLINE1' | translate }}</label>
                    <input id="autocomplete" #addressInput type="text" class="form-control" placeholder="Enter address"
                        [(ngModel)]="addSite.addressLine1" name="addressLine1">
                    <span style="color:red" [hidden]="name.valid || name.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.ADDRESSLINE1REQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="addressLine2" class="fw-semibold">{{ 'PAGES.COMMON.LIST.ADDRESSLINE2' | translate }}</label>
                    <input type="text" class="form-control" id="addressLine2" name="addressLine2" 
                        [(ngModel)]="addSite.addressLine2" #addressLine2="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="city" class="fw-semibold">{{ 'PAGES.COMMON.LIST.CITY' | translate }}</label>
                    <input type="text" class="form-control" id="city" name="city" 
                        required [(ngModel)]="addSite.city" #city="ngModel">
                    <span style="color:red" [hidden]="city.valid || city.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.CITYREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="region" class="fw-semibold">{{ 'PAGES.COMMON.LIST.REGION' | translate }}</label>
                    <input type="text" class="form-control" id="region" name="region" 
                        required [(ngModel)]="addSite.region" #region="ngModel">
                    <span style="color:red" [hidden]="region.valid || region.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.REGIONREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="postalCode" class="fw-semibold">{{ 'PAGES.COMMON.LIST.POSTALCODE' | translate }}</label>
                    <input type="text" class="form-control" id="postalCode" name="postalCode" 
                        required [(ngModel)]="addSite.postalCode" #postalCode="ngModel">
                    <span style="color:red" [hidden]="postalCode.valid || postalCode.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.POSTALCODEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="country" class="fw-semibold">{{ 'PAGES.COMMON.LIST.COUNTRY' | translate }}</label>
                    <select class="form-select" id="country" name="country" [(ngModel)]="addSite.country" #country="ngModel" required>
                        <option *ngFor="let c of countries">{{c.name}}</option>
                    </select>
                    <span style="color:red" [hidden]="country.valid || country.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.COUNTRYREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="openTime" class="fw-semibold">{{ 'PAGES.SITES.LIST.OPENINGTIME' | translate }}</label>
                    <input class="form-control flatpickr-input" type="time" id="openTime" name="openTime" 
                        required [(ngModel)]="addSite.openTime" #openTime="ngModel">
                    <span style="color:red" [hidden]="openTime.valid || openTime.pristine"> 
                        <p>{{ 'PAGES.SITES.LIST.OPENINGTIMEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="closeTime" class="fw-semibold">{{ 'PAGES.SITES.LIST.CLOSINGTIME' | translate }}</label>
                    <input class="form-control flatpickr-input" type="time" id="closeTime" name="closeTime" 
                        required [(ngModel)]="addSite.closeTime" #closeTime="ngModel">
                    <span style="color:red" [hidden]="closeTime.valid || closeTime.pristine"> 
                        <p>{{ 'PAGES.SITES.LIST.CLOSINGTIMEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group mb-3" [hidden]="isAddNew">
                    <label for="enabled" class="fw-semibold">{{ 'PAGES.COMMON.LIST.STATUS' | translate }}</label>
                    <select class="form-select" id="enabled" name="enabled" [(ngModel)]="addSite.enabled" #enabled="ngModel">
                        <option value="true">{{ 'PAGES.COMMON.LIST.ENABLED' | translate }}</option>
                        <option value="false">{{ 'PAGES.COMMON.LIST.DISABLED' | translate }}</option>
                    </select>
                </div>
            </div>
        </div>
        
        <div class="row" *ngFor="let rate of addSite.rates; index as i"> 
            <hr class="mt-4 mb-4"/>

            <h5 class="font-size-14 mb-3">{{ 'PAGES.SITES.LIST.RATE' | translate }} #{{ i + 1 }}</h5>

            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.COMMON.LIST.CHARGERTYPE' | translate }}</label>
                    <select class="form-select" name="chargerType" [(ngModel)]="rate.chargerType" #chargerType="ngModel" required>
                        <option *ngFor="let c of chargerTypes" [ngValue]="c.value">{{ c.label }}</option>
                    </select>
                    <span style="color:red" [hidden]="chargerType.valid || chargerType.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.CHARGERTYPEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.COMMON.LIST.CURRENCY' | translate }}</label>
                    <input type="text" class="form-control" name="currency" 
                        required [(ngModel)]="rate.currency" #currency="ngModel">
                    <span style="color:red" [hidden]="currency.valid || currency.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.CURRENCYREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.COMMON.LIST.SESSIONFEE' | translate }}</label>
                    <input type="number" class="form-control" name="sessionFee" 
                        required [(ngModel)]="rate.sessionFee" #sessionFee="ngModel" min="0">
                    <span style="color:red" [hidden]="sessionFee.valid || sessionFee.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.SESSIONFEEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.SITES.LIST.ENERGYRATEUNIT' | translate }}</label>
                    <input type="text" class="form-control" name="energyRateUnit" 
                        required [(ngModel)]="rate.energyRateUnit" #energyRateUnit="ngModel">
                    <span style="color:red" [hidden]="energyRateUnit.valid || energyRateUnit.pristine"> 
                        <p>{{ 'PAGES.SITES.LIST.ENERGYRATEUNITREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.COMMON.LIST.ENERGYRATE' | translate }}</label>
                    <input type="number" class="form-control" name="energyRate" 
                        required [(ngModel)]="rate.energyRate" #energyRate="ngModel" min="0">
                    <span style="color:red" [hidden]="energyRate.valid || energyRate.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.ENERGYRATEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.SITES.LIST.IDLINGFEEUNIT' | translate }}</label>
                    <input type="text" class="form-control" name="idlingFeeUnit" 
                        required [(ngModel)]="rate.idlingFeeUnit" #idlingFeeUnit="ngModel">
                    <span style="color:red" [hidden]="idlingFeeUnit.valid || idlingFeeUnit.pristine"> 
                        <p>{{ 'PAGES.SITES.LIST.IDLINGFEEUNIT' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.COMMON.LIST.IDLINGFEE' | translate }}</label>
                    <input type="number" class="form-control" name="idlingFee" 
                        required [(ngModel)]="rate.idlingFee" #idlingFee="ngModel" min="0">
                    <span style="color:red" [hidden]="idlingFee.valid || idlingFee.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.IDLINGFEEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.SITES.LIST.PARKINGFEEUNIT' | translate }}</label>
                    <input type="text" class="form-control" name="parkingFeeUnit" 
                        required [(ngModel)]="rate.parkingFeeUnit" #parkingFeeUnit="ngModel">
                    <span style="color:red" [hidden]="parkingFeeUnit.valid || parkingFeeUnit.pristine"> 
                        <p>{{ 'PAGES.SITES.LIST.PARKINGFEEUNITREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group mb-3">
                    <label class="fw-semibold">{{ 'PAGES.COMMON.LIST.PARKINGFEE' | translate }}</label>
                    <input type="number" class="form-control" name="parkingFee" 
                        required [(ngModel)]="rate.parkingFee" #parkingFee="ngModel" min="0">
                    <span style="color:red" [hidden]="parkingFee.valid || parkingFee.pristine"> 
                        <p>{{ 'PAGES.COMMON.LIST.PARKINGFEEREQUIRED' | translate }}</p> 
                    </span>
                </div>
            </div>
        </div>

        <hr/>

        <button type="button" class="btn btn-outline-primary btn-rounded" (click)="addMore()">{{ 'PAGES.COMMON.LIST.ADDMORE' | translate }}</button>

    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">{{ 'PAGES.COMMON.LIST.CANCEL' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">{{ 'PAGES.COMMON.LIST.SAVE' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>