import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { UserAuth } from "../models/authentication.models";
import { AppUtilities } from "src/app/app-utilities";
import { GenericResponse } from "../models/generic-response.models";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    backendApiUrl = "";
    httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    constructor(private http: HttpClient, private appUtilities: AppUtilities) {
        this.backendApiUrl = appUtilities.getUserManagementUrl();
    }

    // getToken
    public getToken(): string {
        return sessionStorage.getItem("token") || "";
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        let params = { emailOrPhone: email, password: password };

        return this.http
            .post<any>(
                `${this.backendApiUrl}/auth/signin`,
                params,
                this.httpOptions
            )

            .pipe(
                map((res) => {
                    // login successful if there's a jwt token in the response
                    const user: UserAuth = res;
                    if (user && user.token && user.uuid) {
                        // store user jwt token and userId in session storage to keep user logged in between page refreshes
                        sessionStorage.setItem("token", user.token);
                        sessionStorage.setItem("uuid", user.uuid);
                    }
                    return user;
                })
            );
    }

    /**
     * Performs the confirmation
     * @param params
     */
    confirmation(params: any) {
        return this.http
            .post<any>(
                `${this.backendApiUrl}/auth/backoffice-confirmation`,
                params,
                this.httpOptions
            )

            .pipe(
                tap((res: GenericResponse) => {
                    this.log(`Account confirmation successful`);
                })
            );
    }

    /**
     * Performs password reset for non-logged in user (forgot password)
     * @param params
     */
    forgotPassword(params: any) {
        return this.http
            .post<any>(
                `${this.backendApiUrl}/auth/forgot-password`,
                params,
                this.httpOptions
            )

            .pipe(
                tap((res: GenericResponse) => {
                    this.log(`Password reset initialization successful`);
                })
            );
    }

    /**
     * Performs save reset password for non-logged in user (forgot password)
     * @param params
     */
    saveResetPassword(params: any) {
        return this.http
            .post<any>(
                `${this.backendApiUrl}/auth/reset-password`,
                params,
                this.httpOptions
            )

            .pipe(
                tap((res: GenericResponse) => {
                    this.log(`Password saved successfully`);
                })
            );
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    signIn(email: string, password: string): Observable<UserAuth> {
        const params = { emailOrPhone: email, password: password };
        return this.http
            .post<any>(
                `${this.backendApiUrl}/auth/signin`,
                params,
                this.httpOptions
            )
            .pipe(
                tap((loggedInUser: UserAuth) =>
                    this.log(`Logged in user is : ${loggedInUser.email}`)
                ),
                catchError(this.handleError<UserAuth>("signIn"))
            );
    }

    /**
     * Logout the user
     */
    logout() {
        sessionStorage.removeItem("token");
    }

    /** Log a HeroService message with the MessageService */
    private log(message: string) {
        // console.log(`AuthenticationService: ${message}`);
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     *
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = "operation", result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            // console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
