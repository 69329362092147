<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="enabled" class="fw-semibold">Notification Type</label>
            <select class="form-select" id="notificationType" name="notificationType" [(ngModel)]="notificationTemplate.notificationType" #notificationType="ngModel" required>
                <option value="CHARGING_SESSION_ENDED">Charging Session Ended</option>
                <option value="CHARGING_SESSION_INTERRUPTED">Charging Session Interrupted</option>
                <option value="CHARGING_SESSION_STARTED">Charging Session Started</option>
                <option value="FAVOURITE_STATION_OUT_OF_SERVICE">Favourite Station Out of Service</option>
                <option value="IDLING_FEES_BEGIN_IN_5_MINUTES">Idling Fees Begin In 5 Minutes</option>
                <option value="ORDER_RECEIVED">Order Received</option>
                <option value="PARKING_EXPIRES_IN_10_MINUTES">Parking Expires in 10 Minutes</option>
                <option value="RECEIVE_PROMOTIONAL_OFFERS">Receive Promotional Offers</option>
                <option value="STORED_VALUE_LESS_THAN_10">Stored Value Less Than 10</option>
                <option value="VEHICLE_BATTERY_CHARGED_TO_80">Vehicle Battery Charged to 80</option>
                <option value="WILL_YOU_LIKE_TO_CHARGE">Will You Like to Charge</option>
            </select>
        </div>
        <div class="form-group mb-3">
            <label for="englishSubject" class="fw-semibold">English Subject</label>
            <input type="text" class="form-control" id="englishSubject" name="englishSubject" 
                required [(ngModel)]="notificationTemplate.englishSubject" #englishSubject="ngModel">
            <span style="color:red" [hidden]="englishSubject.valid || englishSubject.pristine"> 
                <p>English subject is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="englishMessage" class="fw-semibold">English Message</label>
            <textarea id="englishMessage" name="englishMessage" class="form-control" rows="4" cols="50" 
                required [(ngModel)]="notificationTemplate.englishMessage" #englishMessage="ngModel"></textarea>
            <span style="color:red" [hidden]="englishMessage.valid || englishMessage.pristine"> 
                <p>English message is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="frenchSubject" class="fw-semibold">French Subject</label>
            <input type="text" class="form-control" id="frenchSubject" name="frenchSubject" 
                required [(ngModel)]="notificationTemplate.frenchSubject" #frenchSubject="ngModel">
            <span style="color:red" [hidden]="frenchSubject.valid || frenchSubject.pristine"> 
                <p>French subject is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="frenchMessage" class="fw-semibold">French Message</label>
            <textarea id="frenchMessage" name="frenchMessage" class="form-control" rows="4" cols="50" 
                required [(ngModel)]="notificationTemplate.frenchMessage" #frenchMessage="ngModel"></textarea>
            <span style="color:red" [hidden]="frenchMessage.valid || frenchMessage.pristine"> 
                <p>French message is required</p> 
            </span>
        </div>
        <div class="form-group mb-3" [hidden]="isAddNew">
            <label for="enabled" class="fw-semibold">Status</label>
            <select class="form-select" id="enabled" name="enabled" [(ngModel)]="notificationTemplate.enabled" #enabled="ngModel">
                <option value="true">Enabled</option>
                <option value="false">Disabled</option>
            </select>
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">Save</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>