import { Component, OnInit } from "@angular/core";
import { Column } from "src/app/core/models/datatable.models";
import { GenericFilter } from "src/app/core/models/generic-filter.models";
import { Order, PagedOrders } from "src/app/core/models/order.models";
import { OrderService } from "src/app/core/services/order.service";

@Component({
    selector: "app-orders",
    templateUrl: "./orders.component.html",
    styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;
    collectionSize!: number;
    orders!: Order[];
    pagedOrders!: PagedOrders;

    columns: Column[] = [
        { key: "reference", name: "Reference Number", isSearchTerm: true },
        { key: "customerName", name: "Customer Name", isSearchTerm: true },
        { key: "description", name: "Description" },
        { key: "details", name: "Details" },
        { key: "notes", name: "Notes" },
        { key: "evse", name: "EVSE", isSearchTerm: true },
        // { key: "siteAddress", name: "Site Address" },
        { key: "energyFees", name: "Energy Fees", prefix: "$" },
        { key: "nonEnergyFees", name: "Non Energy Fees", prefix: "$" },
        { key: "subTotal", name: "Sub Total", prefix: "$" },
        { key: "pst", name: "PST (7%)", prefix: "$" },
        { key: "gst", name: "GST (5%)", prefix: "$" },
        { key: "total", name: "Total", prefix: "$" },
        { key: "paymentStatus", name: "Payment Status" },
        { key: "updatedAt", name: "Last Updated" }
    ];

    constructor(private orderService: OrderService) {}

    ngOnInit(): void {
        //BreadCrumb
        this.breadCrumbItems = [
            { label: "Billing Management" },
            { label: "Orders", active: true },
        ];
    }

    fetchData(filter: GenericFilter) {
        this.orderService.getOrders(filter).subscribe({
            next: (v) => {
                console.log("returned orders : ", v);
                this.pagedOrders = v;
                this.collectionSize = this.pagedOrders.totalElements;
                this.orders = this.pagedOrders.content;
            },
            error: (e) => console.error(e),
            complete: () => {
                console.info("orders retrieved");
            },
        });
    }
}
