<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title | translate}}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="chargingStationIdentifier" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.STATION' | translate }}</label>
            <input type="text" class="form-control" id="chargingStationIdentifier" name="chargingStationIdentifier" 
                required readonly [(ngModel)]="newAction.chargingStationIdentifier" #chargingStationIdentifier="ngModel">
            <span style="color:red" [hidden]="chargingStationIdentifier.valid || chargingStationIdentifier.pristine"> 
                <p>{{ 'PAGES.CHARGERS.LIST.STATIONREQUIRED' | translate }}</p>
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="location" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.LOCATIONURI' | translate }}</label>
            <input type="text" class="form-control" id="location" name="location" 
                required [(ngModel)]="newAction.location" #location="ngModel">
            <span style="color:red" [hidden]="location.valid || location.pristine"> 
                <p>{{ 'PAGES.CHARGERS.LIST.LOCATIONURIREQUIRED' | translate }}</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="retries" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.RETRIES' | translate }}</label>
            <input type="number" class="form-control" id="retries" name="retries" 
                required [(ngModel)]="newAction.retries" #retries="ngModel" min="1">
            <span style="color:red" [hidden]="retries.valid || retries.pristine"> 
                <p>{{ 'PAGES.CHARGERS.LIST.RETRIESREQUIRED' | translate }}</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="retryInterval" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.RETRYINTERVAL' | translate }}</label>
            <input type="number" class="form-control" id="retryInterval" name="retryInterval" 
                required [(ngModel)]="newAction.retryInterval" #retryInterval="ngModel" min="1">
            <span style="color:red" [hidden]="retryInterval.valid || retryInterval.pristine"> 
                <p>{{ 'PAGES.CHARGERS.LIST.RETRYINTERVALREQUIRED' | translate }}</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="retrieve" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.RETRIEVEBY' | translate }}</label>
            <input class="form-control flatpickr-input" type="text" mwlFlatpickr [enableTime]="true"
            dateFormat="Y-m-dTH:i" id="retrieve" name="retrieve" required [(ngModel)]="newAction.retrieve" #retrieve="ngModel">
            <span style="color:red" [hidden]="retrieve.valid || retrieve.pristine"> 
                <p>{{ 'PAGES.CHARGERS.LIST.RETRIEVEBYREQUIRED' | translate }}</p> 
            </span>
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">{{ 'PAGES.COMMON.LIST.CANCEL' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">{{ 'PAGES.COMMON.LIST.SUBMIT' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>