import {
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
} from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject, Subscription } from "rxjs";
import { Column, SubmitData } from "src/app/core/models/datatable.models";
import { GenericFilter } from "src/app/core/models/generic-filter.models";
import { DatatableModalComponent } from "./datatable-modal/datatable-modal.component";
import { SweetalertService } from "src/app/core/services/sweetalert.service";
import { ModalMessage } from "src/app/core/models/modal-message.models";
import { debounceTime, delay } from "rxjs/operators";

@Component({
    selector: "app-datatable",
    templateUrl: "./datatable.component.html",
    styleUrls: ["./datatable.component.scss"],
})
export class DatatableComponent implements OnInit, OnChanges {
    @Input() title!: string;
    @Input() rows!: Array<any>;
    @Input() columns!: Array<Column>;
    @Input() collectionSize!: number;
    @Input() paged!: boolean;
    @Input() searchable!: boolean;
    @Input() hasAction!: boolean;
    @Input() edittable!: boolean;
    @Input() addAllowed!: boolean;
    @Input() submitHandler!: (v: SubmitData) => Observable<any>;

    @Output() onFetchData = new EventEmitter<GenericFilter>();

    @ContentChild("actions", {static: false}) actionsTemplateRef!: TemplateRef<any>;

    searchTermHint!: string;
    searchTerm!: string;
    searchTermChanged: Subject<string> = new Subject<string>();
    inputSub: Subscription | undefined;
    page = 1;
    pageSize = 10;
    genericFilter: GenericFilter = new GenericFilter("", 0, 10, "id", "desc");
    empty = true;
    data!: any;
    modalRef!: NgbModalRef;

    constructor(
        public modalService: NgbModal,
        public sweetalertService: SweetalertService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.rows) {
            this.empty = this.rows?.length < 1;
        }
    }

    ngOnInit(): void {
        if (this.searchable) {
            this.searchTermHint = this.columns
            .filter((c) => c.isSearchTerm)
            .map((c) => c.name)
            .reduce((p, c) => p + " | " + c);
        }

        this.fetchData();

        this.inputSub = this.searchTermChanged
            .pipe(debounceTime(500), delay(200))
            .subscribe((value) => {
                console.log(value);
                this.genericFilter.searchParam = value;
                this.fetchData();
            });
    }

    fetchData(): void {
        this.genericFilter.size = this.pageSize;
        this.genericFilter.page = this.page - 1;
        this.onFetchData.emit(this.genericFilter);
    }

    search(value: string): void {
        this.searchTermChanged.next(value);
    }

    onSizeChange(size: number) {
        this.genericFilter.size = size;
        this.pageSize = size;
        this.page = 1;
        this.fetchData();
    }

    pageChanged(page: number) {
        console.log("page change: ", page);
        this.genericFilter.page = page - 1;
        this.fetchData();
    }

    openModal(data: Object, isAddNew: boolean) {
        this.modalRef = this.modalService.open(DatatableModalComponent);
        this.modalRef.componentInstance.fields = this.columns.filter(
            (v) => v.editType
        );
        this.modalRef.componentInstance.data = data;
        this.modalRef.componentInstance.isAddNew = isAddNew;
        this.modalRef.componentInstance.title = this.title;
        this.modalRef.componentInstance.submit.subscribe((v: SubmitData) =>
            this.onSubmit(v)
        );

        this.modalRef.result
            .then((result: ModalMessage) => {
                if (result) {
                    this.refreshPage();
                }
            })
            .catch(() => {
                // user click outside of the modal form
                console.log("Form: ", "Cancel");
            });
    }

    //refresh page
    refreshPage(): void {
        window.location.reload();
    }

    getDisplayValue(value: any, column: Column): string {
        if (typeof value === "boolean") {
            if (column.booleanDisplay) {
                return value
                    ? column.booleanDisplay[0]
                    : column.booleanDisplay[1];
            } else {
                return value ? "Yes" : "No";
            }
        } else if (value != null) {
            return column.prefix ? column.prefix + value : value;
        } else {
            return "";
        }
    }

    onSubmit(v: SubmitData) {
        console.log(this.submitHandler);
        this.submitHandler(v).subscribe({
            next: (v) => {
                console.log("added product : ", v);
                this.sweetalertService.success(v.message).then(() => {
                    this.modalRef.close(v);
                });
            },
            error: (e) => {
                console.error("error occured : ", e);
                this.sweetalertService.failure(e).then(() => {
                    this.modalRef.componentInstance.processing = false;
                });
            },
            complete: () => {
                console.info("product saved");
            },
        });
    }
}
