import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrdersComponent } from './orders/orders.component';
import { ProductsComponent } from './products/products.component';
import { WalletsComponent } from './wallets/wallets.component';
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { TestMakePaymentComponent } from './orders/test-make-payment/test-make-payment.component';
import { TestReviewPaymentComponent } from './orders/test-review-payment/test-review-payment.component';
import { RefundsComponent } from './refunds/refunds.component';

const routes: Routes = [
  {
    path: 'orders',
    component: OrdersComponent
  },
  {
    path: 'orders/test-make-payment',
    component: TestMakePaymentComponent
  },
  {
    path: 'orders/test-review-payment',
    component: TestReviewPaymentComponent
  },
  {
    path: 'orders/:reference',
    component: OrderDetailsComponent
  },
  {
    path: 'products',
    component: ProductsComponent
  },
  {
    path: 'refunds',
    component: RefundsComponent
  },
  {
    path: 'wallets',
    component: WalletsComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingManagementRoutingModule { }
