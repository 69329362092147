<div class="card">
    <div class="card-body">
        <div class="float-end">
            <div class="avatar-sm mx-auto mb-4">
                <span class="avatar-title rounded-circle bg-light font-size-24">
                    <i class="mdi mdi-account-group text-primary"></i>
                </span>
            </div>
        </div>
        <div>
            <p class="text-muted text-uppercase fw-semibold">{{ 'PAGES.DASHBOARD.LIST.ACTIVEUSERS' | translate }}</p>
            <h4 class="mb-1 mt-1"><span class="counter-value" [countUp]="activeUsersAnalytics.total" ></span></h4>
        </div>
        <p class="text-muted mt-3 mb-0"><span [class]="activeUsersAnalytics.classCss"><i
                    [class]="activeUsersAnalytics.classArrowCss"></i>{{ activeUsersAnalytics.differential }}%</span> {{ 'PAGES.DASHBOARD.LIST.SINCELASTWEEK' | translate }}
        </p>
    </div>
</div>