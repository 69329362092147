import { Component } from '@angular/core';

@Component({
  selector: 'app-firmware-modal',
  templateUrl: './firmware-modal.component.html',
  styleUrl: './firmware-modal.component.scss'
})
export class FirmwareModalComponent {

}
