start page title
<app-pagetitle title="Site Details" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row mb-4">
    <div class="col-xl-3">
        <div class="card h-100">
            <div class="card-body">

                <div class="table-responsive">
                    <h5 class="font-size-16 mb-3">Internal Account Details</h5>

                    <hr class="my-4">

                    <div>
                        <p class="mb-1 text-muted font-size-13">Name :</p>
                        <h5 class="font-size-14">{{ internalAccount.name }}</h5>
                    </div>
                    <div class="mt-3">
                        <p class="mb-1 text-muted font-size-13">Description :</p>
                        <h5 class="font-size-14">{{ internalAccount.description}}</h5>
                    </div>
                    <div class="mt-3">
                        <p class="mb-1 text-muted font-size-13">Wallet Number :</p>
                        <h5 class="font-size-14">{{ internalAccount.wallet.walletNumber}}</h5>
                    </div>
                    <div>
                        <p class="mb-1 text-muted font-size-13">Wallet Type :</p>
                        <h5 class="font-size-14">{{ internalAccount.wallet.walletType}}</h5>
                    </div>
                    <div class="mt-3">
                        <p class="mb-1 text-muted font-size-13">Available Balance :</p>
                        <h5 class="font-size-14">{{ internalAccount.wallet.availableBalance}}</h5>
                    </div>
                    <div class="mt-3">
                        <p class="mb-1 text-muted font-size-13">Status :</p>
                        <h5 class="font-size-14">
                            <span *ngIf="internalAccount.enabled"
                                class="badge badge-soft-success font-size-12">Enabled</span>
                            <span *ngIf="!internalAccount.enabled"
                                class="badge badge-soft-warning font-size-12">Enabled</span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-9">
        <div class="card mb-0">
            <div class="card-body">
                <div class="d-flex mb-4">
                    <h4 class="card-title flex-grow-1">Wallet History</h4>
                </div>

                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6">
                        <div class="dataTables_length" id="tickets-table_length"><label
                                class="d-inline-flex align-items-center">Show
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="pageSize" class="form-control form-control-sm mx-2"
                                    (change)="onSizeChange(pageSize)">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select> entries</label></div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <input type="text" name="searchTerm" id="searchTerm"
                                    class="form-control form-control-sm ms-2" aria-controls="tickets-table"
                                    [(ngModel)]="searchTerm" (input)="search(searchTerm)">
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>

                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable"
                        class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th>Reference</th>
                                <th>Description</th>
                                <th>Transaction Type</th>
                                <th>Amount</th>
                                <th>Balance</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [hidden]="!empty">
                                <td>No data available in table</td>
                            </tr>
                            <tr *ngFor="let history of walletHistories" [hidden]="pagedWalletHistories.empty">
                                <td>
                                    <ngb-highlight [result]="history.reference" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    {{ history.description }}
                                </td>
                                <td>
                                    {{ history.transactionType }}
                                </td>
                                <td>
                                    <ngb-highlight [result]="history.amount | currency: history.currency"
                                        [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="history.balance | currency: history.currency"
                                        [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="history.updatedAt" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- End table -->
                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            Showing
                            <!-- {{permissionsDatatableService.startIndex}} to
                            {{permissionsDatatableService.endIndex}} of {{permissionsDatatableService.totalRecords}} -->
                            entries
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-right float-md-end pagination-rounded">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [maxSize]="5"
                                [boundaryLinks]="true" [pageSize]="pageSize" (pageChange)="pageChanged($event)"
                                class="d-flex justify-content-center">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- End Pagination -->
                </div>

            </div>
        </div>
    </div>
</div>