import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Column, SubmitData } from "src/app/core/models/datatable.models";
import { SweetalertService } from "src/app/core/services/sweetalert.service";

@Component({
    selector: "app-datatable-modal",
    templateUrl: "./datatable-modal.component.html",
    styleUrls: ["./datatable-modal.component.scss"],
})
export class DatatableModalComponent implements OnInit {
    @Input() data!: any;
    @Input() fields!: Column[];
    @Input() isAddNew!: boolean;
    @Input() title!: string;

    @Output() submit = new EventEmitter<SubmitData>();
    // @Input() submit!: (v: SubmitData) => void;

    processing = false;
    updatedData: { [k: string]: any } = {};

    constructor(
        public modal: NgbActiveModal,
        public sweetalertService: SweetalertService
    ) {}

    ngOnInit(): void {
        if (this.isAddNew) {
            this.fields.forEach((v) => (this.updatedData[v.key] = null));
        } else {
            this.updatedData = {...this.data};
        }
        console.log(this.updatedData);
        
    }

    // Handle Delete button click
    onCancel() {
        this.modal.close();
    }

    onSubmit() {
      this.submit.emit({data: this.updatedData, isAddNew: this.isAddNew});
    }
}
