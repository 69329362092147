import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Permission, SelectedPermission } from 'src/app/core/models/permission.models';
import { Role } from 'src/app/core/models/role.models';
import { PermissionService } from 'src/app/core/services/permission.service';
import { RoleService } from 'src/app/core/services/role.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

/**
 * Table Data Match with Search input
 * @param  Permission field value fetch
 * @param term Search the value
 */
function matches(table: SelectedPermission, term: string, pipe: PipeTransform) {
  return pipe.transform(table.id).includes(term) || table.name.toLowerCase().includes(term.toLowerCase())
      || table.description.toLowerCase().includes(term);
}

@Component({
  selector: 'app-role-permissions',
  templateUrl: './role-permissions.component.html',
  styleUrls: ['./role-permissions.component.scss'],
  providers: [DecimalPipe]
})
export class RolePermissionsComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  searchTerm!: string; 
  page = 1;
  pageSize = 100;
  collectionSize!: number;
  currentRate = 8;
  permission!: Permission;
  permissions!: Permission[];
  allPermissions!: SelectedPermission[];
  uuid!: string;
  pageTitle!: string;
  selectedPermissions!: SelectedPermission[];
  processing = false;
  
  @Input() role!: Role;
  
  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private permissionService: PermissionService, 
    private roleService: RoleService, 
    private pipe: DecimalPipe, 
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    this.uuid = String(this.route.snapshot.paramMap.get('uuid'));

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Settings' },
      { label: 'Roles' },
      { label: 'Permissions', active: true }
    ];

    if (this.role == undefined) { 
      this.fetchRole(this.uuid);
    } else {
      this.pageTitle = this.role.name + ' Permissions';
      this.fetchData();
    }
  }

  fetchRole(uuid: string) {
    this.roleService.getRole(this.uuid).subscribe({
      next: (v) => {
        console.log('returned role: ', v);
        this.role = v;
        this.pageTitle = this.role.name + ' Permissions';
        this.fetchData();
      }
    })
  }

  fetchData() {
    this.permissionService.getPermissions().subscribe({
      next: (v) => {
        console.log('returned permissions : ', v);
        this.collectionSize = v.length;
        this.permissions = v;
        this.populateSelected();
        this.allPermissions = this.selectedPermissions;
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('permissions retrieved');
      }
    });
  }

  search(value: string): void {
    this.selectedPermissions = this.allPermissions.filter(table => matches(table, value, this.pipe));

    // this.permissions = this.allPermissions.filter((val) => val.name.toLowerCase().includes(value));
    this.collectionSize = this.selectedPermissions.length;
  }

  populateSelected() {
    this.selectedPermissions = [];
    this.permissions.forEach( (permission) => {
      this.selectedPermissions.push(
        {
          id: permission.id,
          name: permission.name,
          description: permission.description,
          selected: this.role.permissionNames.includes(permission.name)
        }
      )
    }); 
  }

  savePermissions() {
    let rolePermissions = {
      selectedPermissions: this.selectedPermissions.filter(({selected}) => selected).map(({name}) => name)
    }

    console.log('role permissions: ', rolePermissions);

    console.log('save clicked');
    this.processing = true;

    this.roleService.updateRolePermissions(rolePermissions, this.role.uuid).subscribe({
      next: (v) => {
        console.log('updated role permissions: ', v);
        this.sweetalertService.success(v.message).then(() => {
          this.processing = false;
          this.router.navigate(['settings/roles']);
        });
      },
      error: (e) => {
        console.error('error occured : ', e);
        this.sweetalertService.failure(e).then(() => {
          this.processing = false;
        });
      },
      complete: () => {
        console.info('role permissions saved');
      }
    });
  }
}
