import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AppUtilities } from 'src/app/app-utilities';
import { Charger } from 'src/app/core/models/charger.models';

@Component({
  selector: 'app-qr-code-modal',
  templateUrl: './qr-code-modal.component.html',
  styleUrls: ['./qr-code-modal.component.scss']
})
export class QrCodeModalComponent implements OnInit {

  @Input() public charger!: Charger;

  title!: string;
  qrUrl!: string;

  constructor(public modal: NgbActiveModal, private appUtilities: AppUtilities, private translate: TranslateService) { }

  ngOnInit(): void {
    const params = { chargerID: this.charger.chargingStationIdentifier };
    this.title = this.translate.instant('PAGES.COMMON.LIST.QRCODE', params);

    this.qrUrl = `${this.appUtilities.getChargingStationManagementUrl()}/charging-stations/qrcode/${this.charger.chargingStationIdentifier}.png`;
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
