<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="chargingStationIdentifier" class="fw-semibold">Station</label>
            <input type="text" class="form-control" id="chargingStationIdentifier" name="chargingStationIdentifier" 
                required readonly [(ngModel)]="newAction.chargingStationIdentifier" #chargingStationIdentifier="ngModel">
            <span style="color:red" [hidden]="chargingStationIdentifier.valid || chargingStationIdentifier.pristine"> 
                <p>Station Identifier is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="commaSeparatedConfKeyList" class="fw-semibold">Configuration Keys (Comma separated)</label>
            <input type="text" class="form-control" id="commaSeparatedConfKeyList" name="commaSeparatedConfKeyList" 
                [(ngModel)]="newAction.commaSeparatedConfKeyList" #commaSeparatedConfKeyList="ngModel">
        </div>
        <div class="form-group mb-3">
            <label for="commaSeparatedCustomConfKeys" class="fw-semibold">Custom Configuration Keys (Comma separated)</label>
            <input type="text" class="form-control" id="commaSeparatedCustomConfKeys" name="commaSeparatedCustomConfKeys" 
                [(ngModel)]="newAction.commaSeparatedCustomConfKeys" #commaSeparatedCustomConfKeys="ngModel">
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">Submit</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>