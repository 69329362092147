import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { GenericFilter } from "src/app/core/models/generic-filter.models";
import { PagedProduct, Product } from "src/app/core/models/product.models";
import { ProductService } from "src/app/core/services/product.service";
import { Column, SubmitData } from "src/app/core/models/datatable.models";

@Component({
    selector: "app-products",
    templateUrl: "./products.component.html",
    styleUrls: ["./products.component.scss"],
})
export class ProductsComponent implements OnInit {
    breadCrumbItems!: Array<{}>;
    title!: string;
    tableTitle!: string;
    addModalTitle!: string;
    editModalTitle!: string;
    collectionSize!: number;
    products!: Product[];
    pagedProduct!: PagedProduct;
    columns: Column[] = [
        { key: "name", name: "PAGES.COMMON.LIST.NAME", isSearchTerm: true, editType: "text", required: true },
        { key: "description", name: "PAGES.COMMON.LIST.DESCRIPTION", editType: "text" },
        { key: "unitPrice", name: "PAGES.PRODUCTS.LIST.UNITPRICE", prefix: "$", editType: "number", required: true },
        { key: "taxable", name: "PAGES.PRODUCTS.LIST.TAXABLE", editType: "boolean", required: true, booleanDisplay: ["Yes", "No"] },
        { key: "updatedAt", name: "PAGES.COMMON.LIST.LASTUPDATED" },
        { key: "enabled", name: "PAGES.COMMON.LIST.STATUS", editType: "boolean", required: true, booleanDisplay: ["Enabled", "Disabled"]},
    ]; 
    submitHandler = (v: SubmitData): Observable<any> => {
      if (v.isAddNew) {
        return this.productService.addProduct(v.data);
      }
      else {
        return this.productService.updateProduct(v.data, v.data.uuid);
      }
    };

    constructor(
        public modalService: NgbModal,
        private productService: ProductService
    ) {}

    ngOnInit(): void {
        this.title = 'PAGES.PRODUCTS.TEXT';
        this.tableTitle = 'PAGES.PRODUCTS.LIST.LISTTITLE';
        this.addModalTitle = 'PAGES.PRODUCTS.LIST.ADD';
        this.editModalTitle = 'PAGES.PRODUCTS.LIST.EDIT';

        //BreadCrumb
        this.breadCrumbItems = [
            { label: 'MENUITEMS.BILLINGMANAGEMENT.TEXT' },
            { label: "PAGES.PRODUCTS.TEXT", active: true },
        ];
    }

    fetchData(filter: GenericFilter) {
        this.productService.getProducts(filter).subscribe({
            next: (v) => {
                // console.log("returned products : ", v);
                this.pagedProduct = v;
                this.collectionSize = this.pagedProduct.totalElements;
                this.products = this.pagedProduct.content;
            },
            error: (e) => {
                // console.error(e);
            },
            complete: () => {
                // console.info("products retrieved");
            },
        });
    }
}
