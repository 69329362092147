<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title | translate}}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="notificationType" class="fw-semibold">{{ 'PAGES.EMAILTEMPLATES.LIST.NOTIFICATIONTYPE' | translate }}</label>
            <select class="form-select" id="notificationType" name="notificationType" [(ngModel)]="emailTemplate.notificationType" #notificationType="ngModel" required>
                <option *ngFor="let n of notificationTypes" [value]="n.key">{{n.value}}</option>
            </select>
        </div>

        <div class="form-group mb-3">
            <label for="subject" class="fw-semibold">{{ 'PAGES.EMAILTEMPLATES.LIST.SUBJECT' | translate }}</label>
            <input type="text" class="form-control" id="subject" name="subject" 
                required [(ngModel)]="emailTemplate.subject" #subject="ngModel">
            <span style="color:red" [hidden]="subject.valid || subject.pristine"> 
                <p>{{ 'PAGES.EMAILTEMPLATES.LIST.SUBJECTREQUIRED' | translate }}</p> 
            </span>
        </div>

        <div class="form-group mb-3">
            <label for="message" class="fw-semibold">{{ 'PAGES.EMAILTEMPLATES.LIST.MESSAGE' | translate }}</label>
            <editor id="message" name="message" [init]="editorConfig" [(ngModel)]="emailTemplate.message" #message="ngModel"></editor>
        </div>

    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">{{ 'PAGES.COMMON.LIST.CANCEL' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">{{ 'PAGES.COMMON.LIST.SAVE' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>