import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { SettingsRoutingModule } from './settings-routing.module';
import { PermissionsComponent } from './permissions/permissions.component';
import { RolesComponent } from './roles/roles.component';
import { VehicleMakesComponent } from './vehicle-makes/vehicle-makes.component';
import { OrganizationTypesComponent } from './organization-types/organization-types.component';
import { CountriesComponent } from './countries/countries.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PermissionModalComponent } from './permissions/permission-modal/permission-modal.component';
import { RoleModalComponent } from './roles/role-modal/role-modal.component';
import { VehicleMakeModalComponent } from './vehicle-makes/vehicle-make-modal/vehicle-make-modal.component';
import { OrganizationTypeModalComponent } from './organization-types/organization-type-modal/organization-type-modal.component';
import { RolePermissionsComponent } from './roles/role-permissions/role-permissions.component';
import { InternalAccountsComponent } from './internal-accounts/internal-accounts.component';
import { InternalAccountModalComponent } from './internal-accounts/internal-account-modal/internal-account-modal.component';
import { AccountViewComponent } from './internal-accounts/account-view/account-view.component';
import { VehicleMakeViewComponent } from './vehicle-makes/vehicle-make-view/vehicle-make-view.component';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { ConfigurationModalComponent } from './configurations/configuration-modal/configuration-modal.component';
import { FirmwaresComponent } from './firmwares/firmwares.component';
import { FirmwareModalComponent } from './firmwares/firmware-modal/firmware-modal.component';

@NgModule({
  declarations: [
    PermissionsComponent,
    RolesComponent,
    VehicleMakesComponent,
    OrganizationTypesComponent,
    CountriesComponent,
    PermissionModalComponent,
    RoleModalComponent,
    VehicleMakeModalComponent,
    OrganizationTypeModalComponent,
    RolePermissionsComponent,
    InternalAccountsComponent,
    InternalAccountModalComponent,
    AccountViewComponent,
    VehicleMakeViewComponent,
    ConfigurationsComponent,
    ConfigurationModalComponent,
    FirmwaresComponent,
    FirmwareModalComponent
  ],
  imports: [
    SharedModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    NgbModule,
    SettingsRoutingModule
  ]
})
export class SettingsModule { }
