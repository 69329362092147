import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AddProduct, Product } from "src/app/core/models/product.models";
import { ProductService } from "src/app/core/services/product.service";
import { SweetalertService } from "src/app/core/services/sweetalert.service";

@Component({
  selector: "app-product-modal",
  templateUrl: "./product-modal.component.html",
  styleUrls: ["./product-modal.component.scss"],
})
export class ProductModalComponent implements OnInit {
  @Input() public product!: Product;
  @Input() public isAddNew!: boolean;
  addProduct!: AddProduct;

  processing = false;
  title = "PAGES.PRODUCTS.LIST.EDIT";

  constructor(
    public modal: NgbActiveModal,
    private productService: ProductService,
    public sweetalertService: SweetalertService
  ) {}

  ngOnInit(): void {
    if (this.product == undefined) {
      this.addProduct = {
        name: "",
        description: "",
        unitPrice: 0,
        taxable: true,
        enabled: false,
      };
      this.title = "PAGES.PRODUCTS.LIST.ADD";
    } else {
      this.addProduct = {
        name: this.product.name,
        description: this.product.description,
        unitPrice: this.product.unitPrice,
        taxable: this.product.taxable,
        enabled: this.product.enabled,
      };
    }
  }

  // Handle Create button click
  onSubmit() {
    // console.log("create clicked");
    this.processing = true;
    if (this.isAddNew) {
      // Add a new organization
      this.productService.addProduct(this.addProduct).subscribe({
        next: (v) => {
          // console.log("added product : ", v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error("error occured : ", e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          // console.info("product saved");
        },
      });
    } else {
      // Update an existing organization
      this.productService
        .updateProduct(this.addProduct, this.product.uuid)
        .subscribe({
          next: (v) => {
            // console.log("updated product : ", v);
            this.sweetalertService.success(v.message).then(() => {
              this.modal.close(v);
            });
          },
          error: (e) => {
            // console.error("error occured : ", e);
            this.sweetalertService.failure(e).then(() => {
              this.processing = false;
            });
          },
          complete: () => {
            // console.info("product saved");
          },
        });
    }
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
