import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppUtilities } from 'src/app/app-utilities';
import { GenericFilter } from '../models/generic-filter.models';
import { Observable } from 'rxjs';
import { AddCreditCardResponse, Order, PagedOrders, ValidateOrderResponse } from '../models/order.models';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';
import { Analytics } from '../models/dashboard.models';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getBillingManagementUrl();
  }

  getOrders(params: GenericFilter): Observable<PagedOrders> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedOrders>(`${this.backendApiUrl}/orders?${queryString}`, this.httpOptions).pipe(
      tap((pagedOrder: PagedOrders) => {
        this.log(`Retrieved orders`);
      })
    );
  }

  /**
     * Validate order
     */
  validateOrder(params: any): Observable<ValidateOrderResponse> {
    console.log('request : ', params);
    // let apiUrl = this.appUtilities.getLocalBMApiUrl();

    return this.http.post<ValidateOrderResponse>(`${this.backendApiUrl}/orders/validate-order`, params, this.httpOptions).pipe(
      tap((res: ValidateOrderResponse) => {
        this.log(`Validated order`);
      })
    );
  }

  /**
     * Finalize order
     */
  finalizeOrder(params: any): Observable<GenericResponse> {
    console.log('request : ', params);
    // let apiUrl = this.appUtilities.getLocalBMApiUrl();

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/orders/finalize-order`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Finalize Order response`);
      })
    );
  }

  /**
     * Verify order
     */
  verifyOrder(params: any): Observable<Order> {
    console.log('request : ', params);
    // let apiUrl = this.appUtilities.getLocalBMApiUrl();

    return this.http.post<Order>(`${this.backendApiUrl}/orders/verify-new-creditcard-order`, params, this.httpOptions).pipe(
      tap((res: Order) => {
        this.log(`Verify Order response`);
      })
    );
  }

  /**
     * Initiate Add Credit Card
     */
  initiateAddCreditCard(params: any): Observable<AddCreditCardResponse> {
    console.log('request : ', params);

    return this.http.post<AddCreditCardResponse>(`${this.backendApiUrl}/payment-methods/initiate-add-credit-card`, params, this.httpOptions).pipe(
      tap((res: AddCreditCardResponse) => {
        this.log(`Add Credit Card response`);
      })
    );
  }

  /**
     * Finalize Add Credit Card
     */
  finalizeAddCreditCard(params: any): Observable<GenericResponse> {
    console.log('request : ', params);

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/payment-methods/finalizw-add-credit-card`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Finalize Add Credit Card response`);
      })
    );
  }

  /**
     * Get all time orders count
     */
  getAllTimeOrdersCount(): Observable<Analytics> {
    return this.http.get<Analytics>(`${this.backendApiUrl}/orders/all-time-orders`, this.httpOptions).pipe(
      tap((pagedUser: Analytics) => {
        this.log(`Retrieved all time orders count`);
      })
    );
  }

  /**
     * Get total revenue
     */
  getTotalRevenue(): Observable<Analytics> {
    return this.http.get<Analytics>(`${this.backendApiUrl}/orders/total-revenue`, this.httpOptions).pipe(
      tap((pagedUser: Analytics) => {
        this.log(`Retrieved total revenue`);
      })
    );
  }

  private log(message: string) {
    console.log(`OrderService: ${message}`);
  }
}
