import { MapsAPILoader } from '@agm/core';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; 
import { Organization } from 'src/app/core/models/organization.models';
import { Site } from 'src/app/core/models/site.models';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { SiteService } from 'src/app/core/services/site.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-site-view',
  templateUrl: './site-view.component.html',
  styleUrls: ['./site-view.component.scss']
})
export class SiteViewComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  siteId!: string;

  longitude = 0;
  latitude = 0;
  markers: any;
  zoom: number = 15;
  address = '';
  iconUrl = '';
  totalConnectorCount = 0;
  availableConnectorCount = 0;
  chargerCount = 0;
  connectorCount = 0;

  @ViewChild('streetviewMap', { static: true }) streetviewMap: any;
  @ViewChild('streetviewPano', { static: true }) streetviewPano: any;

  @Input() heading: number = 34;
  @Input() pitch: number = 10;
  @Input() scrollwheel: boolean = false;
  @Input() site!: Site;
  organization!: Organization;

  constructor(
    private route: ActivatedRoute, private siteService: SiteService, private organizationService: OrganizationService,
    @Inject(PLATFORM_ID) private platformId: Object, private mapsAPILoader: MapsAPILoader,
    public modalService: NgbModal, public sweetalertService: SweetalertService
  ) { }

  ngOnInit(): void {
    this.siteId = String(this.route.snapshot.paramMap.get('siteId'));

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Charger Management' },
      { label: 'Sites' },
      { label: 'View', active: true }
    ];

    if (this.site == undefined) { 
      this.fetchData();
    } else {
      this.getAddress();
    }
  }

  fetchData(): void {
    this.siteService.getSite(this.siteId).subscribe({
      next: (v) => {
        console.log('returned site : ', v);
        this.site = v;
        this.getAddress();
        this.fetchOrganization(this.site.siteOwnerUuid);
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('site retrieved');
      }
    })
  }

  getAddress() {
    if (this.site.address.addressLine2 == undefined || this.site.address.addressLine2 == '') {
      this.address = `${this.site.address.addressLine1}, ${this.site.address.city}, ${this.site.address.region}, ${this.site.address.postalCode}, ${this.site.address.country}`;
    } else {
      this.address = `${this.site.address.addressLine1}, ${this.site.address.addressLine2}, ${this.site.address.city}, ${this.site.address.region}, ${this.site.address.postalCode}, ${this.site.address.country}`;
    }
    this.latitude = Number(this.site.address.latitude);
    this.longitude = Number(this.site.address.longitude);
    
    this.chargerCount = this.site.chargingStations.length;
    this.site.chargingStations.forEach((chargingStation) => {
      this.connectorCount += chargingStation.connectors.length;
    });
    
    if (isPlatformBrowser(this.platformId)) {
      this.mapsAPILoader.load().then(() => {
        const center = { lat: this.latitude, lng: this.longitude };
      });
    }
  }

  fetchOrganization(siteOwnerUuid: string): void {
    this.organizationService.getOrganization(siteOwnerUuid).subscribe({
      next: (v) => {
        console.log('returned organization : ', v);
        this.organization = v;
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('organization retrieved');
      }
    })
  }

  icon = {
    url: '../../../../../assets/images/pin_green.png',
    scaledSize: {
      width: 36,
      height: 48
    }
  }

}
