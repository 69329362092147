import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppUtilities } from 'src/app/app-utilities';
import { GenericFilter } from '../models/generic-filter.models';
import { tap } from 'rxjs/operators';
import { PagedWalletHistories, WalletHistory } from '../models/wallet-history.models';

@Injectable({
  providedIn: 'root'
})
export class WalletHistoryService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getBillingManagementUrl();
  }

  getWalletHistories(params: GenericFilter, accountUuid: string): Observable<PagedWalletHistories> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    queryString = `${queryString}`;
    return this.http.get<PagedWalletHistories>(`${this.backendApiUrl}/wallets/wallet-histories/${accountUuid}?${queryString}`, this.httpOptions).pipe(
      tap((pagedOrganization: PagedWalletHistories) => {
        this.log(`Retrieved wallet histories`);
      })
    );
  }

  private log(message: string) {
    console.log(`WalletHistoryService: ${message}`);
  }
}
