import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { Permission } from '../models/permission.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getUserManagementUrl();
  }

  /**
     * Get all permissions
     */
  getPermissions(): Observable<Permission[]> {

    return this.http.get<Permission[]>(`${this.backendApiUrl}/permissions`, this.httpOptions).pipe(
      tap((permissions: Permission[]) => {
        this.log(`Retrieved permissions`);
      })
    );
  }

  /**
     * Add permission
     */
  addPermission(params: any): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/permissions`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added permission`);
      })
    );
  }

  /**
     * Update permission
     */
  updatePermission(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/permissions/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated permission`);
      })
    );
  }

  /** Log a PermissionService message */
  private log(message: string) {
    // console.log(`PermissionService: ${message}`);
  }
}
