import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';
import { GenericFilter } from '../models/generic-filter.models';
import { PagedTransaction } from '../models/transaction.models';
import { PagedMeterValue } from '../models/meter-value.models';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getChargingStationManagementUrl();
  }

  /**
     * Get all transactions
     */
  getTransactions(params: GenericFilter): Observable<PagedTransaction> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedTransaction>(`${this.backendApiUrl}/transactions?${queryString}`, this.httpOptions).pipe(
      tap((pagedCharger: PagedTransaction) => {
        this.log(`Retrieved transactions`);
      })
    );
  }

  /**
     * Get all charger transactions
     */
  getChargerTransactions(chargeBoxId: string, params: GenericFilter): Observable<PagedTransaction> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedTransaction>(`${this.backendApiUrl}/transactions/charging-station/${chargeBoxId}?${queryString}`, this.httpOptions).pipe(
      tap((pagedCharger: PagedTransaction) => {
        this.log(`Retrieved charger ${chargeBoxId} transactions`);
      })
    );
  }

  /**
     * Get all charger meter values
     */
  getMeterValues(chargeBoxId: string, transactionUuid: string, params: GenericFilter): Observable<PagedMeterValue> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedMeterValue>(`${this.backendApiUrl}/transactions/meter-values/${transactionUuid}?${queryString}`, this.httpOptions).pipe(
      tap((pagedMeterValue: PagedMeterValue) => {
        this.log(`Retrieved charger ${chargeBoxId} meter values for transaction ${transactionUuid}`);
      })
    );
  }


  /** Log a ChargerService message */
  private log(message: string) {
    console.log(`TransactionService: ${message}`);
  }
}
