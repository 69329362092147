import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Country } from 'src/app/core/models/country.models';
import { AddUser, User } from 'src/app/core/models/user.models';
import { UserService } from 'src/app/core/services/user.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';
import { Role } from 'src/app/core/models/role.models';
import { Organization } from 'src/app/core/models/organization.models';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {

  @Input() public user!: User;
  @Input() public isAddNew!: boolean;
  @Input() public countries!: Country[];
  @Input() public roles!: Role[];
  @Input() public organizations!: Organization[];
  addUser!: AddUser;
  userTypes = [
    { label: "Back Office", value: "BACK_OFFICE"},
    { label: "Non Back Office", value: "NON_BACK_OFFICE"}
  ]


  entryForm!: UntypedFormGroup;
  processing = false;
  title = "Edit User";

  constructor(
    public modal: NgbActiveModal, 
    private userService: UserService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    // filter out customer role
    this.roles = this.roles.filter(role => role.name !== 'CUSTOMER');

    if (this.user == undefined) {
      this.addUser = {
        callingCode: "+1",
        email: "",
        firstName: "",
        lastName: "",
        organizationUuid: "",
        phoneNumber: "",
        roleNames: [],
        userType: "BACK_OFFICE",
        uuid: "",
        enabled: false
      };
      this.title = "Add User";
    } else {
      this.addUser = {
        callingCode: this.user.callingCode,
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        organizationUuid: this.user.organization != null ? this.user.organization.uuid : "",
        phoneNumber: this.user.phoneNumber,
        roleNames: this.user.roleNames,
        userType: this.user.userType,
        uuid: this.user.uuid,
        enabled: this.user.enabled
      };
    }
  }
  
  // Handle Create button click
  onSubmit() {
    console.log('create clicked');
    this.processing = true;
    if (this.isAddNew) {
      // Add a new user
      this.userService.addUser(this.addUser).subscribe({
        next: (v) => {
          console.log('added user : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          console.info('users saved');
        }
      });
    } else {
      // Update an existing user
      this.userService.updateUser(this.addUser, this.user.uuid).subscribe({
        next: (v) => {
          console.log('updated user : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });  
        },
        complete: () => {
          console.info('users saved');
        }
      });
    }
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }

}
