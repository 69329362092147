import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddInternalAccount } from 'src/app/core/models/internal-account.models';
import { InternalAccountService } from 'src/app/core/services/internal-account.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-internal-account-modal',
  templateUrl: './internal-account-modal.component.html',
  styleUrls: ['./internal-account-modal.component.scss']
})
export class InternalAccountModalComponent implements OnInit {

  @Input() public internalAccount!: AddInternalAccount;
  @Input() public isAddNew!: boolean;

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "PAGES.INTERNALACCOUNTS.LIST.EDIT";

  constructor(
    public modal: NgbActiveModal, 
    private internalAccountService: InternalAccountService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    if (this.internalAccount == undefined) {
      this.internalAccount = {
        name: "",
        description: "",
        currency: "CAD",
        uuid: "",
        enabled: false
      };

      this.title = "PAGES.ORGANIZATIONTYPES.LIST.ADD";
    }
  }

  
  // Handle Create button click
  onSubmit() {
    // console.log('create clicked');
    this.processing = true;
    if (this.isAddNew) {
      // Add a new internalaccount
      this.internalAccountService.addInternalAccount(this.internalAccount).subscribe({
        next: (v) => {
          // console.log('added internal account : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          // console.info('internal account saved');
        }
      });
    } else {
      // Update an existing internalaccount
      this.internalAccountService.updateInternalAccount(this.internalAccount, this.internalAccount.uuid).subscribe({
        next: (v) => {
          // console.log('updated internal account : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });  
        },
        complete: () => {
          // console.info('internal account saved');
        }
      });
    }
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
