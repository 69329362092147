import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { SmsTemplate } from '../models/sms-template.models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';

@Injectable({
  providedIn: 'root'
})
export class SmsTemplateService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getUserManagementUrl();
  }

  /**
     * Get all sms templates
     */
  getSmsTemplates(): Observable<SmsTemplate[]> {

    return this.http.get<SmsTemplate[]>(`${this.backendApiUrl}/sms-templates`, this.httpOptions).pipe(
      tap((smsTemplates: SmsTemplate[]) => {
        this.log(`Retrieved smsTemplates`);
      })
    );
  }

  /**
     * Add smsTemplate
     */
  addSmsTemplate(params: any): Observable<GenericResponse> {

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/sms-templates`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added smsTemplates`);
      })
    );
  }

  /**
     * Update smsTemplate
     */
  updateSmsTemplate(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/sms-templates/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated smsTemplates`);
      })
    );
  }

  /** Log a SmsTemplateService message */
  private log(message: string) {
    // console.log(`SmsTemplateService: ${message}`);
  }
}
