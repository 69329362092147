import { DecimalPipe, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; 
import { InternalAccount } from 'src/app/core/models/internal-account.models';
import { InternalAccountService } from 'src/app/core/services/internal-account.service';
import { WalletHistoryService } from 'src/app/core/services/wallet-history.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { PagedWalletHistories, WalletHistory } from 'src/app/core/models/wallet-history.models';

@Component({
  selector: 'app-account-view',
  templateUrl: './account-view.component.html',
  styleUrls: ['./account-view.component.scss'],
  providers: [DecimalPipe]
})
export class AccountViewComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  uuid!: string;

  @Input() internalAccount!: InternalAccount;

  searchTerm!: string;
  searchTermChanged: Subject<string> = new Subject<string>();
  inputSub: Subscription | undefined;
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  genericFilter: GenericFilter = new GenericFilter('', 0, 10, 'id', 'desc');
  walletHistory!: WalletHistory;
  walletHistories!: WalletHistory[];
  pagedWalletHistories!: PagedWalletHistories;
  empty = true;

  constructor(
    private route: ActivatedRoute, private internalAccountService: InternalAccountService,
    private walletHistoryService: WalletHistoryService,
    public modalService: NgbModal, public sweetalertService: SweetalertService
  ) { }

  ngOnInit(): void {
    this.uuid = String(this.route.snapshot.paramMap.get('uuid'));

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Settings' },
      { label: 'Internal Accounts' },
      { label: 'Internal Accounts Details', active: true }
    ];

    if (this.internalAccount == undefined) { 
      this.fetchData();
    } 

    this.fetchWalletHistoryData();

    this.inputSub = this.searchTermChanged.pipe(
      debounceTime(500),
      delay(200)
    ).subscribe(value => {
        console.log(value)
        this.genericFilter.searchParam = value;
        this.fetchWalletHistoryData();
      });
  }

  fetchData(): void {
    this.internalAccountService.getInternalAccount(this.uuid).subscribe({
      next: (v) => {
        console.log('returned internal account : ', v);
        this.internalAccount = v;
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('internal account retrieved');
      }
    })
  }

  fetchWalletHistoryData() {
    this.genericFilter.size = this.pageSize;
    this.genericFilter.page = this.page -1;
    this.walletHistoryService.getWalletHistories(this.genericFilter, this.uuid).subscribe({
      next: (v) => {
        console.log('returned wallet histories : ', v);
        this.pagedWalletHistories = v;
        this.collectionSize = this.pagedWalletHistories.totalElements;
        if (this.pagedWalletHistories.content.length > 0) {
          this.walletHistories = this.pagedWalletHistories.content;
          this.empty = false;
        }
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('wallet histories retrieved');
      }
    });
  }

  search(value: string): void {
    this.searchTermChanged.next(value);
  }

  onSizeChange(size: number) {
    this.genericFilter.size = size;
    this.pageSize = size;
    this.page = 1;
    this.fetchWalletHistoryData();
  }

  pageChanged(page: any) {
    console.log('page change: ', page);
    this.genericFilter.page = page - 1;
    this.fetchWalletHistoryData();
  }

}
