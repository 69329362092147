import { Component, Input, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { CancelReservation, Charger, StopTransaction } from 'src/app/core/models/charger.models';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { PagedReservation, Reservation } from 'src/app/core/models/reservation.models';
import { ChargerService } from 'src/app/core/services/charger.service';
import { ReservationService } from 'src/app/core/services/reservation.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-charger-reservations',
  templateUrl: './charger-reservations.component.html',
  styleUrls: ['./charger-reservations.component.scss']
})
export class ChargerReservationsComponent implements OnInit {

  @Input() charger!: Charger;

  searchTerm!: string;
  searchTermChanged: Subject<string> = new Subject<string>();
  inputSub: Subscription | undefined;
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  genericFilter: GenericFilter = new GenericFilter('', 0, 10, 'id', 'desc');
  reservation!: Reservation;
  reservations!: Reservation[];
  pagedReservations!: PagedReservation;
  empty = true;

  constructor(
    private reservationService: ReservationService, 
    private chargerService: ChargerService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    this.fetchData();

    this.inputSub = this.searchTermChanged.pipe(
      debounceTime(500),
      delay(200)
    ).subscribe(value => {
        console.log(value)
        this.genericFilter.searchParam = value;
        this.fetchData();
      });
  }

  fetchData() {
    this.genericFilter.size = this.pageSize;
    this.genericFilter.page = this.page -1;
    this.reservationService.getChargerReservations(this.charger.chargingStationIdentifier, this.genericFilter).subscribe({
      next: (v) => {
        console.log('returned charger reservations : ', v);
        this.pagedReservations = v;
        this.collectionSize = this.pagedReservations.totalElements;
        if (this.pagedReservations.content.length > 0) {
          this.reservations = this.pagedReservations.content;
          this.empty = false;
        }
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('charger reservations retrieved');
      }
    });
  }

  search(value: string): void {
    this.searchTermChanged.next(value);
  }

  onSizeChange(size: number) {
    this.genericFilter.size = size;
    this.pageSize = size;
    this.page = 1;
    this.fetchData();
  }

  pageChanged(page: any) {
    console.log('page change: ', page);
    this.genericFilter.page = page - 1;
    this.fetchData();
  }

  cancelReservation(reservation: Reservation) {
    this.sweetalertService.confirm('Cancel Reservation?', `Are you sure you want to cancel reservation #${reservation.id}?`).then((result) => {
      console.log('result: ', result);
      if (result.isConfirmed) {
        let cancelReservation: CancelReservation = {
          chargingStationSelectList: [
            {
              chargingStationIdentifier: this.charger.chargingStationIdentifier,
              endpointAddress: 'CancelReservation',
              ocppTransport: "JSON"
            }
          ],
          reservationId: reservation.id
        }

        this.chargerService.cancelReservation(cancelReservation).subscribe({
          next: (v) => {
            console.log('cancel reservation request submitted : ', v);
            this.sweetalertService.success(v.message).then(() => {
              
            });
          },
          error: (e) => {
            console.error('error occured : ', e);
            this.sweetalertService.failure(e).then(() => {
            });
          },
          complete: () => {
            console.info('cancel reservation completed');
          }
        });
      }
    });
  }
}
