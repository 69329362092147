import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.GENERAL.TEXT',
        isTitle: true,
        permissions: []
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARD.TEXT',
        icon: 'bx bxs-dashboard',
        link: '/',
        permissions: ['ROLE_CAN_VIEW_DASHBOARD']
    },
    {
        id: 3,
        label: 'MENUITEMS.SETTINGS.TEXT',
        icon: 'bx bxs-cog',
        permissions: ['ROLE_BACKOFFICE'],
        subItems: [
            {
                id: 4,
                label: 'MENUITEMS.SETTINGS.LIST.PERMISSIONS',
                link: '/settings/permissions',
                permissions: ['ROLE_CAN_VIEW_ROLE'],
                parentId: 3
            },
            {
                id: 5,
                label: 'MENUITEMS.SETTINGS.LIST.ROLES',
                link: '/settings/roles',
                permissions: ['ROLE_CAN_VIEW_ROLE'],
                parentId: 3
            },
            {
                id: 6,
                label: 'MENUITEMS.SETTINGS.LIST.VEHICLEMAKES',
                link: '/settings/vehicle-makes',
                permissions: ['ROLE_CAN_VIEW_ROLE'],
                parentId: 3
            },
            {
                id: 7,
                label: 'MENUITEMS.SETTINGS.LIST.ORGANIZATIONTYPES',
                link: '/settings/organization-types',
                permissions: ['ROLE_CAN_VIEW_ORGANIZATION'],
                parentId: 3
            },
            {
                id: 8,
                label: 'MENUITEMS.SETTINGS.LIST.COUNTRIES',
                link: '/settings/countries',
                permissions: [],
                parentId: 3
            },
            {
                id: 9,
                label: 'MENUITEMS.SETTINGS.LIST.INTERNALACCOUNTS',
                link: '/settings/internal-accounts',
                permissions: ['ROLE_CAN_VIEW_INTERNAL_ACCOUNT'],
                parentId: 3
            },
            ,
            {
                id: 10,
                label: 'MENUITEMS.SETTINGS.LIST.CONFIGURATIONS',
                link: '/settings/configurations',
                permissions: ['ROLE_CAN_VIEW_ROLE'],
                parentId: 3
            },
            ,
            {
                id: 9,
                label: 'MENUITEMS.SETTINGS.LIST.FIRMWARES',
                link: '/settings/firmwares',
                permissions: ['ROLE_CAN_VIEW_CHARGER'],
                parentId: 3
            },
        ]
    },
    {
        id: 10,
        label: 'MENUITEMS.ACCOUNTMANAGEMENT.TEXT',
        isTitle: true,
        permissions: ['ROLE_CAN_VIEW_BACKOFFICE_USER', 'ROLE_CAN_VIEW_ORGANIZATION']
    },
    {
        id: 11,
        label: 'MENUITEMS.ORGANIZATIONS.TEXT',
        icon: 'bx bxs-briefcase',
        link: '/account-management/organizations',
        permissions: ['ROLE_CAN_VIEW_ORGANIZATION']
    },
    {
        id: 12,
        label: 'MENUITEMS.USERS.TEXT',
        icon: 'mdi mdi-account-group',
        link: '/account-management/users',
        permissions: ['ROLE_CAN_VIEW_BACKOFFICE_USER']
    },
    {
        id: 13,
        label: 'MENUITEMS.BILLINGMANAGEMENT.TEXT',
        isTitle: true,
        permissions: ['ROLE_CAN_VIEW_ALL_TRANSACTIONS']
    },
    {
        id: 14,
        label: 'MENUITEMS.ORDERS.TEXT',
        icon: 'bx bxs-dollar-circle',
        link: '/billing-management/orders',
        permissions: ['ROLE_CAN_VIEW_ALL_TRANSACTIONS']
    },
    {
        id: 15,
        label: 'MENUITEMS.REFUNDS.TEXT',
        icon: 'bx bx-dollar-circle',
        link: '/billing-management/refunds',
        permissions: ['ROLE_CAN_VIEW_REFUND']
    },
    {
        id: 16,
        label: 'MENUITEMS.PRODUCTS.TEXT',
        icon: 'bx bxs-purchase-tag',
        link: '/billing-management/products',
        permissions: ['ROLE_CAN_VIEW_PRODUCT']
    },
    {
        id: 17,
        label: 'MENUITEMS.WALLETS.TEXT',
        icon: 'bx bxs-wallet',
        link: '/billing-management/wallets',
        permissions: ['ROLE_CAN_VIEW_WALLET']
    },
    {
        id: 18,
        label: 'MENUITEMS.CHARGERMANAGEMENT.TEXT',
        isTitle: true,
        permissions: ['ROLE_CAN_VIEW_CHARGER', 'ROLE_CAN_PERFORM_CHARGER_OPERATIONS']
    },
    {
        id: 19,
        label: 'MENUITEMS.SITES.TEXT',
        icon: 'bx bx-sitemap',
        link: '/charger-management/sites',
        permissions: ['ROLE_CAN_VIEW_CHARGER']
    },
    {
        id: 20,
        label: 'MENUITEMS.CHARGERS.TEXT',
        icon: 'bx bxs-plug',
        link: '/charger-management/chargers',
        permissions: ['ROLE_CAN_VIEW_CHARGER']
    },
    {
        id: 21,
        label: 'MENUITEMS.CHARGINGPROFILE.TEXT',
        icon: 'bx bx-brightness',
        link: '/charger-management/charging-profiles',
        permissions: ['ROLE_CAN_PERFORM_CHARGER_OPERATIONS']
    },
    {
        id: 22,
        label: 'MENUITEMS.MEMBERSHIPCARDS.TEXT',
        icon: 'bx bxs-id-card',
        link: '/charger-management/membership-cards-stickers',
        permissions: ['ROLE_CAN_PERFORM_CHARGER_OPERATIONS']
    },
    {
        id: 23,
        label: 'MENUITEMS.NOTIFICATIONMANAGEMENT.TEXT',
        isTitle: true,
        permissions: ['ROLE_CAN_VIEW_TEMPLATE']
    },
    {
        id: 24,
        label: 'MENUITEMS.EMAILTEMPLATES.TEXT', 
        icon: 'bx bxs-envelope',
        link: '/notification-management/email-templates',
        permissions: ['ROLE_CAN_VIEW_TEMPLATE']
    },
    {
        id: 25,
        label: 'MENUITEMS.TEMPLATES.TEXT', 
        icon: 'bx bxs-bell-plus',
        link: '/notification-management/notification-templates',
        permissions: ['ROLE_CAN_VIEW_TEMPLATE']
    },
    {
        id: 26,
        label: 'MENUITEMS.PUSHNOTIFICATIONS.TEXT', 
        icon: 'bx bxs-bell-ring',
        link: '/notification-management/push-notifications',
        permissions: ['ROLE_CAN_VIEW_TEMPLATE']
    },
    {
        id: 27,
        label: 'MENUITEMS.SMSTEMPLATES.TEXT', 
        icon: 'bx bxs-comment-add',
        link: '/notification-management/sms-templates',
        permissions: ['ROLE_CAN_VIEW_TEMPLATE']
    },
    {
        id: 28,
        label: 'MENUITEMS.SMSNOTIFICATIONS.TEXT', 
        icon: 'bx bxs-chat',
        link: '/notification-management/sms-notifications',
        permissions: ['ROLE_CAN_VIEW_TEMPLATE']
    },
    {
        id: 1000,
        label: 'MENUITEMS.SPACER.TEXT',
        isTitle: true
    },
];

