<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title | translate}}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="chargingStationIdentifier" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.STATION' | translate }}</label>
            <input type="text" class="form-control" id="chargingStationIdentifier" name="chargingStationIdentifier" 
                required readonly [(ngModel)]="newAction.chargingStationIdentifier" #chargingStationIdentifier="ngModel">
            <span style="color:red" [hidden]="chargingStationIdentifier.valid || chargingStationIdentifier.pristine"> 
                <p>{{ 'PAGES.CHARGERS.LIST.STATIONREQUIRED' | translate }}</p> 
            </span>
        </div>
        <div class="form-group mb-3" *ngIf="isFromList">
            <label for="connectorId" class="fw-semibold">{{ 'PAGES.COMMON.LIST.CONNECTOR' | translate }}</label>
            <input type="number" class="form-control" id="connectorId" name="connectorId" 
                required [(ngModel)]="newAction.connectorId" #connectorId="ngModel" min="1">
            <span style="color:red" [hidden]="connectorId.valid || connectorId.pristine"> 
                <p>{{ 'PAGES.COMMON.LIST.CONNECTORREQUIRED' | translate }}</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="enabled" class="fw-semibold">{{ 'PAGES.CHARGERS.LIST.AVAILABILITYTYPE' | translate }}</label>
            <select class="form-select" id="availType" name="availType" [(ngModel)]="newAction.availType" #availType="ngModel">
                <option value="Inoperative">{{ 'PAGES.CHARGERS.LIST.INOPERATIVE' | translate }}</option>
                <option value="Operative">{{ 'PAGES.CHARGERS.LIST.OPERATIVE' | translate }}</option>
            </select>
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">{{ 'PAGES.COMMON.LIST.CANCEL' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">{{ 'PAGES.COMMON.LIST.SUBMIT' | translate }}</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>