<!-- start page title -->
<app-pagetitle [title]="title | translate" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-md-6 col-xl-3">
        <app-total-revenue-card></app-total-revenue-card>
    </div> <!-- end col-->
    
    <div class="col-md-6 col-xl-3">
        <app-total-revenue-card></app-total-revenue-card>
    </div> <!-- end col-->

    <div class="col-md-6 col-xl-3">
        <app-active-users-card></app-active-users-card>
    </div> <!-- end col-->

    <div class="col-md-6 col-xl-3">
        <app-all-time-orders-card></app-all-time-orders-card>
    </div> <!-- end col-->
</div> <!-- end row-->

<div class="row">
    <div class="col-xl-8">
        <app-sales-analytics-card></app-sales-analytics-card>
    </div> <!-- end col-->

    <div class="col-xl-4">
        <app-check-issues-card></app-check-issues-card>

        <app-earning-reports-card></app-earning-reports-card>
    </div>
</div>
<!-- end row -->

<div class="row">
    <div class="=col-xl-12">
        <app-map-card></app-map-card>
    </div>
</div>

<div class="row">
    <div class="col-xl-6">
        <app-latest-transactions-card></app-latest-transactions-card>
    </div>

    <div class="col-xl-6">
        <app-latest-orders-card></app-latest-orders-card>
    </div>
</div>
<!-- end row -->

<div class="row">
    <div class="col-lg-12">
        <app-recent-users-card></app-recent-users-card>
    </div>
</div>
<!-- end row -->