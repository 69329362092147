import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddSmsTemplate } from 'src/app/core/models/sms-template.models';
import { SmsTemplateService } from 'src/app/core/services/sms-template.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-sms-template-modal',
  templateUrl: './sms-template-modal.component.html',
  styleUrls: ['./sms-template-modal.component.scss']
})
export class SmsTemplateModalComponent implements OnInit {

  @Input() public smsTemplate!: AddSmsTemplate;
  @Input() public isAddNew!: boolean;

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "PAGES.SMSTEMPLATES.LIST.EDIT";

  constructor(
    public modal: NgbActiveModal, 
    private smsTemplateService: SmsTemplateService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    if (this.smsTemplate == undefined) {
      this.smsTemplate = {
        notificationType: "",
        englishMessage: "",
        frenchMessage: "",
        uuid: "",
        enabled: false
      };

      this.title = "PAGES.SMSTEMPLATES.LIST.ADD";
    }
  }

  
  // Handle Create button click
  onSubmit() {
    // console.log('create clicked');
    this.processing = true;
    if (this.isAddNew) {
      // Add a new sms template
      this.smsTemplateService.addSmsTemplate(this.smsTemplate).subscribe({
        next: (v) => {
          // console.log('added notification template : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          // console.info('notification template saved');
        }
      });
    } else {
      // Update an existing notification template
      this.smsTemplateService.updateSmsTemplate(this.smsTemplate, this.smsTemplate.uuid).subscribe({
        next: (v) => {
          // console.log('updated notification template : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });  
        },
        complete: () => {
          // console.info('notification template saved');
        }
      });
    }
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
