<div class="table-responsive mb-0">
    <table class="table align-middle table-striped table-nowrap mb-0">
        <tbody>
            <tr [hidden]="!empty">
                <td>{{ 'PAGES.COMMON.LIST.NODATAAVAILABLE' | translate }}</td>
            </tr>
            <tr *ngFor="let data of transactions">
                <td>
                    {{data.idTag}}
                </td>
                <td>{{data.chargeBoxId}}</td>
                <td>{{data.connector.id}}</td>
                <td><i class="mdi mdi-checkbox-blank-circle text-success" 
                    [ngClass]=" { 
                        'text-warning':data.stopTimestamp === null 
                    }"></i> {{data.stopTimestamp === null ? 'In progress': 'Completed'}}
                </td>
                <td>{{data.startTimestamp | date:"yyyy:MM:dd hh:mm"}}</td>
                <td>
                    <a [routerLink]="['/charger-management/chargers', data.chargeBoxId]" class="btn btn-info btn-sm waves-effect" title="View">
                      <i class="mdi mdi-credit-card-search-outline me-1"> {{ 'PAGES.COMMON.LIST.VIEW' | translate }}</i>
                    </a>
                  </td>
            </tr>
        </tbody>
    </table>
</div>
<!-- end table -->