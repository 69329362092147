import { DecimalPipe } from '@angular/common';
import { Component, OnInit, PipeTransform } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Permission } from 'src/app/core/models/permission.models';
import { PermissionService } from 'src/app/core/services/permission.service';
import { PermissionModalComponent } from './permission-modal/permission-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models';

/**
 * Table Data Match with Search input
 * @param  Permission field value fetch
 * @param term Search the value
 */
function matches(table: Permission, term: string, pipe: PipeTransform) {
  return pipe.transform(table.id).includes(term) || table.name.toLowerCase().includes(term.toLowerCase())
      || table.description.toLowerCase().includes(term)
      || table.uuid.toLowerCase().includes(term)
      || table.createdAt.toLowerCase().includes(term)
      || table.updatedAt.toLowerCase().includes(term);
}

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
  providers: [DecimalPipe]
})
export class PermissionsComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  title!: string;
  searchTerm!: string; 
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  permission!: Permission;
  permissions!: Permission[];
  allPermissions!: Permission[];

  constructor(private permissionService: PermissionService, private pipe: DecimalPipe, public modalService: NgbModal) { }

  ngOnInit(): void {
    this.title = 'PAGES.PERMISSIONS.TEXT';

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'MENUITEMS.SETTINGS.TEXT' },
      { label: 'PAGES.PERMISSIONS.TEXT', active: true }
    ];

    this.fetchData();
  }

  fetchData() {
    this.permissionService.getPermissions().subscribe({
      next: (v) => {
        // console.log('returned permissions : ', v);
        this.collectionSize = v.length;
        this.permissions = v;
        this.allPermissions = this.permissions;
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('permissions retrieved');
      }
    });
  }

  search(value: string): void {
    this.permissions = this.allPermissions.filter(table => matches(table, value, this.pipe));

    // this.permissions = this.allPermissions.filter((val) => val.name.toLowerCase().includes(value));
    this.collectionSize = this.permissions.length;
  }

  openModal(permission: any, isAddNew: boolean) {
    const modalRef = this.modalService.open(PermissionModalComponent);
    modalRef.componentInstance.permission = permission;
    modalRef.componentInstance.isAddNew = isAddNew;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        // console.log('Form: ', 'Cancel');
      });
  }

  //refresh page
  refreshPage(): void {
    window.location.reload();
  }
}
