<div *ngIf="selectedImage" class="my-3">
    <div class="selected-image-container">
        <img [src]="appUtilities.arrayBufferToBase64(selectedImage.data)" alt="Selected Image" class="site-image">
    </div>
</div>

<div class="d-flex">
    <div *ngFor="let image of images" class="position-relative me-2" (mouseenter)="image.hovered = true"
        (mouseleave)="image.hovered = false">
        <img [src]="appUtilities.arrayBufferToBase64(image.data)" alt="Image" class="img-thumbnail"
            (click)="selectImage(image)" [ngClass]="{'selected': image === selectedImage}">
        <button *ngIf="image.hovered || image === selectedImage"
            class="btn btn-danger btn-sm position-absolute top-0 end-0 m-1" (click)="onDelete(image.uuid)">✕</button>
    </div>

    <div *ngIf="images.length < maxImages"
        class="d-flex align-items-center justify-content-center mx-2 border border-secondary rounded"
        style="width: 100px; height: 100px; cursor: pointer;" (click)="fileInput.click()">
        <span>+</span>
        <input type="file" #fileInput class="d-none" (change)="onUpload($event)">
    </div>
</div>