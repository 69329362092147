import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddRole } from 'src/app/core/models/role.models';
import { RoleService } from 'src/app/core/services/role.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-role-modal',
  templateUrl: './role-modal.component.html',
  styleUrls: ['./role-modal.component.scss']
})
export class RoleModalComponent implements OnInit {

  @Input() public role!: AddRole;
  @Input() public isAddNew!: boolean;

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "PAGES.ROLES.LIST.EDITROLE";

  constructor(
    public modal: NgbActiveModal, 
    private roleService: RoleService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    if (this.role == undefined) {
      this.role = {
        name: "",
        description: "",
        uuid: "",
        roleType: "",
        enabled: false
      };

      this.title = "PAGES.ROLES.LIST.ADDROLE";
    }
  }

  
  // Handle Create button click
  onSubmit() {
    // console.log('create clicked');
    this.processing = true;
    if (this.isAddNew) {
      // Add a new role
      this.roleService.addRole(this.role).subscribe({
        next: (v) => {
          // console.log('added role : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          // console.info('role saved');
        }
      });
    } else {
      // Update an existing role
      this.roleService.updateRole(this.role, this.role.uuid).subscribe({
        next: (v) => {
          // console.log('updated role : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });  
        },
        complete: () => {
          // console.info('role saved');
        }
      });
    }
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
