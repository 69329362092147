import { MapsAPILoader } from '@agm/core';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; 
import { Charger, ClearCache, GetLocalListVersion } from 'src/app/core/models/charger.models';
import { ChargerService } from 'src/app/core/services/charger.service';
import { ResetModalComponent } from '../reset-modal/reset-modal.component';
import { ModalMessage } from 'src/app/core/models/modal-message.models';
import { StartTransactionModalComponent } from '../start-transaction-modal/start-transaction-modal.component';
import { TriggerMessageModalComponent } from '../trigger-message-modal/trigger-message-modal.component';
import { ChangeAvailabilityModalComponent } from '../change-availability-modal/change-availability-modal.component';
import { TransactionService } from 'src/app/core/services/transaction.service';
import { ReserveNowModalComponent } from '../reserve-now-modal/reserve-now-modal.component';
import { CancelReservationModalComponent } from '../cancel-reservation-modal/cancel-reservation-modal.component';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';
import { UnlockConnectorModalComponent } from '../unlock-connector-modal/unlock-connector-modal.component';
import { ChangeConfigurationModalComponent } from '../change-configuration-modal/change-configuration-modal.component';
import { GetCompositeScheduleModalComponent } from '../get-composite-schedule-modal/get-composite-schedule-modal.component';
import { GetConfigurationModalComponent } from '../get-configuration-modal/get-configuration-modal.component';
import { GetDiagnosticsModalComponent } from '../get-diagnostics-modal/get-diagnostics-modal.component';
import { SendLocalListModalComponent } from '../send-local-list-modal/send-local-list-modal.component';
import { UpdateFirmwareModalComponent } from '../update-firmware-modal/update-firmware-modal.component';
import { ClearChargingProfileModalComponent } from '../clear-charging-profile-modal/clear-charging-profile-modal.component';
import { SetChargingProfileModalComponent } from '../set-charging-profile-modal/set-charging-profile-modal.component';

@Component({
  selector: 'app-charger-view',
  templateUrl: './charger-view.component.html',
  styleUrls: ['./charger-view.component.scss']
})
export class ChargerViewComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  chargerId!: string;

  longitude = 0;
  latitude = 0;
  markers: any;
  zoom: number = 15;
  address = '';
  iconUrl = '';
  totalConnectorCount = 0;
  availableConnectorCount = 0;

  @ViewChild('streetviewMap', { static: true }) streetviewMap: any;
  @ViewChild('streetviewPano', { static: true }) streetviewPano: any;

  @Input() heading: number = 34;
  @Input() pitch: number = 10;
  @Input() scrollwheel: boolean = false;
  @Input() charger?: Charger;

  constructor(
    private route: ActivatedRoute, private chargerService: ChargerService, private transactionService: TransactionService,
    @Inject(PLATFORM_ID) private platformId: Object, private mapsAPILoader: MapsAPILoader,
    public modalService: NgbModal, public sweetalertService: SweetalertService
  ) { }

  ngOnInit(): void {
    this.chargerId = String(this.route.snapshot.paramMap.get('chargerId'));

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Charger Management' },
      { label: 'Chargers' },
      { label: this.chargerId, active: true }
    ];

    if (this.charger == undefined) { 
      this.fetchData();
    } else {
      this.getAddress();
    }
  }

  fetchData(): void {
    this.chargerService.getCharger(this.chargerId).subscribe({
      next: (v) => {
        console.log('returned charger : ', v);
        this.charger = v;
        this.getAddress();
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('charger retrieved');
      }
    })
  }

  getAddress() {
    if (this.charger?.site.address.addressLine2 == undefined || this.charger?.site.address.addressLine2 == '') {
      this.address = `${this.charger?.site.address.addressLine1}, ${this.charger?.site.address.city}, ${this.charger?.site.address.region}, ${this.charger?.site.address.postalCode}, ${this.charger?.site.address.country}`;
    } else {
      this.address = `${this.charger?.site.address.addressLine1}, ${this.charger?.site.address.addressLine2}, ${this.charger?.site.address.city}, ${this.charger?.site.address.region}, ${this.charger?.site.address.postalCode}, ${this.charger?.site.address.country}`;
    }
    this.latitude = Number(this.charger?.site.address.latitude);
    this.longitude = Number(this.charger?.site.address.longitude);
    
    this.totalConnectorCount = this.charger?.connectors.length ?? 0;
    this.charger?.connectors.forEach((connector) => {
      if (connector.chargePointStatus === 'Available') {
        this.availableConnectorCount += 1;
      }
    });
    
    if (isPlatformBrowser(this.platformId)) {
      this.mapsAPILoader.load().then(() => {
        const center = { lat: this.latitude, lng: this.longitude };
      });
    }
  }

  icon = {
    url: '../../../../../assets/images/pin_green.png',
    scaledSize: {
      width: 36,
      height: 48
    }
  }

  cancelReservationModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(CancelReservationModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }
  
  changeAvailabilityModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ChangeAvailabilityModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  changeConfigurationModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ChangeConfigurationModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  clearCache(charger?: Charger) {
    if (!this.charger) {
      this.sweetalertService.failure("Something went wrong");
    }
    else {
      this.sweetalertService.confirm('Clear Cache?', `Are you sure you want to clear the cache of charger ${charger!.chargingStationIdentifier}?`).then((result) => {
        if (result.isConfirmed) {
          let clearCache: ClearCache = {
            chargingStationSelectList: [
              {
                chargingStationIdentifier: this.charger!.chargingStationIdentifier,
                endpointAddress: 'ClearCache',
                ocppTransport: "JSON"
              }
            ]
          }
  
          this.chargerService.clearCache(clearCache).subscribe({
            next: (v) => {
              console.log('clear cache request submitted : ', v);
              this.sweetalertService.success(v.message).then(() => {
                
              });
            },
            error: (e) => {
              console.error('error occurred : ', e);
              this.sweetalertService.failure(e).then(() => {
              });
            },
            complete: () => {
              console.info('clear cache completed');
            }
          });
        }
      });
    }
  }

  clearChargingProfileModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ClearChargingProfileModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  getCompositeScheduleModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(GetCompositeScheduleModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  getConfigurationModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(GetConfigurationModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  getDiagnosticsModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(GetDiagnosticsModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  getLocalListVersionModal(charger: Charger) {
    console.log('charger: ', charger);
    this.sweetalertService.confirm('Get Local List Version?', `Are you sure you want to get the local list version of charger ${charger.chargingStationIdentifier}?`).then((result) => {
      console.log('result: ', result);
      if (result.isConfirmed) {
        let getLocalListVersion: GetLocalListVersion = {
          chargingStationSelectList: [
            {
              chargingStationIdentifier: charger.chargingStationIdentifier,
              endpointAddress: 'ClearCache',
              ocppTransport: "JSON"
            }
          ]
        }

        this.chargerService.getLocalListVersion(getLocalListVersion).subscribe({
          next: (v) => {
            console.log('getLocalListVersion request submitted : ', v);
            this.sweetalertService.success(v.message).then(() => {
              
            });
          },
          error: (e) => {
            console.error('error occurred : ', e);
            this.sweetalertService.failure(e).then(() => {
            });
          },
          complete: () => {
            console.info('getLocalListVersion completed');
          }
        });
      }
    });
  }

  sendLocalListModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(SendLocalListModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }
  
  reserveNowModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ReserveNowModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }
  
  resetModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(ResetModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  setChargingProfileModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(SetChargingProfileModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  startTransactionModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(StartTransactionModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  triggerMessageModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(TriggerMessageModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  unlockConnectorModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(UnlockConnectorModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }

  updateFirmwareModal(charger: any, isFromList: boolean, connectorIdentifier: any) {
    const modalRef = this.modalService.open(UpdateFirmwareModalComponent);
    modalRef.componentInstance.charger = charger;
    modalRef.componentInstance.connectorIdentifier = connectorIdentifier;
    modalRef.componentInstance.isFromList = isFromList;

    // handle result passing back from modal form component
    modalRef.result
      .then((result: ModalMessage) => {
        if (result) {
          this.fetchData();
        }
      })
      .catch(() => {
        // user click outside of the modal form
        console.log('Form: ', 'Cancel');
      });
  }
}
