<div class="modal-header">
    <h5 class="modal-title mt-0">{{ modalTitle }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="name" class="fw-semibold">Recipient Name</label>
                    <input type="text" class="form-control" id="recipientName" name="recipientName" 
                        readonly [(ngModel)]="pushNotification.recipientName" #recipientName="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="recipientUuid" class="fw-semibold">Recipient UUID</label>
                    <input type="text" class="form-control" id="recipientUuid" name="recipientUuid" 
                        readonly [(ngModel)]="pushNotification.recipientUuid" #recipientUuid="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="notificationType" class="fw-semibold">Notification Type</label>
                    <input type="text" class="form-control" id="notificationType" name="notificationType" 
                        readonly [(ngModel)]="pushNotification.notificationType" #notificationType="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="pushType" class="fw-semibold">Push Type</label>
                    <input type="text" class="form-control" id="pushType" name="pushType" 
                        readonly [(ngModel)]="pushNotification.pushType" #pushType="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="title" class="fw-semibold">Title</label>
                    <input type="text" class="form-control" id="title" name="title" 
                        readonly [(ngModel)]="pushNotification.title" #title="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="msgId" class="fw-semibold">Message ID</label>
                    <input type="text" class="form-control" id="msgId" name="msgId" 
                        readonly [(ngModel)]="pushNotification.msgId" #msgId="ngModel">
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="message" class="fw-semibold">Message</label>
                    <textarea id="message" name="message" class="form-control" rows="10" cols="50" 
                        required [(ngModel)]="pushNotification.message" #message="ngModel"></textarea>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label for="data" class="fw-semibold">Data</label>
                    <textarea id="data" name="data" class="form-control" rows="10" cols="50" 
                        required [(ngModel)]="pushNotification.data" #data="ngModel"></textarea>
                </div>
            </div>
        </div>    
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">Cancel</button>
</div>