import { Injectable } from "@angular/core";
import Swal from "sweetalert2";

@Injectable({
    providedIn: "root",
})
export class SweetalertService {
    constructor() {}

    success(message: string, title = 'Awesome!') {
        let timerInterval: any;
        return Swal.fire({
            title: title,
            html: message,
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                    const content = Swal.getHtmlContainer();
                    if (content) {
                        const b: any = content.querySelector("b");
                        if (b) {
                            b.textContent = Swal.getTimerLeft();
                        }
                    }
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        });
    }

    failure(message: string, title = 'Dang!') {
        let timerInterval: any;
        return Swal.fire({
            title: title,
            html: message,
            icon: "warning",
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                    const content = Swal.getHtmlContainer();
                    if (content) {
                        const b: any = content.querySelector("b");
                        if (b) {
                            b.textContent = Swal.getTimerLeft();
                        }
                    }
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        });
    }

    confirm(title: string, message: string, confirmButtonText='Yes', cancelButtonText = 'Cancel') {
        return Swal.fire({
            title: title,
            text: message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText
        }).then((result) => {
            return result;
        });
    }
}
