<div class="modal-header">
    <h5 class="modal-title mt-0">{{ title }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form #entryForm="ngForm">
        <div class="form-group mb-3">
            <label for="chargingStationIdentifier" class="fw-semibold">Station</label>
            <input type="text" class="form-control" id="chargingStationIdentifier" name="chargingStationIdentifier" 
                required readonly [(ngModel)]="newAction.chargingStationIdentifier" #chargingStationIdentifier="ngModel">
            <span style="color:red" [hidden]="chargingStationIdentifier.valid || chargingStationIdentifier.pristine"> 
                <p>Station Identifier is required</p> 
            </span>
        </div>
        <div class="form-group mb-3">
            <label for="confKey" class="fw-semibold">Configuration Key</label>
            <input type="text" class="form-control" id="confKey" name="confKey" 
                [(ngModel)]="newAction.confKey" #confKey="ngModel">
        </div>
        <div class="form-group mb-3">
            <label for="customConfKey" class="fw-semibold">Custom Configuration Key</label>
            <input type="text" class="form-control" id="customConfKey" name="customConfKey" 
                [(ngModel)]="newAction.customConfKey" #customConfKey="ngModel">
        </div>
        <div class="form-group mb-3">
            <label for="keyType" class="fw-semibold">Key Type</label>
            <select class="form-select" id="keyType" name="keyType" [(ngModel)]="newAction.keyType" #keyType="ngModel">
                <option value="CUSTOM">Custom</option>
                <option value="PREDEFINED">Predefined</option>
            </select>
        </div>
        <div class="form-group mb-3">
            <label for="value" class="fw-semibold">Value</label>
            <input type="text" class="form-control" id="value" name="value" 
                required [(ngModel)]="newAction.value" #value="ngModel">
            <span style="color:red" [hidden]="value.valid || value.pristine"> 
                <p>Value is required</p> 
            </span>
        </div>
    </form>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-light w-md" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="processing"
        (click)="onSubmit()" [disabled]="!entryForm.form.valid">Submit</button>
    <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div></button>
</div>