import { Component, OnInit } from '@angular/core';
import { emailSentBarChart } from '../dashboards/data';
import { ChartType } from '../dashboards/dashboard.model';

@Component({
  selector: 'app-sales-analytics-card',
  templateUrl: './sales-analytics-card.component.html',
  styleUrls: ['./sales-analytics-card.component.scss']
})
export class SalesAnalyticsCardComponent implements OnInit {

  emailSentBarChart!: ChartType;

  constructor() { }

  ngOnInit(): void {
    this.fetchData();
  }

  private fetchData() {
    this.emailSentBarChart = emailSentBarChart;
  }
}
