import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEmailTemplate } from 'src/app/core/models/email-template.models';
import { KeyValue } from 'src/app/core/models/key-value.models';
import { EmailTemplateService } from 'src/app/core/services/email-template.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-email-template-modal',
  templateUrl: './email-template-modal.component.html',
  styleUrls: ['./email-template-modal.component.scss']
})
export class EmailTemplateModalComponent implements OnInit {
  @Input() public emailTemplate!: AddEmailTemplate;
  @Input() public isAddNew!: boolean;
  @Input() public notificationTypes!: KeyValue[];

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "Edit Email Template";

  editorConfig = {
    base_url: '/tinymce',
    suffix: '.min',
    plugins: 'lists link image table wordcount code',
    extended_valid_elements: "span[th:text]"
  };
  
  constructor(
    public modal: NgbActiveModal, 
    private emailTemplateService: EmailTemplateService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    if (this.emailTemplate == undefined) {
      this.emailTemplate = {
        notificationType: "",
        subject: "",
        message: "",
        uuid: "",
        enabled: false
      };

      this.title = "Add Email Template";
    }
  }

  // Handle Create button click
  onSubmit() {
    console.log('create clicked');
    this.processing = true;
    if (this.isAddNew) {
      // Add a new email template
      this.emailTemplateService.addEmailTemplate(this.emailTemplate).subscribe({
        next: (v) => {
          console.log('added email template : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          console.info('email template saved');
        }
      });
    } else {
      // Update an existing email template
      this.emailTemplateService.updateEmailTemplate(this.emailTemplate, this.emailTemplate.uuid).subscribe({
        next: (v) => {
          console.log('updated email template : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });  
        },
        complete: () => {
          console.info('email template saved');
        }
      });
    }
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
