import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddPermission } from 'src/app/core/models/permission.models';
import { PermissionService } from 'src/app/core/services/permission.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-permission-modal',
  templateUrl: './permission-modal.component.html',
  styleUrls: ['./permission-modal.component.scss']
})
export class PermissionModalComponent implements OnInit {

  @Input() public permission!: AddPermission;
  @Input() public isAddNew!: boolean;

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "PAGES.PERMISSIONS.LIST.EDITPERMISSION";

  constructor(
    public modal: NgbActiveModal, 
    private permissionService: PermissionService,
    public sweetalertService: SweetalertService) { }

  ngOnInit(): void {
    if (this.permission == undefined) {
      this.permission = {
        name: "",
        description: "",
        uuid: "",
        enabled: false
      };

      this.title = "PAGES.PERMISSIONS.LIST.ADDPERMISSION";
    }
  }

  
  // Handle Create button click
  onSubmit() {
    // console.log('create clicked');
    this.processing = true;
    if (this.isAddNew) {
      // Add a new permission
      this.permissionService.addPermission(this.permission).subscribe({
        next: (v) => {
          // console.log('added permission : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });
        },
        complete: () => {
          // console.info('permissions saved');
        }
      });
    } else {
      // Update an existing permission
      this.permissionService.updatePermission(this.permission, this.permission.uuid).subscribe({
        next: (v) => {
          // console.log('updated permission : ', v);
          this.sweetalertService.success(v.message).then(() => {
            this.modal.close(v);
          });
        },
        error: (e) => {
          // console.error('error occured : ', e);
          this.sweetalertService.failure(e).then(() => {
            this.processing = false;
          });  
        },
        complete: () => {
          // console.info('permissions saved');
        }
      });
    }
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }
}
