import { Order } from "./order.models";

export interface Refund {
  id: number;
  reference: string;
  txnReference: string;
  reason: string;
  amount: number;
  currency: string;
  paymentStatus: string;
  customerUuid: string;
  customerName: string;
  result: string;
  resultMessage: string;
  order: Order;
  uuid: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  deleted: boolean;
  deletedAt: string;
}

export interface PagedRefund {
  content: Refund[];
  first: boolean;
  last: boolean;
  empty: boolean;
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
  size: number;
  number: number;
}

export interface InitiateRefund {
  amount: number;
  reason: string;
  reference: string;
  txnReference: string;
}