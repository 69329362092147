import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { User } from '../models/authentication.models';
import { AppUtilities } from 'src/app/app-utilities';
import { GenericResponse } from '../models/generic-response.models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private appUtilities: AppUtilities) {
    this.backendApiUrl = appUtilities.getUserManagementUrl();
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')!));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  /**
     * current user
     */
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
  login(email: string, password: string) {
    let params = { emailOrPhone: email, password: password}

    return this.http.post<any>(`${this.backendApiUrl}/auth/signin`, params, this.httpOptions)

        .pipe(map(res => {
            // login successful if there's a jwt token in the response
            let user: User = <User>res;
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
            }
            return user;
        }));
  }

  /**
     * Performs the confirmation
     * @param params
     */
  confirmation(params: any) {

    return this.http.post<any>(`${this.backendApiUrl}/auth/backoffice-confirmation`, params, this.httpOptions)

        .pipe(
          tap((res: GenericResponse) => {
            this.log(`Account confirmation successful`);
          })
        );
  }

  /**
     * Performs password reset for non-logged in user (forgot password)
     * @param params
     */
  forgotPassword(params: any) {

    return this.http.post<any>(`${this.backendApiUrl}/auth/forgot-password`, params, this.httpOptions)

        .pipe(
          tap((res: GenericResponse) => {
            this.log(`Password reset initiatialization successful`);
          })
        );
  }

  /**
     * Performs save reset password for non-logged in user (forgot password)
     * @param params
     */
  saveResetPassword(params: any) {

    return this.http.post<any>(`${this.backendApiUrl}/auth/reset-password`, params, this.httpOptions)

        .pipe(
          tap((res: GenericResponse) => {
            this.log(`Password saved successfully`);
          })
        );
  }

  
  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
  */
  signIn(email: string, password: string): Observable<User> {
    const params = { emailOrPhone: email, password: password}
    return this.http.post<any>(`${this.backendApiUrl}/auth/signin`, params, this.httpOptions).pipe(
      tap((loggedInUser: User) => this.log(`Logged in user is : ${loggedInUser.email}`)),
      catchError(this.handleError<User>('signIn'))
    );
  }

  /**
     * Logout the user
     */
  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null!);
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log(`AuthenticationService: ${message}`);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
