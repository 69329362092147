import { Injectable } from "@angular/core";
import { GenericFilter } from "../models/generic-filter.models";
import { Observable } from "rxjs";
import { PagedProduct } from "../models/product.models";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { AppUtilities } from "src/app/app-utilities";
import { GenericResponse } from "../models/generic-response.models";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  backendApiUrl = "";
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  constructor(private http: HttpClient, private appUtilities: AppUtilities) {
    this.backendApiUrl = appUtilities.getBillingManagementUrl();
  }

  getProducts(params: GenericFilter): Observable<PagedProduct> {
    let queryString = Object.keys(params)
      .map((key) => key + "=" + params[key as keyof GenericFilter])
      .join("&");
    return this.http
      .get<PagedProduct>(
        `${this.backendApiUrl}/products?${queryString}`,
        this.httpOptions
      )
      .pipe(
        tap((pagedProduct: PagedProduct) => {
          this.log(`Retrieved product`);
        })
      );
  }

  addProduct(params: any): Observable<GenericResponse> {
    // console.log('request : ', params);

    return this.http.post<GenericResponse>(`${this.backendApiUrl}/products`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Added product`);
      })
    );
  }

  updateProduct(params: any, uuid: string): Observable<GenericResponse> {

    return this.http.put<GenericResponse>(`${this.backendApiUrl}/products/${uuid}`, params, this.httpOptions).pipe(
      tap((res: GenericResponse) => {
        this.log(`Updated product`);
      })
    );
  }

  private log(message: string) {
    // console.log(`ProductService: ${message}`);
  }
}
