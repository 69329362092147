<!-- start page title -->
<app-pagetitle title="Test Make Payment" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Testing Make Payment</h4>
                <p class="card-title-desc">This page merely test the Credit card payment integration and 
                    should not be deployed to the LIVE server!</p>

                <form #entryForm="ngForm">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="customerName" name="customerName" placeholder="Customer Name" required
                                    [(ngModel)]="validateOrder.customerName" #customerName="ngModel">
                                <label for="customerName">Customer Name</label>
                                <span style="color:red" [hidden]="customerName.valid || customerName.pristine"> 
                                    <p>Customer name is required</p> 
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="customerUuid" name="customerUuid" placeholder="Customer UUID" required
                                    [(ngModel)]="validateOrder.customerUuid" #customerUuid="ngModel">
                                <label for="customerUuid">Customer UUID</label>
                                <span style="color:red" [hidden]="customerUuid.valid || customerUuid.pristine"> 
                                    <p>Customer UUID is required</p> 
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- end row -->
    
                    <hr class="mt-4 mb-4"/>

                    <div class="row" *ngFor="let orderItem of validateOrder.orderItems; index as i">
                        
                        <h5 class="font-size-14 mb-3">Order Item #{{ i + 1 }}</h5>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" [name]="'description_' + i" placeholder="Description" required
                                        [(ngModel)]="orderItem.description" #description="ngModel">
                                    <label for="description">Description</label>
                                    <span style="color:red" [hidden]="description.valid || description.pristine"> 
                                        <p>Description is required</p> 
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-floating">
                                    <select class="form-select" [name]="'feeItem_' + i" aria-label="Floating label Fee Item" [(ngModel)]="orderItem.feeItem" #feeItem="ngModel">
                                        <option value="MEMBERSHIP_CARD">Membership Card</option>
                                        <option value="NEW_DECAL">New Decal</option>
                                        <option value="REPLACEMENT_DECAL">Replacement Decal</option>
                                        <option value="TOP_UP">Top Up</option>
                                    </select>
                                    <label for="feeItem">Fee Item</label>
                                    <span style="color:red" [hidden]="feeItem.valid || feeItem.pristine"> 
                                        <p>Fee Item is required</p> 
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- end row -->
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-floating mb-3">
                                    <input type="number" class="form-control" [name]="'name_' + i" placeholder="Unit Cost" required min="0"
                                        [(ngModel)]="orderItem.unitCost" #unitCost="ngModel">
                                    <label for="unitCost">Unit Cost</label>
                                    <span style="color:red" [hidden]="unitCost.valid || unitCost.pristine"> 
                                        <p>Unit Cost is required</p> 
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-floating mb-3">
                                    <input type="number" class="form-control" [name]="'unitsAcquired_' + i" placeholder="Units Acquired" required min="0"
                                        [(ngModel)]="orderItem.unitsAcquired" #unitsAcquired="ngModel">
                                    <label for="unitsAcquired">Units Acquired</label>
                                    <span style="color:red" [hidden]="unitsAcquired.valid || unitsAcquired.pristine"> 
                                        <p>Units Acquired is required</p> 
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- end row -->
                    </div>

                    <button type="button" class="btn btn-outline-primary btn-rounded mt-3 mb-4" (click)="addMore()">Add more</button>
    
                    <div>
                        <button type="button" class="btn btn-primary w-md" [hidden]="processing"(click)="onSubmit()" [disabled]="!entryForm.form.valid">Save</button>
                        <button type="button" class="btn btn-primary w-md" [hidden]="!processing" disabled>
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>