import { DecimalPipe } from '@angular/common';
import { Component, OnInit, PipeTransform, QueryList, ViewChildren } from '@angular/core';
import { Country } from 'src/app/core/models/country.models';
import { CountryService } from 'src/app/core/services/country.service';

/**
 * Table Data Match with Search input
 * @param  Country field value fetch
 * @param term Search the value
 */
function matches(table: Country, term: string, pipe: PipeTransform) {
  return pipe.transform(table.id).includes(term) || table.name.toLowerCase().includes(term.toLowerCase())
      || table.official_name_es.toLowerCase().includes(term)
      || table.official_name_fr.toLowerCase().includes(term)
      || table.country_code.toLowerCase().includes(term)
      || table.currency_code.toLowerCase().includes(term)
      || table.currency_name.toLowerCase().includes(term)
      || table.currency_numeric_code.toLowerCase().includes(term)
      || table.uuid.toLowerCase().includes(term)
      || table.createdAt.toLowerCase().includes(term)
      || table.updatedAt.toLowerCase().includes(term);
}

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss'],
  providers: [DecimalPipe]
})
export class CountriesComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  searchTerm!: string;
  page = 1;
  pageSize = 10;
  collectionSize!: number;
  currentRate = 8;
  countries!: Country[];
  allCountries!: Country[];

  constructor(private countryService: CountryService, private pipe: DecimalPipe) { }

  ngOnInit(): void {
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Settings' },
      { label: 'Countries', active: true }
    ];

    this.countryService.getCountries().subscribe({
      next: (v) => {
        console.log('returned countries : ', v);
        this.collectionSize = v.length;
        this.countries = v;
        this.allCountries = this.countries;
      },
      error: (e) => console.error(e),
      complete: () => {
        console.info('countries retrieved');
      }
    });
  }

  search(value: string): void {
    this.countries = this.allCountries.filter(table => matches(table, value, this.pipe));
    this.collectionSize = this.countries.length;
  }
}
