import { Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, GoogleMap, MapAdvancedMarker } from '@angular/google-maps';
import { TranslateService } from '@ngx-translate/core';
import { AppUtilities } from 'src/app/app-utilities';
import { Address } from 'src/app/core/models/address.models';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { Site, PagedSite } from 'src/app/core/models/site.models';
import { ScriptsService } from 'src/app/core/services/scripts.service';
import { SiteService } from 'src/app/core/services/site.service';

@Component({
  selector: 'app-map-card',
  templateUrl: './map-card.component.html',
  styleUrl: './map-card.component.scss'
})
export class MapCardComponent implements OnInit {

  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;

  apiLoaded = false;
  markers: Array<any> = [];
  
  longitude = -123.01;
  latitude = 49.23;
  zoom: number = 11;

  page = 1;
  pageSize = 1000;
  collectionSize!: number;
  genericFilter: GenericFilter = new GenericFilter('', 0, 1000, 'id', 'asc');
  sites!: Site[];
  pagedSites!: PagedSite;
  siteURL = "/charger-management/sites/";

  selectedMarker: any = null; // Store selected marker data for InfoWindow

  options: google.maps.MapOptions = {
    center: { lat: -123.01, lng: 49.23 },
    zoom: 11,
  };

  constructor(
    private siteService: SiteService, 
    private scriptsService: ScriptsService,
    private appUtilities: AppUtilities,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    this.genericFilter.size = this.pageSize;
    this.genericFilter.page = this.page - 1;
    this.siteService.getSites(this.genericFilter).subscribe({
      next: (v) => {
        this.pagedSites = v;
        this.collectionSize = this.pagedSites.totalElements;
        if (this.pagedSites.content.length > 0) {
          this.sites = this.pagedSites.content;
        } 

        this.scriptsService.load('GoogleMap').then(() => {
          this.apiLoaded = true;
          this.options.center = { lat: this.latitude, lng: this.longitude };
          this.options.mapId = this.appUtilities.appConfig.googleMapId;

          if (this.sites.length > 0) {
            // console.log(this.sites.length + " sites retrieved");
            this.markers = this.sites.map(site => {
              let imgTag = document.createElement("img"); // Create the image element
              imgTag.src = site.enabled 
                ? '../../../../../assets/images/pin_green.png' 
                : '../../../../../assets/images/pin_gray.png';
              imgTag.width = 36; 
              imgTag.height = 48;
              imgTag.style.cursor = "pointer"; // Make it clickable
            
              // Return the marker with content as the img element
              return {
                position: { 
                  lat: Number(site.address.latitude), 
                  lng: Number(site.address.longitude) 
                },
                content: imgTag, // Set the imgTag as content
                title: site.name,
                address: site.address.addressLine1,
                numChargers: site.chargingStations.length,
                link: `${this.siteURL}${site.uuid}`,
              };
            });
          }
        });
      },
      error: (e) => console.error(e),
      complete: () => {
        // console.info('Sites retrieved');
      }
    });
  }

  openInfoWindow(marker: any) {
    this.selectedMarker = marker;
    this.infoWindow.open();
  }

  findSiteByName(siteName: string): Site | undefined {
    return this.sites.find(site => site.name.toLowerCase() === siteName.toLowerCase());
  }

  onMarkerClick(marker: MapAdvancedMarker) {
    const site = this.findSiteByName(marker.advancedMarker.title);
    const { totalConnectors, availableConnectors } = this.appUtilities.getConnectorCount(site);
    const addressLabel = this.translate.instant('PAGES.COMMON.LIST.ADDRESS');
    const totalChargersLabel = this.translate.instant('PAGES.COMMON.LIST.TOTALCHARGERS');
    const connectorsLabel = this.translate.instant('PAGES.COMMON.LIST.CONNECTORS');
    const viewLabel = this.translate.instant('PAGES.COMMON.LIST.VIEWDETAILS');
    const contentString = `
    <div id="content">
      <h4 class="firstHeading">${site?.name}</h4>
      <div id="bodyContent">
        <p><b>${addressLabel}:</b> ${this.appUtilities.getAddressString(site?.address ?? {} as Address)} <br>
        <b>${totalChargersLabel}:</b> ${site?.chargingStations.length} <br>
        <b>${connectorsLabel}:</b> ${totalConnectors} (${availableConnectors} of ${totalConnectors} available).
        </p>
        <p><a href="${this.siteURL}${site?.uuid}">${viewLabel}</a></p>
      </div>
    </div>`;

    this.infoWindow.openAdvancedMarkerElement(marker.advancedMarker, contentString);
  }

}
