import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Charger, SetChargingProfile } from 'src/app/core/models/charger.models';
import { ChargerService } from 'src/app/core/services/charger.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';
import { ChargingProfileService  } from 'src/app/core/services/charging-profile.service';
import { GenericFilter } from 'src/app/core/models/generic-filter.models';
import { ChargingProfile, PagedChargingProfile } from 'src/app/core/models/charging-profile.models';

@Component({
  selector: 'app-set-charging-profile-modal',
  templateUrl: './set-charging-profile-modal.component.html',
  styleUrls: ['./set-charging-profile-modal.component.scss']
})
export class SetChargingProfileModalComponent implements OnInit {

  @Input() public charger!: Charger;
  @Input() public isFromList!: boolean;
  @Input() public connectorIdentifier!: number;

  entryForm!: UntypedFormGroup;
  processing = false;
  title = "PAGES.CHARGERS.LIST.SETCHARGINGPROFILE";
  chargingProfiles!: ChargingProfile[];
  pagedChargingProfiles!: PagedChargingProfile;
  profilesEmpty = true;

  constructor(
    public modal: NgbActiveModal, 
    private chargerService: ChargerService,
    private chargingProfileService: ChargingProfileService,
    public sweetalertService: SweetalertService) { }
    public newAction!: {
      chargingStationIdentifier: string, connectorId: number, chargingProfileUuid: string
    }

  ngOnInit(): void {
    this.fetchChargingProfileData();
    
    let connectorId = 0;
    if (!this.isFromList) {
      connectorId = this.connectorIdentifier;
    }
    this.newAction = {
      chargingStationIdentifier: this.charger.chargingStationIdentifier,
      connectorId: connectorId,
      chargingProfileUuid: ''
    }
  }

  
  // Handle Create button click
  onSubmit() {
    let setChargingProfile: SetChargingProfile = {
      chargingStationSelectList: [
        {
          chargingStationIdentifier: this.newAction.chargingStationIdentifier,
          endpointAddress: 'SetChargingProfile',
          ocppTransport: "JSON"
        }
      ],
      connectorId: this.newAction.connectorId,
      chargingProfileUuid: this.newAction.chargingProfileUuid
    }

    this.processing = true;
    this.chargerService.setChargingProfile(setChargingProfile).subscribe({
      next: (v) => {
        // console.log('setChargingProfile request submitted : ', v);
        this.sweetalertService.success(v.message).then(() => {
          this.modal.close(v);
        });
      },
      error: (e) => {
        // console.error('error occured : ', e);
        this.sweetalertService.failure(e).then(() => {
          this.processing = false;
        });
      },
      complete: () => {
        // console.info('setChargingProfile completed');
      }
    });
  }

  // Handle Delete button click
  onCancel() {
    this.modal.close();
  }

  fetchChargingProfileData() {
    let genericFilter: GenericFilter = {};
    genericFilter.size = 1000;
    genericFilter.page = 0;
    this.chargingProfileService.getChargingProfiles(genericFilter).subscribe({
      next: (v) => {
        // console.log('returned chargingProfiles : ', v);
        this.pagedChargingProfiles = v;
        if (this.pagedChargingProfiles.content.length > 0) {
          this.chargingProfiles = this.pagedChargingProfiles.content;
          this.profilesEmpty = false;
        }
      },
      error: (e) => {
        // console.error(e);
      },
      complete: () => {
        // console.info('chargingProfiles retrieved');
      }
    });
  }
}
