<!-- start page title -->
<app-pagetitle title="Charger Meter Values" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div>
        <div class="row">
            <div class="col-md-6 col-xl-4">
                <div class="card card-height-100">
                    <div class="card-body">
                        <div class="d-flex flex-wrap align-items-center">
                            <div class="avatar-sm">
                                <span class="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                                    <i class="bx bxs-bolt-circle text-primary"></i>
                                </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h5 class="font-size-15 mb-0 text-truncate">{{ charger.chargingStationIdentifier}}</h5>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-2 pt-1">
                            <div *ngIf="charger.enabled">
                                <div class="mt-1 text-center">
                                    <div>
                                        <div class="d-flex flex-row justify-content-between align-items-center ">
                                            <div class="d-flex p-2">
                                                <i class="mdi mdi-circle font-size-10 mt-1 text-success"></i>
                                                <div class="flex-grow-1 ms-2">
                                                    <p class="mb-0">Status </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="mb-0 text-success fw-semibold font-size-15"><i
                                        class="fas fa-check-circle align-middle font-size-18"></i> Enabled</p>
                            </div>
                            <div *ngIf="!charger.enabled">
                                <div class="mt-1 text-center">
                                    <div>
                                        <div class="d-flex flex-row justify-content-between align-items-center ">
                                            <div class="d-flex p-2">
                                                <i class="mdi mdi-circle font-size-10 mt-1 text-warning"></i>
                                                <div class="flex-grow-1 ms-2">
                                                    <p class="mb-0">Status </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="mb-0 text-warning fw-semibold font-size-15"><i
                                        class="fas fa-times align-middle font-size-18"></i> Disabled</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-4">
                <div class="card card-height-100">
                    <div class="card-body">
                        <div class="d-flex flex-wrap align-items-center">
                            <div class="avatar-sm">
                                <span class="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                                    <i class="bx bxs-plug text-primary"></i>
                                </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h5 class="font-size-15 mb-0 text-truncate">Connector</h5>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-3 pt-1">
                            <div>
                                <h4 class="font-size-20"># {{ connectorIdentifier }}</h4>
                                <p class="mb-0 text-success fw-semibold font-size-15">Connector Identifier</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-4">
                <div class="card card-height-100">
                    <div class="card-body">
                        <div class="d-flex flex-wrap align-items-center">
                            <div class="avatar-sm">
                                <span class="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                                    <i class="fas fa-battery-three-quarters text-primary"></i>
                                </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h5 class="font-size-15 mb-0 text-truncate">Transaction <span class="text-muted"></span>
                                </h5>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-3 pt-1">
                            <div>
                                <h4 class="font-size-20"># {{ transaction.id }}</h4>
                                <p class="mb-0 text-success fw-semibold font-size-15">0.0000 kWhs consumed</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex mb-4">
                    <h4 class="card-title flex-grow-1">List of Meter Values</h4>
                </div>

                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6">
                        <div class="dataTables_length" id="tickets-table_length"><label
                                class="d-inline-flex align-items-center">Show
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="pageSize" class="form-control form-control-sm mx-2"
                                    (change)="onSizeChange(pageSize)">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select> entries</label></div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <input type="text" name="searchTerm" id="searchTerm"
                                    class="form-control form-control-sm ms-2" aria-controls="tickets-table"
                                    [(ngModel)]="searchTerm" (input)="search(searchTerm)">
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>

                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable"
                        class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th>Connector</th>
                                <th>Measurerand</th>
                                <th>Value</th>
                                <th>Unit</th>
                                <th>Context</th>
                                <th>Format</th>
                                <th>Location</th>
                                <th>Phase</th>
                                <th>Last Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [hidden]="!empty">
                                <td>No data available in table</td>
                            </tr>
                            <tr *ngFor="let meterValue of meterValues" [hidden]="pagedMeterValues.empty">
                                <td>
                                    {{ meterValue.connector.connectorIdentifier }}
                                </td>
                                <td>
                                    <ngb-highlight [result]="meterValue.measurand" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="meterValue.value" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="meterValue.unit" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="meterValue.context" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="meterValue.format" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="meterValue.location" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="meterValue.phase" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="charger.updatedAt" [term]="searchTerm">
                                    </ngb-highlight>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- End table -->
                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            Showing
                            <!-- {{permissionsDatatableService.startIndex}} to
                            {{permissionsDatatableService.endIndex}} of {{permissionsDatatableService.totalRecords}} -->
                            entries
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-right float-md-end pagination-rounded">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [maxSize]="5"
                                [boundaryLinks]="true" [pageSize]="pageSize" (pageChange)="pageChanged($event)"
                                class="d-flex justify-content-center">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- End Pagination -->
                </div>
            </div>
        </div>
    </div>
</div>