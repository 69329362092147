import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from 'src/app/app-utilities';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response.models';
import { GenericFilter } from '../models/generic-filter.models';
import { PagedMessageLog } from '../models/message-log.models';

@Injectable({
  providedIn: 'root'
})
export class MessageLogService {

  backendApiUrl = '';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private appUtilities: AppUtilities) { 
    this.backendApiUrl = appUtilities.getChargingStationManagementUrl();
  }

  /**
     * Get all message logs
     */
  getMessageLogs(params: GenericFilter): Observable<PagedMessageLog> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedMessageLog>(`${this.backendApiUrl}/logs?${queryString}`, this.httpOptions).pipe(
      tap((pagedCharger: PagedMessageLog) => {
        this.log(`Retrieved message logs`);
      })
    );
  }

  /**
     * Get all charger message logs
     */
  getChargerMessageLogs(chargeBoxId: string, params: GenericFilter): Observable<PagedMessageLog> {
    let queryString = Object.keys(params).map(key => key + '=' + params[key as keyof GenericFilter]).join('&'); 
    return this.http.get<PagedMessageLog>(`${this.backendApiUrl}/logs/charging-station/${chargeBoxId}?${queryString}`, this.httpOptions).pipe(
      tap((pagedCharger: PagedMessageLog) => {
        this.log(`Retrieved charger ${chargeBoxId} message logs`);
      })
    );
  }


  /** Log a MessageLogService message */
  private log(message: string) {
    // console.log(`MessageLogService: ${message}`);
  }
}
